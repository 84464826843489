import { createSlice } from '@reduxjs/toolkit';
import { Filter } from '../../../../store/types/Store.hrList.types';

const initialState: Filter = { search: '' };

const hrListsFiltersSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilter: (state, action: { payload: string }) => {
      return { ...state, search: action.payload };
    },
  },
  extraReducers: {},
});

export const actions = { ...hrListsFiltersSlice.actions };
export const { reducer } = hrListsFiltersSlice;
