import styled from 'styled-components';
import { styled as styledMaterialUI } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { COLORS } from '../../../../../theme/colors';

export const StyledEmployeeTable = styled.div`
  align-self: center;
  height: 100%;
  margin: 0 auto 0 auto;
  padding: 0;
  width: 100%;
`;

export const InnerTooltipText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;
