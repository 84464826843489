import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { InviteNewUserForm } from '../../../../User/InviteNewUserForm/InviteNewUserForm.component';
import { userThunks } from '../../../../../containers/User/User.thunk';

export const AddingModalContent = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [currNewUser, setCurrNewUser] = useState({});

  const handleSubmit = async (data) => {
    const result = await dispatch(
      userThunks.sendInviteToCompanyMemberThunk(data),
    );
    if (
      result.type === userThunks.sendInviteToCompanyMemberThunk.fulfilled.type
    ) {
      await dispatch(userThunks.getCompanyMembersThunk());
      closeModal();
    } else {
      setCurrNewUser(data);
    }
  };
  return (
    <InviteNewUserForm
      handleSubmit={handleSubmit}
      closeModal={closeModal}
      currForm={currNewUser}
    />
  );
};
