import styled from 'styled-components';

export const StyledFooter = styled.div`
  bottom: 0;
  position: absolute;
  width: 100%;
`;

export const StyledFooterInnerBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 28px 80px 0px auto;
  min-height: 100px;
  > div {
    font-size: 16px;
  }
`;

export const StyledPageNumber = styled.span`
  font-size: 16px;
  font-weight: 700;
`;
