import { HomeLeadProfile } from '../../../../store/types/Store.home.types';
import { CreatedRowHome } from './CreateRow.types';

export const CreateRow = (leadProfile: HomeLeadProfile): CreatedRowHome => ({
  id: leadProfile.lead_profile_id,
  profile: leadProfile.name,
  company: leadProfile.company.name || 'hidden',
  salesOwner: leadProfile.sales_owner.username,
  companyContactDetails: leadProfile.company.id || null,
  date: leadProfile.created_at,
  priority: leadProfile.priority,
  candidatesNum: leadProfile.no_of_candidates ?? 0,
  hrOwner: leadProfile.hr_owner || 'hidden',
  action: leadProfile,
});
