import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SectionHeader } from '../../../../../components/CV/SectionHeader/SectionHeader.component';
import { SECTION_TYPE } from '../../../CV.config';
import { actions as cvActions } from '../../../CV.slice';
import { actions as educationActions } from '../Education.slice';

export const EducationHeaderContainer = forwardRef(
  (
    {
      element,
      editable,
      sectionWithParent,
      updateSectionHeight,
      handleDrag,
      handleDrop,
      handleDragOver,
      removeSectionRef,
      setTextFormattingPanelVisibility,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const {
      section: { sectionId, height },
    } = sectionWithParent;
    const headerText = useSelector(
      (state) => state.cv.present.data.education.headerText,
    );
    const handleEditHeaderText = (text) => {
      dispatch(educationActions.updateHeaderText(text));
    };

    const handleAddEntry = () => {
      dispatch(
        cvActions.addEntry({
          sectionId,
          sectionType: SECTION_TYPE.EDUCATION_ENTRY,
        }),
      );
    };

    return (
      <SectionHeader
        ref={ref}
        element={element}
        sectionId={sectionId}
        removeSectionRef={removeSectionRef}
        handleDrag={handleDrag}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        headerText={headerText}
        editable={editable}
        editExperienceSectionName={handleEditHeaderText}
        addExperienceEntry={handleAddEntry}
        setTextFormattingPanelVisibility={setTextFormattingPanelVisibility}
        updateSectionHeight={updateSectionHeight}
        height={height}
      />
    );
  },
);
