import { Container } from '@mui/material';
import React, { forwardRef, PropsWithChildren, useEffect } from 'react';
import { WithHeightUpdateProps } from './WithHeightUpdate.types';

export const WithHeightUpdate = (Component: React.ElementType) => {
  return forwardRef<HTMLDivElement, PropsWithChildren<WithHeightUpdateProps>>(
    (
      {
        updateSectionHeight,
        removeSectionRef,
        sectionId,
        element,
        data,
        headerText,
        children,
        height,
        ...props
      },
      ref,
    ) => {
      useEffect(() => {
        if (!element || !element.offsetHeight) {
          return;
        }

        updateSectionHeight(element);
      }, [data, headerText, element]);

      useEffect(() => {
        if (!removeSectionRef) {
          return;
        }

        return () => {
          removeSectionRef(sectionId);
        };
      }, []);

      return (
        <Container>
          <Component
            ref={ref}
            data={data}
            headerText={headerText}
            element={element}
            {...props}
          >
            {children}
          </Component>
        </Container>
      );
    },
  );
};
