import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledChip = styled(Chip)({
  color: 'black',
  height: '18px',
  fontSize: '13px',
  lineHeight: '15px',
  textTransform: 'capitalize',
  padding: '15px 10px',

  '.MuiChip-label': {
    paddingLeft: '7px',
    paddingRight: '3px',
    fontFamily: '"Neue Haas Grotesk Display Pro", sans-serif',
  },
});
