import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteModal } from '../../DeleteModal';
import { UpdateCooperationStatusModalContentProps } from './UpdateCooperationStatusModalContent.types';

export const UpdateCooperationStatusModalContent = React.forwardRef<
  HTMLDivElement,
  UpdateCooperationStatusModalContentProps
>(({ company, handleSubmit, closeModal }, ref) => {
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.partnerCompanies',
  });
  const buttonsConfig = {
    primaryButton: {
      label: 'Yes',
      action: () => handleSubmit(company.id),
    },
  };

  const dialogText = `${t('confirmation_message')} ${
    company.status ? t('stop') : t('renew')
  } ${t('cooperation_with')} ${company.name}?`;

  return (
    <DeleteModal
      dialogTitle=""
      message={dialogText}
      buttonsConfig={buttonsConfig}
      handleClose={closeModal}
      ref={ref}
    />
  );
});
