import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userThunks } from '../../../../../containers/User/User.thunk';
import { employeeThunks } from '../../../../../containers/Employee/Employees.thunk';
import { EmployeeIdType } from '../../../../../store/types/Store.candidates.types';
import {
  EmployeeModalHookOutput,
  EmployeeModalType,
} from './EmployeesModal.types';
import { AppDispatch } from '../../../../../store/store';
import { mainTechnologiesTagsThunks } from '../../../../../containers/Leads/redux/tags/MainTechnologiesTags.thunk';

export const useEmployeeModal = (
  modalsToDisplay: (EmployeeModalType | 'none')[],
): Partial<EmployeeModalHookOutput> => {
  const [employeeId, setEmployeeId] = useState<EmployeeIdType | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [displayedModal, setDisplayedModal] = useState<
    EmployeeModalType | 'none'
  >('none');

  useEffect(() => {
    dispatch(employeeThunks.getSuggestedSourcesThunk());
    dispatch(userThunks.getHRUsersThunk());
    dispatch(mainTechnologiesTagsThunks.getMainTechnologiesThunk());
  }, []);

  const handleSetDisplayedModal = (
    modalType: EmployeeModalType | 'none',
  ): void => {
    if (modalsToDisplay.includes(modalType)) {
      return setDisplayedModal(modalType);
    }
  };

  return {
    setEmployeeId,
    displayedModal,
    setDisplayedModal: handleSetDisplayedModal,
    modalData: {
      employeeId,
      modalType: displayedModal,
      onModalClose: () => setDisplayedModal('none'),
    },
  };
};
