import { Route } from 'react-router-dom';
import React from 'react';
import { ProfileInfo } from '../containers/User/MyProfile/ProfileInfo/ProfileInfo.component';
import { MyVacancies } from '../containers/User/MyProfile/MyVacancies/MyVacancies';
import { MyCandidates } from '../containers/User/MyProfile/MyCandidates/MyCandidates.component';
import { MyProjects } from '../containers/User/MyProfile/MyProjects/MyProjects.component';
import { MyCompany } from '../containers/User/MyProfile/MyCompany/MyCompany.component';
import { Watched } from '../containers/User/MyProfile/Watched/Watched.component';
import { Ratings } from '../containers/User/MyProfile/Ratings/Ratings.component';
import { AccountsSettings } from '../containers/User/MyProfile/AccountsSettings/AccountsSettings.component';
import { Payments } from '../containers/User/MyProfile/Payments/Payments.component';
import { GeneralPayments } from '../containers/User/MyProfile/GeneralPayments/GeneralPayments.component';
import { MyProfilePaths } from './MyProfilePaths';
import { PartnerCompanies } from '../containers/User/MyProfile/PartnerCompanies/PartnerCompanies.component';

const MyProfilePageRoutes = [
  <Route index element={<MyVacancies />} />,
  <Route path={MyProfilePaths.myCandidates} element={<MyCandidates />} />,
  <Route path={MyProfilePaths.myProjects} element={<MyProjects />} />,
  <Route path={MyProfilePaths.watched} element={<Watched />} />,
  // <Route path={MyProfilePaths.ratings} element={<Ratings />} />,
  <Route path={MyProfilePaths.companyProfile} element={<MyCompany />} />,
  <Route
    path={MyProfilePaths.accountsSettings}
    element={<AccountsSettings />}
  />,
  <Route
    path={MyProfilePaths.partnerCompanies}
    element={<PartnerCompanies />}
  />,
  <Route path={MyProfilePaths.payments} element={<Payments />} />,
  <Route path={MyProfilePaths.generalPayments} element={<GeneralPayments />} />,
];

export default MyProfilePageRoutes;
