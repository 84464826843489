import { styled as materialUIStyled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import styled from 'styled-components';

export const StyledTableHead = materialUIStyled(TableHead)({
  backgroundColor: '#f4f5f7',
  '@media (max-width: 699px)': {
    display: 'none',
  },
});

export const StyledTableCell = materialUIStyled(TableCell)({
  fontSize: '10px',
  fontWeight: '400',
  fontFamily: '"Poppins", sans-serif',
  '&:nth-of-type(6)': {
    paddingLeft: '0',
  },
});

export const StyledSpan = styled.span`
  color: #9fa3b3;
  font-family: 'Poppins', sans-serif;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
`;
