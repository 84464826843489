import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import {
  CompanyItem,
  CompanySizeInterface,
  EditCompanyFormType,
} from '../../../../store/types/Store.company';
import { companyThunks } from '../../../../containers/Company/Company.thunk';
import { Option } from '../../../common/Selector/Selector.types';
import { AppStore } from '../../../../store/types/Store.types';

export const useCompanyInfoFormHandlers = (
  company: CompanyItem,
  setIsEditMode: (value: boolean) => void,
) => {
  const dispatch = useDispatch();
  const [logo, setLogo] = useState('');
  const companySizeOptions = useSelector<AppStore, CompanySizeInterface[]>(
    (state) => state.company.companySizyOptions,
  );
  const [companyInfoFormData, setCompanyInfoFormData] = useState<
    Partial<EditCompanyFormType>
  >({
    company_name: company.name,
    vat_number: company.vat_number || null,
    country: {
      value: company.country ? company.country.code : '',
      name: company.country ? company.country?.name : '',
    },
    email: company.email || '',
    additional_email: company.additional_email || '',
    address: company.address || '',
    website: company.website || '',
    link_to_clutch: company.link_to_clutch || '',
    phone_number: company.phone_number || '',
    logo: company.logo || '',
    company_size: { value: '', name: '' },
  });

  useEffect(() => {
    const newCompanySize = company.company_size
      ? companySizeOptions.find(
          (option) => Number(option.id) === company.company_size,
        )
      : { id: '', name: '' };
    setCompanyInfoFormData({
      company_name: company.name,
      vat_number: company.vat_number || null,
      country: {
        value: company.country ? company.country.code : '',
        name: company.country ? company.country?.name : '',
      },
      email: company.email || '',
      additional_email: company.additional_email || '',
      address: company.address || '',
      website: company.website || '',
      link_to_clutch: company.link_to_clutch || '',
      phone_number: company.phone_number || '',
      logo: company.logo || '',
      company_size: {
        value: newCompanySize ? newCompanySize.id : '',
        name: newCompanySize ? newCompanySize.name : '',
      },
    });
    if (company.logo) {
      setLogo(company.logo);
    }
  }, [company]);

  const handleSelectChange = (
    name: string,
    newValue: string[] | string | Option | number | number[],
  ) => {
    setCompanyInfoFormData({
      ...companyInfoFormData,
      [name]: newValue,
    });
  };

  const handleInputChange = (e: React.BaseSyntheticEvent) => {
    setCompanyInfoFormData({
      ...companyInfoFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileUpload = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();
      setCompanyInfoFormData((prevRequest) => ({
        ...prevRequest,
        logo: file,
      }));

      reader.onerror = () => console.log('file reading has failed');
      reader.onloadend = () => {
        const url = reader.result;
        setLogo(url as string);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const handleFileRemoval = () => {
    companyInfoFormData &&
      companyInfoFormData.logo &&
      setCompanyInfoFormData({
        ...companyInfoFormData,
        logo: '',
      });
    setLogo('');
  };

  const sendForm = async () => {
    const formData = {
      company_name: companyInfoFormData.company_name || '',
      country: companyInfoFormData.country?.value || '',
      company_size: Number(companyInfoFormData.company_size?.value) || null,
      vat_number: companyInfoFormData.vat_number || '',
      email: companyInfoFormData.email || '',
      additional_email: companyInfoFormData.additional_email || '',
      address: companyInfoFormData.address || '',
      website: companyInfoFormData.website || '',
      link_to_clutch: companyInfoFormData.link_to_clutch || '',
      logo: companyInfoFormData.logo || '',
      phone_number: companyInfoFormData.phone_number || '',
      id: company.pk,
    };

    const result = await dispatch(
      companyThunks.updateCompanyInfoThunk(formData) as unknown as AnyAction,
    );
    if (result.type === companyThunks.updateCompanyInfoThunk.fulfilled.type) {
      setIsEditMode(false);
    }
  };

  return {
    companyInfoFormData,
    handleSelectChange,
    logo,
    handleInputChange,
    handleFileUpload,
    handleFileRemoval,
    sendForm,
  };
};
