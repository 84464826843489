import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ButtonsWrapper } from './ActionColumn.styles';
import { EditButton } from '../../../../../../components/common/IconButtons/EditButton';
import { ActivateButton } from '../../../../../../components/common/IconButtons/ActivateButton';
import { DeactivateButton } from '../../../../../../components/common/IconButtons/DeactivateButton';
import { ActionColumnProps } from './ActionColumn.types';
import { UserState } from '../../../../../../store/types/Store.user.types';
import { AppStore } from '../../../../../../store/types/Store.types';

export const ActionColumn = ({
  userId,
  isCompanyAdmin,
  isActive,
  onEditHandler,
  onActivateHandler,
}: ActionColumnProps) => {
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.accountsSettings',
  });
  const userState = useSelector<AppStore, UserState>((state) => state.user);
  const isCurrentUser = userState?.user ? userState?.user.id === userId : false;

  return (
    <ButtonsWrapper>
      {!isCurrentUser &&
        (isActive ? (
          <DeactivateButton
            title={t('deactivate')}
            disabled={!isCompanyAdmin}
            action={() => onActivateHandler(!isActive)}
          />
        ) : (
          <ActivateButton
            title={t('activate')}
            disabled={!isCompanyAdmin}
            action={() => onActivateHandler(!isActive)}
          />
        ))}
      {isCurrentUser && onEditHandler && <EditButton action={onEditHandler} />}
    </ButtonsWrapper>
  );
};
