import { createSlice } from '@reduxjs/toolkit';
import { PublicEmployeeData } from '../../../../store/types/Store.candidates.types';
import { hrListCandidatesThunks } from './HrListsCandidates.thunk';

const { getHrListEmployeesThunk } = hrListCandidatesThunks;

const initialState: PublicEmployeeData[] = [];

const hrListsCandidatesSlice = createSlice({
  name: 'candidates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHrListEmployeesThunk.fulfilled, (state, action) => {
      state.length = 0;
      action.payload.forEach((data: PublicEmployeeData) => {
        state.push({
          id: data.id,
          readable_id: data.readable_id,
          first_name: data.first_name,
          last_name: data.last_name,
          availability: data.availability,
          rate_of_pay_to: data.rate_of_pay_to,
          rate_of_pay_currency: data.rate_of_pay_currency,
          profile: data.profile,
          technologies: data.technologies,
          english_level: data.english_level,
          experience: data.experience,
        });
      });
    });
  },
});

export const actions = { ...hrListsCandidatesSlice.actions };
export const { reducer } = hrListsCandidatesSlice;
