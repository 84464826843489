import styled from 'styled-components';
import { StatusChipSizeType } from './CandidateStatusChip.types';

type StyledMenuItemInlineProps = {
  bgColor: string;
  size: StatusChipSizeType;
  isLoading: boolean;
};

const styledSizeChooser = (size: StatusChipSizeType) => {
  // eslint-disable-next-line radar/no-small-switch
  switch (size) {
    case 'small':
      return `font-size: 12px; 
      width: 100px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 4px 10px;
      `;

    default:
      return `font-size: 14px; padding: 10px 15px;`;
  }
};

export const StyledMenuItemInline = styled.div<StyledMenuItemInlineProps>`
  ${({ size }) => styledSizeChooser(size)}
  background: ${({ bgColor, isLoading }) => (isLoading ? 'white' : bgColor)};
  border-radius: 5px;
  cursor: default;
  text-align: center;
`;

export const StyledLoaderWrapper = styled.span`
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;
