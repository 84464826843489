import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { DialogButton } from '../Buttons/DialogButton/DialogButton.component';
import {
  CloseContainer,
  StyledArrowIcon,
  StyledButtonsContainer,
  StyledContainer,
  StyledFormTitle,
  StyledFormWrapper,
  StyledLabelWrapper,
  StyledTitleWrapper,
} from './Dialog.styles';
import { DialogProps } from './Dialog.types';

export const Dialog = ({
  children,
  dialogTitle,
  oldState,
  newState,
  buttonsConfig,
  handleClose,
}: DialogProps) => {
  return (
    <StyledContainer tabIndex={-1}>
      <StyledLabelWrapper>
        <StyledTitleWrapper>
          <StyledFormTitle>{dialogTitle}</StyledFormTitle>
          {oldState && newState && (
            <>
              <StyledFormTitle>{oldState}</StyledFormTitle>
              <StyledArrowIcon />
              <StyledFormTitle>{newState}</StyledFormTitle>
            </>
          )}
        </StyledTitleWrapper>
        <CloseContainer>
          <IconButton onClick={handleClose}>
            <CloseIcon htmlColor="black" fontSize="small" />
          </IconButton>
        </CloseContainer>
      </StyledLabelWrapper>
      <StyledFormWrapper>{children}</StyledFormWrapper>
      <StyledButtonsContainer>
        <DialogButton
          color={buttonsConfig?.primaryButton.color}
          action={buttonsConfig?.primaryButton.action}
        >
          {buttonsConfig?.primaryButton.label}
        </DialogButton>
        {buttonsConfig?.secondaryButton && (
          <DialogButton
            color={buttonsConfig?.secondaryButton.color}
            action={buttonsConfig?.secondaryButton.action}
          >
            {buttonsConfig.secondaryButton.label}
          </DialogButton>
        )}
      </StyledButtonsContainer>
    </StyledContainer>
  );
};
