import { Modal as ModalMUI } from '@mui/material';
import { styled as materialUIStyled } from '@mui/material/styles';
import styled from 'styled-components';

type ModalProps = {
  wide: boolean;
};

export const StyledModal = styled.div<ModalProps>`
  max-width: ${({ wide }) => (wide ? '60vw' : '450px')};
  position: relative;
`;

export const WideStyledModal = styled.div`
  max-width: 60vw;
  position: relative;
`;

export const StyledLabel = styled.span`
  color: #ffffff;
  font-family: 'Poppins';
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
`;

export const StyledModalWrapper = styled.div`
  background-color: white;
  border-radius: 20px;
  padding: 50px 52px;
`;

interface CloseContainerProps {
  isLabelDisplayed?: boolean;
}

export const CloseContainer = styled.div<CloseContainerProps>`
  align-items: center;
  display: flex;
  justify-content: ${({ isLabelDisplayed }) =>
    isLabelDisplayed ? 'space-between' : 'flex-end'};
  margin-bottom: 20px;
  width: 100%;
  & .MuiButtonBase-root {
    color: white;
    padding: 0px;
  }
`;

export const Modal = materialUIStyled(ModalMUI)({
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '5vh',
  marginBottom: '1vh',
});

export const PrimaryButton = styled.button`
  background: #3cc2d9;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  max-height: 43px;
  padding: 14px 20px;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

export const SecondaryButton = styled.button`
  background: #fbfcfd;
  border: 0.5px solid #eceff4;
  border-radius: 10px;
  color: #abafbd;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  margin-right: 15px;
  max-height: 43px;
  padding: 14px 20px;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;
