import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';

export const StyledAddButton = styled.div`
  color: ${COLORS.accent.primary};
  display: inline-block;
  margin-bottom: auto;
  margin-top: auto;
  padding-left: 30px;
  visibility: hidden;
`;

export const StyledIcon = styled.div`
  cursor: pointer;
`;

export const StyledHeaderContainer = styled.div`
  padding-top: ${({ hasContent }) => (hasContent ? '60px' : '14px')};
  padding-top: ${({ editable }) => !editable && '50px'};

  :hover div {
    visibility: visible;
  }
`;

export const StyledSectionHeader = styled.span`
  display: flex;
  font-size: 24px;
  font-weight: 700;
`;

export const StyledEditableHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  min-width: 30px;
`;
