import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../../common/Dialog/DEPR_Dialog';
import { Input } from '../../common/Input/Input';
import { FormWrapper } from './InviteNewUserForm.styles';
import { InviteNewUserFormProps } from './InviteNewUserForm.types';

export const InviteNewUserForm = ({
  handleSubmit,
  closeModal,
  currForm,
}: InviteNewUserFormProps) => {
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.accountsSettings',
  });
  const [formData, setFormData] = useState({
    email: currForm.email ?? '',
    username: currForm.username ?? '',
  });
  const buttonsConfig = {
    primaryButton: {
      label: t('invite', { ns: 'common', keyPrefix: 'buttons' }),
      action: () => {
        handleSubmit(formData);
      },
    },
  };

  const handleInputChange = (e: React.BaseSyntheticEvent<object, any, any>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Dialog
      dialogTitle={t('invite_new_user')}
      buttonsConfig={buttonsConfig}
      handleClose={closeModal}
    >
      <FormWrapper>
        <Input
          label="Email"
          name="email"
          type="email"
          required
          value={formData.email}
          onChange={handleInputChange}
        />
        <Input
          label="Username"
          name="username"
          required
          value={formData.username}
          onChange={handleInputChange}
        />
      </FormWrapper>
    </Dialog>
  );
};
