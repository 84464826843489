import styled from 'styled-components';
import { BoldIcon } from '../Icons/BoldIcon/BoldIcon.component';
import { BulletPointIcon } from '../Icons/BulletPointIcon/BulletPointIcon.component';
import { ItalicIcon } from '../Icons/ItalicIcon/ItalicIcon.component';
import { UnderlineIcon } from '../Icons/UnderlineIcon/UnderlineIcon.component';

export const StyledPanel = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  position: relative;
  @media print {
    visibility: hidden;
  }
`;

export const StyledBoldIcon = styled(BoldIcon)`
  border: 1px solid black;
  border-radius: 5px;
  height: 30px;

  padding: 3px;
  width: 30px;
`;

export const StyledItalicIcon = styled(ItalicIcon)`
  border: 1px solid black;
  border-radius: 5px;
  height: 30px;

  padding: 3px;
  width: 30px;
`;

export const StyledUnderlineIcon = styled(UnderlineIcon)`
  border: 1px solid black;
  border-radius: 5px;
  height: 30px;

  padding: 3px;
  width: 30px;
`;

export const StyledBulletIcon = styled(BulletPointIcon)`
  border: 1px solid black;
  border-radius: 5px;
  height: 30px;

  padding: 3px;
  width: 30px;
`;
