import { ChartConfiguration } from 'chart.js';

export const statisticsChartOptions: ChartConfiguration<'doughnut'>['options'] =
  {
    plugins: {
      legend: {
        display: false,
      },
      decimation: {
        enabled: true,
      },
      tooltip: {
        position: 'nearest',
        xAlign: 'center',
        yAlign: 'bottom',
        backgroundColor: '#101840',
        padding: 25,
        titleFont: {
          size: 0,
        },
        titleSpacing: 0,
        titleMarginBottom: 0,
        displayColors: false,
      },
    },
    layout: {
      autoPadding: false,
      padding: 1,
    },
    maintainAspectRatio: false,
    responsive: false,
  };
