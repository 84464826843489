import { StyledEngineProvider } from '@mui/styled-engine';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StyledApp } from './App.styles';
import PageSwitch from './config/PageSwitch';
import { ApiController } from './containers/ApiController/ApiController.component';
import { Topbar } from './containers/Topbar/Topbar';
import './index.css';
import { store } from './store/store';
import WsEventProvider from './utils/websockets/WsEventProvider';
import { Footer } from './components/common/Footer/Footer.component';

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <StyledApp>
        <ToastContainer />
        <Provider store={store}>
          <WsEventProvider>
            <BrowserRouter
              basename={
                typeof process.env.BASE_URL === 'string'
                  ? process.env.BASE_URL
                  : ''
              }
            >
              <Topbar />
              <ApiController>
                <PageSwitch />
              </ApiController>
              <Footer />
            </BrowserRouter>
          </WsEventProvider>
        </Provider>
      </StyledApp>
    </StyledEngineProvider>
  );
};

export default App;
