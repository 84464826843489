import { ButtonWrapper, InputButtonWrapper } from './Button.styles';
import { ButtonProps } from './Button.types';

export function Button({
  children,
  action,
  buttonType,
  color,
  fullWidth,
  disabled = false,
  variant = 'primary',
  size = 'medium',
}: ButtonProps) {
  if (buttonType === 'submit') {
    return <InputButtonWrapper color={color} />;
  }
  return (
    <ButtonWrapper
      onClick={(event) => action && action(event)}
      type={buttonType}
      variant={variant}
      {...{ color, fullWidth, disabled, size }}
    >
      {children}
    </ButtonWrapper>
  );
}
