import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { employeePageThunks } from '../../../../../../containers/Employee/EmployeePage/redux/EmployeePage.thunk';
import { leadProfileCandidatesThunks } from '../../../../../../containers/Leads/redux/LeadProfileCandidates.thunk';
import { AppDispatch } from '../../../../../../store/store';
import { DevRequestEmployeePage } from '../../../../../../store/types/Store.employeePage.types';

export const useEmployeePagePartnerNotesHandler = (
  devRequestDetails?: DevRequestEmployeePage,
) => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const currentPartnerNotes = devRequestDetails?.partner_notes ?? '';
  const profileId = devRequestDetails?.profile.profile_id;
  const leadId = devRequestDetails?.lead_id;
  const candidateId = devRequestDetails?.profile.candidate_id;

  const updateCandidatePartnerNotes = async (partnerNotes?: string) => {
    if (leadId && profileId && id && candidateId) {
      await dispatch(
        leadProfileCandidatesThunks.updateCandidateNotesThunk({
          lead_id: leadId,
          candidate_id: candidateId,
          lead_profile_id: profileId,
          notes: {
            employee: id,
            partner_notes: partnerNotes,
          },
        }),
      );
      await dispatch(employeePageThunks.getEmployeePageDataThunk(id));
    }
  };

  const handlePartnerSaving = (notes: string) => {
    if (notes !== currentPartnerNotes && notes) {
      updateCandidatePartnerNotes(notes);
    }
  };

  return {
    handlePartnerSaving,
  };
};
