import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from '../Tooltip/Tooltip.component';
import { StyledButton } from './IconButton.styles';
import { IconButtonProps } from './IconButton.types';

export const ActivateButton = ({
  action,
  title,
  disabled,
}: IconButtonProps) => {
  if (disabled) {
    return (
      <StyledButton disabled={disabled}>
        <CloseIcon style={{ fontSize: '16px' }} />
      </StyledButton>
    );
  }

  return (
    <Tooltip title={title || 'Activate'} position="table">
      <StyledButton onClick={action} disabled={disabled}>
        <CloseIcon style={{ fontSize: '16px' }} />
      </StyledButton>
    </Tooltip>
  );
};
