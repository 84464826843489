import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, NavLink, useMatch } from 'react-router-dom';
import { routes } from '../../../config/routes';
// import { isPartner } from '../../../Helpers';
import {
  // ClientListProfile,
  ExternalClientCredentials,
} from '../../../store/types/Store.clientList.types';
import { AppStore } from '../../../store/types/Store.types';
import navbarLogo from '../images/navbar_logo.svg';
import NavbarNotification from '../Notifications/NavbarNotification';
import {
  RightIconsWrapper,
  StyledLogo,
  StyledMenuButton,
  StyledNavbar,
} from './Navbar.styles';
import { NavbarProps } from './Navbar.types';
import { NavbarItems } from './NavbarItems';
import { AppPaths } from '../../../config/AppPaths';

export const Navbar = ({
  user,
  externalClientAuthData,
}: // onBasketClick,
NavbarProps) => {
  const [dropdownToggle, setDropdownToggle] = useState(false);
  // const basketContent = useSelector<AppStore, ClientListProfile[] | undefined>(
  //   (state) => state.clientListRelated.config.basket_content,
  // );

  const isCandidateFormPage = (): boolean => {
    const createEmployeePage = useMatch(AppPaths.createEmployee);
    const editEmployeePage = useMatch(AppPaths.editEmployee);
    return !!createEmployeePage || !!editEmployeePage;
  };

  const externalClientCredentials = useSelector<
    AppStore,
    ExternalClientCredentials
  >((state) => state.clientListRelated.credentials);

  const getUserId = () => {
    if (externalClientAuthData) {
      return externalClientCredentials.id;
    }
    return user?.user?.id;
  };

  // const isClientListBasketShown = () =>
  //   (isPartner(user?.user) || externalClientAuthData) &&
  //   location.pathname.includes('shared-list');

  const closeDropdown = () => {
    setDropdownToggle(false);
  };

  useEffect(() => {
    document.addEventListener('mouseup', (e) => {
      const container = document.getElementById('dropdown');
      if (container && !container.contains(e.target as Node)) {
        setDropdownToggle(false);
      }
    });

    return () => {
      document.removeEventListener('mouseup', (e) => {
        const container = document.getElementById('dropdown');
        if (container && e.target && !container.contains(e.target as Node)) {
          setDropdownToggle(false);
        }
      });
    };
  }, []);

  return (
    <>
      <StyledNavbar>
        <NavLink
          to={{
            pathname: externalClientAuthData
              ? generatePath(routes.clientListLogin.path, {
                  id: getUserId(),
                })
              : routes.main.path,
          }}
        >
          <StyledLogo
            src={navbarLogo}
            alt="logo"
            onClick={() => closeDropdown()}
          />
        </NavLink>
        <RightIconsWrapper>
          {/* {isClientListBasketShown() && (
            <StyledBasketButton onClick={onBasketClick}>
              <Badge badgeContent={basketContent?.length}>
                <GroupAddIcon fill="white" />
              </Badge>
            </StyledBasketButton>
          )} */}
          <NavbarNotification />
          <StyledMenuButton onClick={() => setDropdownToggle(!dropdownToggle)}>
            <FontAwesomeIcon icon={faBars} />
          </StyledMenuButton>
        </RightIconsWrapper>
        {!isCandidateFormPage() && (
          <NavbarItems
            collapsible={false}
            user={user}
            externalClientAuthData={externalClientAuthData}
          />
        )}
      </StyledNavbar>
      {dropdownToggle && (
        <NavbarItems
          id="dropdown"
          collapsible
          user={user}
          externalClientAuthData={externalClientAuthData}
        />
      )}
    </>
  );
};
