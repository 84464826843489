import { Box } from '@mui/material';
import { useState } from 'react';
import { StyledContainer, StyledEditButton } from './MyCompanyProfile.styles';
import { CompanyInfo } from '../../Company/CompanyInfo/CompanyInfo.component';
import { CompanyInfoForm } from '../CompanyInfoForm/CompanyInfoForm.component';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { COLORS } from '../../../theme/colors';

export const MyCompanyProfile = ({
  isCompanyAdmin,
  companyId,
}: {
  isCompanyAdmin: boolean;
  companyId: number;
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <StyledContainer>
      {isCompanyAdmin && isEditMode ? (
        <CompanyInfoForm companyId={companyId} setIsEditMode={setIsEditMode} />
      ) : (
        <Box
          height={345}
          width="100%"
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <CompanyInfo companyId={companyId} />
          {isCompanyAdmin && (
            <StyledEditButton onClick={toggleEditMode}>
              <EditIcon width={18} height={18} color={COLORS.accent.primary} />
            </StyledEditButton>
          )}
        </Box>
      )}
    </StyledContainer>
  );
};
