import {
  faMicrochip,
  faMinusCircle,
  faProjectDiagram,
  faTasks,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef } from 'react';
import { NEW_SIDEPROJECTS_DATA_TEMPLATE } from '../../../dataTemplates';
import { pastePlainText } from '../../../Helpers';
import { WithHeightUpdate } from '../../../utils/WithHeightUpdate';
import { StyledEntryContainer } from '../../common/CV/EntryContainer/EntryContainer.styles';
import {
  StyledAddSubsectionButtons,
  StyledData,
  StyledDeleteButton,
  StyledDeleteSubsectionButton,
  StyledIcon,
  StyledInfo,
  StyledLargeHeader,
  StyledLargeTextDiv,
  StyledMinorInfo,
  StyledSmallHeader,
  StyledTextDiv,
  StyledTimePeriod,
} from './SideProjectsEntry.styles';

const SUB_DATA_TYPE = {
  PROJECT_NAME: 'projectName',
  PROJECT_DESCRIPTION: 'projectDescription',
  TECHNOLOGIES: 'technologies',
};

const SUB_DATA_CONFIG = {
  [SUB_DATA_TYPE.PROJECT_NAME]: {
    label: 'projectName',
    icon: faProjectDiagram,
    displayName: 'Project name',
    initialData: '',
    textFormattingPanelVisibility: true,
  },
  [SUB_DATA_TYPE.PROJECT_DESCRIPTION]: {
    label: 'projectDescription',
    icon: faTasks,
    displayName: 'Project description',
    initialData: '',
    textFormattingPanelVisibility: 'bulletable',
  },
  [SUB_DATA_TYPE.TECHNOLOGIES]: {
    label: 'technologies',
    icon: faMicrochip,
    displayName: 'Technologies',
    initialData: '',
    textFormattingPanelVisibility: 'bulletable',
  },
};

function supplySubsectionAttrs(
  { editable, data, handleEditData, setTextFormattingPanelVisibility },
  subsectionName,
  parent,
) {
  return {
    placeholder: NEW_SIDEPROJECTS_DATA_TEMPLATE[subsectionName],
    suppressContentEditableWarning: true,
    contentEditable: editable,
    onPaste: (e) => pastePlainText(e),
    onBlur: (e) => {
      if (
        e.currentTarget.innerHTML === '' ||
        e.currentTarget.innerHTML === '<br>'
      ) {
        e.currentTarget.style.opacity = 1;
      }
      setTextFormattingPanelVisibility(false);
      handleEditData(
        {
          [subsectionName]: e.currentTarget.innerHTML,
        },
        parent,
      );
    },
    onFocus: (e) => {
      if (
        e.currentTarget.innerHTML ===
        NEW_SIDEPROJECTS_DATA_TEMPLATE[subsectionName]
      ) {
        // workaround to enable placing the caret at the beginning of the actual text
        // instead of at the beginning of the corresponding subtitle
        // e.currentTarget.innerHTML = "<br>";
        e.currentTarget.style.opacity = 1;
      }
      setTextFormattingPanelVisibility(
        SUB_DATA_CONFIG[parent].textFormattingPanelVisibility,
      );
    },
    spellCheck: false,
    dangerouslySetInnerHTML: {
      __html: data[subsectionName],
    },
  };
}

const SideProjectsEntryComponent = forwardRef(
  (
    {
      handleDrop,
      handleDrag,
      handleDragOver,
      editable,
      data,
      setTextFormattingPanelVisibility,
      handleEditData,
      handleRemoveEntry,
      handleDeleteSubData,
      handleCreateSubData,
      index,
    },
    ref,
  ) => {
    const [focused, setFocused] = React.useState(false);
    const commonProps = {
      data,
      handleEditData,
      editable,
      setTextFormattingPanelVisibility,
    };

    const handleBlur = (event) => {
      setTextFormattingPanelVisibility(false);
      const name = event.target.getAttribute('name');
      handleEditData({
        [name]: event.target.innerHTML,
      });
    };

    return (
      <StyledEntryContainer
        ref={ref}
        draggable={!focused}
        onDragStart={handleDrag}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        paddingTop={index ? 40 : undefined}
      >
        <StyledData>
          <StyledTimePeriod
            placeholder="MM.YYYY - MM.YYYY"
            suppressContentEditableWarning
            contentEditable={editable}
            onFocus={() => {
              setTextFormattingPanelVisibility(true);
            }}
            onPaste={(e) => pastePlainText(e)}
            name="timePeriod"
            onBlur={handleBlur}
            spellCheck={false}
            dangerouslySetInnerHTML={{
              __html: data.timePeriod,
            }}
          />
          {editable && (
            <StyledDeleteButton>
              <StyledIcon onClick={handleRemoveEntry}>
                <FontAwesomeIcon icon={faMinusCircle} />
              </StyledIcon>
            </StyledDeleteButton>
          )}
          <StyledInfo>
            {data.projectName !== undefined && (
              <div>
                <StyledLargeHeader>Project name:</StyledLargeHeader>
                <StyledLargeTextDiv
                  {...supplySubsectionAttrs(
                    commonProps,
                    'projectName',
                    SUB_DATA_TYPE.PROJECT_NAME,
                  )}
                />
                {editable && (
                  <StyledDeleteSubsectionButton>
                    <StyledIcon
                      onClick={() =>
                        handleDeleteSubData(SUB_DATA_TYPE.PROJECT_NAME)
                      }
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </StyledIcon>
                  </StyledDeleteSubsectionButton>
                )}
              </div>
            )}
            {data.projectDescription !== undefined && (
              <StyledMinorInfo>
                <StyledSmallHeader>Project description:</StyledSmallHeader>
                <StyledTextDiv
                  {...supplySubsectionAttrs(
                    commonProps,
                    'projectDescription',
                    SUB_DATA_TYPE.PROJECT_DESCRIPTION,
                  )}
                />
                {editable && (
                  <StyledDeleteSubsectionButton>
                    <StyledIcon
                      onClick={() =>
                        handleDeleteSubData(SUB_DATA_TYPE.PROJECT_DESCRIPTION)
                      }
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </StyledIcon>
                  </StyledDeleteSubsectionButton>
                )}
              </StyledMinorInfo>
            )}
            {data.technologies !== undefined && (
              <StyledMinorInfo>
                <StyledSmallHeader>Technologies:</StyledSmallHeader>
                <StyledTextDiv
                  {...supplySubsectionAttrs(
                    commonProps,
                    'technologies',
                    SUB_DATA_TYPE.TECHNOLOGIES,
                  )}
                />
                {editable && (
                  <StyledDeleteSubsectionButton>
                    <StyledIcon
                      onClick={() =>
                        handleDeleteSubData(SUB_DATA_TYPE.TECHNOLOGIES)
                      }
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </StyledIcon>
                  </StyledDeleteSubsectionButton>
                )}
              </StyledMinorInfo>
            )}
            {editable && (
              <StyledAddSubsectionButtons>
                {Object.entries(SUB_DATA_CONFIG).map(([propertyName, item]) => {
                  if (data[item.label] === undefined) {
                    return (
                      <StyledIcon
                        title={`Add ${item.displayName}`}
                        key={propertyName}
                        onClick={() =>
                          handleCreateSubData({
                            propertyName,
                            initialData: item.initialData,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={item.icon} />
                      </StyledIcon>
                    );
                  }
                  return null;
                })}
              </StyledAddSubsectionButtons>
            )}
          </StyledInfo>
        </StyledData>
      </StyledEntryContainer>
    );
  },
);

export const SideProjectsEntry = WithHeightUpdate(SideProjectsEntryComponent);
