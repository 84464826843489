import { useEffect, useState } from 'react';
import { PriorityChip } from '../Chips/PriorityChip/PriorityChip';
import { PriorityKeyType } from '../CollapseTable/CollapseTable.types';
import { ChipsContainer } from './ChipSelectLeadPriority.styles';
import { ChipSelectLeadPriorityProps } from './ChipSelectLeadPriority.types';

export const ChipSelectLeadPriority = ({
  options,
  handlePriorityChange,
  currentPriority,
}: ChipSelectLeadPriorityProps) => {
  const [choosenPriority, setChoosenPriority] = useState<PriorityKeyType>();

  useEffect(() => {
    handlePriorityChange(choosenPriority);
  }, [choosenPriority]);

  useEffect(() => {
    currentPriority && setChoosenPriority(currentPriority);
  }, [currentPriority]);

  return (
    <ChipsContainer>
      {options.map((option, index) => (
        <PriorityChip
          option={option}
          priority={option.priority}
          handleClick={() => setChoosenPriority(option.priority)}
          clickable
          isClicked={option.priority === choosenPriority}
          key={`${option.priority}-${index}`}
        />
      ))}
    </ChipsContainer>
  );
};
