import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';

interface StyledButtonProps {
  disabled?: boolean;
}

export const StyledButton = styled.div<StyledButtonProps>`
  align-items: center;
  color: #b5bfc8;
  display: flex;
  height: 25px;
  justify-content: center;
  width: 25px;

  ${({ disabled }) => disabled && `opacity: 0.5;`}

  :hover {
    background: none;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const StyledBlueButton = styled.div<StyledButtonProps>`
  align-items: center;
  color: ${COLORS.accent.primary};
  display: flex;
  height: 25px;
  justify-content: center;
  ${({ disabled }) => disabled && `opacity: 0.5;`}
  width: 25px;

  :hover {
    background: none;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;
