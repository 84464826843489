import { createSlice } from '@reduxjs/toolkit';
import { LeadProfile } from '../../../components/Lead/CandidatesSuggestedByHr/CandidateListItem/CandidateListItem.types';
import { leadProfilesThunks } from './LeadProfiles.thunk';

const { getLeadRequestsThunk } = leadProfilesThunks;

const initialState: LeadProfile[] = [];

const leadProfilesSlice = createSlice({
  name: 'leadProfiles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLeadRequestsThunk.fulfilled, (state, action) => {
      state.length = 0;
      action.payload.forEach((data: LeadProfile) => {
        state.push({
          id: data.id,
          name: data.name,
          priority: data.priority,
          description: data.description,
          lead_profile_status: data.lead_profile_status,
          suggested_candidates: data.suggested_candidates,
          reason: data.reason,
          hr_owner: data.hr_owner,
          created_at: data.created_at,
          main_technologies: data.main_technologies,
          files: data.files,
          owned_by_partner: data.owned_by_partner,
          visibility: data.visibility,
        });
      });
    });
  },
});

export const actions = { ...leadProfilesSlice.actions };
export const { reducer } = leadProfilesSlice;
