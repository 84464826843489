import { createSlice } from '@reduxjs/toolkit';
import { Technology } from '../../../../store/types/Store.leads.types';
import { mainTechnologiesTagsThunks } from './MainTechnologiesTags.thunk';

const { getMainTechnologiesThunk } = mainTechnologiesTagsThunks;

const initialState: Technology[] = [];

const mainTechnologiesTagsSlice = createSlice({
  name: 'mainTechnologies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMainTechnologiesThunk.fulfilled, (state, action) => {
      state.length = 0;
      action.payload.forEach((data: Technology) => {
        state.push({ id: data.id, name: data.name, logo: null });
      });
    });
  },
});

export const actions = { ...mainTechnologiesTagsSlice.actions };
export const { reducer } = mainTechnologiesTagsSlice;
