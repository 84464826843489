import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthHeader, handleResponse } from '../../../utils/api';
import { generateClientListCandidateHistoryUrl } from '../../../utils/urlGenerators';

const getCandidateHistoryThunk = createAsyncThunk(
  'clientListCandidateHistory/getCandidateHistory',
  async (data: { clientId: string; cvId: string }, { getState, dispatch }) => {
    const request = fetch(
      generateClientListCandidateHistoryUrl(data.clientId, data.cvId),
      {
        method: 'GET',
        headers: getAuthHeader(getState()),
      },
    );
    return await handleResponse(request, dispatch);
  },
);

export const clientListCandidateHistoryThunks = {
  getCandidateHistoryThunk,
};
