import { AnyAction } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CollapseTable } from '../../../../../components/common/CollapseTable';
import { Loader } from '../../../../../components/common/Loader';
import { isMobile, isPartner } from '../../../../../Helpers';
import {
  Lead,
  LeadsFilters,
} from '../../../../../store/types/Store.leads.types';
import { AppStore } from '../../../../../store/types/Store.types';
import { UserState } from '../../../../../store/types/Store.user.types';
import { userThunks } from '../../../User.thunk';
import { leadsFiltersThunks } from '../../../../Leads/redux/LeadsFilters.thunk';
import { leadsListThunks } from '../../../../Leads/redux/LeadsList.thunk';
import { industryTagsThunks } from '../../../../Leads/redux/tags/IndustryTags.thunk';
import { leadTagsThunks } from '../../../../Leads/redux/tags/LeadTags.thunk';
import { profileReasonTagsThunks } from '../../../../Leads/redux/tags/ProfileReasonTags.thunk';
import { profileTagsThunks } from '../../../../Leads/redux/tags/ProfileTags.thunk';
import { AddNewRequestModal } from './AddNewRequestModal';
import { createLeadRow } from './helpers/createLeadRow';
import { getFilteredRows } from './helpers/getFilteredRows';
import { StyledLeadsContainer, StyledLeadTable } from './ProjectsTable.styles';
import { CreatedRowLead, LeadsColumn } from './ProjectsTable.types';
import { Header } from './Header';

const ADDITIONAL_COLUMNS = 2;

const ProjectsTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.projects',
  });
  const leads = useSelector<AppStore, Lead[]>(
    (state) => state.leadsRelated.list,
  );
  const filters = useSelector<AppStore, LeadsFilters>(
    (state) => state.leadsRelated.filters,
  );
  const [filteredLeads, setFilteredLeads] = useState<CreatedRowLead[]>([]);
  const [topFilterValue, setTopFilterValue] = useState('current');
  const [openModal, setOpenModal] = useState(false);
  const [filteredSupervisorValue, setFilteredSupervisorValue] = useState<
    string | undefined
  >(filters?.owner);
  const [filteredValue, setFilteredValue] = useState(filters?.search ?? '');
  const currUserState = useSelector<AppStore, UserState>((state) => state.user);
  const numberOfLeads = leads.length;

  const [createdRows, setCreatedRows] = useState<CreatedRowLead[]>([]);

  const getLeadsColumnsAmount = () => columns.length + ADDITIONAL_COLUMNS;

  const columns: LeadsColumn[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('name'),
      sortable: true,
      align: 'left',
      isShowTooltip: true,
      type: 'string',
      minWidth: '155px',
      color: '#000000',
      fontWeight: '600',
      flexBasisMobView: '74%',
      order: 1,
    },
    {
      id: 'date',
      numeric: true,
      disablePadding: true,
      label: t('date'),
      sortable: true,
      align: 'left',
      isShowTooltip: true,
      type: 'date',
      minWidth: '90px',
      flexBasisMobView: '100%',
      order: 3,
    },
    {
      id: 'salesOwner',
      numeric: false,
      disablePadding: true,
      sortable: false,
      label: t('project_owner'),
      align: 'left',
      isShowTooltip: true,
      type: 'string',
      minWidth: '210px',
      flexBasisMobView: '50%',
      order: 4,
    },
    {
      id: 'request',
      numeric: false,
      disablePadding: true,
      label: t('request'),
      sortable: false,
      align: 'left',
      isShowTooltip: false,
      minWidth: '90px',
      padding: '6px 3px 6px 16px',
      maxWidth: '90px',
      flexBasisMobView: '27%',
      order: 5,
    },
    {
      id: 'requestNum',
      numeric: true,
      disablePadding: true,
      label: '',
      sortable: true,
      align: 'left',
      isShowTooltip: false,
      type: 'number',
      padding: '6px 16px 6px 0',
      color: '#000000',
      maxWidth: '20px',
      flexBasisMobView: '50%',
      order: 6,
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'Status',
      sortable: false,
      align: 'left',
      isShowTooltip: false,
      color: '#000000',
      flexBasisMobView: '20%',
      order: 7,
    },
  ];

  useEffect(() => {
    setCreatedRows(leads.map((row) => createLeadRow(row)));
  }, [leads]);

  useEffect(() => {
    dispatch(
      leadsFiltersThunks.saveStateThunk({
        search: filteredValue,
        owner: filteredSupervisorValue,
      }) as unknown as AnyAction,
    );
  }, [filteredValue, filteredSupervisorValue]);

  const loadPrivateData = () => {
    dispatch(leadTagsThunks.getLeadTagsThunk() as unknown as AnyAction);
    dispatch(userThunks.getHRUsersThunk() as unknown as AnyAction);
    dispatch(
      profileReasonTagsThunks.getProfileReasonTagsThunk() as unknown as AnyAction,
    );
    dispatch(userThunks.getSalesUsersThunk() as unknown as AnyAction);
    dispatch(userThunks.getPartnerUsersThunk() as unknown as AnyAction);
    dispatch(profileTagsThunks.getProfileTagsThunk() as unknown as AnyAction);
  };

  const loadPublicLeadData = () => {
    dispatch(leadsListThunks.getLeadsThunk() as unknown as AnyAction);
    dispatch(industryTagsThunks.getIndustryTagsThunk() as unknown as AnyAction);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadPublicLeadData();
    if (!isPartner(currUserState.user)) {
      loadPrivateData();
    }
  }, [isMobile]);

  useEffect(() => {
    setFilteredLeads(
      getFilteredRows(
        createdRows,
        filteredValue,
        topFilterValue,
        filteredSupervisorValue,
      ),
    );
  }, [
    leads,
    createdRows,
    filteredValue,
    filteredSupervisorValue,
    topFilterValue,
  ]);

  if (!leads) {
    return <Loader />;
  }

  return (
    <>
      {openModal && <AddNewRequestModal {...{ openModal, setOpenModal }} />}
      <Header
        {...{
          numberOfProjects: numberOfLeads,
          filteredValue,
          setFilteredValue,
          setOpenModal,
          leads,
          topFilterValue,
          setTopFilterValue,
        }}
      />
      <StyledLeadTable>
        <StyledLeadsContainer>
          <CollapseTable
            tableData={{
              columns,
              rows: filteredLeads,
              type: 'leads',
            }}
            defaultSortKey="date"
            columnAmount={getLeadsColumnsAmount()}
          />
        </StyledLeadsContainer>
      </StyledLeadTable>
    </>
  );
};

export default ProjectsTable;
