import { useTranslation } from 'react-i18next';
import { ButtonsWrapper } from './ActionColumn.styles';
import { EditButton } from '../../../../../components/common/IconButtons/EditButton';
import { DeleteButton } from '../../../../../components/common/IconButtons/DeleteButton';
import { PublishButton } from '../../../../../components/common/IconButtons/PublishButton';

export const ActionColumn = ({
  visibility,
  onEditHandler,
  onPublishHandler,
  onDeleteHandler,
}) => {
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.vacancies',
  });
  return (
    <ButtonsWrapper>
      <PublishButton
        action={onPublishHandler}
        title={t(visibility.toLowerCase())}
        visibilityStatus={visibility}
      />
      {onEditHandler && <EditButton action={onEditHandler} />}
      {onDeleteHandler && <DeleteButton action={onDeleteHandler} />}
    </ButtonsWrapper>
  );
};
