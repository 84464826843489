import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MyCompanyProfile } from '../../../../components/User/MyCompanyProfile/MyCompanyProfile.component';
import { Typography } from '../../../../components/common/Typography/Typography.component';
import { AppStore } from '../../../../store/types/Store.types';
import { UserData } from '../../../../store/types/Store.user.types';

export const MyCompany = () => {
  const { t } = useTranslation(['user'], { keyPrefix: 'my_profile' });
  const user = useSelector<AppStore, UserData | undefined | null>(
    (state) => state.user.user,
  );
  const isCompanyAdmin = user?.is_company_administrator || false;
  const companyId = user?.company?.pk;

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography variant="h4" weight="semibold">
        {t('company_profile')}
      </Typography>
      {companyId && (
        <MyCompanyProfile
          isCompanyAdmin={isCompanyAdmin}
          companyId={companyId}
        />
      )}
    </Box>
  );
};
