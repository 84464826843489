import { FC } from 'react';
import {
  Header1,
  Header2,
  Header3,
  Header4,
  Subtitle,
  Body,
  Caption,
  Overline,
  Link,
} from './Typography.styles';
import { TypographyProps } from './Typography.types';

const mapping = {
  h1: Header1,
  h2: Header2,
  h3: Header3,
  h4: Header4,
  subtitle: Subtitle,
  body: Body,
  caption: Caption,
  overline: Overline,
  link: Link,
};

export const Typography: FC<TypographyProps> = ({ variant, ...props }) => {
  const Component = mapping[variant];
  return <Component variant={variant} {...props} />;
};
