import FormControlLabel from '@mui/material/FormControlLabel';
import { styled as materialUIStyled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';

export const BenchlySwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: COLORS.accent.primary,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: COLORS.accent.hover,
  },
}));

export const StyledSidebar = styled.div`
  align-items: center;
  background-color: white;
  border-right: 3px solid ${COLORS.accent.primary};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  min-height: 100vh;
  min-width: 16vw;
  overflow: auto;
  position: fixed;
  text-align: center;
  top: 55px;
  width: 18vw;
  z-index: 1;

  @media print {
    display: none !important;
  }
`;

export const StyledDeleteButton = styled.div`
  bottom: 3px;
  color: ${COLORS.accent.primary};
  left: 10px;
  position: relative;
`;

export const StyledIcon = styled.div`
  cursor: pointer;
`;

export const StyledCustomContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const StyledButton = styled.button`
  background-color: ${COLORS.accent.primary};
  border: none;
  border-radius: 10px;
  color: white;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  min-height: 50px;
  padding: 10px 5px;
  width: 14vw;
`;

export const Wrapper = styled.div`
  &:hover ${StyledButton} {
    background-color: ${COLORS.accent.hover};
    cursor: pointer;
  }

  &:active ${StyledButton} {
    background-color: ${COLORS.accent.hover};
    border: none;
    cursor: pointer;
    outline: none;
  }

  &:focus ${StyledButton} {
    background-color: ${COLORS.accent.hover};
    border: none;
    cursor: pointer;
  }
`;

export const StyledSpan = styled.span`
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  margin-top: 30px;
  white-space: pre-wrap;
`;

export const StyledSwitchContainer = styled.div`
  color: black;
  margin: 30px 0px 50px 0px;
  text-align: left;
  width: 80%;
`;

export const StyledLink = styled(Link)`
  color: #000;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 22px;
  font-weight: 500;

  &:hover {
    color: ${COLORS.accent.hover};
    text-decoration: none;
  }
`;

export const StyledTextFormattingPanel = styled.div`
  margin-top: 30px;
`;

export const StyledLabel = styled.h6`
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 20x;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
`;

export const StyledFormControlLabel = materialUIStyled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    fontSize: '16px',
    fontFamily: '"Neue Haas Grotesk Display Pro", sans-serif',
  },
});
