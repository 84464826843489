import { createSlice } from '@reduxjs/toolkit';
import { PersistedStoreKeys } from '../../config/PersistedStoreKeys';
import { UserState } from '../../store/types/Store.user.types';
import { userThunks } from './User.thunk';

const {
  loginThunk,
  logoutThunk,
  getHRUsersThunk,
  getUsersThunk,
  getCompanyMembersThunk,
  getSalesUsersThunk,
  getPartnerUsersThunk,
  saveStateThunk,
  getCompanyThunk,
} = userThunks;

const getDataFromStorage = (storageKey: PersistedStoreKeys) => {
  return localStorage.getItem(storageKey)
    ? JSON.parse(localStorage.getItem(storageKey) as string)
    : undefined;
};

/*
TODO: Missing State!! Need to refill with hr partner and other crap
wtf is dis!
*/
const initialState: UserState = {
  token: localStorage.getItem(PersistedStoreKeys.token) || undefined,
  user: getDataFromStorage(PersistedStoreKeys.user),
  // 11.08.22 should be moved to other object or the name should be changed
  filters: getDataFromStorage(PersistedStoreKeys.userFilters) || {
    search: '',
  },
  // TODO: (11.08.22) need to change the casing. This is set in like milion
  // diffrent locations and I do not have time for this in this sprint
  // PS: we are using snake case redux states. Everywhere else in lower camel case
  PartnerUsers: [],
  HRUsers: [],
  SalesUsers: [],
  companyMembers: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUserData: (state) => {
      state.user = undefined;
      state.token = undefined;
    },
  },
  extraReducers: {
    [saveStateThunk.fulfilled as unknown as string]: (state, action) => {
      const data = action.payload;
      state.filters.search = data.search;
    },
    [loginThunk.fulfilled as unknown as string]: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    [logoutThunk.fulfilled as unknown as string]: (state) => {
      state.user = null;
      state.token = '';
    },
    [getHRUsersThunk.fulfilled as unknown as string]: (state, action) => {
      state.HRUsers = action.payload;
    },
    [getSalesUsersThunk.fulfilled as unknown as string]: (state, action) => {
      state.SalesUsers = action.payload;
    },
    [getPartnerUsersThunk.fulfilled as unknown as string]: (state, action) => {
      state.PartnerUsers = action.payload;
    },
    [getUsersThunk.fulfilled as unknown as string]: (state, action) => {
      state.users = action.payload;
      state.usersLoaded = true;
    },
    [getCompanyMembersThunk.fulfilled as unknown as string]: (
      state,
      action,
    ) => {
      state.companyMembers = action.payload;
    },
    [getCompanyThunk.fulfilled as unknown as string]: (state, action) => {
      state.userCompany = action.payload;
    },
  },
});

export const actions = { ...userSlice.actions };
export const { reducer } = userSlice;
