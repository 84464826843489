import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';

export const CloseContainer = styled.div`
  padding-left: 12px;

  & .MuiButtonBase-root {
    margin-bottom: 5px;
    padding: 0px;
  }
`;

export const StyledLabelWrapper = styled.div`
  align-items: center;
  background: white;
  border-bottom: 1px solid ${COLORS.accent.border};
  border-radius: 10px 10px 0px 0px;
  display: flex;
  height: 62px;
  justify-content: space-between;
  padding: 24px 26px;
  position: sticky;
  top: 0px;
  z-index: 1;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
`;
