import { useTranslation } from 'react-i18next';
import { Typography } from '../../../common/Typography/Typography.component';
import {
  LabelIcon,
  StyledChip,
  LabelContainer,
  LabelItems,
} from './SeniorityChip.styles';
import { SeniorityChipProps, LabelVariantProp } from './SeniorityChip.types';

export const seniorityVariants = [
  { value: 'JUNIOR', name: 'Junior', dotsNumber: 1 },
  { value: 'JUNIOR+', name: 'Junior', dotsNumber: 1 },
  { value: 'REGULAR-', name: 'Regular', dotsNumber: 2 },
  { value: 'REGULAR', name: 'Regular', dotsNumber: 2 },
  { value: 'REGULAR+', name: 'Regular', dotsNumber: 2 },
  { value: 'SENIOR-', name: 'Senior', dotsNumber: 3 },
  { value: 'SENIOR', name: 'Senior', dotsNumber: 3 },
];

export const MAX_DOTS_NUMBER = 3;

export const SeniorityChip = ({
  label,
  handleClick,
  option,
  color,
  background,
  experience,
  ...props
}: SeniorityChipProps) => {
  const { t } = useTranslation(['employee'], { keyPrefix: 'employee_details' });
  const [currentSeniority] = seniorityVariants.filter(
    (seniority) => seniority.value === label,
  );
  const filledIcons = [...new Array(currentSeniority.dotsNumber)].map(
    () => 'filled' as LabelVariantProp,
  );
  const regularIcons = [
    ...new Array(MAX_DOTS_NUMBER - currentSeniority.dotsNumber),
  ].map(() => 'regular' as LabelVariantProp);

  const getExperience = (yearsNumber: number) => {
    if (yearsNumber < 1) {
      return `(<1 ${t('one_year')})`;
    }
    if (yearsNumber === 1) {
      return `(${yearsNumber} ${t('one_year')})`;
    }
    return `(${yearsNumber} ${
      yearsNumber > 4 ? t('over_three_years') : t('up_to_three_years')
    })`;
  };

  const getLabel = (name: string) => {
    return (
      <LabelContainer>
        <LabelItems>
          {[...filledIcons, ...regularIcons].map(
            (iconType: LabelVariantProp) => (
              <LabelIcon variant={iconType} />
            ),
          )}
        </LabelItems>
        <Typography variant="overline">{`${name} ${
          experience !== false ? getExperience(experience || 0) : ''
        }`}</Typography>
      </LabelContainer>
    );
  };

  return (
    <StyledChip
      label={getLabel(currentSeniority.name)}
      customColor={color}
      customBackground={background}
      {...props}
    />
  );
};
