import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef } from 'react';
import { pastePlainText } from '../../../Helpers';
import { WithHeightUpdate } from '../../../utils/WithHeightUpdate';
import {
  StyledAddButton,
  StyledEditableHeader,
  StyledHeaderContainer,
  StyledIcon,
  StyledSectionHeader,
} from './SectionHeader.styles';

const SectionHeaderComponent = forwardRef(
  (
    {
      headerText,
      handleDrag,
      handleDrop,
      handleDragOver,
      editable,
      addExperienceEntry,
      editExperienceSectionName,
      setTextFormattingPanelVisibility,
    },
    ref,
  ) => {
    const [focused, setFocused] = React.useState(false);

    return (
      <StyledHeaderContainer
        ref={ref}
        draggable={!focused}
        onDragStart={handleDrag}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        hasContent={!!headerText}
        editable={editable}
      >
        <StyledSectionHeader>
          <StyledEditableHeader
            contentEditable={editable}
            suppressContentEditableWarning
            spellCheck={false}
            onFocus={() => {
              setTextFormattingPanelVisibility(false);
            }}
            onPaste={(e) => pastePlainText(e)}
            onBlur={(e) => {
              setTextFormattingPanelVisibility(false);
              editExperienceSectionName(e.currentTarget.innerHTML);
            }}
          >
            {headerText}
          </StyledEditableHeader>
          {editable && addExperienceEntry && (
            <StyledAddButton>
              <StyledIcon onClick={editable ? addExperienceEntry : undefined}>
                <FontAwesomeIcon icon={faPlusCircle} />
              </StyledIcon>
            </StyledAddButton>
          )}
        </StyledSectionHeader>
      </StyledHeaderContainer>
    );
  },
);

export const SectionHeader = WithHeightUpdate(SectionHeaderComponent);
