import { generatePath, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../../config/routes';
import { StyledNotificationPopperFooterButton } from '../Notifications.styles';

const NotificationPopperFooter = ({ height }: { height: string }) => {
  const { t } = useTranslation(['common'], { keyPrefix: 'notifications' });
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(generatePath(routes.notifications.path));
  };

  return (
    <StyledNotificationPopperFooterButton
      style={{ height }}
      onClick={handleOnClick}
    >
      {t('view_all')}
    </StyledNotificationPopperFooterButton>
  );
};

export default NotificationPopperFooter;
