import Modal from '@mui/material/Modal';
import styled from '@mui/styled-engine';

type StyledModalProps = { width?: string };
export const StyledModal = styled(Modal)(({ width }: StyledModalProps) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  overflowY: 'auto',
  overflowX: 'hidden',
  width,

  '@media (min-width: 700px)': {
    width: '100%',
    maxWidth: '80vw',
    overflowY: 'hidden',
  },

  '@media (max-width: 699px)': {
    maxWidth: '450px',
  },

  '& .MuiBackdrop-root': {
    backgroundColor: 'rgb(0, 0, 0, 0.4)',
    fontFamily: 'Neue Haas Grotesk Display Pro !important',
  },
}));
