import i18n from '../../../i18n';

export const NOW_WORDS = ['present', 'now', 'today', 'current'];
export const CURRENT_EXPERIENCE_CONFIG = {
  format: '##.####',
  placeholder: 'MM.YYYY',
  mask: ['M', 'M', 'Y', 'Y', 'Y', 'Y'],
};
export const EXPERIENCE_CONFIG = {
  format: '##.#### - ##.####',
  placeholder: 'MM.YYYY - MM.YYYY',
  mask: ['M', 'M', 'Y', 'Y', 'Y', 'Y', 'M', 'M', 'Y', 'Y', 'Y', 'Y'],
};

export const ERR_MSG = i18n.t('raiseToastMessage.invalid_month_value', {
  ns: 'common',
});

export const SUB_DATA_TYPE = {
  POSITION: 'position',
  COMPANY: 'company',
  RESPONSIBILITIES: 'responsibilities',
};
