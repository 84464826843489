import styled from 'styled-components';

export const StyledEmployeeTable = styled.div`
  height: 100%;
  margin: 0 auto;
  padding: 60px 70px;
  width: 100%;

  @media (max-width: 800px) {
    padding: 60px 30px;
  }
`;
