/* eslint-disable radar/no-duplicate-string */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../../common/Dialog/DEPR_Dialog';
import { Input } from '../../common/Input/Input';
import { FormWrapper } from './EditForm.styles';

export const EditForm = ({ userToEdit, handleSubmit, handleBack, ref }) => {
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.accountsSettings',
  });
  const [editFormData, setEditFormData] = useState({
    username: userToEdit.username,
    email: userToEdit.email,
  });

  const handleInputChange = (e) => {
    setEditFormData({
      ...editFormData,
      [e.target.name]: e.target.value,
    });
  };

  const buttonsConfig = {
    primaryButton: {
      label: t('save', { ns: 'common', keyPrefix: 'buttons' }),
      action: () => handleSubmit(editFormData, userToEdit),
    },
  };

  return (
    <Dialog
      dialogTitle={t('edit_my_data')}
      buttonsConfig={buttonsConfig}
      handleClose={handleBack}
      ref={ref}
    >
      <FormWrapper>
        <Input
          label="Username"
          name="username"
          required
          defaultValue={userToEdit.username}
          value={editFormData.username}
          onChange={handleInputChange}
        />
        <Input
          label="Email"
          name="email"
          type="email"
          required
          defaultValue={userToEdit.email}
          value={userToEdit.email}
          onChange={handleInputChange}
        />
      </FormWrapper>
    </Dialog>
  );
};
