import Box from '@mui/material/Box';
import { styled as styledMaterialUI } from '@mui/material/styles';
import styled from 'styled-components';

export const StyledBox = styledMaterialUI(Box)({
  '@media (max-width: 668px)': {
    width: '100%',
  },
  padding: '20px',
  minWidth: '514px',
});

export const StyledHeadline = styled.p`
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0;
  margin-top: 3px;
`;

export const Line = styled.hr`
  border-top: 1px solid #eff3fa;
  margin-bottom: 5px;
  margin-top: 3px;
  width: 100%;
`;

export const NoResWrapper = styled.section`
  margin-top: 115px;
`;

export const ChartContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
  justify-content: space-between;
  @media (max-width: 699px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const LegendContainer = styled.div`
  margin-top: 10px;
  max-height: 233px;
  @media (max-width: 699px) {
    height: fit-content;
    max-height: unset;
    width: 95%;
  }
`;
