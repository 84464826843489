import { Menu } from '@mui/material';
import { styled as materialUIStyled } from '@mui/material/styles';
import styled from 'styled-components';

export const StyledMenu = materialUIStyled(Menu)({
  left: '-58px',
  '& .MuiList-root': {
    padding: '20px 14px',
    width: 238,
    height: 264,
  },
});

export const MenuPaperProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: '22%',
      width: 25,
      height: 25,
      bgcolor: '#ffffff',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

type StyledMenuItemProps = {
  bgColor: string;
  disabled?: boolean;
  isActive?: boolean;
};

export const StyledMenuItem = styled.div<StyledMenuItemProps>`
  background: ${({ bgColor, disabled, isActive }) =>
    disabled && !isActive ? '#F4F5F7' : bgColor};
  border-radius: 5px;
  color: ${({ disabled, isActive }) =>
    disabled && !isActive ? '#9FA3B3' : '#101840'};
  cursor: pointer;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 14px;
  margin: 4px 0;
  padding: 3px 23px 3px 17px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  position: relative;
  transition: padding 85ms, margin 85ms, box-shadow 85ms;
  width: max-content;

  &:hover {
    box-shadow: -8px 0 initial;
    margin-left: 5px;
  }
`;

export const CheckMark = styled.span`
  font-size: 9px;
  position: absolute;
  right: 7px;
  top: 7px;

  :before {
    content: '✔';
    display: inline-block;
  }
`;
