import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './i18n';
import Vacancies from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: 'https://c18f04c7da824ac2a7013f2317a0ffba@o4504060823928832.ingest.sentry.io/4504123228684288',
  integrations: [new BrowserTracing()],
  sampleRate: 1.0,
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production',
});

ReactDOM.render(
  <React.StrictMode>
    <Vacancies />
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
