import WarningIcon from '@mui/icons-material/Warning';
import { MessageWrapper, NoResultWrapper } from './NoResult.styles';
import { NoResultProps } from './NoResult.types';

export const NoResult = ({ message }: NoResultProps) => (
  <NoResultWrapper>
    <WarningIcon />
    <MessageWrapper>{message}</MessageWrapper>
  </NoResultWrapper>
);
