import { buttonsColors } from '../../Button/Button.config';
import { ButtonWrapper } from './DialogButton.styles';

export function DialogButton({
  children,
  action = () => {},
  color = buttonsColors.default,
}) {
  return (
    <ButtonWrapper color={color} onClick={action}>
      {children}
    </ButtonWrapper>
  );
}
