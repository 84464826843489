import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_PARTNER, API_URL } from '../../config/urls';
import { isFile } from '../../Helpers';
import {
  getAuthHeader,
  getAuthHeaderMultipart,
  handleResponse,
} from '../../utils/api';
import { PartnerSchema } from '../../store/types/Store.partner.types';
import { raiseToast } from '../../utils/raiseToasts';
import { generatePartnerUpdateUrl } from '../../utils/urlGenerators';

const saveStateThunk = createAsyncThunk('partner/saveState', async (data) => {
  localStorage.setItem('partnerFilters', JSON.stringify(data));
  return data;
});

const addPartnerThunk = createAsyncThunk(
  'partner/addPartner',
  async (data: PartnerSchema, { getState, dispatch }) => {
    const body = new FormData();
    body.append('partner_name', data.partner_name);
    body.append('main_color', data.main_color);
    if (data.address) {
      body.append('address', data.address);
    }
    if (data.phone_number) {
      body.append('phone_number', data.phone_number);
    }
    if (data.logo) {
      body.append('logo', data.logo[0]);
    }
    const request = fetch(API_URL + API_PARTNER, {
      method: 'POST',
      headers: {
        ...getAuthHeaderMultipart(getState()),
      },
      body,
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully added a new partner.');
    }

    return response;
  },
);

const updatePartnerThunk = createAsyncThunk(
  'partner/updatePartner',
  async (
    data: { partnerId: number; payload: PartnerSchema },
    { getState, dispatch },
  ) => {
    const body = new FormData();
    body.append('partner_name', data.payload.partner_name);
    body.append('main_color', data.payload.main_color);
    body.append('address', data.payload.address);
    body.append('phone_number', data.payload.phone_number);
    if (isFile(data.payload.logo[0])) {
      body.append('logo', data.payload.logo[0]);
    } else {
      body.append('logo', '');
    }
    const request = fetch(generatePartnerUpdateUrl(data.partnerId), {
      method: 'PATCH',
      headers: {
        ...getAuthHeaderMultipart(getState()),
      },
      body,
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully updated partner.');
    }
  },
);

const getPartnerThunk = createAsyncThunk(
  'partner/getPartner',
  async (id: number, { getState, dispatch }) => {
    const request = fetch(`${API_URL}${API_PARTNER}${id}/`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const getAllPartnersThunk = createAsyncThunk(
  'partner/getAllPartner',
  async (_data, { getState, dispatch }) => {
    const request = fetch(API_URL + API_PARTNER, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

export const partnerThunks = {
  addPartnerThunk,
  updatePartnerThunk,
  getPartnerThunk,
  getAllPartnersThunk,
  saveStateThunk,
};
