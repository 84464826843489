import { AnyAction } from '@reduxjs/toolkit';
import React, { PropsWithChildren, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppPaths } from '../../../../../config/AppPaths';
import { leadsListThunks } from '../../../../../containers/Leads/redux/LeadsList.thunk';
import {
  Lead,
  LeadDataForm,
  Tag,
} from '../../../../../store/types/Store.leads.types';
import { AppStore } from '../../../../../store/types/Store.types';
import { UserState } from '../../../../../store/types/Store.user.types';
import { LeadForm } from '../../../../Lead/LeadForm/LeadForm';
import { ButtonsConfigType } from '../../DeleteModal/DeleteModal.types';
import { AddingModalContentProps } from './AddingModalContent.types';
import { MyProfilePaths } from '../../../../../config/MyProfilePaths';

export const AddingModalContent = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<AddingModalContentProps>
>(({ closeModal }, ref) => {
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.projects',
  });
  const dispatch = useDispatch();
  const [formLead, setFormLead] = useState<Partial<Lead>>();
  const [processingRequest, setProcessingRequest] = useState(false);
  const companyTags = useSelector<AppStore, Tag[]>(
    (state) => state.leadsRelated.tags.lead,
  )?.map((tag) => tag.name);
  const industryTags = useSelector<AppStore, Tag[]>(
    (state) => state.leadsRelated.tags.industry,
  )?.map((tag) => tag.name);
  const currentUserState = useSelector<AppStore, UserState>(
    (state) => state.user,
  );

  const refreshPageScrollAndExtendLead = (id: string) => {
    window.location.href = `${AppPaths.myProfile}/${MyProfilePaths.myProjects}?id=${id}`;
  };

  const handleSubmit = async (lead: Partial<Lead>) => {
    const data: Partial<LeadDataForm> = {
      ...lead,
      sales_owner: lead.sales_owner?.id || null,
    };
    const result = await dispatch(
      leadsListThunks.createLeadThunk({
        lead: data as unknown as BodyInit,
      }) as unknown as AnyAction,
    );

    if (result.type === leadsListThunks.createLeadThunk.fulfilled.type) {
      closeModal();
      const { id } = result.payload;
      refreshPageScrollAndExtendLead(id);
    } else {
      setFormLead(lead);
    }
  };

  const getButtonsConfig = (lead: Partial<Lead>): ButtonsConfigType => ({
    primaryButton: {
      label: t('saveNewProject'),
      action: async () => {
        if (!processingRequest) {
          setProcessingRequest(true);
          lead && (await handleSubmit(lead));
          setProcessingRequest(false);
        }
      },
    },
  });

  return (
    <LeadForm
      {...{
        currentUserState,
        ref,
        getButtonsConfig,
        industryTags,
        companyTags,
      }}
      dialogTitle={t('addNewProject')}
      handleClose={closeModal}
      leadFetched={formLead}
    />
  );
});
