import PublicOffIcon from '@mui/icons-material/PublicOff';
import PublicIcon from '@mui/icons-material/Public';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { Tooltip } from '../Tooltip/Tooltip.component';
import { StyledButton } from './IconButton.styles';
import { EmployeeVisibilityStatus } from '../../../store/types/Store.candidates.types';
interface PublishButtonProps {
  visibilityStatus: any;
  action: (status: any) => void;
  title: string;
}
export const PublishButton: React.FC<PublishButtonProps> = ({
  visibilityStatus,
  action,
  title,
}) => {
  const renderIcon = () => {
    switch (visibilityStatus) {
      case EmployeeVisibilityStatus.COMPANY:
        return <PublicOffIcon style={{ fontSize: '16px' }} />;
      case EmployeeVisibilityStatus.PARTNERS:
        return <HandshakeIcon style={{ fontSize: '16px' }} />;
      case EmployeeVisibilityStatus.EVERYONE:
        return <PublicIcon style={{ fontSize: '16px' }} />;
      default:
        return <>?</>;
    }
  };
  const nextStatus = () => {
    const idx = Object.values(EmployeeVisibilityStatus).indexOf(
      visibilityStatus,
    );
    if (idx === -1 || idx >= Object.values(EmployeeVisibilityStatus).length - 1)
      return EmployeeVisibilityStatus.COMPANY;
    return Object.values(EmployeeVisibilityStatus)[idx + 1];
  };

  return (
    <Tooltip title={title || 'Publish'} position="table">
      <StyledButton
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();
          action(nextStatus());
        }}
      >
        {renderIcon()}
      </StyledButton>
    </Tooltip>
  );
};
