import { useSelector } from 'react-redux';
import companyLogo from '../../common/images/navbar_logo.svg';
import companyMotto from '../../common/images/company_motto.png';
import {
  StyledAddress,
  StyledCompanyLogo,
  StyledCompanyMotto,
  StyledHeader,
  StyledNavImg,
  StyledPartnerContainer,
  StyledPartnerName,
  StyledPhoneNumber,
} from './Header.styles';
import { COLORS } from '../../../theme/colors';

export const Header = () => {
  const user = useSelector((state) => state.user.user);
  const isPartner = user?.department === 'Partner';

  return (
    <StyledHeader>
      <StyledCompanyLogo
        color={user?.partner?.main_color || COLORS.accent.primary}
      >
        <StyledNavImg
          src={user?.partner?.logo || companyLogo}
          alt="company-logo"
        />
      </StyledCompanyLogo>
      {/* {isPartner ? (
        <StyledPartnerContainer>
          <StyledPartnerName>{user.partner.partner_name}</StyledPartnerName>
          {user.partner.address?.split('\n').map((line, index) => (
            <StyledAddress key={index}>{line}</StyledAddress>
          ))}
          <StyledPhoneNumber>
            {user.partner.phone_number || ''}
          </StyledPhoneNumber>
        </StyledPartnerContainer>
      ) : (
        <StyledCompanyMotto>
          <StyledNavImg src={companyMotto} alt="company-motto" />
        </StyledCompanyMotto>
      )} */}
    </StyledHeader>
  );
};
