import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { styled as styledMaterialUI } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeadContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 393px;
  @media (max-width: 686px) {
    width: 100%;
  }
`;

export const StyledHeadline = styled.p`
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  margin: 0;
`;

export const Line = styled.hr`
  border-top: 1px solid #eff3fa;
  margin-bottom: 5px;
  margin-top: 0;
  width: 393px;
  @media (max-width: 686px) {
    width: 100%;
  }
`;

export const StyledList = styledMaterialUI(List)({
  width: '100%',
  maxWidth: 412,
  position: 'relative',
  overflow: 'auto',
  maxHeight: 240,
  '& ul': { padding: 0 },
  '@media (max-width: 668px)': {
    width: '100%',
    maxWidth: 'unset',
  },
});

export const StyledListItem = styledMaterialUI(ListItem)({
  padding: 0,
});

export const StyledListItemText = styledMaterialUI(ListItemText)({
  '& .MuiListItemText-primary': {
    fontSize: '13px',
    textTransform: 'capitalize',
    color: '#000000',
    fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    fontWeight: '400',
  },
});

export const StyledListItemButton = styledMaterialUI(ListItemButton)({
  padding: '5px 16px 5px 12px',
  '&:hover': {
    backgroundColor: '#EAF0F8',
  },
});

export const StyledLink = styledMaterialUI(Link)({
  color: 'inherit',
  fontSize: '14px',
  maxWidth: '395px',
  width: '100%',
  '&:hover': {
    textDecoration: 'none',
  },
});

export const StyledBox = styledMaterialUI(Box)({
  '@media (max-width: 668px)': {
    width: '100%',
  },
  padding: '20px',
});
