import { createSlice } from '@reduxjs/toolkit';
import {
  ClientListConfig,
  ClientListId,
  ClientListProfile,
  ClientListProfileChangesStore,
  ClientListProfileStatuses,
} from '../../../store/types/Store.clientList.types';
import { Currency } from '../../../store/types/Store.types';

const initialState: ClientListConfig = {
  is_basket_visible: false,
  was_feedback_sent: false,
  basket_content: [],
  tab: ClientListProfileStatuses.PROPOSED,
  isEditCandidateModalOpen: false,
  clientListProfilesChanges: [],
  filter: '',
};

const clientListConfigSlice = createSlice({
  name: 'clientListConfigDict',
  initialState,
  reducers: {
    toggleBasket: (state) => ({
      ...state,
      is_basket_visible: !state.is_basket_visible,
    }),
    setBasketState: (state, action: { payload: ClientListProfile[] }) => ({
      ...state,
      basket_content: action.payload,
    }),
    closeBasket: (state) => ({ ...state, is_basket_visible: false }),
    setTab: (state, action: { payload: ClientListProfileStatuses }) => ({
      ...state,
      tab: action.payload,
    }),
    setWasFeedbackSent: (state, action: { payload: boolean }) => ({
      ...state,
      was_feedback_sent: action.payload,
    }),
    setFilter: (state, action) => ({ ...state, filter: action.payload }),
    addChanges(
      state: ClientListConfig,
      action: {
        payload: {
          changes: ClientListProfileChangesStore;
          changesOccured: boolean;
        };
      },
    ) {
      let copiedChanges = [...state.clientListProfilesChanges];
      let objIdxToModify: number = state.clientListProfilesChanges.findIndex(
        (el) =>
          el.employeeId === action.payload.changes.employeeId &&
          el.clientListId === action.payload.changes.clientListId,
      );

      if (objIdxToModify < 0) {
        objIdxToModify = copiedChanges.length;
      }

      // if no changes occur we null out the field in the changes object
      // this is made so we do not send unecessary requests when user changes
      // and then makes back the changes.
      if (!action.payload.changesOccured) {
        // typescript does not understand that Object.keys() will return
        // keys from object and NOT ARBITRARY STRINGS, so unfortunatly i
        // had to add this ugly type information
        // eslint-disable-next-line no-restricted-syntax
        for (const k of Object.keys(
          action.payload.changes,
        ) as (keyof ClientListProfileChangesStore)[]) {
          if (
            k !== 'employeeId' &&
            k !== 'clientListId' &&
            action.payload.changes[k]
          ) {
            action.payload.changes[k] = undefined;
          }
        }
      }
      copiedChanges[objIdxToModify] = {
        ...state.clientListProfilesChanges[objIdxToModify],
        ...action.payload.changes,
      };

      // if we have "empty changes" I.e. would not need a PATCH request,
      // we filter out those useless changes below
      copiedChanges = copiedChanges.filter((change) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const k of Object.keys(
          change,
        ) as (keyof ClientListProfileChangesStore)[]) {
          if (
            k !== 'employeeId' &&
            k !== 'clientListId' &&
            change[k] !== undefined
          ) {
            return true;
          }
        }
        return false;
      });
      return {
        ...state,
        clientListProfilesChanges: copiedChanges,
      };
    },
    changeClientListEmployeeCurrency(
      state: ClientListConfig,
      action: {
        payload: {
          clientListId: ClientListId;
          currency: Currency;
          employeeIds: string[];
        };
      },
    ) {
      const copiedChanges = [...state.clientListProfilesChanges];

      // eslint-disable-next-line no-restricted-syntax
      for (const employeeId of action.payload.employeeIds) {
        let idxToModify = copiedChanges.findIndex(
          (el) =>
            el.employeeId === employeeId &&
            el.clientListId === action.payload.clientListId,
        );

        if (idxToModify < 0) {
          idxToModify = copiedChanges.length;
        }

        copiedChanges[idxToModify] = {
          ...copiedChanges[idxToModify],
          employeeId,
          clientListId: action.payload.clientListId,
          currency: action.payload.currency,
          rateOfPay: null,
        };
      }

      return {
        ...state,
        clientListProfilesChanges: copiedChanges,
      };
    },
    clearClientListProfileChanges(
      state: ClientListConfig,
      action: { payload: ClientListId },
    ) {
      return {
        ...state,
        clientListProfilesChanges: state.clientListProfilesChanges.filter(
          (el) => el.clientListId !== action.payload,
        ),
      };
    },
    closeEditCandidateModal(state: ClientListConfig) {
      return {
        ...state,
        isEditCandidateModalOpen: false,
      };
    },
    openEditCandidateModal(state: ClientListConfig) {
      return {
        ...state,
        isEditCandidateModalOpen: true,
      };
    },
  },
});

export const actions = { ...clientListConfigSlice.actions };
export const { reducer } = clientListConfigSlice;
