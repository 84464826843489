import WarningIcon from '@mui/icons-material/Warning';
import { SimilarCompanyProps } from '../LeadForm.types';
import {
  DismissButton,
  FoundCompanyWrapper,
  ReviewButton,
  SimilarCompanyContainer,
  WarningText,
  WarningTextWrapper,
} from './SimilarName.styles';

export const SimilarName = ({
  similarCompany,
  changeNameForSimilar,
  setSimilarCompany,
}: SimilarCompanyProps) => {
  return (
    <SimilarCompanyContainer>
      <WarningIcon fontSize="small" color="error" />
      <WarningTextWrapper>
        <WarningText>Company with similar name already exists: </WarningText>
        <FoundCompanyWrapper>
          <WarningText>{similarCompany}</WarningText>
          <ReviewButton onClick={() => changeNameForSimilar()}>
            Change
          </ReviewButton>
          <DismissButton onClick={() => setSimilarCompany(undefined)}>
            Dismiss
          </DismissButton>
        </FoundCompanyWrapper>
      </WarningTextWrapper>
    </SimilarCompanyContainer>
  );
};
