import { Checkbox, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NotificationRadioButtonChecked from './icons/NotificationRadioButtonChecked';
import NotificationRadioButtonUnchecked from './icons/NotificationRadioButtonUnchecked';

const NotificationReadButton = ({
  onNotificationRead,
  checked,
}: {
  onNotificationRead: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}) => {
  const { t } = useTranslation(['common'], { keyPrefix: 'notifications' });
  return (
    <Tooltip title={t('mark_as_read')}>
      <Checkbox
        checked={checked}
        icon={<NotificationRadioButtonUnchecked width="20px" />}
        checkedIcon={<NotificationRadioButtonChecked width="20px" />}
        onChange={onNotificationRead}
      />
    </Tooltip>
  );
};

export default NotificationReadButton;
