import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_LEADS } from '../../../config/urls';
import { CandidateStatusType } from '../../../store/types/Store.candidates.types';
import {
  LeadIdType,
  LeadProfileIdType,
} from '../../../store/types/Store.home.types';
import { getAuthHeader, handleResponse } from '../../../utils/api';
import { raiseToast } from '../../../utils/raiseToasts';
import {
  generateLeadRequestCandidateDetailsUrl,
  generateLeadRequestCandidatesUrl,
  generateLeadRequestUrl,
} from '../../../utils/urlGenerators';
import i18n from '../../../i18n';

const getLeadRequestCandidatesThunk = createAsyncThunk(
  'leadProfileCandidates/getLeadRequestCandidates',
  async (
    data: { leadId: LeadIdType; requestId: LeadProfileIdType },
    { getState, dispatch },
  ) => {
    const URL = generateLeadRequestCandidatesUrl(
      data.leadId,
      data.requestId as unknown as number,
    );
    const request = fetch(`${URL}`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);

interface UpdateCandidateStatusArgs {
  candidate_id: number;
  status: { status: CandidateStatusType; reason?: string };
  lead_profile_id: LeadProfileIdType;
  lead_id: LeadIdType;
}
export interface UpdateCandidateStatusResult {
  meta: {
    arg: {
      candidate_id: number;
      lead_id: LeadIdType;
      lead_profile_id: LeadProfileIdType;
      status: {
        reason: string;
        status: CandidateStatusType;
      };
    };
  };
}

const updateCandidateStatusThunk = createAsyncThunk<
  unknown,
  UpdateCandidateStatusArgs
>(
  'leadProfileCandidates/updateCandidateStatus',
  async (data, { getState, dispatch }) => {
    const request = fetch(
      `${API_LEADS}${data.lead_id}/profiles/${data.lead_profile_id}/candidates/${data.candidate_id}/status/`,
      {
        method: 'POST',
        body: JSON.stringify(data.status),
        headers: {
          ...getAuthHeader(getState()),
        },
      },
    );

    const response = await handleResponse(request, dispatch);
    if (response) {
      const message = i18n.t('raiseToastMessage.candidate_status_updated', {
        ns: 'common',
      });
      raiseToast.success(message);
    }
  },
);

const updateCandidateNotesThunk = createAsyncThunk(
  'leadProfileCandidates/updateCandidateNotes',
  async (
    data: {
      lead_id: LeadIdType;
      candidate_id: number;
      lead_profile_id: LeadProfileIdType;
      notes: { employee: string; hr_notes?: string; partner_notes?: string };
    },
    { getState, dispatch },
  ) => {
    const request = fetch(
      `${API_LEADS}${data.lead_id}/profiles/${data.lead_profile_id}/candidates/${data.candidate_id}/`,
      {
        method: 'PATCH',
        body: JSON.stringify(data.notes),
        headers: {
          ...getAuthHeader(getState()),
        },
      },
    );

    const response = await handleResponse(request, dispatch);
    if (response) {
      const message = i18n.t('raiseToastMessage.candidate_notes_updated', {
        ns: 'common',
      });
      raiseToast.success(message);
    }
  },
);

interface DeleteCandidateArgs {
  lead_id: LeadIdType;
  lead_profile_id: LeadProfileIdType;
  candidate_id: number;
}

const deleteCandidateThunk = createAsyncThunk<unknown, DeleteCandidateArgs>(
  'leadProfileCandidates/deleteCandidate',
  async (data, { getState, dispatch }) => {
    const URL = generateLeadRequestCandidateDetailsUrl(
      data.lead_id,
      data.lead_profile_id as unknown as number,
      data.candidate_id,
    );
    const request = fetch(`${URL}`, {
      method: 'DELETE',
      headers: {
        ...getAuthHeader(getState()),
      },
    });
    const response = await handleResponse(request, dispatch);
    if (response) {
      const message = i18n.t('raiseToastMessage.candidate_deleted', {
        ns: 'common',
      });
      raiseToast.success(message);
    }
  },
);

interface AddSuggestedCandidatesThunkArgs {
  leadId: LeadIdType;
  requestId: LeadProfileIdType;
  payload: { employees: string[] };
}

const addSuggestedCandidatesThunk = createAsyncThunk<
  unknown,
  AddSuggestedCandidatesThunkArgs
>(
  'leadProfileCandidates/addSuggestedCandidates',
  async (data, { getState, dispatch }) => {
    const request = fetch(
      `${generateLeadRequestUrl(data.leadId, data.requestId)}candidates/`,
      {
        method: 'POST',
        body: JSON.stringify(data.payload),
        headers: {
          ...getAuthHeader(getState()),
        },
      },
    );

    const response = await handleResponse(request, dispatch);
    if (response) {
      const message = i18n.t('raiseToastMessage.suggested_candidates_added', {
        ns: 'common',
      });
      raiseToast.success(message);
    }

    return response;
  },
);

export const leadProfileCandidatesThunks = {
  updateCandidateStatusThunk,
  updateCandidateNotesThunk,
  deleteCandidateThunk,
  addSuggestedCandidatesThunk,
  getLeadRequestCandidatesThunk,
};
