import { API_LEADS_ATTACHMENTS } from '../config/urls';
import { BlindCvAttachment } from '../store/types/Store.leads.types';

const token = localStorage.getItem('token');

const authHeaders = {
  'Authorization-Token': `Token ${token}`,
};

export const postLeadFile = (files: BlindCvAttachment[]) => {
  const body = new FormData();
  body.append('file', files[0] as unknown as Blob);

  return fetch(API_LEADS_ATTACHMENTS, {
    method: 'POST',
    headers: authHeaders,
    body,
  });
};
