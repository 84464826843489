import { useTranslation } from 'react-i18next';
import { Counter } from '../../../../../../components/common/Counter/Counter';
import { SearchField } from '../../../../../../components/common/SearchField/SearchField';
import { HeaderProps } from './Header.types';
import { StyledContainer } from './Header.styles';
import { MainHeadline, SearchContainer } from '../../../MyProfile.styles';

export const Header = ({
  numberOfWatched,
  filteredValue,
  setFilteredValue,
}: HeaderProps) => {
  const { t } = useTranslation(['user'], {
    keyPrefix: 'my_profile',
  });
  return (
    <StyledContainer>
      <MainHeadline>
        {t('watched')}
        <Counter number={numberOfWatched} color="#FFFFFF" />
      </MainHeadline>
      <SearchContainer>
        <SearchField initialValue={filteredValue} onChange={setFilteredValue} />
      </SearchContainer>
    </StyledContainer>
  );
};
