import styled from 'styled-components';

export const StyledCustomLegend = styled.span`
  align-items: center;
  color: #101840;
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  gap: 7px;
  &:before {
    background: ${({ color }) => color};
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 7px;
    width: 7px;
  }
`;

export const StyledNumber = styled.span`
  color: #101840;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
`;

export const LegendContainer = styled.p`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 218px;
  @media (max-width: 699px) {
    width: 100%;
  }
`;
