import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../config/routes';
import { actions as userActions } from '../User/User.slice';
import { actions as apiControllerActions } from './ApiController.slice';
import { EResponseCode } from './ApiController.types';

export const ApiController: FC = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const responseCode = useSelector<any>(
    (state) => state.apiController.responseCode,
  );

  useEffect(() => {
    dispatch(apiControllerActions.setResponseCode(undefined));
  }, [responseCode]);

  const handleUnauthenticatedRedirect = () => {
    navigate(routes.login.path);
    dispatch(userActions.clearUserData());
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  };

  responseCode === EResponseCode.Unauthenticated &&
    handleUnauthenticatedRedirect();

  return <>{children}</>;
};
