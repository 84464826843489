import styled from 'styled-components';

export const StyledLoader = styled.div`
  height: 50px;
  left: 50%;
  margin-left: -45px;
  margin-top: -75px;
  position: absolute;
  top: 50%;
  width: 100px;
`;
