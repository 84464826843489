import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { Dialog } from '../../../Dialog/DEPR_Dialog';
import { InviteCompanyModalContentProps } from './InviteCompanyModalContent.types';
import { FormWrapper } from './InviteCompanyModalContent.styles';
import { Input } from '../../../Input';
import { Option } from '../../../Selector/Selector.types';
import { Selector } from '../../../Selector/Selector.component';
import { AppStore } from '../../../../../store/types/Store.types';
import { CountryInterface } from '../../../../../store/types/Store.company';
import { useEffectAsync } from '../../../../../utils/useEffectAsync';
import { companyThunks } from '../../../../../containers/Company/Company.thunk';

export const InviteCompanyModalContent = ({
  handleSubmit,
  closeModal,
}: InviteCompanyModalContentProps) => {
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.partnerCompanies',
  });
  const dispatch = useDispatch();
  const countriesOptions = useSelector<AppStore, CountryInterface>(
    (state) => state.company.countries,
  );
  const [formData, setFormData] = useState({
    vat_number: '',
    country: null,
  });
  const buttonsConfig = {
    primaryButton: {
      label: t('invite', { ns: 'common', keyPrefix: 'buttons' }),
      action: () => {
        formData.country && handleSubmit(formData.vat_number, formData.country);
      },
    },
  };

  useEffectAsync(async () => {
    await dispatch(companyThunks.getCountriesThunk() as unknown as AnyAction);
  }, []);

  const handleInputChange = (e: React.BaseSyntheticEvent<object, any, any>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (
    name: string,
    newValue: string[] | string | Option | number | number[],
  ) => {
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  return (
    <Dialog
      dialogTitle={t('invite_new_user')}
      buttonsConfig={buttonsConfig}
      handleClose={closeModal}
    >
      <FormWrapper>
        <Input
          label={t('vat_number')}
          name="vat_number"
          required
          displayAsRequired
          value={formData.vat_number}
          onChange={handleInputChange}
        />
        <Selector
          label={t('company_country')}
          name="country"
          required
          value={formData.country}
          options={Object.entries(countriesOptions).map(([code, name]) => ({
            value: code,
            name,
          }))}
          size="medium"
          action={(event: SelectChangeEvent<Option>) =>
            handleSelectChange('country', event.target.value as Option)
          }
        />
      </FormWrapper>
    </Dialog>
  );
};
