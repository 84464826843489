import { AnyAction } from '@reduxjs/toolkit';
import React, { PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { leadsListThunks } from '../../../../../containers/Leads/redux/LeadsList.thunk';
import { Lead } from '../../../../../store/types/Store.leads.types';
import { AppStore } from '../../../../../store/types/Store.types';
import { DeleteModal } from '../../DeleteModal';
import { DeletingModalContentProps } from './DeletingModalContent.types';

export const DeletingModalContent = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<DeletingModalContentProps>
>(({ leadId, closeModal }, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'company'], {
    keyPrefix: 'vacancies',
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const lead = useSelector<AppStore, Lead[]>(
    (state) => state.leadsRelated.list,
  );

  const companyName = lead.find((l) => l.id === Number(leadId));

  const removeErrorParam = () => {
    if (searchParams.has('id')) {
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  };

  const handleDeleting = async () => {
    await dispatch(
      leadsListThunks.deleteLeadThunk({
        leadId: leadId.toString(),
      }) as unknown as AnyAction,
    );
    removeErrorParam();
    dispatch(leadsListThunks.getLeadsThunk() as unknown as AnyAction);
    closeModal();
  };

  const buttonsConfig = {
    primaryButton: {
      label: t('confirm_delete', { ns: 'common', keyPrefix: 'buttons' }),
      action: handleDeleting,
    },
  };

  const content = (
    <>
      {t('projects.do_you_want_to_remove', {
        ns: 'company',
        keyPrefix: 'company_details',
      })}{' '}
      {t('projects.project', {
        ns: 'company',
        keyPrefix: 'company_details',
      })}{' '}
      {companyName?.name}?
    </>
  );

  return (
    <DeleteModal
      buttonsConfig={buttonsConfig}
      handleClose={closeModal}
      ref={ref}
      content={content}
    />
  );
});
