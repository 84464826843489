import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';
import { StyledInterfaceProps } from './Input.types';

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  position: relative;
  width: 100%;
`;

export const StyledInput = styled.input<StyledInterfaceProps>`
  border: 1px solid
    ${({ error }) => (error ? COLORS.error.main : COLORS.accent.border)};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${({ error }) => (error ? COLORS.error.main : COLORS.typography.main)};
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  height: 50px;
  padding: 18px;
  width: 100%;

  &::placeholder {
    color: ${COLORS.stroke.secondary};
    font-weight: 400;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
  }
`;

export const InputIcon = styled.span`
  cursor: pointer;
  position: absolute;
  right: 17px;
  top: 38px;
`;
