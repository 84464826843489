import { useEffect, useState } from 'react';
import { FormLabel } from '../FormLabel/FormLabel';
import { StyledInput, StyledInputContainer, InputIcon } from './Input.styles';
import { InputProps } from './Input.types';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye.svg';
import { ReactComponent as EyeOffIcon } from '../../../assets/icons/eye-off.svg';

export const Input = ({
  label,
  name,
  type = 'text',
  value,
  defaultValue,
  onChange,
  placeholder = label,
  onKeyDown,
  required,
  disabled,
  displayAsRequired,
  customStyles,
  maxLength,
  error,
}: InputProps) => {
  const [isShownPassword, setIsShownPassword] = useState(false);
  const [currentType, setCurrentType] = useState(type);

  useEffect(() => {
    if (type === 'password') {
      setCurrentType(isShownPassword ? 'text' : 'password');
    }
  }, [isShownPassword]);

  const toggleIsShownPassword = () => setIsShownPassword(!isShownPassword);

  return (
    <StyledInputContainer style={customStyles}>
      {label && (
        <FormLabel
          htmlFor={name}
          label={error ? `${label}: ${error}` : label}
          disabled={disabled}
          required={displayAsRequired}
          error={!!error}
        />
      )}
      <StyledInput
        id={name}
        name={name}
        type={currentType}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        required={required}
        maxLength={maxLength}
        error={!!error}
      />
      {type === 'password' && (
        <InputIcon onClick={toggleIsShownPassword}>
          {isShownPassword ? <EyeIcon /> : <EyeOffIcon />}
        </InputIcon>
      )}
    </StyledInputContainer>
  );
};
