import { createSlice } from '@reduxjs/toolkit';
import { ValidatedCv } from '../../../../store/types/Store.employeePage.types';
import { employeePageCvsValidationThunks } from './EmployeePageCvsValidation.thunk';

const { getEmployeePageValidatedCvsThunk } = employeePageCvsValidationThunks;

const initialState: ValidatedCv[] = [];

const employeePageCvsValidationSlice = createSlice({
  name: 'employeePageCvs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getEmployeePageValidatedCvsThunk.fulfilled,
      (state, action) => {
        action.payload.forEach((cv: ValidatedCv) => {
          state.push(cv);
        });
      },
    );
  },
});

export const actions = { ...employeePageCvsValidationSlice.actions };
export const { reducer } = employeePageCvsValidationSlice;
