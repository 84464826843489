import { useContext, useEffect } from 'react';
import { WebSocketEventAPI } from '../utils/websockets/eventTypes';
import {
  WebSocketEventContext,
  addNewWebSocketEvent,
} from '../utils/websockets/helpers';

export const useWebSocketEvent = (props: WebSocketEventAPI) => {
  const ctx = useContext(WebSocketEventContext);

  useEffect(() => {
    /*
    This has to be declared inside of useEffect
    Otherwise react would be updating and refreshing
    this component in circle
    */
    let isMounted = true;

    if (isMounted) {
      console.log('Websocket connected');

      addNewWebSocketEvent(ctx, props);
    }
    return () => {
      isMounted = false;
    };
  }, [ctx.setOnMessageEvents]);
};
