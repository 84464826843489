import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_NOTIFICATIONS } from '../../config/urls';
import { NotificationContent } from '../../interfaces/Notifications.types';
import type { RootState } from '../../store/store';
import { getAuthHeader, handleResponse } from '../../utils/api';

const getMyNotifications = createAsyncThunk(
  'notifications/get',
  async (_, thunkAPI) => {
    const resp = await fetch(`${API_NOTIFICATIONS}my/`, {
      method: 'GET',
      headers: getAuthHeader(thunkAPI.getState()),
    });

    return await handleResponse(resp);
  },
);

const changeNotification = createAsyncThunk(
  'notifications/update',
  async (
    data: {
      notifId: string;
      body: BodyInit;
    },
    thunkAPI,
  ): Promise<NotificationContent> => {
    const resp = await fetch(`${API_NOTIFICATIONS}${data.notifId}/`, {
      method: 'PATCH',
      headers: getAuthHeader(thunkAPI.getState()),
      body: data.body,
    });
    return await handleResponse(resp);
  },
);

const markAllNotificationsAsRead = createAsyncThunk(
  'notification/markAllRead',
  async (_, thunkAPI): Promise<any> => {
    const state: RootState = thunkAPI.getState() as RootState;
    const notifArray = state.notifications.notificationArray;

    const changeReadStatusTasks = notifArray
      .filter((notif) => notif.isRead)
      .map(async (notif) => {
        const resp = await fetch(`${API_NOTIFICATIONS}${notif.id}/`, {
          method: 'PATCH',
          headers: getAuthHeader(thunkAPI.getState()),
          body: JSON.stringify({ is_read: true }),
        });
        return await handleResponse(resp);
      });
    await Promise.all(changeReadStatusTasks);
  },
);

export const notificationsThunks = {
  getMyNotifications,
  changeNotification,
  markAllNotificationsAsRead,
};
