import { Candidate } from '../../../../../../store/types/Store.candidates.types';
import {
  HomeLeadProfile,
  LeadProfileIdType,
  SuggestedCandidate,
} from '../../../../../../store/types/Store.home.types';

export const getProfileId = (
  leadProfile?: HomeLeadProfile,
  candidateInfo?: SuggestedCandidate | Candidate,
  leadProfileId?: string,
): LeadProfileIdType | undefined => {
  if (leadProfile?.lead_profile_id) return leadProfile?.lead_profile_id;
  if (typeof candidateInfo?.lead_profile === 'number')
    return candidateInfo?.lead_profile;
  if (leadProfileId) return Number(leadProfileId);
  return undefined;
};
