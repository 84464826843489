/* eslint-disable complexity */
import {
  faTimes,
  faUnlockAlt,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { AnyAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isHrSalesOrAdmin } from '../../../../Helpers';
import { routes } from '../../../../config/routes';
import { clientListCredentialsThunks } from '../../../../containers/ClientList/redux/ClientListCredentials.thunk';
import { ExternalClientCredentials } from '../../../../store/types/Store.clientList.types';
import { AppStore } from '../../../../store/types/Store.types';
import NavbarNotification from '../../Notifications/NavbarNotification';
import {
  CloseIcon,
  MainNavSection,
  NavSideIcon,
  StyledBreak,
  StyledLink,
  StyledLinks,
  StyledVerticalLinks,
  UserSection,
} from './NavbarItems.styles';
import { NavbaritemsProps } from './NavbarItems.types';
import { AddButton } from '../../Buttons/AddButton';
import { useEmployeeModal } from '../../Modals/Employees/EmployeesModal/useEmployeeModal';
import { EmployeesModalFactory } from '../../Modals/Employees/EmployeesModal';
import { AppPaths } from '../../../../config/AppPaths';
import { LanguageSwitch } from '../../LanguageSwitch/LanguageSwitch.component';

export const NavbarItems = ({
  id,
  collapsible,
  user,
  externalClientAuthData,
  dropdownToggler,
}: NavbaritemsProps) => {
  const Component = collapsible ? StyledVerticalLinks : StyledLinks;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['common'], {
    keyPrefix: 'navbar',
  });
  const unregisteredLogout = () => {
    dispatch(clientListCredentialsThunks.logoutThunk() as unknown as AnyAction);
  };
  const { modalData }: any = useEmployeeModal(['add']);

  const externalClientCredentials = useSelector<
    AppStore,
    ExternalClientCredentials
  >((state) => state.clientListRelated.credentials);

  const leftNavbarItems = [
    {
      label: t('candidates'),
      path: routes.main.path,
      end: false,
      isShown: true,
      icon: false,
    },
    {
      label: t('job_offers'),
      path: routes.vacancies.path,
      end: true,
      isShown: !!user && isHrSalesOrAdmin(user.user),
      icon: false,
    },
    // {
    //   label: 'Lists',
    //   path: routes.listsOfCandidatesForSharing.path,
    //   end: false,
    //   isShown: !!user && (isHrSalesOrAdmin(user.user) || isPartner(user.user)),
    //   icon: false,
    // },
  ];

  const rightNavbarItems = [
    // {
    //   label: 'Users',
    //   path: routes.users.path,
    //   end: false,
    //   isShown: !!user && user.user?.is_admin,
    //   icon: false,
    // },
    {
      label: t('my_account'),
      path: routes.myProfile.path,
      end: false,
      isShown: true,
      icon: <NavSideIcon icon={faUserCircle} />,
    },
  ];

  return (
    <Component id={id}>
      <EmployeesModalFactory {...modalData} />
      {Component === StyledVerticalLinks && (
        <CloseIcon icon={faTimes} onClick={dropdownToggler} />
      )}
      {!externalClientAuthData && user ? (
        <>
          <MainNavSection>
            {leftNavbarItems.map((navbarItem) => (
              <StyledLink
                end={navbarItem.end}
                to={{
                  pathname: navbarItem.path,
                }}
                onClick={dropdownToggler}
              >
                {navbarItem.icon}
                {navbarItem.label}
              </StyledLink>
            ))}
          </MainNavSection>
          <Box sx={{ display: 'flex', gap: '40px' }}>
            {Component === StyledLinks ? (
              <UserSection>
                <AddButton
                  variant="secondary"
                  action={() => navigate(AppPaths.createEmployee)}
                  marginRight="18px"
                >
                  {t('add_candidates')}
                </AddButton>
                <NavbarNotification />
                {rightNavbarItems.map((navbarItem) => (
                  <StyledLink
                    end={navbarItem.end}
                    to={{
                      pathname: navbarItem.path,
                    }}
                    onClick={dropdownToggler}
                  >
                    {navbarItem.icon}
                    {navbarItem.label}
                  </StyledLink>
                ))}
              </UserSection>
            ) : (
              <>
                <MainNavSection>
                  {user.user?.is_admin && (
                    <StyledLink
                      to={{
                        pathname: routes.users.path,
                      }}
                      onClick={dropdownToggler}
                    >
                      Users
                    </StyledLink>
                  )}
                </MainNavSection>
                <StyledBreak />
                <UserSection>
                  <StyledLink
                    to={{
                      pathname: routes.myProfile.path,
                    }}
                    onClick={dropdownToggler}
                  >
                    {t('my_account')}
                    <NavSideIcon icon={faUserCircle} />
                  </StyledLink>
                  <StyledLink
                    to={{
                      pathname: routes.logout.path,
                    }}
                  >
                    Log out
                    <NavSideIcon icon={faUnlockAlt} />
                  </StyledLink>
                </UserSection>
              </>
            )}
            <LanguageSwitch />
          </Box>
        </>
      ) : (
        <>
          {externalClientAuthData && (
            <>
              <StyledBreak />
              <UserSection>
                <StyledLink
                  to={generatePath(routes.clientListLogin.path, {
                    id: externalClientCredentials.id,
                  })}
                  onClick={unregisteredLogout}
                >
                  Log out
                  <NavSideIcon icon={faUnlockAlt} />
                </StyledLink>
              </UserSection>
            </>
          )}
        </>
      )}
    </Component>
  );
};
