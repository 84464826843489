import React from 'react';
import {
  ToastText,
  ToastTitle,
  StyledContentWithHeader,
} from './ToastContent.styles';

export const ToastContent = ({ title, content }) => {
  content = Array.isArray(content) ? content : [content];
  return (
    <StyledContentWithHeader>
      {title && <ToastTitle>{title}</ToastTitle>}
      {content.map((line, index) => (
        <ToastText key={index}>{line}</ToastText>
      ))}
    </StyledContentWithHeader>
  );
};
