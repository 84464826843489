import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_HR_LISTS_URL,
  API_UPDATE_EMPLOYEES_IN_HR_LIST,
} from '../../../../config/urls';
import { getAuthHeader, handleResponse } from '../../../../utils/api';
import { raiseToast } from '../../../../utils/raiseToasts';

const getHrListEmployeesThunk = createAsyncThunk(
  'hrLists/getHrListEmployees',
  async (id: string, { getState, dispatch }) => {
    const request = fetch(`${API_HR_LISTS_URL}${id}/employees`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);

const addEmployeeToHrListThunk = createAsyncThunk(
  'hrLists/addEmployee',
  async (
    data: { hrListId: string; candidates: string[] },
    { getState, dispatch },
  ) => {
    const request = fetch(
      `${API_UPDATE_EMPLOYEES_IN_HR_LIST}${data.hrListId}/add_candidates`,
      {
        method: 'POST',
        body: JSON.stringify({
          candidates: data.candidates,
          share_id: data.hrListId,
        }),
        headers: getAuthHeader(getState()),
      },
    );

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully added the employee to the list.');
    }

    return response;
  },
);

const deleteCandidateFromListThunk = createAsyncThunk(
  'hrLists/deleteCandidateFromList',
  async (
    data: { listId: string; employeeId: string },
    { getState, dispatch },
  ) => {
    const request = fetch(
      `${API_UPDATE_EMPLOYEES_IN_HR_LIST}${data.listId}/${data.employeeId}`,
      {
        method: 'DELETE',
        headers: getAuthHeader(getState()),
      },
    );

    const result = await handleResponse(request, dispatch);
    raiseToast.success('Successfully deleted candidate from the list');
    return result;
  },
);

export const hrListCandidatesThunks = {
  addEmployeeToHrListThunk,
  deleteCandidateFromListThunk,
  getHrListEmployeesThunk,
};
