import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export const GeneralPayments = () => {
  const { t } = useTranslation(['user'], { keyPrefix: 'my_profile' });
  return (
    <>
      <Typography component="h2" variant="h6">
        {t('general_payments')}
      </Typography>
    </>
  );
};
