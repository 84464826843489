import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ComputerIcon from '@mui/icons-material/Computer';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { useTranslation } from 'react-i18next';
import { StyledSpan } from '../../../containers/Leads/LeadsTable/LeadsTable.styles';
import {
  addDotsToString,
  getDateString,
  getDateTimeString,
  getUrlWithoutProtocol,
  URLToNormalView,
} from '../../../Helpers';
import { DeleteButton } from '../../common/IconButtons/DeleteButton';
import { EditButton } from '../../common/IconButtons/EditButton';
import { Tooltip } from '../../common/Tooltip/Tooltip.component';
import {
  ControlRow,
  LeadInfoContainer,
  Line,
  RightControls,
  StyledBox,
  StyledFormControlLabel,
  StyledHeadline,
  StyledLeadInfoItem,
  StyledLink,
  StyledSwitch,
} from './AdditionalLeadInfo.styles';

const stringLimit = 26;

export const AdditionalLeadInfo = ({
  leadId,
  leadStatus,
  leadIndustry,
  leadWebsite,
  createdAt,
  salesOwner,
  switchAction,
  editLead,
  deleteLead,
}) => {
  const { t } = useTranslation(['user', 'common', 'company'], {
    keyPrefix: 'my_profile.projects',
  });

  return (
    <StyledBox>
      <StyledHeadline>{t('information')}</StyledHeadline>
      <Line />
      <LeadInfoContainer>
        <StyledLeadInfoItem>
          <DateRangeIcon color="disabled" sx={{ fontSize: 20 }} />
          <Tooltip title={getDateTimeString(createdAt)} position="close">
            <StyledSpan>{getDateString(createdAt)}</StyledSpan>
          </Tooltip>
        </StyledLeadInfoItem>
        <StyledLeadInfoItem>
          <Tooltip title={t('owner')} position="close">
            <AccountCircleIcon color="disabled" sx={{ fontSize: 20 }} />
          </Tooltip>
          {salesOwner?.length > stringLimit ? (
            <Tooltip title={salesOwner} position="close">
              <span>{addDotsToString(salesOwner, stringLimit)}</span>
            </Tooltip>
          ) : (
            salesOwner || 'no owner'
          )}
        </StyledLeadInfoItem>
        <StyledLeadInfoItem>
          <ComputerIcon color="disabled" sx={{ fontSize: 20 }} />
          {leadWebsite ? (
            <Tooltip
              title={getUrlWithoutProtocol(URLToNormalView(leadWebsite))}
              position="close"
            >
              <StyledLink
                href={URLToNormalView(leadWebsite)}
                target="_blank"
                rel="noopener"
              >
                {t('go_to_website')}
              </StyledLink>
            </Tooltip>
          ) : (
            t('no_website')
          )}
        </StyledLeadInfoItem>
        <StyledLeadInfoItem>
          <LocationCityIcon color="disabled" sx={{ fontSize: 20 }} />
          {leadIndustry && leadIndustry.length > stringLimit ? (
            <Tooltip title={leadIndustry} position="close">
              <span>{addDotsToString(leadIndustry, stringLimit)}</span>
            </Tooltip>
          ) : (
            leadIndustry ?? t('no_industry')
          )}
        </StyledLeadInfoItem>
      </LeadInfoContainer>
      <ControlRow>
        <StyledFormControlLabel
          control={
            <StyledSwitch
              checked={leadStatus === 'ACTIVE'}
              onChange={() => switchAction(leadId, leadStatus)}
              name="status"
              size="small"
            />
          }
          label={t(leadStatus, { ns: 'common', keyPrefix: 'status' })}
        />
        <RightControls>
          <EditButton
            action={() => editLead(leadId)}
            title={t('projects.edit_project', {
              ns: 'company',
              keyPrefix: 'company_details',
            })}
          />
          <DeleteButton
            action={() => deleteLead(leadId)}
            title={t('projects.delete_project', {
              ns: 'company',
              keyPrefix: 'company_details',
            })}
          />
        </RightControls>
      </ControlRow>
    </StyledBox>
  );
};
