import { CandidateSkeleton } from './CandidateSkeleton/CandidateSkeleton';
import { CandidatesLoaderProps } from './CandidateView.types';

export const CandidatesLoader = ({ noOfCandidates }: CandidatesLoaderProps) => (
  <>
    {Array(noOfCandidates)
      .fill(0)
      .map((_, index) => (
        <CandidateSkeleton key={index} />
      ))}
  </>
);
