import styled from 'styled-components';

export const StyledContainer = styled.div`
  background-color: #fafafa;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  margin: 50px auto;
  padding: 40px 80px;
  width: 100%;

  @media (min-width: 700px) {
    min-width: 450px;
    max-width: 700px;
  }

  @media (max-width: 699px) {
    max-width: 450px;
    padding: 25px 30px;
  }
`;

export const ProfileWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const StyledUsername = styled.span`
  display: flex;
  font-size: 20px;
  font-weight: 400;
  padding-left: 10px;
`;

export const StyledEmail = styled.span`
  display: flex;
  font-size: 20px;
  font-weight: 200;
  padding-left: 5px;
`;
