import styled, { css } from 'styled-components';

export const StyledPage = styled.div`
  background-color: white;
  font-family: 'Poppins';
  height: 1683px;
  margin: 10px auto 0 auto;
  width: 1190px;
  @media print {
    @page {
      size: 1190px 1695px;
    }
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const StyledPageSections = styled.div``;

export const StyledPageContent = styled.div`
  height: 100%;
  margin-left: 80px;
  position: relative;
`;

export const StyledContainer = styled.div`
  display: flex;
  ${({ $spacing }) =>
    $spacing &&
    css`
      padding-left: 15vw;
    `}
`;

export const StyledPages = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;
