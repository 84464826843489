import { forwardRef } from 'react';
import { pastePlainText } from '../../../Helpers';
import { WithHeightUpdate } from '../../../utils/WithHeightUpdate';
import { StyledEntryContainer } from '../../common/CV/EntryContainer/EntryContainer.styles';
import { StyledAdditionalInfo } from './AdditionalInfoEntry.styles';

const AdditionalInfoEntryComponent = forwardRef(
  (
    { data, editable, setTextFormattingPanelVisibility, handleEditData },
    ref,
  ) => {
    return (
      <StyledEntryContainer ref={ref}>
        <StyledAdditionalInfo
          placeholder={`Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Ab ducimus tempora nobis sapiente id ad ex eaque excepturi fugit.
            Voluptatibus qui rem et sequi dicta perferendis. Eaque, ea vel.
            Necessitatibus ex quod neque molestiae pariatur!`}
          suppressContentEditableWarning
          contentEditable={editable}
          onFocus={() => {
            setTextFormattingPanelVisibility('bulletable');
          }}
          onPaste={(e) => pastePlainText(e)}
          onBlur={(e) => {
            setTextFormattingPanelVisibility(false);
            handleEditData({
              additionalInfo: e.currentTarget.innerHTML,
            });
          }}
          spellCheck={false}
          dangerouslySetInnerHTML={{
            __html: data.additionalInfo,
          }}
        />
      </StyledEntryContainer>
    );
  },
);

export const AdditionalInfoEntry = WithHeightUpdate(
  AdditionalInfoEntryComponent,
);
