export const COLORS = {
  accent: {
    primary: '#2F3B7D',
    secondary: '#F4F5F8',
    hover: '#C4B8CE',
    disabled: '#E6E4E2',
    border: '#DFE1E6',
    primaryHover: '#0F1B5D',
  },
  typography: {
    main: '#1A202C',
    secondary: '#4A5568',
    body: '#2D3748',
    disabled: '#C4C4C4',
    caption: '#6E7786',
  },
  stroke: {
    main: '#F0E0E8',
    secondary: '#AEB3BB',
    border: '#C6C8E3',
  },
  info: {
    main: '#EDF2FF',
    secondary: '#F7F8FD',
    border: '#2F3B7D33',
  },
  error: {
    main: '#EB5757',
    hover: '#FCEBE9',
    text: '#FFFFFF',
  },
  success: {
    main: '#217042',
    hover: '#6FCF97B2',
  },
  warning: {
    main: '#E2781A',
    hover: '#F2994A4D',
  },
  background: {
    primary: '#F8F8F8',
    secondary: '#FFFFFF',
    chip: '#ECEBFD',
    footer: '#EEEFF2',
    neutral: '#F5F5F9',
    tableHeader: '#FAFBFF',
  },
};
