// import SearchIcon from '@mui/icons-material/Search';
import { KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchFieldProps } from './SearchField.types';
import { InputIcon, Root, StyledInput } from './SearсhFiled.styles';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { FormLabel } from '../FormLabel';

export const SearchField = ({
  onSubmit,
  initialValue,
  onChange,
  placeholder,
  label,
}: SearchFieldProps) => {
  const { t } = useTranslation(['common']);
  const [inputValue, setInputValue] = useState<string>(initialValue);

  const handleUserInput = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit && onSubmit(inputValue);
    } else {
      onChange && onChange(inputValue);
    }
  };

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  return (
    <Root>
      {label && <FormLabel label={label} />}
      <InputIcon
        onClick={() => onSubmit && onSubmit(initialValue)}
        style={{ cursor: 'pointer' }}
      >
        <SearchIcon
        // style={{ color: COLORS.stroke.secondary, fontSize: '25px' }}
        />
      </InputIcon>
      <StyledInput
        placeholder={placeholder || t('search')}
        onKeyUp={(e) => handleUserInput(e)}
        onChange={(e) => setInputValue(e.target.value)}
        value={inputValue}
      />
    </Root>
  );
};
