import {
  faComment,
  faFileSignature,
  faMinusCircle,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef } from 'react';
import { NEW_CERTIFICATES_DATA_TEMPLATE } from '../../../dataTemplates';
import { pastePlainText } from '../../../Helpers';
import { WithHeightUpdate } from '../../../utils/WithHeightUpdate';
import { StyledEntryContainer } from '../../common/CV/EntryContainer/EntryContainer.styles';
import {
  StyledAddSubsectionButtons,
  StyledData,
  StyledDeleteButton,
  StyledDeleteSubsectionButton,
  StyledIcon,
  StyledInfo,
  StyledMinorInfo,
  StyledSmallHeader,
  StyledTextDiv,
  StyledTimePeriod,
} from './CertificatesEntry.styles';

const SUB_DATA_TYPE = {
  NAME: 'name',
  PROVIDER: 'provider',
  COMMENT: 'comment',
};

const SUB_DATA_CONFIG = {
  [SUB_DATA_TYPE.NAME]: {
    label: 'name',
    icon: faFileSignature,
    displayName: 'Name',
    initialData: '',
  },
  [SUB_DATA_TYPE.PROVIDER]: {
    label: 'provider',
    icon: faUser,
    displayName: 'Provider',
    initialData: '',
  },
  [SUB_DATA_TYPE.COMMENT]: {
    label: 'comment',
    icon: faComment,
    displayName: 'Comment',
    initialData: '',
  },
};

function supplySubsectionAttrs(
  { editable, data, handleEditData, setTextFormattingPanelVisibility },
  subsectionName,
  parent,
) {
  return {
    placeholder: NEW_CERTIFICATES_DATA_TEMPLATE[subsectionName],
    suppressContentEditableWarning: true,
    contentEditable: editable,
    onPaste: (e) => pastePlainText(e),
    onBlur: (e) => {
      if (
        e.currentTarget.innerHTML === '' ||
        e.currentTarget.innerHTML === '<br>'
      ) {
        e.currentTarget.style.opacity = 1;
      }
      setTextFormattingPanelVisibility(false);
      handleEditData(
        {
          [subsectionName]: e.currentTarget.innerHTML,
        },
        parent,
      );
    },
    onFocus: (e) => {
      if (
        e.currentTarget.innerHTML ===
        NEW_CERTIFICATES_DATA_TEMPLATE[subsectionName]
      ) {
        e.currentTarget.style.opacity = 1;
      }
      setTextFormattingPanelVisibility(true);
    },
    spellCheck: false,
    dangerouslySetInnerHTML: {
      __html: data[subsectionName],
    },
  };
}

const CertificatesEntryComponent = forwardRef(
  (
    {
      handleDrop,
      handleDrag,
      handleDragOver,
      editable,
      data,
      setTextFormattingPanelVisibility,
      handleEditData,
      handleRemoveEntry,
      handleDeleteSubData,
      handleCreateSubData,
      index,
    },
    ref,
  ) => {
    const [focused, setFocused] = React.useState(false);
    const commonProps = {
      data,
      handleEditData,
      editable,
      setTextFormattingPanelVisibility,
    };

    const handleBlur = (event) => {
      setTextFormattingPanelVisibility(false);
      const name = event.target.getAttribute('name');
      handleEditData({
        [name]: event.target.innerHTML,
      });
    };

    return (
      <StyledEntryContainer
        ref={ref}
        draggable={!focused}
        onDragStart={handleDrag}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        paddingTop={index ? 5 : undefined}
      >
        <StyledData>
          <StyledTimePeriod
            placeholder="MM.YYYY"
            suppressContentEditableWarning
            contentEditable={editable}
            onFocus={() => {
              setTextFormattingPanelVisibility(true);
            }}
            onPaste={(e) => pastePlainText(e)}
            name="date"
            onBlur={handleBlur}
            spellCheck={false}
            dangerouslySetInnerHTML={{
              __html: data.date,
            }}
          />
          {editable && (
            <StyledDeleteButton>
              <StyledIcon onClick={handleRemoveEntry}>
                <FontAwesomeIcon icon={faMinusCircle} />
              </StyledIcon>
            </StyledDeleteButton>
          )}
          <StyledInfo>
            {data.name !== undefined && (
              <StyledMinorInfo>
                <StyledSmallHeader>Name:</StyledSmallHeader>
                <StyledTextDiv
                  {...supplySubsectionAttrs(
                    commonProps,
                    'name',
                    SUB_DATA_TYPE.NAME,
                  )}
                />
                {editable && (
                  <StyledDeleteSubsectionButton>
                    <StyledIcon
                      onClick={() => handleDeleteSubData(SUB_DATA_TYPE.NAME)}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </StyledIcon>
                  </StyledDeleteSubsectionButton>
                )}
              </StyledMinorInfo>
            )}
            {data.provider !== undefined && (
              <StyledMinorInfo>
                <StyledSmallHeader>Provider:</StyledSmallHeader>
                <StyledTextDiv
                  {...supplySubsectionAttrs(
                    commonProps,
                    'provider',
                    SUB_DATA_TYPE.PROVIDER,
                  )}
                />
                {editable && (
                  <StyledDeleteSubsectionButton>
                    <StyledIcon
                      onClick={() =>
                        handleDeleteSubData(SUB_DATA_TYPE.PROVIDER)
                      }
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </StyledIcon>
                  </StyledDeleteSubsectionButton>
                )}
              </StyledMinorInfo>
            )}
            {data.comment !== undefined && (
              <StyledMinorInfo>
                <StyledSmallHeader>Comment:</StyledSmallHeader>
                <StyledTextDiv
                  {...supplySubsectionAttrs(
                    commonProps,
                    'comment',
                    SUB_DATA_TYPE.COMMENT,
                  )}
                />
                {editable && (
                  <StyledDeleteSubsectionButton>
                    <StyledIcon
                      onClick={() => handleDeleteSubData(SUB_DATA_TYPE.COMMENT)}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </StyledIcon>
                  </StyledDeleteSubsectionButton>
                )}
              </StyledMinorInfo>
            )}
            {editable && (
              <StyledAddSubsectionButtons>
                {Object.entries(SUB_DATA_CONFIG).map(([propertyName, item]) => {
                  if (data[item.label] === undefined) {
                    return (
                      <StyledIcon
                        title={`Add ${item.displayName}`}
                        key={propertyName}
                        onClick={() =>
                          handleCreateSubData({
                            propertyName,
                            initialData: item.initialData,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={item.icon} />
                      </StyledIcon>
                    );
                  }
                  return null;
                })}
              </StyledAddSubsectionButtons>
            )}
          </StyledInfo>
        </StyledData>
      </StyledEntryContainer>
    );
  },
);

export const CertificatesEntry = WithHeightUpdate(CertificatesEntryComponent);
