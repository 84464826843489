import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import { chipColors } from './config';
import { StyledChip, StyledIconButton } from './StatusChip.styles';
import { StatusChipProps } from './StatusChip.types';

export const StatusChip = ({
  label,
  clickable,
  status,
  handleClick,
  isClicked,
  backgroundColor,
}: StatusChipProps) => {
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    isClicked && setClicked(isClicked);
  }, [isClicked]);

  return (
    <>
      {chipColors[status] ? (
        <StyledChip
          size="small"
          label={label}
          clickable={clickable}
          onClick={() => {
            if (clickable) {
              handleClick && handleClick();
              setClicked((prevClicked) => !prevClicked);
            }
          }}
          fontColor={chipColors[status].label}
          sx={{
            color: chipColors[status].label,
            padding: '7px 8px',
            backgroundColor: backgroundColor || chipColors[status].bg,
          }}
          deleteIcon={
            clicked ? (
              <StyledIconButton disabled={clicked}>
                <CancelIcon
                  sx={{
                    fontSize: 13,
                  }}
                />
              </StyledIconButton>
            ) : (
              <></>
            )
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};
