import { createSlice } from '@reduxjs/toolkit';
import { LeadsFilters } from '../../../store/types/Store.leads.types';
import { leadsFiltersThunks } from './LeadsFilters.thunk';

const { saveStateThunk } = leadsFiltersThunks;

const initialState: LeadsFilters = {
  search: '',
  owner: '',
  checkboxes: {
    active: true,
    archived: false,
  },
};

const leadsFiltersSlice = createSlice({
  name: 'leadsList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveStateThunk.fulfilled, (state, action) => {
      const data = action.payload;
      state.search = data.search;
      state.owner = data.owner;
      state.checkboxes = data.checkboxes;
    });
  },
});

export const actions = { ...leadsFiltersSlice.actions };
export const { reducer } = leadsFiltersSlice;
