import {
  faCommentAlt,
  faFlask,
  faGraduationCap,
  faMinusCircle,
  faUniversity,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef } from 'react';
import { NEW_EDUCATION_DATA_TEMPLATE } from '../../../dataTemplates';
import { pastePlainText } from '../../../Helpers';
import { WithHeightUpdate } from '../../../utils/WithHeightUpdate';
import { StyledEntryContainer } from '../../common/CV/EntryContainer/EntryContainer.styles';
import {
  StyledAddSubsectionButtons,
  StyledData,
  StyledDeleteButton,
  StyledDeleteSubsectionButton,
  StyledIcon,
  StyledInfo,
  StyledLargeHeader,
  StyledLargeTextDiv,
  StyledMinorInfo,
  StyledSmallHeader,
  StyledTextDiv,
  StyledTimePeriod,
} from './EducationEntry.styles';

const SUB_DATA_TYPE = {
  UNIVERSITY: 'university',
  FIELD_OF_STUDY: 'fieldOfStudy',
  COMMENT: 'comment',
  DEGREE: 'degree',
};

const SUB_DATA_CONFIG = {
  [SUB_DATA_TYPE.UNIVERSITY]: {
    label: 'university',
    icon: faUniversity,
    displayName: 'University',
    textFormattingPanelVisibility: true,
    initialData: {
      universityTitle: '',
      university: '',
    },
  },
  [SUB_DATA_TYPE.FIELD_OF_STUDY]: {
    label: 'fieldOfStudy',
    icon: faFlask,
    displayName: 'Field of Study',
    textFormattingPanelVisibility: true,
    initialData: {
      fieldOfStudyTitle: '',
      fieldOfStudy: '',
    },
  },
  [SUB_DATA_TYPE.COMMENT]: {
    label: 'comment',
    icon: faCommentAlt,
    displayName: 'Comment',
    textFormattingPanelVisibility: 'bulletable',
    initialData: {
      commentTitle: '',
      comment: '',
    },
  },
  [SUB_DATA_TYPE.DEGREE]: {
    label: 'degree',
    icon: faGraduationCap,
    displayName: 'Degree',
    textFormattingPanelVisibility: true,
    initialData: {
      degreeTitle: '',
      degree: '',
    },
  },
};

function supplySubtitleAttrs(
  { editable, data, handleEditData, setTextFormattingPanelVisibility },
  subtitleName,
  parent,
) {
  return {
    placeholder: NEW_EDUCATION_DATA_TEMPLATE[subtitleName],
    suppressContentEditableWarning: true,
    contentEditable: editable,
    onFocus: () => {
      setTextFormattingPanelVisibility(true);
    },
    onPaste: (e) => pastePlainText(e),
    onBlur: (e) => {
      setTextFormattingPanelVisibility(false);
      handleEditData(
        {
          [subtitleName]: e.currentTarget.innerHTML,
        },
        parent,
      );
    },
    spellCheck: false,
    dangerouslySetInnerHTML: { __html: data[parent][subtitleName] },
  };
}

function supplySubsectionAttrs(
  { editable, data, handleEditData, setTextFormattingPanelVisibility },
  subsectionName,
  parent,
) {
  return {
    placeholder: NEW_EDUCATION_DATA_TEMPLATE[subsectionName],
    suppressContentEditableWarning: true,
    contentEditable: editable,
    onPaste: (e) => pastePlainText(e),
    onBlur: (e) => {
      if (
        e.currentTarget.innerHTML === '' ||
        e.currentTarget.innerHTML === '<br>'
      ) {
        e.currentTarget.style.opacity = 1;
      }
      setTextFormattingPanelVisibility(false);
      handleEditData(
        {
          [subsectionName]: e.currentTarget.innerHTML,
        },
        parent,
      );
    },
    onFocus: (e) => {
      if (
        e.currentTarget.innerHTML ===
        NEW_EDUCATION_DATA_TEMPLATE[subsectionName]
      ) {
        // workaround to enable placing the caret at the beginning of the actual text
        // instead of at the beginning of the corresponding subtitle
        // e.currentTarget.innerHTML = "<br>";
        e.currentTarget.style.opacity = 1;
      }
      setTextFormattingPanelVisibility(
        SUB_DATA_CONFIG[parent].textFormattingPanelVisibility,
      );
    },
    spellCheck: false,
    dangerouslySetInnerHTML: {
      __html: data[parent][subsectionName],
    },
  };
}

const EducationEntryComponent = forwardRef(
  (
    {
      handleDrop,
      handleDrag,
      handleDragOver,
      editable,
      data,
      setTextFormattingPanelVisibility,
      handleEditData,
      handleRemoveEntry,
      handleDeleteSubData,
      handleCreateSubData,
      index,
    },
    ref,
  ) => {
    const [focused, setFocused] = React.useState(false);

    const commonProps = {
      data,
      handleEditData,
      editable,
      setTextFormattingPanelVisibility,
    };
    return (
      <StyledEntryContainer
        ref={ref}
        draggable={!focused}
        onDragStart={handleDrag}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        paddingTop={index ? 40 : undefined}
      >
        <StyledData>
          <StyledTimePeriod
            placeholder="MM.YYYY - MM.YYYY"
            suppressContentEditableWarning
            contentEditable={editable}
            onFocus={() => {
              setTextFormattingPanelVisibility(true);
            }}
            onPaste={(e) => pastePlainText(e)}
            onBlur={(e) => {
              setTextFormattingPanelVisibility(false);
              handleEditData({
                timePeriod: e.currentTarget.innerHTML,
              });
            }}
            dangerouslySetInnerHTML={{
              __html: data.timePeriod,
            }}
            spellCheck={false}
          />
          {editable && (
            <StyledDeleteButton>
              <StyledIcon onClick={handleRemoveEntry}>
                <FontAwesomeIcon icon={faMinusCircle} />
              </StyledIcon>
            </StyledDeleteButton>
          )}
          <StyledInfo>
            {data.university && (
              <div>
                <StyledLargeHeader
                  {...supplySubtitleAttrs(
                    commonProps,
                    'universityTitle',
                    SUB_DATA_TYPE.UNIVERSITY,
                  )}
                />
                <StyledLargeTextDiv
                  {...supplySubsectionAttrs(
                    commonProps,
                    'university',
                    SUB_DATA_TYPE.UNIVERSITY,
                  )}
                />
                {editable && (
                  <StyledDeleteSubsectionButton>
                    <StyledIcon
                      onClick={() =>
                        handleDeleteSubData(SUB_DATA_TYPE.UNIVERSITY)
                      }
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </StyledIcon>
                  </StyledDeleteSubsectionButton>
                )}
              </div>
            )}
            {data.fieldOfStudy && (
              <StyledMinorInfo>
                <StyledSmallHeader
                  {...supplySubtitleAttrs(
                    commonProps,
                    'fieldOfStudyTitle',
                    SUB_DATA_TYPE.FIELD_OF_STUDY,
                  )}
                />
                <StyledTextDiv
                  {...supplySubsectionAttrs(
                    commonProps,
                    'fieldOfStudy',
                    SUB_DATA_TYPE.FIELD_OF_STUDY,
                  )}
                />
                {editable && (
                  <StyledDeleteSubsectionButton>
                    <StyledIcon
                      onClick={() =>
                        handleDeleteSubData(SUB_DATA_TYPE.FIELD_OF_STUDY)
                      }
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </StyledIcon>
                  </StyledDeleteSubsectionButton>
                )}
              </StyledMinorInfo>
            )}
            {data.comment && (
              <StyledMinorInfo>
                <StyledSmallHeader
                  {...supplySubtitleAttrs(
                    commonProps,
                    'commentTitle',
                    SUB_DATA_TYPE.COMMENT,
                  )}
                />
                <StyledTextDiv
                  {...supplySubsectionAttrs(
                    commonProps,
                    'comment',
                    SUB_DATA_TYPE.COMMENT,
                  )}
                />
                {editable && (
                  <StyledDeleteSubsectionButton>
                    <StyledIcon
                      onClick={() => handleDeleteSubData(SUB_DATA_TYPE.COMMENT)}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </StyledIcon>
                  </StyledDeleteSubsectionButton>
                )}
              </StyledMinorInfo>
            )}
            {data.degree && (
              <StyledMinorInfo>
                <StyledSmallHeader
                  {...supplySubtitleAttrs(
                    commonProps,
                    'degreeTitle',
                    SUB_DATA_TYPE.DEGREE,
                  )}
                />
                <StyledTextDiv
                  {...supplySubsectionAttrs(
                    commonProps,
                    'degree',
                    SUB_DATA_TYPE.DEGREE,
                  )}
                />
                {editable && (
                  <StyledDeleteSubsectionButton>
                    <StyledIcon
                      onClick={() => handleDeleteSubData(SUB_DATA_TYPE.DEGREE)}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </StyledIcon>
                  </StyledDeleteSubsectionButton>
                )}
              </StyledMinorInfo>
            )}
            {editable && (
              <StyledAddSubsectionButtons>
                {Object.entries(SUB_DATA_CONFIG).map(([propertyName, item]) => {
                  if (!data[item.label]) {
                    return (
                      <StyledIcon
                        title={`Add ${item.displayName}`}
                        key={propertyName}
                        onClick={() =>
                          handleCreateSubData({
                            propertyName,
                            initialData: item.initialData,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={item.icon} />
                      </StyledIcon>
                    );
                  }
                  return null;
                })}
              </StyledAddSubsectionButtons>
            )}
          </StyledInfo>
        </StyledData>
      </StyledEntryContainer>
    );
  },
);

export const EducationEntry = WithHeightUpdate(EducationEntryComponent);
