import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  RegisteruserParams,
  InviteCompanyMemberParams,
} from '../../config/Api.types';
import { PersistedStoreKeys } from '../../config/PersistedStoreKeys';
import {
  API_ALL_USERS,
  API_COMPANY_MEMBERS,
  API_COMPANY_MEMBERS_INVITE,
  API_CHANGE_PASSWORD,
  API_REQUEST_CHANGE_PASSWORD,
  API_RESET_PASSWORD,
  API_HR_USERS,
  API_LOGIN_URL,
  API_PARTNER_USERS,
  API_REGISTER_URL,
  API_SALES_USERS,
  API_URL,
} from '../../config/urls';
import { MyPersonalData, UserState } from '../../store/types/Store.user.types';
import { getAuthHeader, handleResponse } from '../../utils/api';
import { raiseToast } from '../../utils/raiseToasts';
import i18n from '../../i18n';

const jsonContentTypeHeader = { 'Content-Type': 'application/json' };

const loginThunk = createAsyncThunk<UserState>(
  'user/login',
  async (data, { dispatch }) => {
    const request = fetch(API_URL + API_LOGIN_URL, {
      method: 'POST',
      headers: jsonContentTypeHeader,
      body: JSON.stringify(data),
    });

    const response = await handleResponse(request, dispatch); // I'm not sure if it throws an error here

    if (response.token && response.user) {
      localStorage.setItem(PersistedStoreKeys.token, response.token); //todo: make a localStorage service
      localStorage.setItem(
        PersistedStoreKeys.user,
        JSON.stringify(response.user),
      );

      return response;
    }
    throw new Error();
  },
);

const saveStateThunk = createAsyncThunk('user/saveState', async (data) => {
  localStorage.setItem(PersistedStoreKeys.userFilters, JSON.stringify(data));
  return data;
});

const logoutThunk = createAsyncThunk('user/logout', async () => {
  localStorage.removeItem(PersistedStoreKeys.user);
  localStorage.removeItem(PersistedStoreKeys.token);
  raiseToast.info('You were logged out.');
});

const registerThunk = createAsyncThunk<RegisteruserParams>(
  'user/register',
  async (data, { getState, dispatch }) => {
    const request = fetch(API_URL + API_REGISTER_URL, {
      method: 'POST',
      headers: {
        ...getAuthHeader(getState()),
      },
      body: JSON.stringify(data),
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      const message = i18n.t('raiseToastMessage.user_registered', {
        ns: 'common',
      });
      raiseToast.success(message);
    }

    return response;
  },
);

const sendInviteToCompanyMemberThunk =
  createAsyncThunk<InviteCompanyMemberParams>(
    'user/sendInviteToCompanyMember',
    async (data, { getState, dispatch }) => {
      const request = fetch(API_COMPANY_MEMBERS_INVITE, {
        method: 'POST',
        headers: {
          ...getAuthHeader(getState()),
        },
        body: JSON.stringify(data),
      });

      const response = await handleResponse(request, dispatch);
      if (response) {
        const message = i18n.t('raiseToastMessage.invitation_sent', {
          ns: 'common',
        });
        raiseToast.success(message);
      }

      return response;
    },
  );

const requestPasswordResetThunk = createAsyncThunk(
  'user/requestPasswordReset',
  async (data: { email: string }, { dispatch }) => {
    const request = fetch(API_REQUEST_CHANGE_PASSWORD, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      const message = i18n.t(
        'raiseToastMessage.password_changing_request_sent',
        {
          ns: 'common',
        },
      );
      raiseToast.success(message);
    }
  },
);

const resetPasswordThunk = createAsyncThunk(
  'user/resetPassword',
  async (data: { token: string; password: string }, { dispatch }) => {
    const request = fetch(API_RESET_PASSWORD, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      const message = i18n.t('raiseToastMessage.password_updated', {
        ns: 'common',
      });
      raiseToast.success(message);
    }
  },
);

const changePasswordThunk = createAsyncThunk(
  'user/changePassword',
  async (data, { getState, dispatch }) => {
    const request = fetch(API_URL + API_CHANGE_PASSWORD, {
      method: 'PUT',
      headers: {
        ...getAuthHeader(getState()),
      },
      body: JSON.stringify(data),
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      const message = i18n.t('raiseToastMessage.password_updated', {
        ns: 'common',
      });
      raiseToast.success(message);
    }
  },
);

const getHRUsersThunk = createAsyncThunk(
  'user/getHRUsers',
  async (_data, { getState, dispatch }) => {
    const request = fetch(API_URL + API_HR_USERS, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const getSalesUsersThunk = createAsyncThunk(
  'user/getSalesUsers',
  async (_data, { getState, dispatch }) => {
    const request = fetch(API_URL + API_SALES_USERS, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const getPartnerUsersThunk = createAsyncThunk(
  'user/getPartnerUsers',
  async (_data, { getState, dispatch }) => {
    const request = fetch(API_URL + API_PARTNER_USERS, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const getUsersThunk = createAsyncThunk(
  'users/getUsers',
  async (_data, { getState, dispatch }) => {
    const request = fetch(`${API_ALL_USERS}`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const getCompanyMembersThunk = createAsyncThunk(
  'users/getCompanyMembers',
  async (_data, { getState, dispatch }) => {
    const request = fetch(API_COMPANY_MEMBERS, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const updateMyPersonalData = createAsyncThunk(
  'users/MyPersonalData',
  async (
    data: { userId: number; payload: MyPersonalData },
    { getState, dispatch },
  ) => {
    const request = fetch(`${API_COMPANY_MEMBERS}${data.userId}/`, {
      method: 'PATCH',
      body: JSON.stringify(data.payload),
      headers: {
        ...getAuthHeader(getState()),
      },
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      const message = i18n.t('raiseToastMessage.user_info_updated', {
        ns: 'common',
      });
      raiseToast.success(message);
    }
  },
);

const deactivateUser = createAsyncThunk(
  'users/deactivateUser',
  async (data: { userId: string }, { getState, dispatch }) => {
    const request = fetch(`${API_ALL_USERS}${data.userId}`, {
      method: 'DELETE',
      headers: getAuthHeader(getState()),
    });

    const result = await handleResponse(request, dispatch);
    const message = i18n.t('raiseToastMessage.user_deactivated', {
      ns: 'common',
    });
    raiseToast.success(message);
    return result;
  },
);

const updateUserThunk = createAsyncThunk(
  'users/updateUser',
  async (
    data: { userId: string; payload: UserState },
    { getState, dispatch },
  ) => {
    const request = fetch(`${API_ALL_USERS}${data.userId}/`, {
      method: 'PATCH',
      body: JSON.stringify(data.payload),
      headers: {
        ...getAuthHeader(getState()),
      },
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      const message = i18n.t('raiseToastMessage.user_info_updated', {
        ns: 'common',
      });
      raiseToast.success(message);
    }
  },
);

const updateUserActivityThunk = createAsyncThunk(
  'users/updateUserActivity',
  async (
    data: { userId: number; payload: { is_active: boolean } },
    { getState, dispatch },
  ) => {
    const request = fetch(`${API_COMPANY_MEMBERS}${data.userId}/active/`, {
      method: 'PATCH',
      body: JSON.stringify(data.payload),
      headers: {
        ...getAuthHeader(getState()),
      },
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      const message = i18n.t('raiseToastMessage.user_activity_updated', {
        ns: 'common',
      });
      raiseToast.success(message);
    }
  },
);

const getCompanyThunk = createAsyncThunk(
  'users/getCompany',
  async (data: { companyId: string }, { getState, dispatch }) => {
    const request = fetch(`/apu/users/company/${data.companyId}/`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

export const userThunks = {
  loginThunk,
  registerThunk,
  logoutThunk,
  saveStateThunk,
  resetPasswordThunk,
  changePasswordThunk,
  requestPasswordResetThunk,
  getHRUsersThunk,
  getUsersThunk,
  getCompanyMembersThunk,
  sendInviteToCompanyMemberThunk,
  deactivateUser,
  updateUserThunk,
  updateMyPersonalData,
  updateUserActivityThunk,
  getSalesUsersThunk,
  getPartnerUsersThunk,
  getCompanyThunk,
};
