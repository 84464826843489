import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';

export const StyledLanguageData = styled.div`
  display: flex;
  margin-right: 80px;

  :hover div {
    visibility: visible;
  }
`;

export const StyledTimePeriod = styled.div`
  font-weight: 200;
  width: 300px;
`;

export const StyledLanguage = styled.div`
  display: inline;
  font-weight: 700;
`;

export const StyledInfo = styled.div`
  margin-left: 76px;
`;

export const StyledLanguageMarks = styled.div`
  display: flex;
  font-size: 24px;
  font-weight: 700;
  justify-content: space-between;
  width: 654px;
`;

export const StyledSmallHeader = styled.span`
  float: left;
  font-weight: 400;
  padding-right: 5px;
`;

export const StyledLanguageTextDiv = styled.div`
  display: inline;
  float: left;
  font-weight: 700;
  min-width: 15px;
  padding-right: 5px;

  :empty:before {
    content: attr(placeholder);
    font-weight: 200;
    opacity: 0.3;

    @media print {
      opacity: 0;
    }
  }
`;

export const StyledTextDiv = styled.div`
  display: inline;
  flex: 1;
  font-weight: 200;
  min-height: 24px;
  min-width: 46px;

  :empty:before {
    content: attr(placeholder);
    opacity: 0.3;

    @media print {
      opacity: 0;
    }
  }
`;

export const StyledDeleteButton = styled.div`
  color: ${COLORS.accent.primary};
  display: inline-block;
  left: 30px;
  position: relative;
  visibility: hidden;
`;

export const StyledIcon = styled.div`
  cursor: pointer;
`;
