import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { COLORS } from '../../../theme/colors';
import { Button } from '../Button/Button.component';
import {
  GlobalStyle,
  StyledButtonsContainer,
  StyledContainer,
  StyledHeaderContainer,
  StyledLogo,
} from './BenchlyDialog.styles';
import { Typography } from '../Typography/Typography.component';
import { BenchlyDialogProps } from './BenchlyDialog.types';
import { LanguageSwitch } from '../LanguageSwitch/LanguageSwitch.component';
import { AppPaths } from '../../../config/AppPaths';

const GlobalStyleProxy: any = GlobalStyle;

export const BenchlyDialog = ({
  children,
  dialogTitle,
  dialogSubtitle,
  buttonsConfig,
  isCenteredTitle = false,
}: BenchlyDialogProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isClickable = location.pathname !== '/';
  const handleLogoClick = () => {
    isClickable && navigate(AppPaths.home);
  };
  return (
    <>
      <GlobalStyleProxy />
      <Box sx={{ display: 'flex', justifyContent: 'center', margin: '0 40px' }}>
        <StyledLogo
          onClick={handleLogoClick}
          style={{ transform: 'translateX(23px)' }}
          isClickable={isClickable}
        />
        <Box sx={{ paddingRight: '32px' }}>
          <LanguageSwitch />
        </Box>
      </Box>
      <StyledContainer tabIndex={-1}>
        <StyledHeaderContainer isCenteredTitle={isCenteredTitle}>
          {dialogSubtitle && (
            <Typography variant="subtitle" color={COLORS.typography.body}>
              {dialogSubtitle}
            </Typography>
          )}
          {dialogTitle && <Typography variant="h2">{dialogTitle}</Typography>}
        </StyledHeaderContainer>
        {children}
        {buttonsConfig && (
          <StyledButtonsContainer>
            {buttonsConfig.primaryButton && (
              <Button
                color={buttonsConfig.primaryButton.color}
                action={buttonsConfig.primaryButton.action}
                buttonType={buttonsConfig.primaryButton.type}
              >
                {buttonsConfig.primaryButton.label}
              </Button>
            )}
            {buttonsConfig.secondaryButton && (
              <Button
                color={buttonsConfig.secondaryButton.color}
                action={buttonsConfig.secondaryButton.action}
              >
                {buttonsConfig.secondaryButton.label}
              </Button>
            )}
          </StyledButtonsContainer>
        )}
      </StyledContainer>
    </>
  );
};
