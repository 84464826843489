import { useTranslation } from 'react-i18next';
import { CandidateStatusModel } from './Leads.types';

export const useLeadsConfig = () => {
  const { t } = useTranslation(['company'], {
    keyPrefix: 'company_details.projects',
  });
  const candidateStatuses: CandidateStatusModel[] = [
    {
      name: 'MATCHED',
      showedName: t('statuses.matched'),
      chartColor: 'rgb(0 74 98 / 80%)',
      bgColor:
        'linear-gradient(0deg, rgba(253, 253, 253, 0.2), rgba(253, 253, 253, 0.2)), #E6E9EE',
    },
    {
      name: 'SENT',
      showedName: t('statuses.sent'),
      chartColor: '#F9E371',
      bgColor:
        'linear-gradient(0deg, rgba(253, 253, 253, 0.2), rgba(253, 253, 253, 0.2)), #f7f4db',
    },
    {
      name: 'IN THE PROCESS',
      showedName: t('statuses.in_the_process'),
      chartColor: '#0A92CC',
      bgColor:
        'linear-gradient(0deg, rgba(253, 253, 253, 0.2), rgba(253, 253, 253, 0.2)), #C3E9F6',
    },
    {
      name: 'ACCEPTED',
      showedName: t('statuses.accepted'),
      chartColor: '#7AD799',
      bgColor:
        'linear-gradient(0deg, rgba(253, 253, 253, 0.2), rgba(253, 253, 253, 0.2)), #C0EDB1',
    },
    {
      name: 'ACCEPTED IN ANOTHER PROCESS',
      showedName: t('statuses.accepted_in_another_process'),
      chartColor: '#8D65B3',
      bgColor:
        'linear-gradient(0deg, rgba(253, 253, 253, 0.2), rgba(253, 253, 253, 0.2)), #ebd2f1',
    },
    {
      name: 'LOST',
      showedName: t('statuses.lost'),
      chartColor: '#F9C271',
      bgColor:
        'linear-gradient(0deg, rgba(253, 253, 253, 0.2), rgba(253, 253, 253, 0.2)), #eddcdc',
    },
    {
      name: 'REJECTED',
      showedName: t('statuses.rejected'),
      chartColor: '#0A92CC',
      bgColor:
        'linear-gradient(0deg, rgba(253, 253, 253, 0.2), rgba(253, 253, 253, 0.2)), #a3d2e7',
    },
  ];
  const statusesWithReason = ['Lost', 'Rejected', 'LOST', 'REJECTED'];

  const statusOfRequestWithReason = 'LOST';
  const defaultStatusOfNewRequest = 'ACTIVE';

  const priorities = {
    low: 'low',
    medium: 'medium',
    high: 'high',
    veryHigh: 'very high',
  };

  const statuses = {
    active: 'ACTIVE',
    won: 'WON',
    lost: 'LOST',
    notActive: 'NOT ACTIVE',
  };

  const profileProps = {
    priority: 'priority',
    status: 'lead_profile_status',
  };

  return {
    candidateStatuses,
    statusesWithReason,
    statusOfRequestWithReason,
    defaultStatusOfNewRequest,
    priorities,
    statuses,
    profileProps,
  };
};
