export const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export function placeCaretAtEnd(el) {
  el.focus();
  if (
    typeof window.getSelection !== 'undefined' &&
    typeof document.createRange !== 'undefined'
  ) {
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange !== 'undefined') {
    const textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
}

export function pastePlainText(event) {
  const paste = event.clipboardData.getData('Text');
  const selection = window.getSelection();
  if (!selection.rangeCount) return false;
  selection.deleteFromDocument();
  selection.getRangeAt(0).insertNode(document.createTextNode(paste));
  event.preventDefault();
}

export function removeHtml(str) {
  const temporalDivElement = document.createElement('div');
  temporalDivElement.innerHTML = str;
  return temporalDivElement.textContent || temporalDivElement.innerText || '';
}

export function isAdminOrHr(user) {
  return user.is_admin || user.department === 'Human Resources';
}

export function isPartner(user) {
  return user?.department === 'Partner';
}

export const isFile = (input) => 'File' in window && input instanceof File;

export function sanitizeCVEditor(cvElement) {
  const cv = document.cloneNode(true);
  cv.body.innerHTML = '';
  cv.body.appendChild(cvElement.cloneNode(true));
  const html = cv.documentElement.innerHTML;
  const { origin } = window.location;
  return html.replaceAll('/static/css', `${origin}/static/css`);
}

export const isHrSalesOrAdmin = (user) =>
  user.is_admin ||
  user.department === 'Sales' ||
  user.department === 'Human Resources';

export const getSortedTechnologiesWithoutDuplicates = (employees) => {
  const occurenceMap = new Map();
  employees?.people?.results?.forEach((employee) => {
    employee.technologies?.forEach((technology) => {
      const sanitizedTechnology = technology
        .toLowerCase()
        .replaceAll(/ |(<[a-zA-Z]+\/?>)/g, '');

      if (!occurenceMap.has(sanitizedTechnology)) {
        occurenceMap.set(sanitizedTechnology, 1);
      } else {
        occurenceMap.set(
          sanitizedTechnology,
          occurenceMap.get(sanitizedTechnology) + 1,
        );
      }
    });
  });
  const sortOccurenceMap = new Map(
    [...occurenceMap.entries()].sort((a, b) => b[1] - a[1]),
  );

  return [...sortOccurenceMap.keys()].map(
    (key) => key.charAt(0).toUpperCase() + key.slice(1),
  );
};

export function filterByTechnology(
  candidatesTechnologies,
  filteredValueTechnology,
) {
  let result = true;
  if (filteredValueTechnology.length !== 0) {
    if (candidatesTechnologies.length !== 0) {
      const technologiesNotFormatted = [];
      candidatesTechnologies.forEach((technology) => {
        technologiesNotFormatted.push(
          technology.toLowerCase().replaceAll(' ', ''),
        );
      });
      result = filteredValueTechnology.every((elem) =>
        technologiesNotFormatted.includes(
          elem.toLowerCase().replaceAll(' ', ''),
        ),
      );
    } else {
      result = false;
    }
  }
  return result;
}

export const addDotsToString = (string, limit) =>
  string?.length > limit ? `${string.substring(0, limit)}...` : string;

const getFilteredArray = (arr, property, filteredValue) =>
  arr.filter((item) => item[property] === filteredValue);

export const getDateString = (date) => {
  const tempDate = new Date(date);
  const day = tempDate.getDate();
  const month = tempDate.getMonth() + 1;
  const year = tempDate.getFullYear();
  return `${day}.${month}.${year}`;
};

export const getDateTimeString = (date) => new Date(date).toLocaleString();

export const getUrlWithoutProtocol = (url) => new URL(url).host;

export const URLToNormalView = (url) =>
  !/^https?:\/\//i.test(url) ? `http://${url}` : url;

const getSortedProfilesByPriority = (profiles, profileProps, priorities) => [
  ...getFilteredArray(profiles, profileProps.priority, priorities.veryHigh),
  ...getFilteredArray(profiles, profileProps.priority, priorities.high),
  ...getFilteredArray(profiles, profileProps.priority, priorities.medium),
  ...getFilteredArray(profiles, profileProps.priority, priorities.low),
];

export const sortProfilesByStatus = (
  profiles,
  profileProps,
  statuses,
  priorities,
) => [
  ...getSortedProfilesByPriority(
    getFilteredArray(profiles, profileProps.status, statuses.active),
    profileProps,
    priorities,
  ),
  ...getSortedProfilesByPriority(
    getFilteredArray(profiles, profileProps.status, statuses.won),
    profileProps,
    priorities,
  ),
  ...getSortedProfilesByPriority(
    getFilteredArray(profiles, profileProps.status, statuses.lost),
    profileProps,
    priorities,
  ),
  ...getSortedProfilesByPriority(
    getFilteredArray(profiles, profileProps.status, statuses.notActive),
    profileProps,
    priorities,
  ),
];

export const htmlDecode = (value) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = value;
  return txt.value;
};

export const clearCvFormatting = (string) => {
  return String(string)
    ?.replaceAll(/&nbsp;/g, ' ')
    .replace('<div><br></div>', '')
    .replaceAll('<div><br>', '<br>')
    .replaceAll('<div>', '<br>')
    .replaceAll('</ul><br>', '</ul>')
    .replaceAll('<li>', '<li style="font-size: 13px;">')
    .replaceAll('20px', '13px');
};

export const findMainTechnology = (candidate) => {
  const splittedProfile = candidate?.profile?.toLowerCase().split(' ');
  const techNames = candidate?.technologies?.map((tech) =>
    tech.name?.toLowerCase(),
  );
  const commonTech = splittedProfile?.filter((value) =>
    techNames?.includes(value),
  );
  return commonTech?.length
    ? candidate?.technologies?.find(
        (elem) => elem.name?.toLowerCase() === commonTech[0],
      )
    : candidate?.technologies?.find((elem) => elem.logo);
};

export const substituteEmptyStringLabel = (obj, candidateStrings) => {
  if (!('' in obj)) return obj;

  const data = { ...obj };
  const defaultLabels = ['Unknown', 'None', ''];
  const labels =
    candidateStrings?.length > 0
      ? candidateStrings.concat(defaultLabels)
      : defaultLabels;

  const label = labels.filter((x) => !(x in data))[0];
  if (label !== undefined) {
    data[label] = data[''];
    delete data[''];
  }
  return data;
};

// object keys are iterated by insertion order
export const sortObjKeysByValue = (obj, ascending) => {
  return Object.entries(obj)
    .sort(([, a], [, b]) => (ascending ? a - b : b - a))
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
};
