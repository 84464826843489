import { createSlice } from '@reduxjs/toolkit';
import { Tag } from '../../../../store/types/Store.leads.types';
import { industryTagsThunks } from './IndustryTags.thunk';

const { getIndustryTagsThunk } = industryTagsThunks;

const initialState: Tag[] = [];

const industryTagsSlice = createSlice({
  name: 'industry',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIndustryTagsThunk.fulfilled, (state, action) => {
      state.length = 0;
      action.payload.forEach((data: Tag) => {
        state.push({ id: data.id, name: data.name });
      });
    });
  },
});

export const actions = { ...industryTagsSlice.actions };
export const { reducer } = industryTagsSlice;
