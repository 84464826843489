import { styled as materialUIStyled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { COLORS } from '../../../theme/colors';

export const FilterButton = materialUIStyled(Button)({
  marginRight: '10px',
  float: 'left',
  fontSize: '14px',
  textTransform: 'none',
  variants: [
    {
      props: { variant: 'selected' },
      style: {
        fontWeight: 'bold',
        border: `1px dashed ${COLORS.accent.border}`,
        background: COLORS.accent.border,
      },
    },
  ],
});
