import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { toast } from 'react-toastify';
import { StyledContent } from '../components/Toasts/ToastContent.styles';

const TOAST_OPTIONS = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  newestOnTop: true,
  icon: true,
};

export const raiseToast = {
  default: (content, options = TOAST_OPTIONS) => {
    toast(content, options);
  },
  success: (content, options = TOAST_OPTIONS) => {
    toast.success(
      <StyledContent>
        <CheckCircleIcon fontSize="small" sx={{ color: '#21CA1E' }} /> {content}
      </StyledContent>,
      options,
    );
  },
  error: (content, options = TOAST_OPTIONS) => {
    toast.error(
      <StyledContent>
        <ErrorIcon fontSize="small" sx={{ color: '#FF0A0A' }} /> {content}
      </StyledContent>,
      options,
    );
  },
  info: (content, options = TOAST_OPTIONS) => {
    toast.info(
      <StyledContent>
        <InfoIcon fontSize="small" sx={{ color: '#5196FF' }} /> {content}
      </StyledContent>,
      options,
    );
  },
  warn: (content, options = TOAST_OPTIONS) => {
    toast.warn(
      <StyledContent>
        <WarningIcon fontSize="small" sx={{ color: '#FFCC00' }} /> {content}
      </StyledContent>,
      options,
    );
  },
};
