import styled from 'styled-components';

type StyledOptionItemProps = { color?: string };

export const StyledOptionItem = styled.li<StyledOptionItemProps>`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 13px;
  font-weight: 400;
  height: 10;
  margin: 0;
  ${({ color }) => color && `color: ${color}!important`};
`;

export const ErrorsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: end;
  margin-left: auto;
`;
