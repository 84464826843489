import background from '../../../../../components/common/images/client-background.png';
import navbarLogo from '../../../../../components/common/images/navbar_logo.svg';
import { NewInput } from '../../../../../components/common/NewInput/NewInput.component';
import { Tooltip } from '../../../../../components/common/Tooltip/Tooltip.component';
import { LoginProps } from '../ClientLoginPage.types';
import { Quote } from '../Quote/Quote';
import {
  FormContainer,
  FormTitle,
  FormWrapper,
  MobContainer,
  StyledButton,
  StyledLogo,
  StyledPage,
} from './LoginMobile.styles';

export const LoginMobile = ({
  disabled,
  password,
  handlePasswordChange,
  submitCode,
  id,
  screenWidth,
}: LoginProps) => (
  <StyledPage>
    <MobContainer img={background}>
      <StyledLogo src={navbarLogo} alt="logo" />
      <FormContainer>
        <FormTitle>Log in</FormTitle>
        <FormWrapper>
          <NewInput
            label="Password"
            name="client-password"
            placeholder="Paste password"
            value={password}
            handleInputChange={handlePasswordChange}
            disabled={disabled}
          />
          {disabled ? (
            <Tooltip title="This list does not exist">
              <div>
                <StyledButton disabled={disabled}>View candidates</StyledButton>
              </div>
            </Tooltip>
          ) : (
            <StyledButton
              onClick={() => submitCode(id, password)}
              disabled={disabled}
            >
              View candidates
            </StyledButton>
          )}
        </FormWrapper>
      </FormContainer>
    </MobContainer>
    <Quote screenWidth={screenWidth} />
  </StyledPage>
);
