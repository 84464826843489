import { useSelector } from 'react-redux';
import { Navbar } from '../../components/common/Navbar/Navbar';
import { ExternalClientCredentials } from '../../store/types/Store.clientList.types';
import { AppStore } from '../../store/types/Store.types';
import { UserState } from '../../store/types/Store.user.types';
// import { actions as clientListActions } from '../ClientList/redux/ClientListConfig.slice';

export const Topbar = () => {
  // const dispatch = useDispatch();
  const user = useSelector<AppStore, UserState>((state) => state.user);
  const externalClientAuthData = useSelector<
    AppStore,
    ExternalClientCredentials
  >((state) => state.clientListRelated.credentials);

  // const handleBasketClick = () => {
  //   dispatch(clientListActions.toggleBasket());
  // };

  const chooseTopbarVariant = () => {
    if (user.token && user.user) {
      return (
        <Navbar
          user={user}
          // onBasketClick={handleBasketClick}
        />
      );
    }
    if (externalClientAuthData && externalClientAuthData.token) {
      return (
        <Navbar
          externalClientAuthData={externalClientAuthData}
          // onBasketClick={handleBasketClick}
        />
      );
    }
    return <></>;
  };
  return chooseTopbarVariant();
};
