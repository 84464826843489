import { createSlice } from '@reduxjs/toolkit';
import { Cv } from '../../../../store/types/Store.candidates.types';
import {
  ClientListEmployeePage,
  DevRequestEmployeePage,
  EmployeePageData,
} from '../../../../store/types/Store.employeePage.types';
import { employeePageThunks } from './EmployeePage.thunk';

const { getEmployeePageDataThunk } = employeePageThunks;

const initialState: EmployeePageData = {
  cv_list: [],
  dev_requests: [],
  client_lists: [],
};

const employeePageSlice = createSlice({
  name: 'employeePage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmployeePageDataThunk.fulfilled, (state, action) => {
      state.cv_list.length = 0;
      action.payload.cv_list.forEach((cv: Cv) => {
        state.cv_list.push(cv);
      });
      state.dev_requests.length = 0;
      action.payload.dev_requests.forEach(
        (devRequest: DevRequestEmployeePage) => {
          state.dev_requests.push(devRequest);
        },
      );
      state.details = action.payload.details;
      state.client_lists.length = 0;
      action.payload.client_lists.forEach(
        (clientList: ClientListEmployeePage) => {
          state.client_lists.push(clientList);
        },
      );
    });
  },
});

export const actions = { ...employeePageSlice.actions };
export const { reducer } = employeePageSlice;
