import styled from 'styled-components';

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  max-width: 100%;
  min-width: 100%;
`;

export const StyledInput = styled.input`
  border: 1px solid #dce1eb;
  border-radius: 10px;
  box-sizing: border-box;
  font-family: 'Poppins';
  font-size: 14px;
  height: 57px;
  padding: 20px;
  width: 100%;

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 340px) {
    min-width: 100%;
    max-width: 100%;
  }
`;

export const Label = styled.label`
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 10px;
`;
