import Box from '@mui/material/Box';
import styled from 'styled-components';

export const StyledLeadsContainer = styled.div`
  margin-bottom: 60px;
  margin-top: 15px;
`;

export const StyledLeadTable = styled.div`
  align-self: center;
  height: 100%;
  margin: 0 auto;
  width: 100%;
`;

export const StyledSpan = styled.span`
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
`;

export const StyledBox = styled(Box)`
  @media screnn and (max-width: 1500px) {
    marginleft: 10px;
  }
  overflow: hidden;
`;

export const StyledHeadline = styled.p`
  color: #101840;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  margin: 3px 0 0 5px;
  width: 215px;
  @media (max-width: 686px) {
    margin-left: 0;
  }
`;

export const GreetingsHeadline = styled.p`
  color: #000000;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin: 29px 0 0 65px;

  @media (max-width: 686px) {
    margin: 23px 20px;
  }
`;
