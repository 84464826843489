import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    '@media (max-width: 686px)': {
      width: '80%',
      maxWidth: '686px',
    },
  },
  root: {
    // height: 50,
    borderRadius: 8,
    padding: '0px',
    color: 'rgba(0,0,0,0.5)',
    '& .MuiSelect-iconOutlined': {
      top: '42%',
      right: '14px',
    },
    '& .MuiSelect-outlined': {
      fontSize: '14px',
      lineHeight: '36px',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'white',
      borderRadius: 8,
      outline: 'none',
    },
    '& .PrivateNotchedOutline-root-5': {
      top: '0',
      padding: '5px 8px',
    },
    '& .MuiList-padding': {
      padding: '10px',
    },
    '& svg': {
      stroke: COLORS.typography.secondary,
    },
  },
  input: {
    '& .MuiInputBase-input': {
      height: 38,
      borderRadius: 4,
      border: `1px solid ${COLORS.accent.border}`,
      color: COLORS.typography.body,
      padding: '5px 12px',
      '&:focus': {
        borderRadius: 4,
      },
    },
  },
}));

export const MenuProps = {
  PaperProps: {
    style: {
      padding: '5px 0 0px 0px',
    },
  },
};

export const StyledMenuItem = styled.div`
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 14px;
  padding: 0px;
`;

export const StyledSpan = styled.span`
  color: ${COLORS.typography.body};
  font-size: 14px;
  margin: 0px;
  padding-bottom: 0px;
`;

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';
  font-size: 14px;
  width: 100%;
`;
