import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { MyCandidatesTable } from './MyCandidatesTable/MyCandidatesTable.component';
import { HeadControls, MainHeadline } from '../MyProfile.styles';
import { Counter } from '../../../../components/common/Counter/Counter';

export const MyCandidates = () => {
  const { t, i18n } = useTranslation(['user'], { keyPrefix: 'my_profile' });
  return (
    <>
      <MyCandidatesTable />
    </>
  );
};
