import styled, { createGlobalStyle } from 'styled-components';
import LoginBackground from '../../../assets/login-bg.png';
import { COLORS } from '../../../theme/colors';
import { ReactComponent as Logo } from '../images/navbar_logo.svg';
import {
  StyledHeaderContainerProps,
  StyledLogoProps,
} from './BenchlyDialog.types';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }

  body {
    background-image: url('${LoginBackground}');
    background-repeat: no-repeat;
    background-attachment: fixed;  
    background-size: cover;
    margin: 0;
    padding: 0;
  }
`;

export const StyledContainer = styled.div`
  background-color: ${COLORS.background.secondary};
  border: 1px solid ${COLORS.accent.border};
  border-radius: 8px;
  margin: 45px auto;
  padding: 80px;
  width: 100%;

  @media (min-width: 700px) {
    min-width: 450px;
    max-width: 500px;
  }

  @media (max-width: 699px) {
    max-width: 450px;
    padding: 25px 30px 25px 30px;
  }
`;

export const StyledHeaderContainer = styled.div<StyledHeaderContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  ${({ isCenteredTitle }) => isCenteredTitle && 'align-items: center;'}
`;

export const StyledButtonsContainer = styled.div`
  margin-top: 48px;
  width: 100%;
`;

export const StyledLogo = styled(Logo)<StyledLogoProps>`
  margin: 30px auto 0;
  width: 140px;
  &:hover {
    ${({ isClickable }) => isClickable && 'cursor: pointer;'}
  }
`;
