import { AnyAction } from '@reduxjs/toolkit';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PdfDownloader } from '../../../containers/CV/PdfDownloader/PdfDownloader.component';
import { vacanciesPageThunks } from '../../../containers/Vacancies/Vacancies.thunk';
import { VacanciesPageContext } from '../../../containers/Vacancies/VacanciesPageHrSales/VacanciesPageContext/VacanciesPageContext';
import { isPartner, removeHtml } from '../../../Helpers';
import { Cv } from '../../../store/types/Store.candidates.types';
import { SuggestedCandidate } from '../../../store/types/Store.home.types';
import { AppStore } from '../../../store/types/Store.types';
import { AddNewItemButton } from '../../common/Buttons/AddNewItemButton/AddNewItemButton';
import { NoResult } from '../../common/NoResult';
import { CandidateBar } from './CandidateBar';
import { CandidatesLoader } from './CandidateBar/CandidatesLoader';
import {
  HeadContainer,
  Line,
  StyledBox,
  StyledHeadline,
  StyledList,
} from './Candidates.styles';
import { CandidatesProps } from './Candidates.types';
import { myVacanciesPageThunks } from '../../../containers/User/MyProfile/MyVacancies/MyVacancies.thunk';

export const Candidates = ({
  leadProfileData,
  isOpen,
  type = 'all',
}: CandidatesProps) => {
  const { t } = useTranslation(['vacancies'], { keyPrefix: 'vacancies' });
  const user = useSelector<AppStore>((state) => state.user?.user);
  const candidates = useSelector<AppStore, SuggestedCandidate[] | undefined>(
    (state) =>
      state.home.leadProfiles.find(
        (elem) => elem.lead_profile_id === leadProfileData.lead_profile_id,
      )?.candidates,
  );
  const myCandidates = useSelector<AppStore, SuggestedCandidate[] | undefined>(
    (state) =>
      state.myVacancies.leadProfiles.find(
        (elem) => elem.lead_profile_id === leadProfileData.lead_profile_id,
      )?.candidates,
  );
  const currentCandidates = type === 'all' ? candidates : myCandidates;
  const dispatch = useDispatch();
  const { handleAddSuggestedCandidateButton } =
    useContext(VacanciesPageContext);

  const [cvToDownload, setCvToDownload] = useState<Cv | undefined>();
  const [downloadFinished, setDownloadFinished] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line radar/no-all-duplicated-branches
    if (isOpen && leadProfileData) {
      if (type === 'all') {
        !candidates &&
          dispatch(
            vacanciesPageThunks.getHomeCandidatesThunk({
              leadId: leadProfileData.lead_id as unknown as number,
              requestId: leadProfileData.lead_profile_id as unknown as number,
            }) as unknown as AnyAction,
          );
      } else {
        !myCandidates &&
          dispatch(
            myVacanciesPageThunks.getMyVacanciesCandidatesThunk({
              leadId: leadProfileData.lead_id as unknown as number,
              requestId: leadProfileData.lead_profile_id as unknown as number,
            }) as unknown as AnyAction,
          );
      }
    }
  }, [leadProfileData, isOpen]);

  const createCvFilename = () => {
    const currentCandidatesList = type === 'all' ? candidates : myCandidates;
    const candidateData = currentCandidatesList?.find(
      (cand) => cand.employee === cvToDownload?.employee,
    );
    const profileName = removeHtml(cvToDownload?.profile_name);
    const firstName = candidateData?.employee_data.first_name;
    const readableId = candidateData?.employee_data.readable_id;

    if (isPartner(user)) {
      return `${firstName}-${readableId}-${profileName}`;
    }
    return `${firstName}-${profileName}`;
  };

  return (
    <StyledBox>
      <HeadContainer>
        <StyledHeadline>
          {t('candidates')} ({leadProfileData.no_of_candidates})
        </StyledHeadline>
        {!isPartner(user) && (
          <AddNewItemButton
            action={() => handleAddSuggestedCandidateButton(leadProfileData)}
          />
        )}
      </HeadContainer>
      <Line />
      <StyledList>
        {isOpen &&
          leadProfileData.no_of_candidates > 0 &&
          (currentCandidates ? (
            currentCandidates.map((candidate, index) => (
              <CandidateBar
                key={`${index}-${candidate.id}`}
                type={type}
                {...{
                  candidate,
                  leadProfileData,
                  downloadFinished,
                  setDownloadFinished,
                  setCvToDownload,
                }}
              />
            ))
          ) : (
            <CandidatesLoader
              noOfCandidates={leadProfileData.no_of_candidates}
            />
          ))}
        {leadProfileData.no_of_candidates === 0 && (
          <NoResult message={t('no_suggested_candidates')} />
        )}
        {cvToDownload && !downloadFinished && (
          <PdfDownloader
            key={cvToDownload.id}
            cvId={cvToDownload.id}
            fileName={createCvFilename()}
            setDownloadFinished={setDownloadFinished}
          />
        )}
      </StyledList>
    </StyledBox>
  );
};
