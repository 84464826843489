export const findMatchingTag = (companyTags: string[], name: string) => {
  if (companyTags.includes(name)) return '';
  return (
    companyTags.find((tag) => {
      return tag.toLowerCase() === name.toLowerCase();
    }) ||
    companyTags.find((tag) => {
      return tag.toLowerCase().includes(name.toLowerCase());
    }) ||
    ''
  );
};
