import { CandidateStatusModel } from '../../../../containers/Leads/Leads.config';
import { CandidateStatusState } from '../../../../containers/Leads/LeadsTable/LeadsTableExpendableRow/LeadsTableExpandableRow.types';

export const getStatusesStatistics = (
  candidateStatuses: CandidateStatusModel[],
  candidates: CandidateStatusState[],
) => {
  const statistics: Record<string, number> = {};
  candidateStatuses
    .map((status) => status.name)
    .forEach((curStatus) => {
      const amount = candidates.filter(
        (candidate) => candidate.status === curStatus,
      ).length;
      statistics[curStatus] = amount;
    });
  return statistics;
};
