import { CreatedRowLead } from '../../../../containers/Leads/LeadsTable/LeadsTable.types';
import { CreatedRowHome } from '../../../../containers/Vacancies/VacanciesPageHrSales/CreateRow/CreateRow.types';
import { LeadIdType } from '../../../../store/types/Store.home.types';

export const getPageWithLeadToOpen = (
  rows: (CreatedRowHome | CreatedRowLead)[],
  idOpenedLead: LeadIdType,
  rowsPerPage: number,
) => {
  const founded = rows.findIndex((row) => row.id === idOpenedLead);
  return founded ? Math.ceil(founded / rowsPerPage) - 1 : 0;
};
