import { ComparatorType, DictionaryType } from './BaseTable.types';

export const getComparator = (
  order: 'asc' | 'desc',
  orderBy: string | null,
  customComparator: ComparatorType | null,
) => {
  const comparatorFactory = (a: DictionaryType, b: DictionaryType): any => {
    if (!orderBy) {
      return 0;
    }

    if (customComparator) {
      return customComparator(a[orderBy], b[orderBy]);
    }

    if (a[orderBy] === b[orderBy]) return 0;

    return a[orderBy] < b[orderBy] ? -1 : 1;
  };

  return order === 'desc'
    ? (a: DictionaryType, b: DictionaryType) => comparatorFactory(a, b)
    : (a: DictionaryType, b: DictionaryType) => -comparatorFactory(a, b);
};
