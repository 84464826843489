import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { FormEvent, useState } from 'react';
import { Currency } from '../../../../../store/types/Store.types';
import { raiseToast } from '../../../../../utils/raiseToasts';
import { AutocompleteInput } from '../../../AutocompleteInput';
import { Input } from '../../../Input/Input';
import { TechnologyAutocomplete } from '../TechnologyAutocomplete/TechnologyAutocomplete';
import { MAIN_TECHNOLOGIES_LIMIT } from './EmployeeForm.config';
import {
  CurrencyMenuItem,
  CurrencySelect,
  RateOfPayContainer,
  StyledEmployeeFormContainer,
  StyledFormContainer,
  StyledFormRowContainer,
  StyledFormTitle,
  StyledSubmitButon,
  StyledFormFooter,
} from './EmployeeForm.styles';
import {
  EmployeeFormProps,
  EmployeeFormSubmitResult,
  LanguageLevel,
  LANGUAGE_LEVELS,
} from './EmployeeForm.types';
import { Selector } from '../../../Selector/Selector.component';
import { Option } from '../../../Selector/Selector.types';
import { EmployeeVisibilityStatus } from '../../../../../store/types/Store.candidates.types';

export const EmployeeForm = ({
  employeeData,
  formTitle,
  onFormSubmit,
  supervisorList,
  technologyNames,
  submitButtonName,
  defaultSupervisor,
  selectedTechnologies,
  suggestedSources,
}: EmployeeFormProps) => {
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.candidates',
  });
  const visibilityOptions = Object.values(EmployeeVisibilityStatus).map(
    (visibilityStatus) => ({
      value: visibilityStatus,
      name: t(visibilityStatus.toLowerCase()),
    }),
  );
  const [currency, setCurrency] = useState<Currency>(
    employeeData?.rate_of_pay_currency || Currency.EUR,
  );
  const [visibility, setVisibility] = useState<Option>(
    visibilityOptions.find(
      (option) => option.value === employeeData?.visibility,
    ) || visibilityOptions[0],
  );
  const [mainTechnologies, setMainTechnologies] = useState<string[]>(
    selectedTechnologies || [],
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    ['e', 'E', '.', ',', '-'].includes(e.key) && e.preventDefault();
  };

  const handleMainTechnologiesChange = (newValue: string[]) => {
    if (newValue?.length > MAIN_TECHNOLOGIES_LIMIT) {
      raiseToast.warn(
        `${t('you_can_choose_only_up_to', {
          ns: 'common',
          keyPrefix: 'raiseToastMessage',
        })} You can choose only up to ${MAIN_TECHNOLOGIES_LIMIT} ${t(
          'technologies',
          {
            ns: 'common',
            keyPrefix: 'raiseToastMessage',
          },
        )}.`,
      );
    } else {
      setMainTechnologies(newValue);
    }
  };

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e?.target as unknown as Record<
      string,
      Record<'value', string>
    >;

    const modalResult: EmployeeFormSubmitResult = {
      first_name: target.firstName.value,
      last_name: target.lastName.value,
      profile: target.profile.value,
      availability: target.availability.value,
      rate_of_pay_from: parseFloat(target.rateOfPayFrom.value),
      rate_of_pay_to: parseFloat(target.rateOfPayTo.value),
      rate_of_pay_currency: currency,
      supervisor: target.supervisor.value,
      source: target.source.value,
      english_level: target.englishLevel.value as LanguageLevel,
      technologies: mainTechnologies,
      recruitee_link: target.recruiteeLink.value,
      email: target.email.value,
      visibility: visibility.value,
    };

    if (onFormSubmit) {
      onFormSubmit(modalResult);
    }
  };

  return (
    <StyledFormContainer onSubmit={handleFormSubmit}>
      <StyledFormTitle>{formTitle}</StyledFormTitle>
      <StyledEmployeeFormContainer>
        <StyledFormRowContainer>
          <Input
            label={t('candidates_first_name')}
            name="firstName"
            placeholder={t('first_name')}
            required
            displayAsRequired
            defaultValue={employeeData?.first_name}
          />
          <Input
            label={t('candidates_last_name')}
            name="lastName"
            placeholder={t('last_name')}
            required
            displayAsRequired
            defaultValue={employeeData?.last_name}
          />
        </StyledFormRowContainer>
        <StyledFormRowContainer>
          <Input
            label={t('email')}
            name="email"
            defaultValue={employeeData?.email}
          />

          <Selector
            label={t('visibility')}
            value={visibility}
            name="visibility"
            action={(event: SelectChangeEvent<Option>) =>
              setVisibility(event.target.value)
            }
            options={visibilityOptions}
            size="medium"
          />
        </StyledFormRowContainer>
        <StyledFormRowContainer>
          <Input
            label={t('profile')}
            name="profile"
            displayAsRequired
            required
            defaultValue={employeeData?.profile}
          />
          <Input
            label={t('availability')}
            name="availability"
            displayAsRequired
            required
            defaultValue={employeeData?.availability}
          />
        </StyledFormRowContainer>
        <StyledFormRowContainer>
          <RateOfPayContainer>
            <Input
              label={t('rate_of_pay_from')}
              displayAsRequired
              name="rateOfPayFrom"
              type="number"
              required
              defaultValue={employeeData?.rate_of_pay_from}
              onKeyDown={handleKeyDown}
            />
            <Input
              label={t('rate_of_pay_to')}
              name="rateOfPayTo"
              type="number"
              displayAsRequired
              required
              defaultValue={employeeData?.rate_of_pay_to}
              onKeyDown={handleKeyDown}
            />
          </RateOfPayContainer>

          <Selector
            label={t('currency')}
            value={{ name: currency, value: currency }}
            name="currency"
            action={(event: SelectChangeEvent<Option>) => {
              setCurrency(event.target.value.value as Currency);
            }}
            options={Object.values(Currency).map((mapperdCurrency) => ({
              name: `${mapperdCurrency} /h`,
              value: mapperdCurrency,
            }))}
            size="medium"
          />
        </StyledFormRowContainer>
        <StyledFormRowContainer>
          <AutocompleteInput
            label={t('supervisor')}
            name="supervisor"
            displayAsRequired
            required
            options={supervisorList}
            value={defaultSupervisor || employeeData?.supervisor}
            freeSolo
          />
          <AutocompleteInput
            label={t('english_level')}
            displayAsRequired
            required
            name="englishLevel"
            options={LANGUAGE_LEVELS}
            value={employeeData?.english_level}
            freeSolo
          />
        </StyledFormRowContainer>
        <TechnologyAutocomplete
          options={technologyNames}
          name="technologies"
          label={t('main_technologies')}
          displayAsRequired
          required
          handleInputChange={handleMainTechnologiesChange}
          value={mainTechnologies}
        />
        <AutocompleteInput
          label={t('source')}
          name="source"
          options={suggestedSources}
          value={employeeData?.source ?? suggestedSources[0]}
          freeSolo
        />
        <Input
          label={t('recruitee_link')}
          name="recruiteeLink"
          placeholder="..."
          defaultValue={employeeData?.recruitee_link || ''}
        />
      </StyledEmployeeFormContainer>
      <StyledFormFooter>
        <StyledSubmitButon>{submitButtonName}</StyledSubmitButon>
      </StyledFormFooter>
    </StyledFormContainer>
  );
};
