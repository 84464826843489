import styled, { css } from 'styled-components';
import { COLORS } from '../../../theme/colors';
import { StyledLabelProps } from './FormLabel.types';

export const labelStyles = css<StyledLabelProps>`
  ${({ error }) => css`
    color: ${error ? COLORS.error.main : COLORS.typography.secondary};
    font-family: 'Poppins';
    font-size: ${error ? '13px' : '14px'};
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 4px;
    padding-right: 5px;
  `}
`;

export const StyledLabel = styled.label`
  ${labelStyles}
`;

export const DisabledLabel = styled.label`
  ${labelStyles}
  margin-left: 1px;
  opacity: 0.6;
`;
