import { AddEmployeeModalContent } from '../AddingModalContent';
import { DeleteFromWatchedModalContent } from '../DeleteFromWatchedModalContent';
import { DeleteModalContent } from '../DeleteModalContent';
import { EditingModalContent } from '../EditModalContent';
import { EmployeeModalDispatcherProps } from './EmployeesModal.types';

export const EmployeeModalDispatcher = ({
  modalToDisplay,
  employeeId,
  onModalClose,
}: EmployeeModalDispatcherProps) => {
  if (modalToDisplay === 'add') {
    return <AddEmployeeModalContent onClose={onModalClose} />;
  }

  if (modalToDisplay === 'edit' && employeeId) {
    return (
      <EditingModalContent onClose={onModalClose} employeeId={employeeId} />
    );
  }

  if (modalToDisplay === 'delete' && employeeId) {
    return (
      <DeleteModalContent
        onArchiveCancel={onModalClose}
        onClose={onModalClose}
        employeeId={employeeId}
      />
    );
  }

  if (modalToDisplay === 'deleteFromWatched' && employeeId) {
    return (
      <DeleteFromWatchedModalContent
        onClose={onModalClose}
        employeeId={employeeId}
      />
    );
  }

  throw new Error(`Unknown modal type: ${modalToDisplay}`);
};
