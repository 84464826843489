import { useTranslation } from 'react-i18next';
import { usePriorityOptionsConfig } from '../../../common/Chips/PriorityChip/config';
import { ChipSelectLeadPriority } from '../../../common/ChipSelectLeadPriority';
import { PriorityKeyType } from '../../../common/CollapseTable/CollapseTable.types';
import { FormLabel } from '../../../common/FormLabel';
import {
  PriorityContainer,
  StyledLabelContainer,
} from '../LeadRequestForm.styles';
import { LeadPriorityProps } from './LeadPriority.types';

export const LeadPriority = ({
  leadRequest,
  setRequest,
}: LeadPriorityProps) => {
  const { t } = useTranslation(['vacancies'], { keyPrefix: 'vacancies' });
  const { priorityOptions } = usePriorityOptionsConfig();
  const handlePriorityChipChange = (newPriority?: PriorityKeyType) => {
    setRequest({
      ...leadRequest,
      priority: newPriority,
    });
  };
  return (
    <PriorityContainer>
      <StyledLabelContainer>
        <FormLabel label={t('priority')} />
      </StyledLabelContainer>
      <ChipSelectLeadPriority
        options={priorityOptions}
        handlePriorityChange={handlePriorityChipChange}
        currentPriority={leadRequest.priority}
      />
    </PriorityContainer>
  );
};
