import { useTranslation } from 'react-i18next';
import { AnyAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { employeeThunks } from '../../../../containers/Employee/Employees.thunk';
import { RootState } from '../../../../store/store';
import { EmployeeWritableDTO } from '../../../../store/types/Employees.types';
import { Technology } from '../../../../store/types/Store.leads.types';
import { EmployeeForm } from './EmployeeForm/EmployeeForm';
import { EmployeeFormSubmitResult } from './EmployeeForm/EmployeeForm.types';
import { EditModalProps } from './types';

export const EditingModalContent = ({
  onClose,
  employeeId,
}: EditModalProps) => {
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.candidates',
  });
  const dispatch = useDispatch();
  const employeeFetched = useSelector((state: RootState) =>
    state.employees.people.results.find((empl) => empl.id === employeeId),
  );
  const suggestedSources = useSelector(
    (state: RootState) => state.employees.suggestedSources,
  );
  const allSupervisors: string[] = useSelector((state: RootState) =>
    state.user.HRUsers.map((it) => it.username),
  );
  const technologyTags: Technology[] = useSelector(
    (state: RootState) => state.leadsRelated.tags.main_technologies,
  );

  const handleSubmit = async (employee: EmployeeFormSubmitResult) => {
    if (employee.technologies) {
      const techIds = technologyTags.filter(
        (it) =>
          employee.technologies && employee.technologies.includes(it.name),
      );
      delete employee.technologies;
      employee.technology_tags = techIds.map((it) => it.id);
    }

    await dispatch(
      employeeThunks.partialUpdateEmployeeThunk({
        employeeData: employee as unknown as EmployeeWritableDTO,
        employeeId,
      }) as unknown as AnyAction,
    );

    dispatch(
      employeeThunks.getEmployeeThunk({ employeeId }) as unknown as AnyAction,
    );
    onClose();
  };

  return (
    <EmployeeForm
      employeeData={employeeFetched}
      formTitle={t('edit_candidate')}
      onFormSubmit={handleSubmit}
      submitButtonName={t('update', { ns: 'common', keyPrefix: 'buttons' })}
      technologyNames={technologyTags.map((it) => it.name)}
      supervisorList={allSupervisors}
      suggestedSources={suggestedSources}
      selectedTechnologies={technologyTags
        .filter((it) => employeeFetched?.technology_tags.includes(it.id))
        .map((it) => it.name)}
    />
  );
};
