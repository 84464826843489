import { Popover } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as notificationActions } from '../../../containers/Notifications/Notification.slice';
import { notificationsThunks } from '../../../containers/Notifications/Notification.thunks';
import { useWebSocketEvent } from '../../../hooks/useWebSocketEvent';
import { NotificationContent } from '../../../interfaces/Notifications.types';
import { AppDispatch, RootState } from '../../../store/store';
import { AppStore } from '../../../store/types/Store.types';
import { UserState } from '../../../store/types/Store.user.types';
import NotificationButton from './NotificationButton';
import NotificationPopperContent from './Popper/NotificationPopperContent';

const NavbarNotification = () => {
  const [anchorMenuEl, setAnchorMenuEl] = useState<HTMLButtonElement | null>(
    null,
  );
  const notificationArray: NotificationContent[] = useSelector(
    (state: RootState) => state.notifications.notificationArray,
  );
  const userState = useSelector<AppStore, UserState>((state) => state.user);
  const dispatch = useDispatch<AppDispatch>();

  useWebSocketEvent({
    name: 'importantButton',
    category: 'notification',
    callback: (e) => {
      console.log(e);
      userState.user && dispatch(notificationsThunks.getMyNotifications());
    },
  });

  useEffect(() => {
    userState.user && dispatch(notificationsThunks.getMyNotifications());
    if (userState.user) {
      const interval = setInterval(() => {
        dispatch(notificationsThunks.getMyNotifications());
      }, 60000);
      return () => clearInterval(interval);
    }
  }, []);

  const amountOfUnreadMessages = notificationArray.filter(
    (el) => !el.isRead,
  ).length;

  const onReadAllClickHandler = async () => {
    await dispatch(notificationsThunks.markAllNotificationsAsRead());
    userState && (await dispatch(notificationsThunks.getMyNotifications()));
  };

  const changeNotificationReadStatus = async (notificationPosition: number) => {
    /*
    FIrst we perform an optimistic update of the read status. Then we are
    sending the patch request to update the data on the Backend. After 
    the request is resolved we perform an update of the UI again to ensure
    the data is 
    */
    const selectedNotification = notificationArray[notificationPosition];

    dispatch(
      notificationActions.optimisticNotificationUpdate({
        notificationIndex: notificationPosition,
        newStatus: !selectedNotification.isRead,
      }),
    );
    await dispatch(
      notificationsThunks.changeNotification({
        notifId: selectedNotification.id,
        body: JSON.stringify({ is_read: !selectedNotification.isRead }),
      }),
    );
  };

  const showNotificationMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenuEl(e.currentTarget);
  };

  const hideNotificationMenu = () => {
    setAnchorMenuEl(null);
  };

  const isMenuOpen = Boolean(anchorMenuEl);

  return (
    <>
      <NotificationButton
        numberOfNotifications={amountOfUnreadMessages}
        onClick={showNotificationMenu}
        disabled={notificationArray.length === 0}
      />
      <Popover
        open={isMenuOpen}
        onClose={hideNotificationMenu}
        anchorEl={anchorMenuEl}
        sx={{ marginTop: '5px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            borderRadius: '0px',
          },
        }}
      >
        <NotificationPopperContent
          feedContent={notificationArray}
          onReadOnClick={(idx) => changeNotificationReadStatus(idx)}
          onReadAllClick={onReadAllClickHandler}
          onNotificationClick={hideNotificationMenu}
        />
      </Popover>
    </>
  );
};

export default NavbarNotification;
