import { createSlice } from '@reduxjs/toolkit';
import { SuggestedCandidate } from '../../../store/types/Store.home.types';
import { leadProfileCandidatesThunks } from './LeadProfileCandidates.thunk';

const { getLeadRequestCandidatesThunk } = leadProfileCandidatesThunks;

const initialState: SuggestedCandidate[] = [];

const leadProfileCandidatesSlice = createSlice({
  name: 'leadProfileCandidates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getLeadRequestCandidatesThunk.fulfilled,
      (state, action) => {
        state.length = 0;
        action.payload.forEach((data: SuggestedCandidate) => {
          state.push(data);
        });
      },
    );
  },
});

export const actions = { ...leadProfileCandidatesSlice.actions };
export const { reducer } = leadProfileCandidatesSlice;
