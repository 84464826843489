import { createSlice } from '@reduxjs/toolkit';
import { languagesTagsThunks } from './LanguagesTags.thunk';
import { Language } from '../../../../store/types/Store.employeePage.types';

const { getLanguagesThunk } = languagesTagsThunks;

const initialState: Language[] = [];

const languagesTagsSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLanguagesThunk.fulfilled, (state, action) => {
      state.length = 0;
      action.payload.forEach((data: string[]) => {
        const [value, name] = data;
        state.push({ value, name });
      });
    });
  },
});

export const actions = { ...languagesTagsSlice.actions };
export const { reducer } = languagesTagsSlice;
