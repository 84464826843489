import { useTranslation } from 'react-i18next';

export const useLeadRequestFormConfig = () => {
  const { t } = useTranslation(['vacancies'], { keyPrefix: 'vacancies' });
  const visibilityOptions = [
    { value: 'OWN_COMPANY', name: t('own_company') },
    {
      value: 'OWN_AND_PARTNER_COMPANIES',
      name: t('own_and_partner_companies'),
    },
    { value: 'EVERYONE', name: t('everyone') },
  ];

  return {
    visibilityOptions,
  };
};
