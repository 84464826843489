import { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LanguagesEntry } from '../../../../../components/CV/Languages/LanguagesEntry.component';
import { actions as cvActions } from '../../../CV.slice';
import { actions as languagesActions } from '../Languages.slice';

export const LanguagesEntryContainer = forwardRef(
  (
    {
      element,
      editable,
      sectionWithParent,
      updateSectionHeight,
      handleDrop,
      handleDragOver,
      updateElement,
      handleDrag,
      removeSectionRef,
      setTextFormattingPanelVisibility,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const {
      section: { sectionId, defaultData, height },
      parentSection: { sectionId: parentSectionId },
    } = sectionWithParent;
    const data = useSelector((state) =>
      state.cv.present.data.languages.entries.find(
        (entry) => entry.sectionId === sectionId,
      ),
    )?.data;

    useEffect(() => {
      if (!data) {
        dispatch(
          languagesActions.createData({
            sectionId,
            defaultData,
          }),
        );
      }
    }, [data]);

    const handleEditData = (dataP) => {
      dispatch(
        languagesActions.updateEntryData({
          sectionId,
          data: dataP,
        }),
      );
    };

    const handleRemoveEntry = () => {
      dispatch(
        cvActions.deleteEntry({
          sectionId,
          parentSectionId,
        }),
      );
      dispatch(languagesActions.deleteData(sectionId));
    };

    return (
      <LanguagesEntry
        ref={ref}
        element={element}
        sectionId={sectionId}
        removeSectionRef={removeSectionRef}
        editable={editable}
        handleDrag={handleDrag}
        updateElement={updateElement}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        handleEditData={handleEditData}
        isExperienceSectionDisplayed
        updateSectionHeight={updateSectionHeight}
        setTextFormattingPanelVisibility={setTextFormattingPanelVisibility}
        handleRemoveEntry={handleRemoveEntry}
        data={data || {}}
        height={height}
      />
    );
  },
);
