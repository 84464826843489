import { HomeLeadProfile } from '../../../../../../store/types/Store.home.types';

export const getLeadId = (
  homeLeadProfile?: HomeLeadProfile,
  id?: string,
): number | undefined => {
  if (id) return Number(id);
  if (homeLeadProfile?.lead_id) return Number(homeLeadProfile.lead_id);
  return undefined;
};
