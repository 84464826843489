import { Chip, IconButton } from '@mui/material';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
interface Props {
  isMobile?: boolean;
  fontColor?: string;
}

export const StyledChip = styled(Chip)<Props>`
  color: black;
  display: table-cell;
  font-size: 11px;
  height: 100%;
  line-height: 15px;
  ${isMobile && `maxWidth: '98px'`};
  max-width: 150px;
  .MuiChip-label {
    color: ${({ fontColor }) => fontColor ?? 'black'};
    font-family: 'Poppins', sans-serif;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
`;

export const StyledIconButton = styled(IconButton)({
  padding: 0,
  margin: 0,
});
