import { AnyAction } from '@reduxjs/toolkit';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { employeeThunks } from '../../../../../containers/Employee/Employees.thunk';
import {
  Candidate,
  EmployeeTableDataInterfaceWithExperienceValid,
} from '../../../../../store/types/Store.candidates.types';
import { AppStore } from '../../../../../store/types/Store.types';
import { useEffectAsync } from '../../../../../utils/useEffectAsync';
import { AutocompleteInput } from '../../../AutocompleteInput';
import { CandidateOptionItemWithStatus } from '../../../CandidateOptionItemWithStatus';
import { CandidateChip } from '../../../Chips/CandidateChip';
import { Dialog } from '../../../Dialog/DEPR_Dialog';
import { getSortedEmployees } from '../../../helpers/getSortedEmployees';
import { AddingSuggestedCandidatesModalContentProps } from './AddingSuggestedCandidatesModal.types';
import { CandidatesList } from './AddingSuggestedCandidatesModalContent.styles';
import { isCandidateAlreadyAdded } from './helpers/isCandidateAlreadyAdded';
import { isCandidateOptionDisabled } from './helpers/isCandidateOptionDisabled';
import { useAddSuggestedCandidates } from './useAddSuggestedCandidates';

export const AddingSuggestedCandidatesModalContent = React.forwardRef<
  HTMLDivElement,
  AddingSuggestedCandidatesModalContentProps
>(({ closeModal, leadId, leadProfileId, suggestedCandidatesIds }) => {
  const { t } = useTranslation(['vacancies', 'user', 'common']);
  const dispatch = useDispatch();
  const params = useParams();
  const { addSuggestedCandidates } = useAddSuggestedCandidates({
    leadProfileId,
    leadId: leadId || Number(params.id),
    cbFn: closeModal,
  });

  const buttonsConfig = {
    primaryButton: {
      label: t('save', { ns: 'common', keyPrefix: 'buttons' }),
      action: async () =>
        await addSuggestedCandidates(
          selectedCandidates.map((candidate) => candidate.id as string),
        ),
    },
  };

  const [selectedCandidates, setSelectedCandidates] = useState<
    EmployeeTableDataInterfaceWithExperienceValid[]
  >([]);
  const [sortedEmploees, setSortedEmploees] = useState<
    EmployeeTableDataInterfaceWithExperienceValid[]
  >([]);
  const possibleCandidates = useSelector<
    AppStore,
    EmployeeTableDataInterfaceWithExperienceValid[]
  >((state) => state.employees.possibleCandidates);

  const homeCandidates = useSelector<AppStore, string[] | undefined>((state) =>
    state.home.leadProfiles
      .find((elem) => elem.lead_profile_id === leadProfileId)
      ?.candidates?.map((el) => el.employee_data.id),
  );

  useEffect(() => {
    possibleCandidates &&
      possibleCandidates.length &&
      setSortedEmploees(getSortedEmployees(possibleCandidates));
  }, [possibleCandidates]);

  useEffectAsync(async () => {
    if (leadId && leadProfileId) {
      await dispatch(
        employeeThunks.getPossibleCandidatesThunk({
          leadId,
          leadProfileId,
        }) as unknown as AnyAction,
      );
    }
  }, [leadId, leadProfileId]);

  const handleDeleting = (candidateToRemove: Candidate) => {
    const newCandidates = selectedCandidates.filter(
      (candidate) => candidate.id !== candidateToRemove.id,
    );
    setSelectedCandidates(newCandidates);
  };

  return (
    <Dialog
      dialogTitle={t('add_new_candidates', { keyPrefix: 'vacancies' })}
      buttonsConfig={buttonsConfig}
      handleClose={closeModal}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <AutocompleteInput
          multiple
          label={t('candidates_dev', { keyPrefix: 'vacancies' })}
          name="candidates"
          value={selectedCandidates}
          options={sortedEmploees}
          getOptionLabel={(option) => {
            if (typeof option === 'object') {
              const { first_name, last_name, readable_id } = option;
              return `${first_name} ${last_name} - ${readable_id}`;
            }
            return option;
          }}
          renderOption={(
            props: HTMLAttributes<HTMLLIElement>,
            option: EmployeeTableDataInterfaceWithExperienceValid,
          ) => <CandidateOptionItemWithStatus {...{ props, option }} />}
          onChange={(_event, newValue) =>
            setSelectedCandidates(
              newValue as unknown as EmployeeTableDataInterfaceWithExperienceValid[],
            )
          }
          getOptionDisabled={(option) =>
            isCandidateOptionDisabled(option) ||
            isCandidateAlreadyAdded(option, suggestedCandidatesIds) ||
            isCandidateAlreadyAdded(option, homeCandidates)
          }
          disableCloseOnSelect
        />
        <CandidatesList>
          {selectedCandidates.map((candidate, index) => (
            <CandidateChip
              key={`${index}-${candidate.id}`}
              option={candidate}
              label={`${candidate.first_name || candidate.profile} ${
                candidate.last_name
              }`}
              handleClick={(option: unknown) =>
                handleDeleting(option as unknown as Candidate)
              }
            />
          ))}
        </CandidatesList>
      </Box>
    </Dialog>
  );
});
