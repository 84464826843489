import React, { useState } from 'react';
import { AutocompleteInput } from '../../../AutocompleteInput';
import { Dialog } from '../../../Dialog/DEPR_Dialog';
import { capitalizeString } from '../../../helpers/capitalizeString';
import { ReasonChangeStatusModalContentProps } from './ReasonChangeStatusModalContent.types';

export const ReasonChangeStatusModalContent = ({
  oldStatus,
  newStatus,
  closeModal,
  reasonTags,
  changeStatus,
}: ReasonChangeStatusModalContentProps) => {
  const [selectedReason, setSelectedReason] = useState();
  const buttonsConfig = {
    primaryButton: {
      label: 'Save',
      action: () => {
        newStatus && changeStatus(newStatus, selectedReason);
        closeModal();
      },
    },
  };
  return (
    <Dialog
      dialogTitle="Status:"
      oldState={oldStatus && capitalizeString(oldStatus)}
      newState={newStatus && capitalizeString(newStatus)}
      buttonsConfig={buttonsConfig}
      handleClose={closeModal}
    >
      <AutocompleteInput
        label="Choose reason"
        name="reason"
        options={reasonTags.map((tag) => tag.reason)}
        value={selectedReason}
        handleInputChange={(e: React.BaseSyntheticEvent) =>
          setSelectedReason(e.target.innerText)
        }
        handleCustomOption={(e: React.BaseSyntheticEvent) =>
          setSelectedReason(e.target.value)
        }
        freeSolo
      />
    </Dialog>
  );
};
