import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { ArrowCellStyled } from '../Row.styles';
import { ArrowIconProps } from './ArrowIcon.types';

export const ArrowIcon = ({ isOpen, setIsOpen }: ArrowIconProps) => (
  <ArrowCellStyled>
    <IconButton
      aria-label="expand row"
      size="small"
      onClick={(e: React.BaseSyntheticEvent) => {
        e.stopPropagation();
        setIsOpen((prevOpen) => !prevOpen);
      }}
    >
      {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  </ArrowCellStyled>
);
