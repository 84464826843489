import { createSlice } from '@reduxjs/toolkit';
import { Tag } from '../../../../store/types/Store.leads.types';
import { profileTagsThunks } from './ProfileTags.thunk';

const { getProfileTagsThunk } = profileTagsThunks;

const initialState: Tag[] = [];

const profileTagsSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfileTagsThunk.fulfilled, (state, action) => {
      state.length = 0;
      action.payload.forEach((data: Tag) => {
        state.push({ id: data.id, name: data.name });
      });
    });
  },
});

export const actions = { ...profileTagsSlice.actions };
export const { reducer } = profileTagsSlice;
