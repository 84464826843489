import React from 'react';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import Tooltip from '@mui/material/Tooltip';
import { StyledIconButton } from './ItalicIcon.styles';

export const ItalicIcon = ({ action, title = 'Italic', ...iconOptions }) => (
  <StyledIconButton onClick={action}>
    <Tooltip title={title}>
      <FormatItalicIcon style={{ color: 'black' }} {...iconOptions} />
    </Tooltip>
  </StyledIconButton>
);
