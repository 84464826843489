import { AnyAction } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppPaths } from '../../../../../config/AppPaths';
import { leadProfileCandidatesThunks } from '../../../../../containers/Leads/redux/LeadProfileCandidates.thunk';
import { leadProfilesThunks } from '../../../../../containers/Leads/redux/LeadProfiles.thunk';
import { vacanciesPageThunks } from '../../../../../containers/Vacancies/Vacancies.thunk';
import {
  LeadIdType,
  LeadProfileIdType,
  SuggestedCandidate,
} from '../../../../../store/types/Store.home.types';
import { raiseToast } from '../../../../../utils/raiseToasts';
import { myVacanciesPageThunks } from '../../../../../containers/User/MyProfile/MyVacancies/MyVacancies.thunk';
import { useLeadsConfig } from '../../../../../containers/Leads/useLeadsConfig';
import i18n from '../../../../../i18n';

export const useChangeStatus = (
  leadId: LeadIdType,
  leadProfileId: LeadProfileIdType,
  candidate?: SuggestedCandidate,
  type?: 'my-candidates' | 'all',
) => {
  const { candidateStatuses } = useLeadsConfig();
  const [changeStatusInProgress, setChangeStatusInProgress] =
    useState<boolean>(false);
  const dispatch = useDispatch();

  const changeStatus = async (newStatus: string, reason?: string) => {
    if (leadId && leadProfileId && candidate) {
      setChangeStatusInProgress(true);
      const founded = candidateStatuses.find(
        (candidateStatus) =>
          candidateStatus.showedName === newStatus ||
          candidateStatus.name === newStatus,
      );
      if (founded && candidate) {
        const result = await dispatch(
          leadProfileCandidatesThunks.updateCandidateStatusThunk({
            lead_id: leadId,
            lead_profile_id: leadProfileId,
            candidate_id: candidate.candidate_id || candidate.id!,
            status: { status: founded.name, reason: reason || '' },
          }) as unknown as AnyAction,
        );
        if (
          result.type ===
          leadProfileCandidatesThunks.updateCandidateStatusThunk.fulfilled.type
        ) {
          dispatch(
            leadProfilesThunks.getLeadRequestsThunk({
              leadId,
            }) as unknown as AnyAction,
          );
          if (type === 'all') {
            dispatch(
              vacanciesPageThunks.getHomeCandidatesThunk({
                leadId: leadId as unknown as number,
                requestId: leadProfileId as unknown as number,
              }) as unknown as AnyAction,
            );
          } else {
            dispatch(
              myVacanciesPageThunks.getMyVacanciesCandidatesThunk({
                leadId: leadId as unknown as number,
                requestId: leadProfileId as unknown as number,
              }) as unknown as AnyAction,
            );
          }
          setChangeStatusInProgress(false);
        }
      }
    } else {
      const message = i18n.t('raiseToastMessage.something_went_wrong', {
        ns: 'common',
      });
      raiseToast.error(message);
      console.error('Lack of input data: ', leadId, leadProfileId, candidate);
    }
  };
  return {
    changeStatus,
    changeStatusInProgress,
    setChangeStatusInProgress,
  };
};
