import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AnyAction } from '@reduxjs/toolkit';
import { Box } from '@mui/material';
import { CompanyInfoProps } from './CompanyInfo.types';
import { AppStore } from '../../../store/types/Store.types';
import {
  CompanyItem,
  CompanySizeInterface,
} from '../../../store/types/Store.company';
import { useEffectAsync } from '../../../utils/useEffectAsync';
import { companyThunks } from '../../../containers/Company/Company.thunk';
import { DetailItem } from './DetailItem/DetailItem.component';
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/email.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
import { ReactComponent as VatsIcon } from '../../../assets/icons/vats.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/copy-link.svg';
import { ReactComponent as UsersIcon } from '../../../assets/icons/users.svg';
import { COLORS } from '../../../theme/colors';

export const CompanyInfo = ({ companyId }: CompanyInfoProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['company'], {
    keyPrefix: 'company_details',
  });
  const company = useSelector<AppStore, CompanyItem>(
    (state) => state.company.company,
  );
  const companySizeOptions = useSelector<AppStore, CompanySizeInterface[]>(
    (state) => state.company.companySizyOptions,
  );

  const currentCompanySize = company.company_size
    ? companySizeOptions.find(
        (option) => Number(option.id) === company.company_size,
      )
    : { id: '', name: '' };

  const detailsItems = [
    {
      id: 1,
      label: t('companyName'),
      content: company.name || '',
      companyLogo: company.logo || '',
      copyable: false,
    },
    {
      id: 2,
      label: t('company_country'),
      content: company.country?.name || '',
      icon: <LocationIcon />,
      copyable: false,
    },
    {
      id: 3,
      label: t('emailAddress'),
      content: company.email || '',
      link: `mailto:${company.email}`,
      icon: <EmailIcon />,
      copyable: true,
    },
    {
      id: 4,
      label: t('additional_email'),
      content: company.additional_email || '',
      link: `mailto:${company.additional_email}`,
      icon: <EmailIcon />,
      copyable: true,
    },
    {
      id: 5,
      label: t('phone'),
      content: company.phone_number || '',
      link: `tel:${company.phone_number}`,
      icon: <PhoneIcon />,
      copyable: true,
    },
    {
      id: 6,
      label: t('vat_number'),
      content: company.vat_number || '',
      icon: <VatsIcon />,
      copyable: false,
    },
    {
      id: 7,
      label: t('address'),
      content: company.address || '',
      icon: <LocationIcon />,
      copyable: false,
    },
    {
      id: 8,
      label: t('website'),
      content: company.website || '',
      link: company.website || '',
      icon: <LinkIcon stroke={COLORS.accent.primary} />,
      copyable: true,
    },
    {
      id: 9,
      label: t('clutch'),
      content: company.link_to_clutch || '',
      link: company.link_to_clutch || '',
      icon: <LinkIcon stroke={COLORS.accent.primary} />,
      copyable: true,
    },
    {
      id: 10,
      label: t('company_size'),
      content:
        currentCompanySize?.name &&
        `${currentCompanySize?.name} ${t('employees')}`,
      icon: <UsersIcon stroke={COLORS.accent.primary} />,
      copyable: false,
    },
  ];

  useEffectAsync(async () => {
    await dispatch(
      companyThunks.getCompanyInfoThunk(companyId) as unknown as AnyAction,
    );
  }, [companyId]);

  useEffectAsync(async () => {
    dispatch(companyThunks.getCompanySizesThunk() as unknown as AnyAction);
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="32px"
      height="100%"
      flexWrap="wrap"
      width="100%"
    >
      {detailsItems.map(
        (item) =>
          item.content &&
          item.content.length !== 0 && (
            <DetailItem
              key={item.id}
              label={item.label}
              content={item.content}
              link={item.link}
              copyable={item.copyable}
              icon={item.icon}
              companyLogo={item.companyLogo}
            />
          ),
      )}
    </Box>
  );
};
