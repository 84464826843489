import { EmployeeTableDataInterfaceWithExperienceValid } from '../../../../../../store/types/Store.candidates.types';

export const isCandidateAlreadyAdded = (
  candidate: EmployeeTableDataInterfaceWithExperienceValid,
  suggestedCandidatesIds?: string[],
) => {
  if (suggestedCandidatesIds) {
    return suggestedCandidatesIds.includes(candidate.id.toString());
  }
  return false;
};
