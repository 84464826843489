import { AddingModalContent } from '../../../../../../components/common/Modals/Leads/AddingModalContent/AddingModalContent';
import { StyledModalWrapper } from '../../../../../../components/common/Modals/Leads/LeadsModal/LeadsModal.styles';
import { StyledModal } from '../../../../../../components/common/Modals/Modal.styles';
import { AddNewRequestModalProps } from './AddNewRequestModal.types';

export const AddNewRequestModal = ({
  openModal,
  setOpenModal,
}: AddNewRequestModalProps) => (
  <StyledModal
    open={openModal}
    aria-labelledby="candidates-modal-title"
    aria-describedby="candidates-modal-description"
    width="459px"
  >
    <StyledModalWrapper>
      <AddingModalContent closeModal={() => setOpenModal(false)} />
    </StyledModalWrapper>
  </StyledModal>
);
