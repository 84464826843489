import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef } from 'react';
import { pastePlainText } from '../../../../Helpers';
import {
  StyledAddButton,
  StyledDeleteColumnButton,
  StyledIcon,
  StyledSectionHeader,
  StyledTechnologies,
} from './TechnologyHeader.styles';

export const TechnologyHeader = forwardRef(
  (
    {
      handleDrag,
      handleDrop,
      handleDragOver,
      editable,
      children,
      setTextFormattingPanelVisibility,
      technologyGroupName,
      handleAddEntry,
      handleRemoveEntry,
      handleEditData,
    },
    ref,
  ) => {
    const [focused, setFocused] = React.useState(false);

    return (
      <StyledTechnologies
        ref={ref}
        draggable={!focused}
        onDragStart={handleDrag}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => setFocused(false)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'space-between',
          }}
        >
          <StyledSectionHeader
            placeholder="Type"
            suppressContentEditableWarning
            contentEditable={editable}
            onFocus={() => {
              setTextFormattingPanelVisibility(false);
            }}
            onPaste={(e) => pastePlainText(e)}
            onBlur={
              editable
                ? (e) => {
                    setTextFormattingPanelVisibility(false);
                    handleEditData(e.target.innerHTML);
                  }
                : undefined
            }
            spellCheck={false}
            dangerouslySetInnerHTML={{ __html: technologyGroupName }}
          />
          {editable ? (
            <div style={{ display: 'flex' }}>
              <StyledAddButton>
                <StyledIcon onClick={() => handleAddEntry()}>
                  <FontAwesomeIcon icon={faPlusCircle} />
                </StyledIcon>
              </StyledAddButton>
              <StyledDeleteColumnButton>
                <StyledIcon onClick={handleRemoveEntry}>
                  <FontAwesomeIcon icon={faMinusCircle} />
                </StyledIcon>
              </StyledDeleteColumnButton>
            </div>
          ) : undefined}
        </div>
        <div>{children()}</div>
      </StyledTechnologies>
    );
  },
);
