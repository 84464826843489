export const bulletForChrome = (document) => {
  const selection = document.getSelection().toString();
  const wasTextSelected = selection !== '';
  const text = document.activeElement.innerText.split('\n');
  const firstLine = text[0];
  const oneLineSelection = !selection.includes('\n') && wasTextSelected;

  if (oneLineSelection) {
    if (firstLine.length && selection === firstLine) {
      document.execCommand('insertText', false, '\n');
      document.execCommand('InsertUnorderedList', false, null);
      document.execCommand('insertText', false, selection);
    } else {
      document.execCommand('InsertUnorderedList', false, null);
    }
  } else {
    document.execCommand('InsertUnorderedList', false, null);
    const html = document.activeElement.innerHTML;
    if (html[1] === 'u' || html[6] === 'u') {
      if (selection) {
        document.execCommand('insertText', false, '\n\n');
        document.execCommand('InsertUnorderedList', false, null);
        document.execCommand('insertText', false, selection);
      } else {
        document.execCommand('InsertUnorderedList', false, null);
        document.execCommand('insertText', false, '\n');
        document.execCommand('InsertUnorderedList', false, null);
      }
    }
  }
};

export const bulletForFirefox = (document) => {
  const selection = document.getSelection().toString();
  const wasTextSelected = selection !== '';
  const text = document.activeElement.innerText.split('\n');
  const firstLine = text[0];
  const oneLineSelection = !selection.includes('\n') && wasTextSelected;

  if (oneLineSelection) {
    if (firstLine.length && selection === firstLine) {
      document.execCommand('insertText', false, '\n');
      document.execCommand('InsertUnorderedList', false, null);
      document.execCommand('insertText', false, selection);
    } else {
      document.execCommand('InsertUnorderedList', false, null);
    }
  } else {
    document.execCommand('InsertUnorderedList', false, null);
    const html = document.activeElement.innerHTML;
    if (html[1] === 'u') {
      document.execCommand('InsertUnorderedList', false, null);
      document.execCommand('selectAll', false, null);
      document.execCommand('insertText', false, '\n');
      document.execCommand('insertHTML', false, html);
    } else if (html[6] === 'u') {
      document.execCommand('selectAll', false, null);
      document.execCommand('insertText', false, '\n');
      document.execCommand('insertHTML', false, html);
    }
  }
};
