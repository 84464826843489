/* eslint-disable react/destructuring-assignment */
import {
  Box,
  CircularProgress,
  TableBody,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledBaseTable,
  StyledBaseTableCell,
  StyledBaseTableContainer,
} from './BaseTable.styles';
import { BaseTableProps, ComparatorType } from './BaseTable.types';
import { BaseTableHeader } from './BaseTableHeader';
import { BaseTablePaginator } from './BaseTablePaginator';
import { BaseTableRow } from './BaseTableRow';
import { getComparator } from './utils';

export const BaseTable = (props: BaseTableProps) => {
  props = {
    ...{
      loading: false,
      dense: false,
      paginationDefaultPageLen: 10,
    },
    ...props,
  };
  if (!props.paginationDefaultPageLen) {
    throw Error();
  }
  const { t } = useTranslation(['common']);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const [customComparator, setCustomComparator] =
    useState<ComparatorType | null>(null);

  const handleRequestSort = useCallback(
    (property: string, customComparatorParam?: ComparatorType): void => {
      const isAsc = orderBy === property && order === 'asc';
      if (customComparatorParam) {
        setCustomComparator(() => customComparatorParam);
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      } else {
        setCustomComparator(null);

        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      }
    },
    [orderBy, order],
  );

  const corelateRowsWithColumns = useCallback(
    (row) =>
      props.columns.map((col) => {
        let data = row[col.field];

        if (col.valueGetter) {
          data = col.valueGetter({ row, value: data });
        }

        if (data) {
          if (col.renderCell) {
            return col.renderCell({ row, value: data });
          }
          return data;
        }
        return t('none');
      }),
    [props.columns],
  );

  const sortedRows = useMemo(
    () => props.rows.sort(getComparator(order, orderBy, customComparator)),
    [order, orderBy, props.rows],
  );

  const amountOfEmptyRows = Math.max(
    0,
    (1 + props.pageNumber) * props.paginationDefaultPageLen - props.rows.length,
  );

  if (props.loading) {
    return (
      <StyledBaseTableContainer style={props.customContainerStyles}>
        <TableContainer>
          <Box
            sx={{
              marginX: 'auto',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <CircularProgress disableShrink color="inherit" />
          </Box>
        </TableContainer>
      </StyledBaseTableContainer>
    );
  }

  if (!props.rows.length) {
    return (
      <StyledBaseTableContainer style={props.customContainerStyles}>
        <TableContainer>
          <Box
            sx={{
              marginX: 'auto',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <h2>{t('messages.no_data_found')}</h2>
          </Box>
        </TableContainer>
      </StyledBaseTableContainer>
    );
  }

  return (
    <StyledBaseTableContainer style={props.customContainerStyles}>
      <TableContainer>
        <StyledBaseTable>
          <BaseTableHeader
            order={order}
            orderBy={orderBy}
            onSortEvent={handleRequestSort}
            columns={props.columns}
            isSelectable={false}
            customHeaderCellStyles={props.customHeaderCellStyles}
          />
          <TableBody>
            {sortedRows
              .slice(
                props.pageNumber * props.paginationDefaultPageLen,
                (props.pageNumber + 1) * props.paginationDefaultPageLen,
              )
              .map((row, index) => (
                <BaseTableRow
                  key={index}
                  onRowClick={() => props.onRowClick && props.onRowClick(row)}
                  row={corelateRowsWithColumns(row)}
                  columns={props.columns}
                  customCellStyles={props.customCellStyles}
                />
              ))}
            {props.pagination && amountOfEmptyRows > 0 && (
              <TableRow
                style={{
                  maxHeight: 61 * amountOfEmptyRows,
                }}
              >
                <StyledBaseTableCell
                  colSpan={props.columns.length}
                  style={props.customCellStyles}
                />
              </TableRow>
            )}
          </TableBody>
        </StyledBaseTable>
        {props.pagination && (
          <BaseTablePaginator
            defaultPageLength={props.paginationDefaultPageLen}
            noOfRows={props.rows.length}
            onChangePage={props.onPageNumberChange}
            pageNumber={props.pageNumber}
            setPageNumber={props.onPageNumberChange}
            // onRowsPerPageChange={setPageLength}
            {...props.customPaginationStyles}
          />
        )}
      </TableContainer>
    </StyledBaseTableContainer>
  );
};
