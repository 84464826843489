import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  LeadIdType,
  LeadProfileIdType,
} from '../../../store/types/Store.home.types';
import { getAuthHeader, handleResponse } from '../../../utils/api';
import { generateLeadRequestCandidateStatusHistoryUrl } from '../../../utils/urlGenerators';

const getCandidateStatusHistoryThunk = createAsyncThunk(
  ' leadProfileCandidateHistory/getCandidateStatusHistory',
  async (
    data: {
      leadId: LeadIdType;
      requestId: LeadProfileIdType;
      candidateId: number;
    },
    { getState, dispatch },
  ) => {
    const URL = generateLeadRequestCandidateStatusHistoryUrl(
      data.leadId,
      data.requestId,
      data.candidateId,
    );
    const request = fetch(`${URL}`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);
export const leadProfileCandidateHistoryThunks = {
  getCandidateStatusHistoryThunk,
};
