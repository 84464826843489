import { Box } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  addDotsToString,
  getDateString,
  getDateTimeString,
} from '../../../../../Helpers';
import { Button } from '../../../Button/Button.component';
import { PriorityIndicator } from '../../../PriorityIndicator';
import { Tooltip } from '../../../Tooltip/Tooltip.component';
import { STRING_LIMIT } from '../config';
import { isDateColumnType } from '../Helpers/isDateColumnType';
import { StyledSpan, StyledTableHeader } from '../Row.styles';
import { HomeRowHeaderProps } from './HomeRowHeader.types';
import { ReactComponent as PhoneIcon } from '../../../../../assets/icons/phone.svg';
import { CompanyDetailsModal } from '../../../../Company/CompanyDetailsModal/CompanyDetailsModal.component';

export const HomeRowHeader = ({ tableData, column }: HomeRowHeaderProps) => {
  const { t } = useTranslation(['vacancies'], { keyPrefix: 'vacancies' });
  const [openCompanyDetailsModal, setOpenCompanyDetailsModal] = useState(false);

  const onContactDetailsClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    setOpenCompanyDetailsModal(true);
  };

  const getRow = (id: string) => {
    switch (id) {
      case 'priority':
        return <PriorityIndicator priority={tableData.row.priority} />;
      case 'action':
        return '';
      case 'companyContactDetails':
        return (
          <>
            {tableData.row.companyContactDetails ? (
              <Button
                size="small"
                variant="secondary"
                action={onContactDetailsClick}
              >
                <Box
                  display="flex"
                  gap="10px"
                  alignItems="center"
                  sx={{ fontSize: '14px' }}
                >
                  <PhoneIcon fill="white" />
                  {t('contact')}
                </Box>
              </Button>
            ) : (
              ''
            )}
          </>
        );
      default:
        return (
          <StyledSpan
            isHidden={tableData.row[column.id] === 'hidden'}
            color={column.color}
          >
            {tableData.row[column.id]}
          </StyledSpan>
        );
    }
  };

  return (
    <StyledTableHeader maxWidth={column?.maxWidth} minWidth={column?.minWidth}>
      {column.renderCell &&
        column.renderCell({ row: tableData.row, value: column })}
      {!column.renderCell &&
      column.isShowTooltip &&
      tableData.row[column.id]!.toString().length > STRING_LIMIT ? (
        <Tooltip
          title={
            isDateColumnType(column)
              ? getDateTimeString(tableData.row[column.id])
              : tableData.row[column.id]
          }
          position="regular"
        >
          <StyledSpan
            isHidden={tableData.row[column.id] === 'hidden'}
            color={column.color}
          >
            {isDateColumnType(column)
              ? getDateString(tableData.row[column.id])
              : addDotsToString(tableData.row[column.id], STRING_LIMIT)}
          </StyledSpan>
        </Tooltip>
      ) : (
        <>{getRow(column.id)}</>
      )}
      {tableData.row.companyContactDetails !== null && (
        <CompanyDetailsModal
          openModal={openCompanyDetailsModal}
          setOpenModal={setOpenCompanyDetailsModal}
          companyId={tableData.row.companyContactDetails}
        />
      )}
    </StyledTableHeader>
  );
};
