import { createSlice } from '@reduxjs/toolkit';
import { ClientListCandidateHistory } from '../../../store/types/Store.clientList.types';
import { clientListCandidateHistoryThunks } from './ClientListCandidateHistory.thunk';

const { getCandidateHistoryThunk } = clientListCandidateHistoryThunks;

const initialState: ClientListCandidateHistory[] = [];

const clientListCandidateHistorySlice = createSlice({
  name: 'clientListCandidateHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCandidateHistoryThunk.fulfilled, (state, action) => {
      state.length = 0;
      action.payload.forEach((data: ClientListCandidateHistory) => {
        state.push({
          archived: data.archived,
          client_list: data.client_list,
          confirmed_by_client: data.confirmed_by_client,
          created_at: data.created_at,
          notes: data.notes,
          rate_of_pay_currency: data.rate_of_pay_currency,
          rate_of_pay_to: data.rate_of_pay_to,
          status: data.status,
        });
      });
    });
  },
});

export const actions = { ...clientListCandidateHistorySlice.actions };
export const { reducer } = clientListCandidateHistorySlice;
