import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { employeeThunks } from '../../../../containers/Employee/Employees.thunk';
import { EmployeeForm } from './EmployeeForm/EmployeeForm';
import { CreateModalProps } from './types';
import { EmployeeWritableDTO } from '../../../../store/types/Employees.types';
import { EmployeeFormSubmitResult } from './EmployeeForm/EmployeeForm.types';
import { RootState } from '../../../../store/store';
import { Technology } from '../../../../store/types/Store.leads.types';

export const AddEmployeeModalContent = ({ onClose }: CreateModalProps) => {
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.candidates',
  });
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state: RootState) => state.user?.user?.username,
  );
  const suggestedSources = useSelector(
    (state: RootState) => state.employees.suggestedSources,
  );
  const allSupervisors: string[] = useSelector((state: RootState) =>
    state.user.HRUsers.map((it) => it.username),
  );
  const technologyTags: Technology[] = useSelector(
    (state: RootState) => state.leadsRelated.tags.main_technologies,
  );

  const handleFormSubmit = (employeeData: EmployeeFormSubmitResult) => {
    if (employeeData.technologies) {
      const techIds = technologyTags.filter(
        (it) =>
          employeeData.technologies &&
          employeeData.technologies.includes(it.name),
      );
      delete employeeData.technologies;
      employeeData.technology_tags = techIds.map((it) => it.id);
    }

    dispatch(
      employeeThunks.createEmployeeThunk({
        employeeData: employeeData as unknown as EmployeeWritableDTO,
      }) as unknown as AnyAction,
    );

    onClose();
  };

  return (
    <EmployeeForm
      employeeData={{ supervisor: currentUser }}
      formTitle={t('add_new_candidate')}
      onFormSubmit={handleFormSubmit}
      submitButtonName={t('save', { ns: 'common', keyPrefix: 'buttons' })}
      technologyNames={technologyTags.map((it) => it.name)}
      supervisorList={allSupervisors}
      suggestedSources={suggestedSources}
    />
  );
};
