import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ButtonsWrapper } from './ActionColumn.styles';
import { EditButton } from '../../../../../../components/common/IconButtons/EditButton';
import { DeleteButton } from '../../../../../../components/common/IconButtons/DeleteButton';
import { PublishButton } from '../../../../../../components/common/IconButtons/PublishButton';
import { ActivateButton } from '../../../../../../components/common/IconButtons/ActivateButton';
import { DeactivateButton } from '../../../../../../components/common/IconButtons/DeactivateButton';
import { DownloadButton } from '../../../../../../components/common/IconButtons/DownloadButton';
export const ActionColumn = ({
  visibility,
  archiveStatus,
  onArchiveHandler,
  onEditHandler,
  onPublishHandler,
  onDeleteHandler,
  onDownloadHandler,
}) => {
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.candidates',
  });
  const userState = useSelector((state) => state.user);
  return (
    <ButtonsWrapper>
      <PublishButton
        action={onPublishHandler}
        title={t(visibility.toLowerCase())}
        visibilityStatus={visibility}
      />
      {onDownloadHandler && (
        <DownloadButton action={onDownloadHandler} title={t('download_cv')} />
      )}
      {onEditHandler && <EditButton action={onEditHandler} />}
      {userState.user.is_company_administrator &&
        (archiveStatus === 'ARCHIVED' ? (
          <ActivateButton action={() => onArchiveHandler('PUBLISHED')} />
        ) : (
          <DeactivateButton action={() => onArchiveHandler('ARCHIVED')} />
        ))}

      {userState.user.is_company_administrator && onDeleteHandler && (
        <DeleteButton action={onDeleteHandler} />
      )}
    </ButtonsWrapper>
  );
};
