/* eslint-disable radar/no-duplicate-string */
export const NEW_EDUCATION_DATA_TEMPLATE = {
  pk: null,
  timePeriod: '',
  university: 'Lorem ipsum dolor sit amet.',
  universityTitle: 'The University:',
  fieldOfStudy: 'Lorem ipsum dolor sit amet.',
  fieldOfStudyTitle: 'Field of Study:',
  comment:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium iste in illum totam incidunt facere quas, suscipit officia nostrum accusamus dolor at ipsum laborum possimus sint laudantium repellat consectetur eius!',
  commentTitle: 'Comment:',
  degree: 'Lorem ipsum dolor sit amet.',
  degreeTitle: 'Degree:',
};

export const NEW_SIDEPROJECTS_DATA_TEMPLATE = {
  pk: null,
  timePeriod: '',
  projectName: 'Lorem ipsum dolor sit amet.',
  projectDescription: 'Lorem ipsum dolor sit amet.',
  technologies:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium iste in illum totam incidunt facere quas, suscipit officia nostrum accusamus dolor at ipsum laborum possimus sint laudantium repellat consectetur eius!',
};

export const NEW_EXPERIENCE_DATA_TEMPLATE = {
  pk: null,
  timePeriod: '',
  company: 'Lorem ipsum dolor sit amet.',
  position: 'Lorem ipsum dolor sit amet.',
  responsibilities:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium iste in illum totam incidunt facere quas, suscipit officia nostrum accusamus dolor at ipsum laborum possimus sint laudantium repellat consectetur eius!',
};

export const NEW_CERTIFICATES_DATA_TEMPLATE = {
  pk: null,
  timePeriod: '',
  name: 'Lorem ipsum dolor sit amet.',
  nameTitle: 'Name:',
  provider: 'Lorem ipsum dolor sit amet.',
  provioderTitle: 'Provider:',
  comment:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium iste in illum totam incidunt facere quas, suscipit officia nostrum accusamus dolor at ipsum laborum possimus sint laudantium repellat consectetur eius!',
  commentTitle: 'Comment:',
};
