import { LeadProfileStatusType } from '../../../../store/types/Store.leads.types';

type ColorModel = {
  label: string;
  bg: string;
};

export const chipColors: Record<ChipStatusesType, ColorModel> = {
  active: {
    label: '#569E35',
    bg: '#B1DD9D',
  },
  lost: {
    label: '#D2A141',
    // eslint-disable-next-line radar/no-duplicate-string
    bg: 'rgba(248, 185, 64, 0.4);',
  },
  won: {
    label: '#53A538',
    bg: '#D1F0C3',
  },
  notActive: {
    label: '#F84040',
    bg: '#FED9D9',
  },
  archived: {
    label: '#F84040',
    bg: '#FED9D9',
  },
  availability: {
    label: '#9FA3B3',
    bg: '#F4F5F7',
  },
  accepted: {
    label: '#53A538',
    bg: '#D1F0C3',
  },
  rejected: {
    label: '#F84040',
    bg: '#FED9D9',
  },
  proposed: {
    label: '#D2A141',
    bg: 'rgba(248, 185, 64, 0.4);',
  },
  sent: {
    label: '#53A538',
    bg: '#D1F0C3',
  },
  awaiting: {
    label: '#F84040',
    bg: '#FED9D9',
  },
  'new candidate': {
    label: '#D2A141',
    bg: 'rgba(248, 185, 64, 0.4);',
  },
  unknown: {
    label: '#F84040',
    bg: '#FED9D9',
  },
  MATCHED: {
    label: '#1F8597',
    bg: 'rgba(111, 204, 234, 0.5)',
  },
  SENT: {
    label: '#C9A097',
    bg: '#FCF1E1',
  },
  'IN THE PROCESS': {
    label: '#D2A141',
    bg: 'rgba(248, 185, 64, 0.4);',
  },
  REJECTED: {
    label: '#F84040',
    bg: '#FED9D9',
  },
  LOST: {
    label: '#F84040',
    bg: '#FED9D9',
  },
  'ACCEPTED IN ANOTHER PROCESS': {
    label: '#77AF99',
    bg: '#D0EEE3',
  },
  ACCEPTED: {
    label: '#53A538',
    bg: '#D1F0C3',
  },
};

export type ChipStatusesType =
  | 'active'
  | 'lost'
  | 'won'
  | 'notActive'
  | 'availability'
  | 'accepted'
  | 'rejected'
  | 'proposed'
  | 'awaiting'
  | 'new candidate'
  | 'sent'
  | 'archived'
  | 'unknown'
  | 'MATCHED'
  | 'SENT'
  | 'IN THE PROCESS'
  | 'REJECTED'
  | 'LOST'
  | 'ACCEPTED IN ANOTHER PROCESS'
  | 'ACCEPTED';

export type StatusOptionsType = {
  label: 'Won' | 'Lost' | 'Active' | 'Not Active' | 'Accepted' | 'Rejected';
  status: LeadProfileStatusType;
  color: string;
};
