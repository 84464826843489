import { HomeColumn } from '../../../components/common/CollapseTable/CollapseTable.types';
import { COLORS } from '../../../theme/colors';

export const columns: HomeColumn[] = [
  {
    id: 'lead',
    numeric: false,
    disablePadding: true,
    label: 'Lead',
    sortable: true,
    align: 'left',
    isShowTooltip: true,
    type: 'string',
    minWidth: '190px',
    maxWidth: '190px',
    color: COLORS.typography.disabled,
    fontWeight: '500',
    flexBasisMobView: '74%',
    order: 1,
  },
  {
    id: 'profile',
    numeric: false,
    disablePadding: true,
    label: 'Profile',
    sortable: true,
    align: 'left',
    isShowTooltip: true,
    type: 'string',
    minWidth: '190px',
    maxWidth: '190px',
    color: '#000000',
    flexBasisMobView: '80%',
    order: 3,
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: true,
    label: 'Date',
    sortable: true,
    align: 'left',
    isShowTooltip: true,
    type: 'date',
    minWidth: '90px',
    padding: '6px 6px 6px 16px',
    flexBasisMobView: '26%',
    order: 4,
  },
  {
    id: 'salesOwner',
    numeric: false,
    disablePadding: true,
    label: 'Lead owner',
    sortable: false,
    align: 'left',
    isShowTooltip: false,
    type: 'string',
    minWidth: '170px',
    maxWidth: '170px',
    flexBasisMobView: '64%',
    order: 5,
  },
  {
    id: 'priority',
    numeric: false,
    disablePadding: true,
    label: 'Priority',
    sortable: true,
    align: 'left',
    isShowTooltip: false,
    minWidth: '90px',
    padding: '6px 16px 6px 0',
    flexBasisMobView: '30%',
    order: 6,
  },
  {
    id: 'candidatesNum',
    numeric: true,
    disablePadding: true,
    label: 'Candidates',
    sortable: true,
    align: 'left',
    isShowTooltip: false,
    type: 'number',
    color: '#000000',
    minWidth: '40px',
    maxWidth: '40px',
    padding: '6px 0 6px 16px',
    flexBasisMobView: '17%',
    order: 7,
  },
  {
    id: 'hrOwner',
    numeric: false,
    disablePadding: true,
    label: 'HR owner',
    sortable: true,
    align: 'left',
    isShowTooltip: false,
    flexBasisMobView: '51%',
    order: 8,
  },
];

export const ADDITIONAL_COLUMNS = 2;

export const getHomeColumnsAmount = () => columns.length + ADDITIONAL_COLUMNS;
