import { createSlice } from '@reduxjs/toolkit';
import { NotesSchema } from '../../../store/types/Store.partner.types';
import { clientListMessageThunks } from './ClientListMessage.thunk';

const { getClientListMessagesThunk } = clientListMessageThunks;

const initialState: NotesSchema[] = [];

const clientListMessageSlice = createSlice({
  name: 'clientListMessage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClientListMessagesThunk.fulfilled, (state, action) => {
      state.length = 0;
      action.payload.forEach((data: NotesSchema) => {
        state.push({
          note: data.note,
          created_at: data.created_at,
        });
      });
    });
  },
});

export const actions = { ...clientListMessageSlice.actions };
export const { reducer } = clientListMessageSlice;
