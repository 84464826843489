import { useGridApiContext } from '@mui/x-data-grid';
import { StyledPagination } from './Table.styles';

export const CustomPagination = () => {
  const apiRef = useGridApiContext();

  return (
    <StyledPagination
      color="primary"
      boundaryCount={1}
      count={apiRef.current.state.pagination.pageCount}
      page={apiRef.current.state.pagination.page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
};
