import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_INDUSTRY_TAGS } from '../../../../config/urls';
import { getAuthHeader, handleResponse } from '../../../../utils/api';

const getIndustryTagsThunk = createAsyncThunk(
  'leads/getIndustryTags',
  async (_, { getState, dispatch }) => {
    const request = fetch(`${API_INDUSTRY_TAGS}`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);

export const industryTagsThunks = {
  getIndustryTagsThunk,
};
