import { Pagination, Table, TableCell } from '@mui/material';
import { Box } from '@mui/system';
import styled from 'styled-components';

export const StyledBaseTableCell = styled(TableCell)`
  background: white;
  color: #101840;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  height: 61px;
  line-height: 16px;
  padding-left: 26px;
`;

export const StyledBaseTableHeaderCell = styled(TableCell)`
  background: #f4f5f7;
  color: #9fa3b3;
  cursor: pointer;
  font-size: 11px;
  height: 40px;
  padding: 0 0 0 26px;
`;

export const StyledBaseTableContainer = styled(Box)`
  padding-top: 20px;
`;

export const StyledBaseTable = styled(Table)`
  background-color: white;
  border: none;
  flex-direction: column;
  height: 100%;
  min-height: 300px;
  width: 100%;
  .muitablepagination-displayedrows {
    color: #101840;
    font-size: 14px;
    margin-bottom: 0px;
  }
  .muitablepagination-selectlabel {
    color: #101840;
    display: block;
    font-size: 14px;
    margin-bottom: 0px;
    margin-right: 0;
  }
  .muitablepagination-select {
    color: #101840;
    font-size: 14px;
    margin: 2px 6px 0px 6px;
    padding: 0;
  }
  .muiiconbutton-root {
    font-size: 13px;
  }
`;

export const StyledPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    font-size: 14px;
  }
  .MuiPaginationItem-root.Mui-selected {
    background: rgba(60, 194, 217, 0.3);
    color: #000000;
  }
  float: right;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-top: 20px;
`;
