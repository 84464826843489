import styled from 'styled-components';

export const StyledModalWrapper = styled.div`
  align-items: center;
  background: white;
  border-radius: 8px;
  margin: 0px;
  max-height: 100vh;
  max-width: 492px;
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0a0a0;
    border-radius: 25px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 0px 25px 25px 0px;
  }
`;
