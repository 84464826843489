import styled from 'styled-components';

export const StyledFormTitle = styled.h2`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 28px;
  font-weight: 400;
  line-height: 41.18px;
  margin: 0px 72px;
  min-width: 320px;

  @media (max-width: 686px) {
    margin: 0px 30px;
  }
`;

export const StyledDescription = styled.p`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  margin: 20px 72px 0 72px;

  & p {
    margin: 0;
  }

  & strong {
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 13px;
    font-weight: 600;
  }

  & em {
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 13px;
  }

  & u {
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 13px;
  }

  & li {
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 13px;
  }

  & ul {
    padding-left: 5px;
  }

  & ol {
    padding-left: 5px;
  }

  @media (max-width: 686px) {
    margin: 20px 30px 0 30px;
  }
`;
