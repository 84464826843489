import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Typography } from '../../common/Typography/Typography.component';
import { StyledButtonsContainer } from '../../common/BenchlyDialog/BenchlyDialog.styles';
import { BenchlyDialog } from '../../common/BenchlyDialog/BenchlyDialog.component';
import { buttonsColors } from '../../common/Button/Button.config';
import { InputButtonWrapper } from '../../common/Button/Button.styles';
import { Input } from '../../common/Input/Input';
import { LoginFormProps } from './LoginForm.types';
import {
  StyledForm,
  StyledForgotPasswordSection,
  StyledRegistrationSection,
  StyledLink,
} from './LoginForm.styles';
import { COLORS } from '../../../theme/colors';
import { FormCheckbox } from '../../common/Checkbox/FormCheckbox/FormCheckbox.component';
import { AppPaths } from '../../../config/AppPaths';

const LOCAL_STORAGE_EMAIL = 'benchly-email';

export const LoginForm = ({ handleSubmit }: LoginFormProps) => {
  const { t } = useTranslation(['user'], { keyPrefix: 'login' });
  const [isMarkedRememberMe, setIsMarkedRememberMe] = useState(true);

  const toggleIsMarkedRememberMe = () =>
    setIsMarkedRememberMe(!isMarkedRememberMe);

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    const email = e.target[0].value;
    const password = e.target[1].value;
    if (isMarkedRememberMe) {
      localStorage.setItem(LOCAL_STORAGE_EMAIL, email);
    } else {
      localStorage.setItem(LOCAL_STORAGE_EMAIL, '');
    }
    handleSubmit({ email, password });
  };

  return (
    <BenchlyDialog dialogTitle={t('login')} dialogSubtitle={t('welcomeBack')}>
      <StyledForm onSubmit={handleFormSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
          <Input
            label={t('email')}
            name="email"
            type="email"
            defaultValue={localStorage.getItem(LOCAL_STORAGE_EMAIL) || ''}
            required
            autocomplete
          />
          <Input
            label={t('password')}
            name="password"
            type="password"
            required
            autocomplete
          />
        </Box>
        <StyledForgotPasswordSection>
          <FormCheckbox
            options={[
              {
                value: t('rememberMe'),
                label: t('rememberMe'),
                checked: isMarkedRememberMe,
              },
            ]}
            onSelect={toggleIsMarkedRememberMe}
          />
          <StyledLink weight="medium" href={AppPaths.passwordResetRequest}>
            {t('forgotPassword')}
          </StyledLink>
        </StyledForgotPasswordSection>
        <StyledButtonsContainer>
          <InputButtonWrapper
            color={buttonsColors.default}
            value={t('login')}
          />
        </StyledButtonsContainer>
      </StyledForm>
      <StyledRegistrationSection>
        <Typography variant="body" color={COLORS.typography.secondary}>
          {t('haveNoAccount')}?
        </Typography>
        <StyledLink weight="semibold" href={AppPaths.registerCompany}>
          {t('register')}
        </StyledLink>
      </StyledRegistrationSection>
    </BenchlyDialog>
  );
};
