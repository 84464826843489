import { AnyAction } from '@reduxjs/toolkit';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { StyledContainer } from '../../../components/CV/PdfDownloader/PdfDownloader.styles';
import { sanitizeCVEditor } from '../../../Helpers';
// eslint-disable-next-line import/no-cycle
import CV from '../CV.component';
import { cvThunks } from '../CV.thunk';
import { PdfDownloaderProps } from './PdfDownloader.types';

export const PdfDownloader = memo(
  ({
    injectData = null,
    fileName,
    cvId,
    setDownloadFinished,
  }: PdfDownloaderProps) => {
    const dispatch = useDispatch();

    const handleDownload = async (element: string) => {
      if (element) {
        await dispatch(
          cvThunks.downloadPdfThunk({
            filename: fileName,
            html: sanitizeCVEditor(element),
          }) as unknown as AnyAction,
        );
        setDownloadFinished && setDownloadFinished(true);
      }
    };

    return (
      <StyledContainer>
        <CV
          onPagesFinished={handleDownload}
          cvId={cvId}
          injectData={injectData}
          editable={false}
        />
      </StyledContainer>
    );
  },
);
