import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AnyAction } from '@reduxjs/toolkit';
import { filterByTechnology, getDateString } from '../../../../../Helpers';
import { BaseTable } from '../../../../../components/common/BaseTable/BaseTable.component';
import { CandidateChip } from '../../../../../components/common/Chips/CandidateChip';
import { PreviewButton } from '../../../../../components/common/IconButtons/PreviewButton';
import { CVModal } from '../../../../../components/common/Modals/CVModal/CVModal.component';
import { EmployeesModalFactory } from '../../../../../components/common/Modals/Employees/EmployeesModal';
import { useEmployeeModal } from '../../../../../components/common/Modals/Employees/EmployeesModal/useEmployeeModal';
import { Tooltip } from '../../../../../components/common/Tooltip/Tooltip.component';
import { AppPaths } from '../../../../../config/AppPaths';
import { truncateString } from '../../../../../utils/truncate';
import { useEffectAsync } from '../../../../../utils/useEffectAsync';
import { employeeThunks } from '../../../../Employee/Employees.thunk';
import {
  StyledAvailability,
  StyledDate,
  StyledEmployeeTable,
  StyledProfile,
  StyledCompany,
  FlexWrapper,
  TechnologiesContainer,
  ButtonInnerInfo,
} from './EmployeesTable.styles';
import { Header } from './Header';
import { SeniorityChip } from '../../../../../components/common/Chips/SeniorityChip';
import { COLORS } from '../../../../../theme/colors';
import { Button } from '../../../../../components/common/Button/Button.component';
import { ReactComponent as BookmarkIcon } from '../../../../../assets/icons/bookmark.svg';
import { getPayRate, getYearDiff } from '../../../../../utils/common';
import { Technology } from '../../../../../store/types/Store.leads.types';
import {
  Cv,
  EmployeeTableDataInterface,
} from '../../../../../store/types/Store.candidates.types';
import { AppStore } from '../../../../../store/types/Store.types';
import { BaseTableColumnParam } from '../../../../../components/common/BaseTable/BaseTable.types';

export const EmployeesTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.candidates',
  });
  const [displayedCV, setDisplayedCV] = useState<string>();
  const [openCVModal, setOpenCVModal] = useState(false);
  const employees = useSelector<AppStore, EmployeeTableDataInterface[]>(
    (state: any) => state.employees.watched,
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const allMainTechnologies = useSelector<AppStore, Technology[]>(
    (state: any) => state.leadsRelated.tags.main_technologies,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFromUrl = searchParams.get('page') || 0;
  const { setEmployeeId, setDisplayedModal, modalData }: any = useEmployeeModal(
    ['deleteFromWatched', 'none'],
  );

  const handleOpenCvModal = (cvId: string) => {
    setDisplayedCV(cvId);
    setOpenCVModal(true);
  };

  const handleCloseCvModal = () => {
    setOpenCVModal(false);
  };

  const handleObserveButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    employeeId: string,
  ) => {
    event.stopPropagation();
    setDisplayedModal('deleteFromWatched');
    setEmployeeId(employeeId);
  };

  const getTechnologiesToShow = (technologyTags: number[]) =>
    technologyTags &&
    !!technologyTags.length &&
    allMainTechnologies.filter((tech: Technology) =>
      technologyTags.includes(tech.id),
    );

  const columns: BaseTableColumnParam[] = [
    {
      field: 'profile',
      headerName: t('position'),
      align: 'left',
      customStyles: { minWidth: 200 },
      renderCell: ({ value }: any) => (
        <StyledProfile>{value ?? 'no data'}</StyledProfile>
      ),
    },
    {
      field: 'seniority',
      headerName: 'Seniority',
      sortable: false,
      customStyles: { flex: 0.6, minWidth: 40 },
      align: 'left',
      renderCell: ({ value }: any) =>
        value.seniority && (
          <SeniorityChip
            label={value.seniority}
            experience={
              value.careerStartDate
                ? getYearDiff(new Date(value.careerStartDate), new Date())
                : false
            }
          />
        ),
    },
    {
      field: 'technologies',
      headerName: t('technologies'),
      sortable: false,
      customStyles: { flex: 0.9, minWidth: 200 },
      align: 'left',
      renderCell: ({ value }: any) =>
        getTechnologiesToShow(value) ? (
          <TechnologiesContainer>
            {(getTechnologiesToShow(value) as Technology[]).map(
              (technology) => (
                <CandidateChip key={technology.id} label={technology.name} />
              ),
            )}
          </TechnologiesContainer>
        ) : (
          <div>-</div>
        ),
    },
    {
      field: 'availability',
      headerName: t('availability'),
      sortable: false,
      align: 'left',
      customStyles: { minWidth: '40px' },
      renderCell: ({ row: { availability } }: any) =>
        availability && availability.length > 30 ? (
          <Tooltip title={availability} position="close">
            <StyledAvailability>
              <CandidateChip label={truncateString(availability, 20)} />
            </StyledAvailability>
          </Tooltip>
        ) : (
          <StyledAvailability>
            <CandidateChip label={availability || 'no data'} />
          </StyledAvailability>
        ),
    },
    {
      field: 'payRate',
      headerName: t('rate'),
      customStyles: { flex: 0.4, minWidth: 150 },
      align: 'left',
      sortable: false,
    },
    {
      field: 'company',
      headerName: t('company'),
      customStyles: { flex: 0.6, minWidth: 40 },
      align: 'left',
      renderCell: ({ value }: any) => <StyledCompany>{value}</StyledCompany>,
    },
    {
      field: 'nOfCVs',
      headerName: 'CV/s',
      customStyles: { flex: 0.2 },
      sortable: true,
      align: 'left',
      renderCell: ({ row }: any) => {
        return (
          <FlexWrapper>
            <Typography sx={{ fontSize: 13, marginRight: '5px' }}>
              {row.nOfCVs}
            </Typography>
            <PreviewButton
              action={(event) => {
                event.stopPropagation();
                handleOpenCvModal(row.cv.find((cv: Cv) => cv.is_shared).id);
              }}
            />
          </FlexWrapper>
        );
      },
    },
    {
      field: 'lastUpdate',
      headerName: t('update'),
      customStyles: { flex: 0.3 },
      align: 'left',
      renderCell: ({ value }: any) => {
        return <StyledDate>{value}</StyledDate>;
      },
      valueGetter: ({ value }: any) => {
        return getDateString(value);
      },
    },
    {
      field: 'action',
      headerName: '',
      customStyles: { flex: 0.5 },
      sortable: false,
      align: 'left',
      renderCell: ({ value: { id } }: any) => {
        return (
          <Button
            color={COLORS.accent.secondary}
            action={(event) => handleObserveButtonClick(event, id)}
          >
            <ButtonInnerInfo>
              <BookmarkIcon
                fill={COLORS.accent.primary}
                stroke={COLORS.accent.primary}
              />
              <Typography
                sx={{ fontSize: 12, fontWeight: 500, fontFamily: 'Poppins' }}
                color={COLORS.accent.primary}
              >
                {t('watched')}
              </Typography>
            </ButtonInnerInfo>
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    if (employees) {
      setRows(
        filterCandidates(employees as any).map((p: any, i: number) =>
          createRow(p, i),
        ),
      );
    }
  }, [searchParams]);

  useEffect(() => {
    setRows(
      filterCandidates(employees).map((p: any, i: number) => createRow(p, i)),
    );

    loading && setLoading(false);
  }, [employees]);

  useEffectAsync(async () => {
    await Promise.all([
      dispatch(
        employeeThunks.getWatchedEmployeesThunk() as unknown as AnyAction,
      ),
    ]);
  }, []);

  const createRow = (employeeData: EmployeeTableDataInterface, idx: number) => {
    const fullName = `${employeeData.first_name} ${employeeData.last_name}`;
    const {
      rate_of_pay_from: rateOfPayFrom,
      rate_of_pay_to: rateOfPayTo,
      rate_of_pay_currency: currency,
    } = employeeData;
    const payRate =
      (!!rateOfPayFrom || !!rateOfPayTo) &&
      getPayRate(rateOfPayFrom, rateOfPayTo, currency);

    return {
      id: idx,
      isSelected: employeeData,
      employeeId: employeeData.readable_id ?? 'no id',
      profile: employeeData.profile,
      availability: employeeData.availability,
      company: employeeData.company ? employeeData.company.name : 'no data',
      seniority: {
        seniority: employeeData.seniority,
        careerStartDate: employeeData.career_start_date,
      },
      technologies: employeeData.technology_tags,
      cv: employeeData.cvs || [],
      payRate,
      nOfCVs: employeeData.cvs.length,
      action: { id: employeeData.id, fullName, history: navigate },
      lastUpdate: employeeData.updated_at,
    };
  };

  const filterCandidates = (candidates: any) => {
    const filteredValueTechnologies = searchParams.getAll('tech') || [];
    const filteredValueName = searchParams.get('search') || '';
    const filteredSupervisorValue = searchParams.get('supervisor') || '';

    return candidates.filter((candidate: EmployeeTableDataInterface) => {
      const profile = candidate.profile || 'no profile';
      const seniority = candidate.seniority || 'no seniority';
      const company =
        (candidate.company && candidate.company.name) || 'no company';
      const supervisor = candidate.supervisor || 'no supervisor';

      const resultTech = filterByTechnology(
        candidate.technologies,
        filteredValueTechnologies,
      );
      if (!resultTech) {
        return false;
      }

      const resultMultiSearch =
        profile.toLowerCase().includes(filteredValueName.toLowerCase()) ||
        company.toLowerCase().includes(filteredValueName.toLowerCase()) ||
        seniority.toLowerCase().includes(filteredValueName.toLowerCase());
      if (!resultMultiSearch) {
        return false;
      }

      return (
        !filteredSupervisorValue ||
        supervisor.toLowerCase() === filteredSupervisorValue.toLowerCase()
      );
    });
  };

  return (
    <>
      <Header
        numberOfWatched={employees.length}
        filteredValue={searchParams.get('search') || ''}
        setFilteredValue={(val) => {
          searchParams.set('search', val as string);
          searchParams.delete('page');
          setSearchParams(searchParams);
        }}
      />
      <StyledEmployeeTable>
        <EmployeesModalFactory {...modalData} />
        <BaseTable
          columns={columns}
          onRowClick={(row) => {
            navigate(generatePath(AppPaths.employeeId, { id: row.action.id }));
          }}
          rows={rows}
          loading={loading}
          paginationDefaultPageLen={15}
          pageNumber={pageFromUrl ? Math.max(+pageFromUrl - 1, 0) : 0}
          onPageNumberChange={(pageNum) => {
            const newPageNumber = (pageNum + 1).toString();
            if (searchParams.get('page') !== newPageNumber) {
              searchParams.set('page', newPageNumber);
              setSearchParams(searchParams);
            }
          }}
          pagination
        />
      </StyledEmployeeTable>
      <CVModal
        openModal={openCVModal}
        closeModal={handleCloseCvModal}
        cvId={displayedCV}
      />
    </>
  );
};
