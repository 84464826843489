import { ExperienceHeaderContainer } from './Sections/Experience/ExperienceHeader/ExperienceHeaderContainer.component';
import { ExperienceEntryContainer } from './Sections/Experience/ExperienceEntryContainer/ExperienceEntryContainer.component';
import { TechnologiesHeaderContainer } from './Sections/Technologies/TechnologiesHeaderContainer/TechnologiesHeaderContainer.component';
import { TechnologiesEntryContainer } from './Sections/Technologies/TechnologiesEntryContainer/TechnologiesEntryContainer.component';
import { TechnologyHeaderContainer } from './Sections/Technologies/TechnologyHeaderContainer/TechnologyHeaderContainer.component';
import { TechnologyEntryContainer } from './Sections/Technologies/TechnologyEntryContainer/TechnologyEntryContainer.component';
import { LanguagesHeaderContainer } from './Sections/Languages/LanguagesHeaderContainer/LanguagesHeaderContainer.component';
import { LanguagesEntryContainer } from './Sections/Languages/LanguagesEntryContainer/LanguagesEntryContainer.component';
import { EducationEntryContainer } from './Sections/Education/EducationEntryContainer/EducationEntryContainer.component';
import { EducationHeaderContainer } from './Sections/Education/EducationHeaderContainer/EducationHeaderContainer.component';
import { PositionHeaderContainer } from './Sections/Position/PositionHeaderContainer/PositionHeaderContainer.component';
import { PositionEntryContainer } from './Sections/Position/PositionEntryContainer/PositionEntryContainer.component';
import { AdditionalInfoHeaderContainer } from './Sections/AdditionalInfo/AdditionalInfoHeaderContainer/AdditionalInfoHeaderContainer.component';
import { AdditionalInfoEntryContainer } from './Sections/AdditionalInfo/AdditionalInfoEntryContainer/AdditionalInfoEntryContainer.component';
import { SideProjectsHeaderContainer } from './Sections/SideProjects/SideProjectsHeaderContainer/SideProjectsHeaderContainer.component';
import { SideProjectsEntryContainer } from './Sections/SideProjects/SideProjectsEntryContainer/SideProjectsEntryContainer.component';
import { CustomSectionHeaderContainer } from './Sections/CustomSection/CustomSectionHeaderContainer/CustomSectionHeaderContainer.component';
import { CustomSectionEntryContainer } from './Sections/CustomSection/CustomSectionEntryContainer/CustomSectionEntryContainer.component';
import { CertificatesEntryContainer } from './Sections/Certificates/CertificatesEntryContainer/CertificatesEntryContainer.component';
import { CertificatesHeaderContainer } from './Sections/Certificates/CertificatesHeader/CetificatesHeaderContainer.component';
import { SECTION_TYPE } from './CV.config';

export const sectionTypeToComponent = new Map([
  [
    SECTION_TYPE.EXPERIENCE_HEADER,
    {
      component: ExperienceHeaderContainer,
      dataPropName: 'experience',
      displayName: 'Experience',
    },
  ],
  [
    SECTION_TYPE.EXPERIENCE_ENTRY,
    {
      component: ExperienceEntryContainer,
      dataPropName: 'experience',
    },
  ],
  [
    SECTION_TYPE.TECHNOLOGIES_HEADER,
    {
      component: TechnologiesHeaderContainer,
      dataPropName: 'technologies',
      displayName: 'Technologies',
    },
  ],
  [
    SECTION_TYPE.TECHNOLOGIES_ENTRY,
    {
      component: TechnologiesEntryContainer,
      dataPropName: 'technologies',
    },
  ],
  [
    SECTION_TYPE.TECHNOLOGY_HEADER,
    {
      component: TechnologyHeaderContainer,
      dataPropName: 'technologies',
    },
  ],
  [
    SECTION_TYPE.TECHNOLOGY_ENTRY,
    {
      component: TechnologyEntryContainer,
      dataPropName: 'technologies',
    },
  ],
  [
    SECTION_TYPE.LANGUAGES_HEADER,
    {
      component: LanguagesHeaderContainer,
      dataPropName: 'languages',
      displayName: 'Languages',
    },
  ],
  [
    SECTION_TYPE.LANGUAGES_ENTRY,
    {
      component: LanguagesEntryContainer,
      dataPropName: 'languages',
    },
  ],
  [
    SECTION_TYPE.EDUCATION_ENTRY,
    {
      component: EducationEntryContainer,
      dataPropName: 'education',
    },
  ],
  [
    SECTION_TYPE.EDUCATION_HEADER,
    {
      component: EducationHeaderContainer,
      dataPropName: 'education',
      displayName: 'Education',
    },
  ],
  [
    SECTION_TYPE.POSITION_HEADER,
    {
      component: PositionHeaderContainer,
      dataPropName: 'position',
      displayName: 'Position',
    },
  ],
  [
    SECTION_TYPE.POSITION_ENTRY,
    {
      component: PositionEntryContainer,
      dataPropName: 'position',
    },
  ],
  [
    SECTION_TYPE.ADDITIONAL_INFO_HEADER,
    {
      component: AdditionalInfoHeaderContainer,
      dataPropName: 'additionalInfo',
      displayName: 'Additional info',
    },
  ],
  [
    SECTION_TYPE.ADDITIONAL_INFO_ENTRY,
    {
      component: AdditionalInfoEntryContainer,
      dataPropName: 'additionalInfo',
    },
  ],
  [
    SECTION_TYPE.SIDE_PROJECTS_HEADER,
    {
      component: SideProjectsHeaderContainer,
      dataPropName: 'sideProjects',
      displayName: 'Side projects',
    },
  ],
  [
    SECTION_TYPE.SIDE_PROJECTS_ENTRY,
    {
      component: SideProjectsEntryContainer,
      dataPropName: 'sideProjects',
    },
  ],
  [
    SECTION_TYPE.CUSTOM_SECTION_HEADER,
    {
      component: CustomSectionHeaderContainer,
      dataPropName: 'customSections',
    },
  ],
  [
    SECTION_TYPE.CUSTOM_SECTION_ENTRY,
    {
      component: CustomSectionEntryContainer,
      dataPropName: 'customSections',
    },
  ],
  [
    SECTION_TYPE.CERTIFICATES_HEADER,
    {
      component: CertificatesHeaderContainer,
      dataPropName: 'certificates',
      displayName: 'Certificates',
    },
  ],
  [
    SECTION_TYPE.CERTIFICATES_ENTRY,
    {
      component: CertificatesEntryContainer,
      dataPropName: 'certificates',
    },
  ],
]);
