import { useTranslation } from 'react-i18next';
import { StatusOptionsType } from './config';

export const useLeadStatusOptionsConfig = () => {
  const { t } = useTranslation(['common'], { keyPrefix: 'vacancyStatuses' });
  const leadStatusOptions: StatusOptionsType[] = [
    {
      label: t('won'),
      status: 'WON',
      color: '#53A538',
    },
    {
      label: t('lost'),
      status: 'LOST',
      color: '#D2A141',
    },
    {
      label: t('active'),
      status: 'ACTIVE',
      color: '#569E35',
    },
    {
      label: t('not_active'),
      status: 'NOT ACTIVE',
      color: '#F84040',
    },
  ];
  return { leadStatusOptions };
};
