import styled from 'styled-components';
import { COLORS } from '../../../../theme/colors';

export const StyledAddColumnButton = styled.div`
  color: ${COLORS.accent.primary};
  float: right;
  margin-top: -40px;
  position: absolute;
  visibility: hidden;
`;

export const StyledDeleteColumnButton = styled.div`
  color: ${COLORS.accent.primary};
  margin-left: 10px;
  margin-right: 10px;
  visibility: hidden;
`;

export const StyledTechnologiesSection = styled.div`
  display: grid;
  font-size: 24px;
  font-weight: 700;
  grid-template-columns: 25% 25% 25% 25%;
  padding: ${(props) => props.paddingTop || '0px'} 0px 0px 0px;
  row-gap: 20px;
  width: 1000px;

  :hover ${StyledAddColumnButton} {
    visibility: visible;
  }
`;

export const StyledAddButton = styled.div`
  color: ${COLORS.accent.primary};
  float: right;
  margin-left: 10px;
  visibility: hidden;
`;

export const StyledTechnologies = styled.div`
  display: inline-block;
  flex: 1 1 0;
  padding-top: 20px;
  vertical-align: top;
  width: 25%;

  > div {
    display: flex;
    flex-direction: column;
    line-height: 36px;
  }

  :hover ${StyledAddButton} {
    visibility: visible;
  }

  :hover ${StyledDeleteColumnButton} {
    visibility: visible;
  }
`;

export const StyledSectionHeader = styled.span`
  font-size: 24px;
  font-weight: 700;
  max-width: 180px;
  min-width: 120px;

  :empty:before {
    content: attr(placeholder);
    font-weight: 700;
    opacity: 0.3;

    @media print {
      opacity: 0;
    }
  }
`;

export const StyledIcon = styled.div`
  cursor: pointer;
`;
