import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_common from './locales/en/common.json';
import en_my_profile from './locales/en/user/my_profile.json';
import pl_my_profile from './locales/pl/user/my_profile.json';
import en_reset_password from './locales/en/user/password_reset.json';
import pl_reset_password from './locales/pl/user/password_reset.json';
import pl_employee_details from './locales/pl/employee/employee_details.json';
import en_employee_details from './locales/en/employee/employee_details.json';
import en_login from './locales/en/user/login.json';
import pl_login from './locales/pl/user/login.json';
import en_company_profile from './locales/en/user/company_profile.json';
import pl_company_profile from './locales/pl/user/company_profile.json';
import en_registration from './locales/en/company/registration.json';
import pl_registration from './locales/pl/company/registration.json';
import pl_invitation from './locales/pl/company/invitation.json';
import en_invitation from './locales/en/company/invitation.json';
import pl_vacancies from './locales/pl/vacancies/vacancies.json';
import en_vacancies from './locales/en/vacancies/vacancies.json';
import en_company_details from './locales/en/company/company_details.json';
import pl_company_details from './locales/pl/company/company_details.json';
import pl_common from './locales/pl/common.json';

const resources = {
  en: {
    common: en_common,
    user: {
      my_profile: en_my_profile,
      reset_password: en_reset_password,
      login: en_login,
      company_profile: en_company_profile,
    },
    employee: {
      employee_details: en_employee_details,
    },
    company: {
      registration: en_registration,
      company_details: en_company_details,
      invitation: en_invitation,
    },
    vacancies: {
      vacancies: en_vacancies,
    },
  },
  pl: {
    common: pl_common,
    user: {
      my_profile: pl_my_profile,
      reset_password: pl_reset_password,
      login: pl_login,
      company_profile: pl_company_profile,
    },
    employee: {
      employee_details: pl_employee_details,
    },
    company: {
      registration: pl_registration,
      company_details: pl_company_details,
      invitation: pl_invitation,
    },
    vacancies: {
      vacancies: pl_vacancies,
    },
  },
};

const getLanguage = () => window.localStorage.i18nextLng || 'en';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLanguage(),
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
