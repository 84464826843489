/* eslint-disable import/no-cycle */
import React, { LazyExoticComponent } from 'react';
import UsersPageRoutes from '../containers/UsersPage/UsersPageRoutes';
import MyProfilePageRoutes from './MyProfilePageRoutes';
import { AppPaths } from './AppPaths';

// Lazy imports of each screen
const Login = React.lazy(
  () => import('../containers/User/Login/Login.component'),
);
const Logout = React.lazy(() => import('../containers/User/Logout.component'));
const Register = React.lazy(
  () => import('../containers/User/Register/Register.component'),
);
const RegisterCompany = React.lazy(
  () =>
    import('../containers/Company/RegisterCompany/RegisterCompany.component'),
);
const RegisterRequestConfirm = React.lazy(
  () =>
    import(
      '../containers/Company/RegisterRequestConfirm/RegisterRequestConfirm.component'
    ),
);
const MyProfile = React.lazy(
  () => import('../containers/User/MyProfile/MyProfile.component'),
);
const Employees = React.lazy(
  () => import('../containers/Employee/Employees.component'),
);
const CV = React.lazy(() => import('../containers/CV/CV.component'));
const Lists = React.lazy(() => import('../containers/Lists/Lists'));
const ClientList = React.lazy(
  () => import('../containers/ClientList/ClientList'),
);
const HrList = React.lazy(
  () => import('../containers/Lists/HrLists/HrList/HrList'),
);
const LeadsTable = React.lazy(
  () => import('../containers/Leads/LeadsTable/LeadsTable'),
);
const LeadDetail = React.lazy(
  () => import('../containers/Leads/LeadDetail/LeadDetail'),
);
const Vacancies = React.lazy(() => import('../containers/Vacancies/Vacancies'));

const UsersPage = React.lazy(
  () => import('../containers/UsersPage/UsersPage.component'),
);
const ClientListCvPreview = React.lazy(
  () => import('../containers/CV/CvPreview/ClientListView/ClientListCvPreview'),
);
const HrCvPreview = React.lazy(
  () => import('../containers/CV/CvPreview/HrCvPreview/HrCvPreview'),
);
const ClientListDetailsView = React.lazy(
  () => import('../containers/ClientList/ClientListHrView/ClientListHrView'),
);
const NotificationPage = React.lazy(
  () => import('../components/common/Notifications/Page/NotificationPage'),
);

const CandidateFormPage = React.lazy(
  () =>
    import(
      '../components/Announcements/AddingAnnouncementForm/AddingAnnouncementForm.component'
    ),
);

const EmployeePage = React.lazy(
  () => import('../containers/Employee/EmployeePage/EmployeePage'),
);

const PasswordResetRequest = React.lazy(
  () =>
    import(
      '../containers/User/ForgotPassword/PasswordResetRequest/PasswordResetRequest.component'
    ),
);

const ResetPasswordLinkSent = React.lazy(
  () =>
    import(
      '../containers/User/ForgotPassword/ResetPasswordLinkSent/ResetPasswordLinkSent.component'
    ),
);

const ChangePassword = React.lazy(
  () =>
    import(
      '../containers/User/ForgotPassword/ChangePassword/ChangePassword.component'
    ),
);

const ResetPasswordConfirmed = React.lazy(
  () =>
    import(
      '../containers/User/ForgotPassword/ResetPasswordConfirmed/ResetPasswordConfirmed.component'
    ),
);

const AssistanceRequest = React.lazy(
  () =>
    import('../containers/User/AssistanceRequest/AssistanceRequest.component'),
);

const AcceptCompanyInvitation = React.lazy(
  () =>
    import(
      '../containers/Company/AcceptCompanyInvitation/AcceptCompanyInvitation.component'
    ),
);

const PrivacyPolicy = React.lazy(
  () => import('../containers/User/PrivacyPolicy/PrivacyPolicy.component'),
);

const TermsAndConditions = React.lazy(
  () =>
    import(
      '../containers/User/TermsAndConditions/TermsAndConditions.component'
    ),
);

export enum AuthorizationTypes {
  Standard, // standard session cookie / token
  PublicToken, // special authorization type for external clients
  None, // no authorization
}

export interface RouteType {
  path: string;
  nestedRoutes?: JSX.Element[];
  component?: LazyExoticComponent<any>;
  authentication: AuthorizationTypes;
}

export const routes: { [key: string]: RouteType } = {
  login: {
    path: AppPaths.login,
    component: Login,
    authentication: AuthorizationTypes.None,
  },
  logout: {
    path: AppPaths.logout,
    component: Logout,
    authentication: AuthorizationTypes.None,
  },
  register: {
    path: AppPaths.register,
    component: Register,
    authentication: AuthorizationTypes.Standard,
  },
  registerCompany: {
    path: AppPaths.registerCompany,
    component: RegisterCompany,
    authentication: AuthorizationTypes.None,
  },
  registerCompanyWithToken: {
    path: AppPaths.registerCompanyWithToken,
    component: RegisterCompany,
    authentication: AuthorizationTypes.None,
  },
  registerRequestConfirm: {
    path: AppPaths.registerRequestConfirm,
    component: RegisterRequestConfirm,
    authentication: AuthorizationTypes.None,
  },
  myProfile: {
    path: AppPaths.myProfile,
    component: MyProfile,
    nestedRoutes: MyProfilePageRoutes,
    authentication: AuthorizationTypes.Standard,
  },
  vacancies: {
    path: AppPaths.vacancies,
    component: Vacancies,
    authentication: AuthorizationTypes.Standard,
  },
  clientListLogin: {
    path: AppPaths.sharedListId,
    component: ClientList,
    authentication: AuthorizationTypes.PublicToken,
  },
  clientListPartner: {
    path: AppPaths.sharedListPartnerId,
    component: ClientList,
    authentication: AuthorizationTypes.Standard,
  },
  employee: {
    path: AppPaths.employeeId,
    component: EmployeePage,
    authentication: AuthorizationTypes.Standard,
  },
  createEmployee: {
    path: AppPaths.createEmployee,
    component: CandidateFormPage,
    authentication: AuthorizationTypes.Standard,
  },
  editEmployee: {
    path: AppPaths.editEmployee,
    component: CandidateFormPage,
    authentication: AuthorizationTypes.Standard,
  },
  createCv: {
    path: AppPaths.employeeCv,
    component: CV,
    authentication: AuthorizationTypes.Standard,
  },
  editCv: {
    path: AppPaths.employeeCvId,
    component: CV,
    authentication: AuthorizationTypes.Standard,
  },
  listsOfCandidatesForSharing: {
    path: AppPaths.listsOfCandidatesForSharing,
    component: Lists,
    authentication: AuthorizationTypes.Standard,
  },
  users: {
    path: AppPaths.users,
    component: UsersPage,
    nestedRoutes: UsersPageRoutes,
    authentication: AuthorizationTypes.Standard,
  },

  listOfCandidatesForSharing: {
    path: AppPaths.sharedEmployeeList,
    component: HrList,
    authentication: AuthorizationTypes.Standard,
  },
  clientListCvPreview: {
    path: AppPaths.clientListCvPreview,
    component: ClientListCvPreview,
    authentication: AuthorizationTypes.None,
  },
  hrCvPreview: {
    path: AppPaths.hrCvPreview,
    component: HrCvPreview,
    authentication: AuthorizationTypes.Standard,
  },
  leadDetail: {
    path: AppPaths.leadDetail,
    component: LeadDetail,
    authentication: AuthorizationTypes.Standard,
  },
  // leads: {
  //   path: AppPaths.leads,
  //   component: LeadsTable,
  //   authentication: AuthorizationTypes.Standard,
  // },
  clientList: {
    path: AppPaths.sharedEmployeeListClientId,
    component: ClientListDetailsView,
    authentication: AuthorizationTypes.Standard,
  },
  main: {
    path: AppPaths.home,
    component: Employees,
    authentication: AuthorizationTypes.Standard,
  },
  notifications: {
    path: AppPaths.notifications,
    component: NotificationPage,
    authentication: AuthorizationTypes.Standard,
  },
  passwordResetRequest: {
    path: AppPaths.passwordResetRequest,
    component: PasswordResetRequest,
    authentication: AuthorizationTypes.None,
  },
  resetPasswordLinkSent: {
    path: AppPaths.resetPasswordLinkSent,
    component: ResetPasswordLinkSent,
    authentication: AuthorizationTypes.None,
  },
  changePassword: {
    path: AppPaths.changePassword,
    component: ChangePassword,
    authentication: AuthorizationTypes.None,
  },
  resetPasswordConfirmed: {
    path: AppPaths.resetPasswordConfirmed,
    component: ResetPasswordConfirmed,
    authentication: AuthorizationTypes.None,
  },
  assistanceRequest: {
    path: AppPaths.assistanceRequest,
    component: AssistanceRequest,
    authentication: AuthorizationTypes.None,
  },
  acceptCompanyInvitation: {
    path: AppPaths.acceptCompanyInvitation,
    component: AcceptCompanyInvitation,
    authentication: AuthorizationTypes.Standard,
  },
  privacyPolicy: {
    path: AppPaths.privacyPolicy,
    component: PrivacyPolicy,
    authentication: AuthorizationTypes.None,
  },
  termsAndConditions: {
    path: AppPaths.termsAndConditions,
    component: TermsAndConditions,
    authentication: AuthorizationTypes.None,
  },
};
