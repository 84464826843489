import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { NoResult } from '../NoResult';
import { CustomPagination } from './Pagination.component';
import { useStyles } from './Table.styles';

const overridenComponents = {
  ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
  ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
  ColumnUnsortedIcon: UnfoldMoreIcon,
  Pagination: CustomPagination,
  NoRowsOverlay: () => <NoResult message="No rows found" />,
};

export const Table = ({
  columns,
  rows,
  density = 'compact',
  ...dataGridProps
}) => {
  const [pageSize, setPageSize] = React.useState(20);
  const cols = columns.map((col) => {
    return {
      headerAlign: 'center',
      ...col,
    };
  });

  const classes = useStyles(dataGridProps);

  return (
    <DataGrid
      autoHeight
      className={classes.root}
      componentsProps={{
        pagination: {
          SelectProps: {
            MenuProps: {
              className: classes.dropdown,
            },
          },
        },
      }}
      headerHeight={density === 'standard' ? 40 : 58}
      disableSelectionOnClick
      rows={rows}
      pageSize={pageSize}
      onPageSizeChange={(value) => setPageSize(value)}
      rowsPerPageOptions={[25, 50, 100]}
      columns={cols}
      disableColumnMenu
      pagination
      density={density}
      components={overridenComponents}
      {...dataGridProps}
    />
  );
};
