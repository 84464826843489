import { Route } from 'react-router-dom';
import { PartnersTable } from '../Partner/PartnersTable/PartnersTable.component';
import { UsersTable } from '../User/UsersTable/UsersTable.component';

const UsersPageRoutes = [
  <Route index element={<UsersTable />} />,
  <Route path="users" element={<UsersTable />} />,
  <Route path="partners" element={<PartnersTable />} />,
];

export default UsersPageRoutes;
