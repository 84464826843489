import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { Box, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AppStore } from '../../../store/types/Store.types';
import {
  CompanyItem,
  CompanySizeInterface,
  CountryInterface,
} from '../../../store/types/Store.company';
import { useEffectAsync } from '../../../utils/useEffectAsync';
import { companyThunks } from '../../../containers/Company/Company.thunk';
import { useCompanyInfoFormHandlers } from './hooks/useCompanyInfoFormHandlers';
import { StyledForm, StyledLogo } from './CompanyInfoForm.styles';
import { Input } from '../../common/Input';
import { InputButtonWrapper } from '../../common/Button/Button.styles';
import { COLORS } from '../../../theme/colors';
import { Option } from '../../common/Selector/Selector.types';
import { Selector } from '../../common/Selector/Selector.component';
import { Dropzone } from '../../common/Dropzone/Dropzone.component';
import { FormLabel } from '../../common/FormLabel';
import { DeleteButton } from '../../common/IconButtons/DeleteButton';

export const CompanyInfoForm = ({
  companyId,
  setIsEditMode,
}: {
  companyId: number;
  setIsEditMode: (value: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['company'], { keyPrefix: 'registration' });

  const companySizeOptions = useSelector<AppStore, CompanySizeInterface[]>(
    (state) => state.company.companySizyOptions,
  );
  const shownCompanySizeOptions: Option[] = companySizeOptions.map(
    (option) => ({
      value: option.id,
      name: `${option.name} ${t('employees')}`,
    }),
  );
  const countriesOptions = useSelector<AppStore, CountryInterface>(
    (state) => state.company.countries,
  );
  const company = useSelector<AppStore, CompanyItem>(
    (state) => state.company.company,
  );

  const {
    companyInfoFormData,
    handleSelectChange,
    handleInputChange,
    handleFileUpload,
    handleFileRemoval,
    logo,
    sendForm,
  } = useCompanyInfoFormHandlers(company, setIsEditMode);

  useEffectAsync(async () => {
    await dispatch(
      companyThunks.getCompanyInfoThunk(companyId) as unknown as AnyAction,
    );
  }, [companyId]);

  useEffectAsync(async () => {
    await Promise.all([
      dispatch(companyThunks.getCompanySizesThunk() as unknown as AnyAction),
      dispatch(companyThunks.getCountriesThunk() as unknown as AnyAction),
    ]);
  }, []);

  const onSubmitClick: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    sendForm();
  };

  return (
    <StyledForm onSubmit={onSubmitClick}>
      <Box display="flex" gap="24px">
        <Box display="flex" flexDirection="column" gap="24px" width="100%">
          <Input
            label={t('companyName')}
            name="company_name"
            required
            value={companyInfoFormData.company_name || ''}
            onChange={handleInputChange}
          />
          <Input
            label={t('emailAddress')}
            name="email"
            value={companyInfoFormData.email || ''}
            onChange={handleInputChange}
          />
          <Input
            label={t('additional_email')}
            name="additional_email"
            value={companyInfoFormData.additional_email || ''}
            onChange={handleInputChange}
          />
          <Input
            label={t('vat_number')}
            name="vat_number"
            disabled
            value={companyInfoFormData.vat_number || ''}
            onChange={handleInputChange}
          />
          <Selector
            label={t('company_country')}
            name="country"
            value={companyInfoFormData.country || null}
            options={Object.entries(countriesOptions).map(([code, name]) => ({
              value: code,
              name,
            }))}
            size="medium"
            action={(event: SelectChangeEvent<Option>) =>
              handleSelectChange('country', event.target.value as Option)
            }
          />
          <Selector
            label={t('company_size')}
            name="company_size"
            options={shownCompanySizeOptions}
            size="medium"
            value={companyInfoFormData.company_size || null}
            action={(event: SelectChangeEvent<Option>) =>
              handleSelectChange('company_size', event.target.value as Option)
            }
          />
        </Box>
        <Box display="flex" flexDirection="column" gap="24px" width="100%">
          <Input
            label={t('address')}
            name="address"
            value={companyInfoFormData.address || ''}
            onChange={handleInputChange}
          />
          <Input
            label={t('phone')}
            name="phone_number"
            value={companyInfoFormData.phone_number || ''}
            onChange={handleInputChange}
          />
          <Input
            label={t('website')}
            name="website"
            value={companyInfoFormData.website || ''}
            onChange={handleInputChange}
          />
          <Input
            label={t('clutch')}
            name="link_to_clutch"
            value={companyInfoFormData.link_to_clutch || ''}
            onChange={handleInputChange}
          />
          <Box display="flex" flexDirection="column" gap="20px">
            <FormLabel label="Logo" />
            {logo ? (
              <Box display="flex" gap="7px" alignItems="center">
                <StyledLogo
                  src={logo}
                  alt={`${companyInfoFormData.company_name}-logo`}
                />
                <DeleteButton title="Delete logo" action={handleFileRemoval} />
              </Box>
            ) : (
              <Dropzone handleFileSelection={handleFileUpload} />
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: '100%', marginTop: '23px' }}>
        <InputButtonWrapper color={COLORS.accent.primary} value={t('save')} />
      </Box>
    </StyledForm>
  );
};
