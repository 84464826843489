import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteModal } from '../../DeleteModal';
import { LeadProfileDeleteModalContentProps } from './LeadProfileDeleteModalContent.types';

export const LeadProfileDeleteModalContent = React.forwardRef<
  HTMLDivElement,
  LeadProfileDeleteModalContentProps
>(({ closeModal, handleDelete }, ref) => {
  const { t } = useTranslation(['vacancies', 'common'], {
    keyPrefix: 'vacancies',
  });
  const handleDeleting = async () => {
    handleDelete && handleDelete();
    closeModal();
  };

  const buttonsConfig = {
    primaryButton: {
      label: t('confirm_delete', { ns: 'common', keyPrefix: 'buttons' }),
      action: handleDeleting,
    },
  };

  const content = <p>{t('confirmation_delete_vacancy')}</p>;

  return (
    <DeleteModal
      dialogTitle=""
      content={content}
      buttonsConfig={buttonsConfig}
      handleClose={closeModal}
      ref={ref}
    />
  );
});
