import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_HOME_PAGE } from '../../../../config/urls';
import { HomeLeadProfile } from '../../../../store/types/Store.home.types';
import { getAuthHeader, handleResponse } from '../../../../utils/api';
import { generateLeadRequestCandidatesUrl } from '../../../../utils/urlGenerators';

const getMyVacanciesDataThunk = createAsyncThunk<HomeLeadProfile>(
  'myVacancies/getMyVacanciesData',
  async (_data, { getState, dispatch }) => {
    const request = fetch(`${API_HOME_PAGE}my-vacancies/?company_filter=1`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const getMyVacanciesCandidatesThunk = createAsyncThunk(
  'myVacancies/getMyVacanciesCandidates',
  async (
    data: { leadId: number; requestId: number },
    { getState, dispatch },
  ) => {
    const URL = generateLeadRequestCandidatesUrl(
      data.leadId,
      data.requestId as unknown as number,
    );
    const request = fetch(`${URL}`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);

export const myVacanciesPageThunks = {
  getMyVacanciesDataThunk,
  getMyVacanciesCandidatesThunk,
};
