import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { styled as materialUIStyled } from '@mui/material/styles';
import styled from 'styled-components';

const FONT_FAMILY = '"Neue Haas Grotesk Display Pro", sans-serif';

export const StyledAutoComplete = materialUIStyled(Autocomplete)({
  background: 'white',
  width: '100%',
  minWidth: '300px',
  border: '1px solid #d7ddea',
  borderRadius: 3,
  flexBasis: 'fit-content',

  '& .MuiAutocomplete-endAdornment': {
    position: 'relative',
    marginLeft: 'auto',
    right: 0,
    paddingTop: 0,
  },
  '& .MuiAutocomplete-input': {
    fontSize: 13,
    fontFamily: 'Neue Haas Grotesk Display Pro',
  },
  '& .MuiInputBase-input-MuiOutlinedInput-input': {
    height: 10,
    margin: 0,
    padding: '0px !important',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    height: 45,
  },
  '& .MuiOutlinedInput-root': {
    padding: '0px 0px 0px 9px !important',
  },
  '& .MuiAutocomplete-tag ': {
    fontSize: '12px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '17px',
    fontFamily: FONT_FAMILY,
    color: '#000000',
    top: '-15px',
    left: '-14px',
    zIndex: 0,
  },
  '& .MuiChip-root': {
    color: 'black',
    background: 'linear-gradient(0deg, #F6F7F8, #F6F7F8), #F9F9F9;',
    height: '18px',
    fontSize: '12px',
    fontFamily: FONT_FAMILY,
    textTransform: 'capitalize',
    padding: '12px 5px',
  },
  '& .MuiChip-label': {
    padding: '2px 7px',
  },
});

export const StyledSpan = styled.span`
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 13px;
`;

export const StyledChip = materialUIStyled(Chip)({
  fontSize: '12px',
  fontFamily: FONT_FAMILY,
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
    marginLeft: '1px',
  },
});

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 13px;
  margin-bottom: 20px;
`;
