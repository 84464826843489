import { AnyAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { leadProfileCandidatesThunks } from '../../../../../../containers/Leads/redux/LeadProfileCandidates.thunk';
import { leadProfilesThunks } from '../../../../../../containers/Leads/redux/LeadProfiles.thunk';
import { vacanciesPageThunks } from '../../../../../../containers/Vacancies/Vacancies.thunk';
import { useAddSuggestedCandidatesProps } from './useAddSuggestedCandidates.types';

export const useAddSuggestedCandidates = ({
  leadProfileId,
  leadId,
  cbFn,
}: useAddSuggestedCandidatesProps) => {
  const dispatch = useDispatch();

  const addSuggestedCandidates = async (candidates: string[]) => {
    if (leadProfileId && leadId) {
      const result = await dispatch(
        leadProfileCandidatesThunks.addSuggestedCandidatesThunk({
          leadId,
          requestId: leadProfileId,
          payload: { employees: candidates },
        }) as unknown as AnyAction,
      );
      if (
        result.type ===
        leadProfileCandidatesThunks.addSuggestedCandidatesThunk.fulfilled.type
      ) {
        cbFn();
        await Promise.all([
          dispatch(
            leadProfilesThunks.getLeadRequestsThunk({
              leadId,
            }) as unknown as AnyAction,
          ),
          dispatch(
            vacanciesPageThunks.getHomePageDataThunk() as unknown as AnyAction,
          ),
          dispatch(
            vacanciesPageThunks.getHomePageStatsThunk() as unknown as AnyAction,
          ),
        ]);
      }
    }
  };

  return { addSuggestedCandidates };
};
