import { useTranslation } from 'react-i18next';
import { AddButton } from '../../../../../../components/common/Buttons/AddButton';
import { Counter } from '../../../../../../components/common/Counter/Counter';
import { SearchField } from '../../../../../../components/common/SearchField/SearchField';
import { HeaderProps } from './Header.types';
import {
  HeadControls,
  MainHeadline,
  FiltersContainer,
  SearchContainer,
} from '../../../MyProfile.styles';
import {
  TableFilter,
  filterRows,
  countTopFilters,
} from '../../../../../../components/common/TableFilter/TableFilter';
import { getTopFilterOptions, searchInColumns } from '../../MyProjects.config';

export const Header = ({
  numberOfProjects,
  filteredValue,
  setFilteredValue,
  setOpenModal,
  leads,
  topFilterValue,
  setTopFilterValue,
}: HeaderProps) => {
  const { t } = useTranslation(['user'], {
    keyPrefix: 'my_profile',
  });
  const topFilterOptions = getTopFilterOptions(t);
  return (
    <>
      <HeadControls>
        <MainHeadline>
          {t('my_projects')}
          <Counter number={numberOfProjects} color="#FFFFFF" />
        </MainHeadline>
      </HeadControls>
      <HeadControls>
        <FiltersContainer>
          <TableFilter
            filterOptions={topFilterOptions}
            filterValue={topFilterValue}
            filterCounts={countTopFilters(
              filterRows(
                leads,
                searchInColumns,
                filteredValue,
                topFilterOptions,
                topFilterValue,
                false,
              ),
              topFilterOptions,
            )}
            changeHandler={setTopFilterValue}
          />
        </FiltersContainer>
        <SearchContainer>
          <SearchField
            initialValue={filteredValue}
            onChange={setFilteredValue}
          />
        </SearchContainer>
        <AddButton marginLeft="1" action={() => setOpenModal(true)}>
          {t('projects.addNewProject')}
        </AddButton>
      </HeadControls>
    </>
  );
};
