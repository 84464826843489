import { useState } from 'react';
import { useSelector } from 'react-redux';
import { isPartner } from '../../../../../Helpers';
import { AppStore } from '../../../../../store/types/Store.types';
import { DeleteButton } from '../../../../common/IconButtons/DeleteButton';
import { DownloadButton } from '../../../../common/IconButtons/DownloadButton';
import { PreviewButton } from '../../../../common/IconButtons/PreviewButton';
import { StyledButtonGroup } from './ButtonGroup.styles';
import { ButtonGroupProps } from './ButtonGroup.types';

export const ButtonGroup = ({
  candidate,
  hanldeOpenModal,
  handleDownloadCv,
  handleDeleteSuggestedCandidate,
  loading,
  downloadFinished,
}: ButtonGroupProps) => {
  const user = useSelector<AppStore>((state) => state?.user?.user);
  const [foundedCv] = useState(
    candidate.employee_data.cvs.find((cv) => cv.is_shared),
  );

  return (
    <StyledButtonGroup>
      <PreviewButton action={hanldeOpenModal} disabled={!foundedCv} />
      <DownloadButton
        action={() => foundedCv && handleDownloadCv(foundedCv)}
        disabled={!foundedCv || !downloadFinished}
      />
      {!isPartner(user) && (
        <DeleteButton
          action={handleDeleteSuggestedCandidate}
          disabled={loading}
        />
      )}
    </StyledButtonGroup>
  );
};
