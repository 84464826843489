import { faTimes } from '@fortawesome/free-solid-svg-icons';
import CV from '../../../../../containers/CV/CV.component';
import { CVModalProps } from '../CVModal.types';
import { CloseIcon, StyledContainer } from './CVModalContainer.styles';

export const CVModalContainer = ({
  closeModal,
  cvId,
  injectData = undefined,
}: Partial<CVModalProps>) => {
  return (
    <>
      <CloseIcon icon={faTimes} onClick={() => closeModal && closeModal()} />
      <StyledContainer>
        <CV
          editable={false}
          cvId={cvId}
          injectData={injectData}
          onPagesFinished={undefined}
        />
      </StyledContainer>
    </>
  );
};
