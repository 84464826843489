import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/common/Button/Button.component';
import { AppPaths } from '../../config/AppPaths';
import { StyledEmployeeTable } from './NotFound.styles';

export const NotFound = () => {
  const navigate = useNavigate();
  const handleButton = () => {
    navigate(AppPaths.home);
  };

  return (
    <StyledEmployeeTable>
      <h1>404 Error</h1>
      <p>Sorry, we can`t find the page you`re looking for.</p>
      <Button action={handleButton}>Go to Home page</Button>
    </StyledEmployeeTable>
  );
};
