export type AutocompleteType = 'name' | 'hr_owner' | 'technologies' | 'reason';

export enum LeadRequestFormFieldType {
  name = 'name',
  hrOwner = 'hr_owner',
  technologies = 'technologies',
  reason = 'reason',
}

export const QUILL_MODULE_TEXT_FORMATTING_WITH_BULLETS = {
  toolbar: [['bold', 'italic', 'underline'], [{ list: 'bullet' }]],
};
