import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteModal } from '../../DeleteModal';
import { StyledName } from './SuggestedCandidateDeleteModalContent.styles';
import { SuggestedCandidateDeleteModalContentProps } from './SuggestedCandidateDeleteModalContent.types';

export const SuggestedCandidateDeleteModalContent = React.forwardRef<
  HTMLDivElement,
  SuggestedCandidateDeleteModalContentProps
>(({ closeModal, handleDelete, name }, ref) => {
  const { t } = useTranslation(['common', 'company'], {
    keyPrefix: 'vacancies',
  });
  const handleDeleting = async () => {
    handleDelete && handleDelete();
    closeModal();
  };

  const buttonsConfig = {
    primaryButton: {
      label: t('confirm_delete', { ns: 'common', keyPrefix: 'buttons' }),
      action: handleDeleting,
    },
  };

  const content = (
    <p>
      {t('projects.do_you_want_to_remove', {
        ns: 'company',
        keyPrefix: 'company_details',
      })}
      <StyledName>{name}</StyledName>{' '}
      {t('projects.from_suggested_candidates', {
        ns: 'company',
        keyPrefix: 'company_details',
      })}
    </p>
  );

  return (
    <DeleteModal
      dialogTitle=""
      content={content}
      buttonsConfig={buttonsConfig}
      handleClose={closeModal}
      ref={ref}
    />
  );
});
