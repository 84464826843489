import React, { PropsWithChildren } from 'react';
import { StyledModal } from '../../Modal.styles';
import { DeletingModalContent } from '../DeletingModalContent';
import { EditingModalContent } from '../EditingModalContent';
import { RequestAddingModalContent } from '../Requests/RequestAddingModalContent';
import { RequestDeletingModalContent } from '../Requests/RequestDeletingModalContent';
import { RequestEditingModalContent } from '../Requests/RequestEditingModalContent/RequestEditingModalContent';
import { StyledModalWrapper } from './LeadsModal.styles';
import { LeadsModalProps } from './LeadsModal.types';

export const LeadsModal = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<LeadsModalProps>
>(
  (
    {
      modalFor,
      isModalOpen,
      closeModal,
      hrOwners,
      profileTags,
      reasonTags,
      leadIdToEdit,
      leadId,
      leadProfileId,
      leads,
    },
    ref,
  ) => {
    const modalsOptions = () => {
      switch (modalFor) {
        case 'addingRequestModal':
          return (
            <RequestAddingModalContent
              {...{
                closeModal,
                hrOwners,
                nameTags: profileTags,
                reasonTags,
                leadId,
                leads,
              }}
            />
          );
        case 'deletingRequestModal':
          return (
            leadId && (
              <RequestDeletingModalContent
                {...{ closeModal, leadId }}
                requestId={Number(leadProfileId)}
              />
            )
          );
        case 'editingRequestModal':
          return (
            leadId && (
              <RequestEditingModalContent
                {...{ closeModal, hrOwners, reasonTags, leadId }}
                requestId={Number(leadProfileId)}
                nameTags={profileTags}
              />
            )
          );
        case 'editingLeadModal':
          return (
            leadIdToEdit && (
              <EditingModalContent {...{ closeModal, leadIdToEdit }} />
            )
          );
        case 'deletingLeadModal':
          return leadId && <DeletingModalContent {...{ closeModal, leadId }} />;
        default:
          break;
      }
    };

    return (
      <StyledModal
        ref={ref}
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="candidates-modal-title"
        aria-describedby="candidates-modal-description"
      >
        <StyledModalWrapper>{modalsOptions()}</StyledModalWrapper>
      </StyledModal>
    );
  },
);
