import { AnyAction } from '@reduxjs/toolkit';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { statusOfRequestWithReason } from '../../../containers/Leads/Leads.config';
import { mainTechnologiesTagsThunks } from '../../../containers/Leads/redux/tags/MainTechnologiesTags.thunk';
import { Technology } from '../../../store/types/Store.leads.types';
import { AppStore } from '../../../store/types/Store.types';
import { UserState } from '../../../store/types/Store.user.types';
import { AutocompleteInput } from '../../common/AutocompleteInput';
import { Dialog } from '../../common/Dialog/DEPR_Dialog';
import { Dropzone } from '../../common/Dropzone/Dropzone.component';
import { FilesList } from '../../common/FilesList/FilesList';
import { FormLabel } from '../../common/FormLabel';
import { MainTechnologiesAutocomplete } from '../MainTechnologiesAutocomplete/MainTechnologiesAutocomplete.component';
import { LeadPriority } from './LeadPriority';
import { QUILL_MODULE_TEXT_FORMATTING_WITH_BULLETS } from './LeadRequestForm.config';
import {
  MainTechnologiesContainer,
  ReasonContainer,
  StyledReactQuill,
  StyledRow,
  StyledFormHalfRowContainer,
  StyledTextContainer,
  OptionItem,
} from './LeadRequestForm.styles';
import { LeadRequestFormProps } from './LeadRequestForm.types';
import { LeadStatus } from './LeadStatus';
import { useLeadRequestFormHandlers } from './hooks/useLeadRequestHandlers';
import { Selector } from '../../common/Selector/Selector.component';
import { Option } from '../../common/Selector/Selector.types';
import { useLeadRequestFormConfig } from './useLeadRequestFormConfig';

export const LeadRequestForm = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<LeadRequestFormProps>
>(
  ({
    requestFetched,
    dialogTitle,
    getButtonsConfig,
    handleClose,
    hrOwners,
    nameTags,
    reasonTags,
    leadId,
    leads,
  }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['vacancies'], { keyPrefix: 'vacancies' });
    const allMainTechnologies = useSelector<AppStore, Technology[]>(
      (state) => state.leadsRelated.tags.main_technologies,
    );

    const userState = useSelector<AppStore, UserState>((state) => state.user);
    const { visibilityOptions } = useLeadRequestFormConfig();
    const {
      setDefaultRequestData,
      updateFetchedRequestDisplay,
      owner,
      name,
      reason,
      setReason,
      mainTechnologies,
      description,
      visibility,
      clickedStatusOption,
      setClickedStatusOption,
      request,
      setRequest,
      showFileLoader,
      lead,
      handleInputChange,
      handleFileUpload,
      handleFileRemoval,
      handleMainTechnologiesChange,
      handleDescriptionChange,
      handleChange,
      handleVisibilityChange,
      handleLeadChange,
    } = useLeadRequestFormHandlers({
      requestFetched,
      userState,
      allMainTechnologies,
    });

    useEffect(() => {
      dispatch(
        mainTechnologiesTagsThunks.getMainTechnologiesThunk() as unknown as AnyAction,
      );
      !requestFetched && setDefaultRequestData();
    }, []);

    useEffect(() => {
      leadId && updateFetchedRequestDisplay();
    }, [leadId]);

    return (
      <Dialog
        dialogTitle={dialogTitle}
        buttonsConfig={getButtonsConfig(request)}
        handleClose={handleClose}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="24px"
          paddingBottom="20px"
        >
          {leadId === null && (
            <StyledRow>
              <AutocompleteInput
                label={t('project_name')}
                error={
                  leads && leads.length === 0
                    ? t('warning_message_for_no_projects')
                    : ''
                }
                name="lead"
                options={leads || []}
                getOptionLabel={(value: any) => value.name || ''}
                isOptionEqualToValue={(option: any, valueA: any) =>
                  option.id === valueA.id
                }
                renderOption={(props, option: any) => (
                  <OptionItem key={option.id} value={option as any} {...props}>
                    {option.name}
                  </OptionItem>
                )}
                value={lead}
                required
                displayAsRequired
                onChange={(_event, newValue) => handleLeadChange(newValue)}
                disableClearable
              />
            </StyledRow>
          )}
          <StyledRow>
            <AutocompleteInput
              label={t('position')}
              name="name"
              options={nameTags}
              value={name || ''}
              freeSolo
              handleInputChange={handleChange}
              handleCustomOption={handleInputChange}
              disableClearable
            />
            <AutocompleteInput
              label={t('hr_owner')}
              name="hr_owner"
              options={hrOwners || ''}
              value={owner || request.hr_owner || ''}
              disabled={userState.user?.department === 'Partner'}
              handleInputChange={handleChange}
              disableClearable
            />
          </StyledRow>
          <StyledRow>
            <LeadPriority setRequest={setRequest} leadRequest={request} />
            <LeadStatus
              {...{
                setRequest,
                leadRequest: request,
                setClickedStatusOption,
                setReason,
              }}
            />
          </StyledRow>
          <StyledRow>
            <MainTechnologiesContainer>
              <MainTechnologiesAutocomplete
                options={allMainTechnologies}
                name="technologies"
                label={t('main_technologies')}
                handleInputChange={handleMainTechnologiesChange}
                value={mainTechnologies}
                disableClearable
              />
            </MainTechnologiesContainer>
            <ReasonContainer>
              <AutocompleteInput
                label={t('reason')}
                name="reason"
                freeSolo
                options={reasonTags}
                value={reason}
                handleInputChange={handleChange}
                handleCustomOption={handleInputChange}
                disabled={clickedStatusOption !== statusOfRequestWithReason}
              />
            </ReasonContainer>
          </StyledRow>
          <StyledTextContainer>
            <FormLabel htmlFor={name} label={t('description')} />
            <StyledReactQuill
              theme="snow"
              value={description}
              onChange={handleDescriptionChange}
              modules={QUILL_MODULE_TEXT_FORMATTING_WITH_BULLETS}
            />
          </StyledTextContainer>
          <Dropzone handleFileSelection={handleFileUpload}></Dropzone>
          <FilesList
            {...{ handleFileRemoval, showFileLoader }}
            files={request.files}
          />
          <StyledFormHalfRowContainer>
            <Selector
              label={t('visibility')}
              value={visibility || null}
              name="visibility"
              action={(event: SelectChangeEvent<Option>) =>
                handleVisibilityChange(event.target.value as Option)
              }
              options={visibilityOptions}
              size="medium"
            />
          </StyledFormHalfRowContainer>
        </Box>
      </Dialog>
    );
  },
);
