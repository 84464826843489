import React from 'react';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import Tooltip from '@mui/material/Tooltip';
import { StyledIconButton } from './BoldIcon.styles';

export const BoldIcon = ({ action, title = 'Bold', ...iconOptions }) => (
  <StyledIconButton onClick={action}>
    <Tooltip title={title}>
      <FormatBoldIcon style={{ color: 'black' }} {...iconOptions} />
    </Tooltip>
  </StyledIconButton>
);
