import { CreatedRowLead } from '../ProjectsTable.types';
import {
  activeFilter,
  archivedFilter,
  ownerFilter,
  searchFilter,
} from './filters';

export const getValuesByTopFilter = (row: CreatedRowLead, filter: string) => {
  if (filter === 'current') return activeFilter(row);
  if (filter === 'archived') return archivedFilter(row);
  return activeFilter(row) || archivedFilter(row);
};

export const getFilteredRows = (
  createdRows: CreatedRowLead[],
  filteredValue: string,
  topFilterValue: string,
  filteredSupervisorValue?: string,
) => {
  const founded = filteredValue.length
    ? createdRows.filter((row) => searchFilter(row, filteredValue))
    : [...createdRows];
  const filteredByOwner = filteredSupervisorValue
    ? founded.filter((row) => ownerFilter(row, filteredSupervisorValue))
    : [...founded];
  return filteredByOwner.filter((row) =>
    getValuesByTopFilter(row, topFilterValue),
  );
};
