import { Label, StyledInput, StyledInputContainer } from './NewInput.styles';

export const NewInput = ({
  label,
  name,
  type = 'text',
  value,
  handleInputChange,
  placeholder = label,
  handleKeyDown = undefined,
  required = false,
  disabled,
  ...inputProps
}) => {
  return (
    <StyledInputContainer>
      {label && <Label>{label}</Label>}
      <StyledInput
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        value={value}
        disabled={disabled}
        required={required}
        {...inputProps}
      />
    </StyledInputContainer>
  );
};
