import { Lead } from '../../../../../../store/types/Store.leads.types';
import { CreatedRowLead } from '../ProjectsTable.types';

export const createLeadRow = (leadData: Lead): CreatedRowLead => ({
  id: leadData.id,
  name: leadData.name,
  industry: leadData.industry,
  date: leadData.created_at ?? 'no data',
  salesOwner: leadData.sales_owner.username ?? 'no owner',
  request: 'Request dev:',
  requestNum: leadData.requests_num ?? 0,
  status: leadData.status,
});
