import Button from '@mui/material/Button';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  min-width: 30px;
  padding: 6px 5px;
  ${({ disabled }) => disabled && `opacity: 0.4;`}
`;

export const StyledButtonGroup = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
`;
