import Box from '@mui/material/Box';
import { styled as materialUIStyled } from '@mui/material/styles';
import styled from 'styled-components';

export const HeadContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 632px;
  @media (max-width: 699px) {
    width: 100%;
  }
`;

export const StyledHeadline = styled.p`
  color: #101840;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin: 0 0 10px 0;
`;

export const Line = styled.hr`
  border-top: 1px solid #efefef;
  margin-bottom: 5px;
  margin-top: 0;
  width: 632px;
  @media (max-width: 699px) {
    width: 100%;
  }
`;

export const StyledList = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  margin-top: 30px;
  overflow: auto;
  padding: 0;
  position: relative;
`;

export const StyledBox = materialUIStyled(Box)({
  '@media (max-width: 699px)': {
    margin: 0,
    width: '100%',
  },
  padding: '20px',
});
