import { PropsWithChildren } from 'react';
import { FormLabel } from '../FormLabel/FormLabel';
import { StyledInputContainer, StyledTextField } from './TextField.styles';
import { TextFieldProps } from './TextField.types';

export const TextField = ({
  label,
  name,
  value,
  handleInputChange,
  handleKeyDown,
  required,
  multiline,
  disabled,
  placeholder,
  rows,
  ...textAreaProps
}: PropsWithChildren<TextFieldProps>) => (
  <StyledInputContainer>
    {label && <FormLabel htmlFor={name} {...{ label, disabled }} />}
    <StyledTextField
      id={name}
      name={name}
      placeholder={placeholder || label}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      value={value}
      required={required}
      rows={rows}
      {...textAreaProps}
    />
  </StyledInputContainer>
);
