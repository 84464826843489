import { createSlice } from '@reduxjs/toolkit';
import { CandidateStatus } from '../../../store/types/Store.leads.types';
import { leadProfileCandidateHistoryThunks } from './LeadProfileCandidateHistory.thunk';

const { getCandidateStatusHistoryThunk } = leadProfileCandidateHistoryThunks;

const initialState: CandidateStatus[] = [];

const leadProfileCandidateHistorySlice = createSlice({
  name: 'leadProfileCandidateHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getCandidateStatusHistoryThunk.fulfilled,
      (state, action) => {
        state.length = 0;
        action.payload.forEach((data: CandidateStatus) => {
          state.push({
            id: data.id,
            candidate: data.candidate,
            status: data.status,
            reason: data.reason,
            created_at: data.created_at,
          });
        });
      },
    );
  },
});

export const actions = { ...leadProfileCandidateHistorySlice.actions };
export const { reducer } = leadProfileCandidateHistorySlice;
