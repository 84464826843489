import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { CandidateChip } from '../../../../../components/common/Chips/CandidateChip';
import { SearchField } from '../../../../../components/common/SearchField/SearchField';
import { Table } from '../../../../../components/common/Table/Table.component';
import { AddButton } from '../../../../../components/common/Buttons/AddButton';
import { Tooltip } from '../../../../../components/common/Tooltip/Tooltip.component';
import { addDotsToString, removeHtml } from '../../../../../Helpers';
import { useEffectAsync } from '../../../../../utils/useEffectAsync';
import { employeeThunks } from '../../../../Employee/Employees.thunk';
import { ActionColumn } from './ActionColumn/ActionColumn.component';
import {
  STRING_LIMIT,
  getTopFilterOptions,
  searchInColumns,
} from './MyCandidatesTable.config';
import { StyledEmployeeTable } from './MyCandidatesTable.styles';
import { useEmployeeModal } from '../../../../../components/common/Modals/Employees/EmployeesModal/useEmployeeModal';
import { EmployeesModalFactory } from '../../../../../components/common/Modals/Employees/EmployeesModal';
import {
  TableFilter,
  filterRows,
  countTopFilters,
} from '../../../../../components/common/TableFilter/TableFilter';
import { raiseToast } from '../../../../../utils/raiseToasts';
import { PdfDownloader } from '../../../../CV/PdfDownloader/PdfDownloader.component';
import { AppPaths } from '../../../../../config/AppPaths';
import {
  HeadControls,
  MainHeadline,
  FiltersContainer,
  SearchContainer,
  StyledEmail,
  StyledLink,
  StyledEmptyResult,
} from '../../MyProfile.styles';
import { Counter } from '../../../../../components/common/Counter/Counter';

export const MyCandidatesTable = () => {
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.candidates',
  });
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const filters = {}; //useSelector((state) => state.employees.filters.employee);
  const employees = useSelector((state) => state.employees.people.results);
  const [searchValue, setSearchValue] = useState(filters.search ?? '');
  const [cvIdToDownload, setCvIdToDownload] = useState(false);
  const [employeeIdToDownload, setEmployeeIdToDownload] = useState('');
  const [downloadFinished, setDownloadFinished] = useState(false);
  const [profileNameToDownload, setProfileNameToDownload] = useState('');
  const [nameToDownload, setNameToDownload] = useState('');
  const [optimisticChanges, setOptimisticChanges] = useState({});
  const [topFilterValue, setTopFilterValue] = useState('current');
  const navigate = useNavigate();

  const topFilterOptions = getTopFilterOptions(t);
  const { setEmployeeId, setDisplayedModal, modalData } = useEmployeeModal([
    'add',
    'edit',
    'delete',
  ]);

  const handleDownloadCv = (readableId, cvId, profileName) => {
    setProfileNameToDownload(profileName);
    setDownloadFinished(false);
    setCvIdToDownload(cvId);
    setEmployeeIdToDownload(readableId);
  };
  const columns = [
    {
      field: 'fullName',
      headerName: t('name'),
      type: 'string',
      align: 'left',
      headerAlign: 'left',
      flex: 1.2,
      minWidth: 180,
      cellClassName: 'name-cell',
      renderCell: ({ row }) => (
        <StyledLink
          href={generatePath(AppPaths.employeeId, {
            id: row.employeeUid,
          })}
        >
          {row.fullName}
        </StyledLink>
      ),
    },
    {
      field: 'profile',
      headerName: t('position'),
      flex: 1.2,
      minWidth: 140,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ row: { profile } }) => (
        <CandidateChip
          size="small"
          label={addDotsToString(profile, STRING_LIMIT)}
        />
      ),
    },
    {
      field: 'email',
      headerName: t('email'),
      flex: 1.2,
      minWidth: 140,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ row }) => (
        <StyledEmail href={`mailto:${row.email}`}>{row.email}</StyledEmail>
      ),
    },
    {
      field: 'payRateFrom',
      headerName: t('rate'),
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      flex: 0.5,
      minWidth: 80,
      renderCell: ({ row }) => <>{row.payRate}</>,
    },
    {
      field: 'availability',
      headerName: t('availability'),
      flex: 1,
      minWidth: 130,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ row: { availability } }) =>
        availability && availability.length > STRING_LIMIT ? (
          <Tooltip title={availability} position="table">
            <div>
              <CandidateChip
                size="small"
                label={addDotsToString(availability, STRING_LIMIT)}
              />
            </div>
          </Tooltip>
        ) : (
          <CandidateChip size="small" label={availability || 'No data'} />
        ),
    },
    {
      field: 'action',
      headerName: t('actions', { ns: 'common', keyPrefix: 'columns' }),
      align: 'right',
      headerAlign: 'left',
      minWidth: 165,
      sortable: false,
      renderCell: ({ value, row }) => {
        return (
          <ActionColumn
            archiveStatus={row.status}
            visibility={row.visibility}
            onEditHandler={() => {
              navigate(
                generatePath(AppPaths.editEmployee, {
                  id: value.id,
                }),
              );
            }}
            onArchiveHandler={(archiveStatus) => {
              row.archiveStatus.onArchiveChange(archiveStatus);
            }}
            onDeleteHandler={() => {
              setEmployeeId(value.id);
              setDisplayedModal('delete');
            }}
            onPublishHandler={(publishStatus) => {
              row.publishStatus.onPublishStatusChange(publishStatus);
            }}
            onDownloadHandler={() => {
              const chosenEmployee = employees.find(
                (employee) => employee.id === value.id,
              );
              const cvToDownload = chosenEmployee?.cvs.find(
                (cv) => cv.is_shared === true,
              );
              setNameToDownload(chosenEmployee.first_name);
              if (cvToDownload) {
                handleDownloadCv(
                  chosenEmployee.readable_id,
                  cvToDownload.id,
                  removeHtml(cvToDownload.profile_name),
                );
              } else {
                raiseToast.error(
                  t('no_cv_found', { ns: 'common', keyPrefix: 'messages' }),
                );
              }
            }}
          />
        );
      },
    },
  ];
  const updateRows = () => {
    setRows(
      filterRows(
        employees,
        searchInColumns,
        searchValue,
        topFilterOptions,
        topFilterValue,
      ).map((p, i) => createRow(p, i)),
    );
  };
  useEffect(() => {
    if (employees?.length) {
      updateRows();
      loading && setLoading(false);
    }
  }, [employees, searchValue, topFilterValue]);

  useEffectAsync(async () => {
    window.scrollTo(0, 0);
    await dispatch(employeeThunks.getMyEmployeesThunk());
  }, []);

  const setField = (employeeId, fieldName, value) => {
    const data = {
      employeeId,
      payload: { [fieldName]: value },
    };
    const updatedChanges = optimisticChanges;
    if (updatedChanges[employeeId]) {
      updatedChanges[employeeId][fieldName] = value;
    } else {
      updatedChanges[employeeId] = {
        [fieldName]: value,
      };
    }
    setOptimisticChanges(updatedChanges);
    dispatch(employeeThunks.updateEmployeeActivityThunk(data));
  };

  const getField = (employeeId, trulyActive, fieldName) => {
    if (optimisticChanges[employeeId]) {
      return optimisticChanges[employeeId][fieldName];
    }
    return trulyActive;
  };
  const createRow = (employeeData, idx) => {
    const { rate_of_pay_from: rateOfPayFrom, rate_of_pay_to: rateOfPayTo } =
      employeeData;
    const fullName = `${employeeData.first_name} ${employeeData.last_name}`;
    return {
      id: idx,
      employeeId: employeeData.readable_id,
      employeeUid: employeeData.id,
      fullName,
      firstName: employeeData.first_name,
      status: employeeData.status,
      lastName: employeeData.last_name,
      email: employeeData.email ?? '',
      profile: employeeData.profile ?? t('no_profile'),
      availability: employeeData.availability,
      visibility: employeeData.visibility,
      payRateFrom: employeeData.rate_of_pay_from,
      payRate:
        rateOfPayFrom === rateOfPayTo
          ? `${rateOfPayFrom || 0} ${employeeData.rate_of_pay_currency}` || 0
          : `${rateOfPayFrom || 0} - ${rateOfPayTo || 0} ${
              employeeData.rate_of_pay_currency
            }`,
      activeStatus: {
        id: employeeData.id,
        active: getField(employeeData.id, employeeData.is_active, 'is_active'),
        onActiveStatusChange: (newStatus) =>
          setField(employeeData.id, 'is_active', newStatus),
      },
      archiveStatus: {
        id: employeeData.id,
        status: getField(employeeData.id, employeeData.status, 'status'),
        onArchiveChange: (newStatus) =>
          setField(employeeData.id, 'status', newStatus),
      },
      publishStatus: {
        id: employeeData.id,
        visibility: getField(
          employeeData.id,
          employeeData.visibility,
          'visibility',
        ),
        onPublishStatusChange: (newStatus) =>
          setField(employeeData.id, 'visibility', newStatus),
      },

      action: employeeData,
    };
  };
  return (
    <StyledEmployeeTable>
      {cvIdToDownload && !downloadFinished && (
        <PdfDownloader
          setDownloadFinished={setDownloadFinished}
          cvId={cvIdToDownload}
          fileName={`${nameToDownload}-${
            profileNameToDownload !== '' ? profileNameToDownload + '-' : ''
          }${employeeIdToDownload || 'CV'}`}
        />
      )}
      <HeadControls>
        <MainHeadline>
          {t('my_candidates', { keyPrefix: 'my_profile' })}
          <Counter number={employees.length} color="#FFFFFF" />
        </MainHeadline>
      </HeadControls>
      <HeadControls>
        <FiltersContainer>
          <TableFilter
            filterOptions={topFilterOptions}
            filterValue={topFilterValue}
            filterCounts={countTopFilters(
              filterRows(
                employees,
                searchInColumns,
                searchValue,
                topFilterOptions,
                topFilterValue,
                false,
              ),
              topFilterOptions,
            )}
            changeHandler={setTopFilterValue}
          />
        </FiltersContainer>
        <SearchContainer>
          <SearchField initialValue={searchValue} onSubmit={setSearchValue} />
        </SearchContainer>
        <AddButton
          marginLeft={1}
          action={() => navigate(AppPaths.createEmployee)}
        >
          {t('add_candidate')}
        </AddButton>
      </HeadControls>
      <EmployeesModalFactory {...modalData} />
      {rows.length > 0 ? (
        <Table
          columns={columns}
          rows={rows}
          checkboxSelection={false}
          density="standard"
          loading={loading}
        />
      ) : (
        <StyledEmptyResult>
          {t('no_data_found', { ns: 'common', keyPrefix: 'messages' })}
        </StyledEmptyResult>
      )}
    </StyledEmployeeTable>
  );
};
