import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { COLORS } from '../../../theme/colors';
import { Typography } from '../Typography/Typography.component';

export function LanguageSwitch() {
  const { i18n } = useTranslation();

  const updateLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('i18nextLng', language);
  };

  return (
    <div style={{ paddingTop: 20 }}>
      <Button
        variant="outlined"
        style={{
          color: COLORS.accent.primary,
          border: `1px solid ${COLORS.stroke.secondary}`,
          borderRadius: '8px',
          padding: '6px 0',
        }}
        onClick={() => updateLanguage(i18n.language === 'en' ? 'pl' : 'en')}
      >
        <Typography variant="h4">
          {i18n.language === 'en' ? 'pl' : 'en'}
        </Typography>
      </Button>
    </div>
  );
}
