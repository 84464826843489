import { createSlice, current } from '@reduxjs/toolkit';
import { vacanciesPageThunks } from './Vacancies.thunk';
const { getHomePageDataThunk, getHomePageStatsThunk, getHomeCandidatesThunk } =
  vacanciesPageThunks;

const initialState = {
  leadProfiles: [],
  stats: {
    main_technologies_count: {},
    active_lead_profiles: 0,
    active_leads: 0,
    candidates_status_count: {},
  },
};

const vacanciesSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: {
    [getHomePageDataThunk.fulfilled]: (state, { payload }) => {
      state.leadProfiles = payload;
    },
    [getHomeCandidatesThunk.fulfilled]: (state, action) => {
      const arr = current(state.leadProfiles);
      const found = arr.find(
        (elem) => elem.lead_profile_id === action.meta.arg.requestId,
      );
      const index = arr.indexOf(found);
      if (index > -1) {
        state.leadProfiles[index].candidates = action.payload;
      }
    },
    [getHomePageStatsThunk.fulfilled]: ({ stats }, { payload }) => {
      stats.main_technologies_count = payload.main_technologies_count;
      stats.active_lead_profiles = payload.active_lead_profiles;
      stats.active_leads = payload.active_leads;
      stats.candidates_status_count = payload.candidates_status_count;
    },
  },
});

export const actions = { ...vacanciesSlice.actions };
export const { reducer } = vacanciesSlice;
