import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthHeader, handleResponse } from '../../../../utils/api';
import { generateEmployeePageCvsValidationUrl } from '../../../../utils/urlGenerators';

const getEmployeePageValidatedCvsThunk = createAsyncThunk(
  'employeePage/getValidatedCvs',
  async (employeeId: string, { getState, dispatch }) => {
    const request = fetch(generateEmployeePageCvsValidationUrl(employeeId), {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

export const employeePageCvsValidationThunks = {
  getEmployeePageValidatedCvsThunk,
};
