import { useDispatch } from 'react-redux';
import { PartnerForm } from '../../../components/Partner/PartnerForm/PartnerForm.component';
import { partnerThunks } from '../Partner.thunk';

export const RegisterPartner = ({ closeModal }) => {
  const dispatch = useDispatch();
  const handleSubmit = async (data) => {
    const result = await dispatch(partnerThunks.addPartnerThunk(data));
    if (result.type === partnerThunks.addPartnerThunk.fulfilled.type) {
      await dispatch(partnerThunks.getAllPartnersThunk());
      closeModal();
    }
  };

  return <PartnerForm handleSubmit={handleSubmit} closeModal={closeModal} />;
};
