import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef, useRef } from 'react';
import { pastePlainText, placeCaretAtEnd } from '../../../Helpers';
import { WithHeightUpdate } from '../../../utils/WithHeightUpdate';
import { StyledEntryContainer } from '../../common/CV/EntryContainer/EntryContainer.styles';
import {
  StyledDeleteButton,
  StyledIcon,
  StyledInfo,
  StyledLanguage,
  StyledLanguageData,
  StyledLanguageMarks,
  StyledLanguageTextDiv,
  StyledSmallHeader,
  StyledTextDiv,
  StyledTimePeriod,
} from './LanguagesEntry.styles';

const LanguagesEntryComponent = forwardRef(
  (
    {
      data,
      handleDrop,
      handleDrag,
      handleDragOver,
      editable,
      setTextFormattingPanelVisibility,
      handleEditData,
      handleRemoveEntry,
    },
    ref,
  ) => {
    //todo: remove refs, use event.target
    const understandingRef = useRef(null);
    const speakingRef = useRef(null);
    const readingRef = useRef(null);
    const writingRef = useRef(null);

    //todo: use one state object with all attributes
    const [previousUnderstanding, setPreviousUnderstanding] = React.useState(
      data.understanding,
    );

    const [previousSpeaking, setPreviousSpeaking] = React.useState(
      data.speaking,
    );

    const [previousReading, setPreviousReading] = React.useState(data.reading);

    const [previousWriting, setPreviousWriting] = React.useState(data.writing);

    React.useEffect(() => {
      setPreviousUnderstanding(data.understanding);
    }, [data.understanding]);

    React.useEffect(() => {
      setPreviousSpeaking(data.speaking);
    }, [data.speaking]);

    React.useEffect(() => {
      setPreviousReading(data.reading);
    }, [data.reading]);

    React.useEffect(() => {
      setPreviousWriting(data.writing);
    }, [data.writing]);

    function isInputValid(input) {
      if (input.length > 1) return false;
      if (!Number.isInteger(Number.parseInt(input, 10)) && input !== '')
        return false;
      return !(
        Number.parseInt(input, 10) > 5 || Number.parseInt(input, 10) < 1
      );
    }

    function handleInput(refP, previousValue, setPreviousValue) {
      const content = refP.current.innerHTML;
      if (isInputValid(content)) {
        setPreviousValue(content);
      } else refP.current.innerHTML = previousValue;
    }

    const marksPlaceholder = '[1-5]';
    const [focused, setFocused] = React.useState(false);

    return (
      <StyledEntryContainer
        ref={ref}
        draggable={!focused}
        onDragStart={handleDrag}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        <StyledLanguageData>
          <StyledTimePeriod>
            <StyledLanguage>
              <StyledLanguageTextDiv
                placeholder="Language"
                suppressContentEditableWarning
                contentEditable={editable}
                onFocus={() => {
                  setTextFormattingPanelVisibility(true);
                }}
                onPaste={(e) => pastePlainText(e)}
                onBlur={(e) => {
                  setTextFormattingPanelVisibility(false);
                  handleEditData({
                    languageName: e.currentTarget.textContent,
                  });
                }}
                spellCheck={false}
                dangerouslySetInnerHTML={{ __html: data.languageName }}
              />
            </StyledLanguage>
            {marksPlaceholder}
          </StyledTimePeriod>
          {editable ? (
            <StyledDeleteButton>
              <StyledIcon onClick={() => handleRemoveEntry()}>
                <FontAwesomeIcon icon={faMinusCircle} />
              </StyledIcon>
            </StyledDeleteButton>
          ) : undefined}
          <StyledInfo>
            <StyledLanguageMarks>
              <div style={{ display: 'flex' }}>
                <StyledSmallHeader>Understanding:</StyledSmallHeader>
                <StyledTextDiv
                  placeholder={marksPlaceholder}
                  ref={understandingRef}
                  suppressContentEditableWarning
                  contentEditable={editable}
                  onInput={(e) => {
                    handleInput(
                      understandingRef,
                      previousUnderstanding,
                      setPreviousUnderstanding,
                    );
                    placeCaretAtEnd(e.currentTarget);
                  }}
                  onPaste={(e) => pastePlainText(e)}
                  onBlur={() =>
                    handleEditData({
                      understanding: understandingRef.current.innerHTML,
                    })
                  }
                  spellCheck={false}
                >
                  {data.understanding}
                </StyledTextDiv>
              </div>
              <div style={{ display: 'flex' }}>
                <StyledSmallHeader>Speaking:</StyledSmallHeader>
                <StyledTextDiv
                  placeholder={marksPlaceholder}
                  ref={speakingRef}
                  suppressContentEditableWarning
                  contentEditable={editable}
                  onInput={(e) => {
                    handleInput(
                      speakingRef,
                      previousSpeaking,
                      setPreviousSpeaking,
                    );
                    placeCaretAtEnd(e.currentTarget);
                  }}
                  onPaste={(e) => pastePlainText(e)}
                  onBlur={() =>
                    handleEditData({
                      speaking: speakingRef.current.innerHTML,
                    })
                  }
                  spellCheck={false}
                >
                  {data.speaking}
                </StyledTextDiv>
              </div>
              <div style={{ display: 'flex' }}>
                <StyledSmallHeader>Reading: </StyledSmallHeader>
                <StyledTextDiv
                  placeholder={marksPlaceholder}
                  ref={readingRef}
                  suppressContentEditableWarning
                  contentEditable={editable}
                  onInput={(e) => {
                    handleInput(
                      readingRef,
                      previousReading,
                      setPreviousReading,
                    );
                    placeCaretAtEnd(e.currentTarget);
                  }}
                  onPaste={(e) => pastePlainText(e)}
                  onBlur={() =>
                    handleEditData({
                      reading: readingRef.current.innerHTML,
                    })
                  }
                  spellCheck={false}
                >
                  {data.reading}
                </StyledTextDiv>
              </div>
              <div style={{ display: 'flex' }}>
                <StyledSmallHeader>Writing: </StyledSmallHeader>
                <StyledTextDiv
                  placeholder={marksPlaceholder}
                  ref={writingRef}
                  suppressContentEditableWarning
                  contentEditable={editable}
                  onInput={(e) => {
                    handleInput(
                      writingRef,
                      previousWriting,
                      setPreviousWriting,
                    );
                    placeCaretAtEnd(e.currentTarget);
                  }}
                  onPaste={(e) => pastePlainText(e)}
                  onBlur={() =>
                    handleEditData({
                      writing: writingRef.current.innerHTML,
                    })
                  }
                  spellCheck={false}
                >
                  {data.writing}
                </StyledTextDiv>
              </div>
            </StyledLanguageMarks>
          </StyledInfo>
        </StyledLanguageData>
      </StyledEntryContainer>
    );
  },
);

export const LanguagesEntry = WithHeightUpdate(LanguagesEntryComponent);
