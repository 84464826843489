import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';

export const StyledIconButton = styled(IconButton)`
  height: 30px;
  width: 30px;

  &:hover {
    background: transparent;
  }
`;
