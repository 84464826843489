import styled from 'styled-components';

interface DivWithImageBackgroundProps {
  img: string;
}

export const QuoteContainer = styled.div<DivWithImageBackgroundProps>`
  background: ${(props) => `url(${props.img})`};
  background-size: cover;
  margin-top: 50px;
  min-height: 100vh;
  width: 42%;
`;

export const MobContainer = styled.div<DivWithImageBackgroundProps>`
  background: ${(props) => `url(${props.img})`};
  margin-top: 50px;
  max-height: 400px;
  min-width: 100%;
`;

export const StyledLogo = styled.img`
  margin-left: 5.14%;
  margin-top: 20px;
  max-height: 48px;
`;

export const FormContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  min-height: 20vh;
  min-width: 58%;

  @media screen and (max-width: 780px) {
    margin-top: 90px;
  }

  @media screen and (max-width: 340px) {
    max-width: 100%;
    min-width: 100%;
  }
`;

export const StyledPage = styled.div`
  display: flex;
  margin-top: -50px;
  min-height: 100vh;
  width: 100%;

  @media screen and (max-width: 780px) {
    flex-direction: column;
  }
`;

interface FormProps {
  disabled?: boolean;
}

export const FormWrapper = styled.div`
  align-items: flex-end;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  max-width: 95%;
  min-height: 266px;
  padding: 50px 52px;

  @media screen and (min-width: 780px) {
    min-width: 450px;
  }

  @media screen and (max-width: 780px) {
    min-width: 90%;
  }
`;

export const StyledButton = styled.button<FormProps>`
  background-color: ${({ disabled }) => (disabled ? '#CCCCCC' : '#3cc2d9')};
  border: none;
  border-radius: 10px;
  color: ${({ disabled }) => (disabled ? 'white' : '#f4f7fa')};
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  max-height: 55px;
  padding: 20px 60px;
  width: 235px;
  ${({ disabled }) => disabled && `pointer-events:none;`}

  ${({ disabled }) =>
    !disabled &&
    `&:hover {
  cursor: pointer;
}`}

@media screen and (max-width: 780px) {
    width: 100%;
  }

  @media screen and (max-width: 340px) {
    max-width: 100%;
    min-width: 100%;
  }
`;

export const FormTitle = styled.span`
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;

  @media screen and (max-width: 780px) {
    color: white;
  }
`;
