import styled from 'styled-components';

export const StyledModalWrapper = styled.div`
  margin: 0px;
  position: relative;
  width: 100%;

  @media (min-width: 700px) {
    min-width: 450px;
    max-width: 700px;
  }

  @media (max-width: 699px) {
    max-width: 450px;
  }
`;
