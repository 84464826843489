import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationReadButton from './NotificationReadButton';
import {
  DateStringTextContainer,
  StyledChip,
  StyledNotificationContainer,
  StyledNotificationContentContainer,
  StyledNotificationContentContainerWithoutTitle,
  StyledNotificationTitle,
} from './Notifications.styles';
import { SingleNotificationElementProps } from './Notifications.types';

const SingleNotificationElement = (props: SingleNotificationElementProps) => {
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false);
  const [elementHovered, setElementHovered] = useState<boolean>(false);
  const navigate = useNavigate();
  const { onNotificationClick, onNotificationRead, content, height, wide } =
    props;

  const handleReflinkClick = (e: React.MouseEvent) => {
    if (onNotificationClick) {
      onNotificationClick(e);
    }
    if (onNotificationRead && !content.isRead) {
      onNotificationRead();
    }

    if (content.referenceLink) {
      navigate(content.referenceLink);
    }
  };

  return (
    <StyledNotificationContainer
      height={height}
      isRead={content.isRead}
      onMouseLeave={() => setIsButtonVisible(false)}
      onMouseEnter={() => setIsButtonVisible(true)}
    >
      <StyledNotificationContentContainer
        wide={wide}
        // onClick={handleReflinkClick}
        onMouseEnter={() => setElementHovered(true)}
        onMouseLeave={() => setElementHovered(false)}
      >
        <Box display="flex">
          <StyledNotificationTitle elementHovered={elementHovered}>
            {content.title}
          </StyledNotificationTitle>
        </Box>
        <StyledNotificationContentContainerWithoutTitle wide={wide}>
          <StyledChip label={content.variant} size="small" />
          <Box marginY="auto">
            <DateStringTextContainer>
              {content.dateString}
            </DateStringTextContainer>
          </Box>
        </StyledNotificationContentContainerWithoutTitle>
      </StyledNotificationContentContainer>
      {onNotificationRead && isButtonVisible && (
        <Box sx={{ margin: 'auto 0px auto auto' }}>
          <NotificationReadButton
            checked={content.isRead}
            onNotificationRead={onNotificationRead}
          />
        </Box>
      )}
    </StyledNotificationContainer>
  );
};

export default SingleNotificationElement;
