import { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TechnologyEntry } from '../../../../../components/CV/Technologies/TechnologyEntry/TechnologyEntry.component';
import { actions as cvActions } from '../../../CV.slice';
import { actions as technologyActions } from '../../Technologies/Technologies.slice';

export const TechnologyEntryContainer = forwardRef(
  (
    {
      editable,
      sectionWithParent,
      handleDrag,
      updateElement,
      handleDrop,
      handleDragOver,
      removeSectionRef,
      setTextFormattingPanelVisibility,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const {
      section: { sectionId, defaultData },
      parentSection: { sectionId: parentSectionId },
    } = sectionWithParent;
    const parentData = useSelector((state) =>
      state.cv.present.data.technologies.entries.find(
        (entry) => entry.sectionId === parentSectionId,
      ),
    );
    const data = parentData?.entries.find(
      (entry) => entry.sectionId === sectionId,
    )?.data;

    useEffect(() => {
      if (!data) {
        dispatch(
          technologyActions.createTechnologyData({
            sectionId,
            parentSectionId,
            defaultData,
          }),
        );
      }
    }, [data]);

    const handleEditData = (dataP) => {
      dispatch(
        technologyActions.updateEntryData({
          sectionId,
          parentSectionId,
          data: dataP,
        }),
      );
    };

    const handleRemoveEntry = () => {
      dispatch(
        cvActions.deleteEntry({
          sectionId,
          parentSectionId,
        }),
      );
      dispatch(technologyActions.deleteData({ sectionId, parentSectionId }));
    };

    return (
      <TechnologyEntry
        ref={ref}
        editable={editable}
        sectionId={sectionId}
        removeSectionRef={removeSectionRef}
        setTextFormattingPanelVisibility={setTextFormattingPanelVisibility}
        draggable
        handleDrag={handleDrag}
        updateElement={updateElement}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        handleEditData={handleEditData}
        handleRemoveEntry={handleRemoveEntry}
        data={data || {}}
      />
    );
  },
);
