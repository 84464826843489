import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: 'Neue Haas Grotesk Display Pro';
  }

  body {
    background: white;
  }
`;

export const StyledLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const CloseContainer = styled.div`
  & .MuiButtonBase-root {
    padding: 0px 0px 5px 0px;
  }
`;

export const StyledFormWrapper = styled.div`
  align-items: center;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0px;
  padding: 16px 16px 118px 16px;
  width: 100%;
`;

export const StyledContainer = styled.div`
  width: 801px;

  @media (min-width: 700px) {
    min-width: 450px;
    max-width: 700px;
  }

  @media (max-width: 699px) {
    max-width: 450px;
    padding: 25px 30px 25px 30px;
  }
`;

export const StyledFormTitle = styled.h3`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 24px;
  font-weight: 400;
  line-height: 27px;
`;

export const StyledMessage = styled.h3`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 24px;
  font-weight: 400;
  line-height: 27px;
  padding: 60px 76px 22px 70px;
  text-align: center;
`;
