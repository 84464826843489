import styled from 'styled-components';
import { COLORS } from '../../../../theme/colors';

export const StyledDeleteButton = styled.div`
  color: ${COLORS.accent.primary};
  margin-left: 20px;
  margin-right: 10px;
  position: relative;
  visibility: hidden;
`;

export const StyledTechnologiesEntry = styled.div`
  position: relative;

  :hover ${StyledDeleteButton} {
    visibility: visible;
  }
`;

export const StyledTextDiv = styled.div`
  font-weight: 200;
  max-width: 200px;
  min-width: 120px;

  :empty:before {
    content: attr(placeholder);
    opacity: 0.3;

    @media print {
      opacity: 0;
    }
  }
`;

export const StyledIcon = styled.div`
  cursor: pointer;
  margin-bottom: auto;
  margin-top: auto;
`;
