import { createSlice } from '@reduxjs/toolkit';
import { ReasonTag } from '../../../../store/types/Store.leads.types';
import { profileReasonTagsThunks } from './ProfileReasonTags.thunk';

const { getProfileReasonTagsThunk } = profileReasonTagsThunks;

const initialState: ReasonTag[] = [];

const profileReasonTagsSlice = createSlice({
  name: 'profileReason',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfileReasonTagsThunk.fulfilled, (state, action) => {
      state.length = 0;
      action.payload.forEach((data: ReasonTag) => {
        state.push({ id: data.id, reason: data.reason });
      });
    });
  },
});

export const actions = { ...profileReasonTagsSlice.actions };
export const { reducer } = profileReasonTagsSlice;
