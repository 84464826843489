import TextField from '@mui/material/TextField';
import { FormLabel } from '../../../../../components/common/FormLabel';
import { Tooltip } from '../../../../../components/common/Tooltip/Tooltip.component';
import { addDotsToString } from '../../../../../Helpers';
import {
  StyledAutoComplete,
  StyledChip,
  StyledInputContainer,
  StyledSpan,
} from './TechnologyAutocomplete.styles';
import { TechnologyAutocompleteProps } from './TechnologyAutocomplete.types';

const stringLimit = 36;

export const TechnologyAutocomplete = ({
  options,
  label,
  required,
  name,
  handleInputChange,
  displayAsRequired,
  value,
  ...props
}: TechnologyAutocompleteProps) => {
  return (
    <StyledInputContainer>
      {label && (
        <FormLabel htmlFor={name} label={label} required={displayAsRequired} />
      )}
      <StyledAutoComplete
        multiple
        placeholder="..."
        value={value}
        options={options}
        limitTags={3}
        onChange={(_e, newVal) => handleInputChange(newVal as string[])}
        renderTags={(val, getTagProps) =>
          val.map((option, index) => (
            <StyledChip {...getTagProps({ index })} label={option} />
          ))
        }
        renderOption={(prop: any, option: any) => {
          if (option.length > stringLimit) {
            return (
              <Tooltip title={option} position="close">
                <StyledSpan {...prop}>
                  {addDotsToString(option, stringLimit)}
                </StyledSpan>
              </Tooltip>
            );
          }
          return <StyledSpan {...prop}>{option}</StyledSpan>;
        }}
        isOptionEqualToValue={(option, valueA) => option === valueA}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            maxRows={3}
            name="technologies"
            placeholder="..."
            required={required && value.length === 0}
            multiline
          />
        )}
        disableCloseOnSelect
        {...props}
        noOptionsText={<StyledSpan>No option</StyledSpan>}
      />
    </StyledInputContainer>
  );
};
