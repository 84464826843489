import { createAsyncThunk } from '@reduxjs/toolkit';
import { PersistedStoreKeys } from '../../../config/PersistedStoreKeys';
import { handleResponse } from '../../../utils/api';
import { generateExternalClientListUrl } from '../../../utils/urlGenerators';

const loginThunk = createAsyncThunk(
  'externalClient/login',
  async (params: { id: string; externalClientCode: string }) => {
    const request = fetch(generateExternalClientListUrl(params.id), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'External-Access-Token': params.externalClientCode,
      },
    });
    const response = await handleResponse(request);
    if (Object.keys(response).length) {
      await localStorage.setItem(
        PersistedStoreKeys.externalClientToken,
        params.externalClientCode,
      );
      await localStorage.setItem(
        PersistedStoreKeys.externalClientId,
        params.id,
      );
      return {
        token: params.externalClientCode,
        id: params.id,
      };
    }
    return null;
  },
);

const logoutThunk = createAsyncThunk('externalClient/logout', async () => {
  localStorage.removeItem(PersistedStoreKeys.externalClientId);
  localStorage.removeItem(PersistedStoreKeys.externalClientToken);
});

export const clientListCredentialsThunks = {
  loginThunk,
  logoutThunk,
};
