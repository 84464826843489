import styled from 'styled-components';
import { styled as materialUIStyled } from '@mui/material/styles';
import { Autocomplete } from '@mui/material';
import { COLORS } from '../../../theme/colors';

type StyledAutoCompleteProps = {
  withLeftIcon: boolean;
};

export const StyledAutoComplete = materialUIStyled(
  Autocomplete,
)<StyledAutoCompleteProps>(({ withLeftIcon }) => ({
  background: 'white',
  color: COLORS.typography.main,
  width: '100%',
  minWidth: '240px',
  border: `1px solid ${COLORS.accent.border}`,
  borderRadius: 4,
  height: 50,

  '& .MuiAutocomplete-endAdornment': {
    position: 'relative',
    marginLeft: 'auto',
    right: 0,
    paddingTop: 0,
  },
  '& .MuiAutocomplete-input': {
    fontSize: 14,
    fontFamily: 'Poppins',
    color: COLORS.typography.main,
  },
  '& .MuiInputBase-input-MuiOutlinedInput-input': {
    height: 10,
    margin: 0,
    padding: '0px !important',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    height: 50,
  },
  '& .MuiOutlinedInput-root': {
    padding: withLeftIcon
      ? '7px 0px 0px 50px !important'
      : '7px 0px 0px 9px !important',
  },
}));

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';
  font-size: 14px;
  position: relative;
  width: 100%;
`;

export const StyledSpan = styled.span`
  font-family: 'Poppins';
  font-size: 14px;
`;

export const InputIcon = styled.span`
  bottom: 7px;
  left: 14px;
  position: absolute;
`;
