import { createSlice } from '@reduxjs/toolkit';
import { idGenerator } from '../../../../utils/idGenerator';
import { actions as cvActions } from '../../CV.slice';
import { cvThunks } from '../../CV.thunk';

const { loadCvThunk, injectCvDataThunk } = cvThunks;

const initialState = {
  entries: [],
};

const customSectionSlice = createSlice({
  name: 'customSections',
  initialState,
  reducers: {
    createData: (state, action) => {
      const sectionId = action.payload;
      state.entries.push({
        sectionId,
        headerText: 'Custom section',
        entries: [],
      });
    },
    createEntryData: (state, action) => {
      const { sectionId, parentSectionId } = action.payload;

      const parent = state.entries.find(
        (entry) => entry.sectionId === parentSectionId,
      );
      const entries = parent ? parent.entries : state.entries;

      if (parent) {
        entries.push({
          sectionId,
          timePeriod: '',
          entries: [...new Array(3).keys()].map(() => ({
            entryId: idGenerator(),
            firstPart: '',
            secondPart: '',
          })),
        });
        return;
      }

      entries.push({
        sectionId: parentSectionId,
        headerText: 'Custom section',
        entries: [
          {
            sectionId,
            timePeriod: '',
            entries: [...new Array(3).keys()].map(() => ({
              entryId: idGenerator(),
              firstPart: '',
              secondPart: '',
            })),
          },
        ],
      });
    },
    createInnerEntry: (state, action) => {
      const { sectionId, parentSectionId } = action.payload;

      const parentEntries = state.entries.find(
        (entry) => entry.sectionId === parentSectionId,
      ).entries;
      const innerEntries = parentEntries.find(
        (entry) => entry.sectionId === sectionId,
      ).entries;
      innerEntries.push({
        entryId: idGenerator(),
        firstPart: '',
        secondPart: '',
      });
    },
    deleteInnerEntry: (state, action) => {
      const { sectionId, parentSectionId, entryId } = action.payload;

      const parentEntries = state.entries.find(
        (entry) => entry.sectionId === parentSectionId,
      ).entries;
      const innerEntries = parentEntries.find(
        (entry) => entry.sectionId === sectionId,
      ).entries;
      const targetIndex = innerEntries.findIndex(
        (entry) => entry.entryId === entryId,
      );
      innerEntries.splice(targetIndex, 1);
    },
    editInnerEntry: (state, action) => {
      const { sectionId, parentSectionId, entryId, data } = action.payload;

      const parentEntries = state.entries.find(
        (entry) => entry.sectionId === parentSectionId,
      ).entries;
      const innerEntries = parentEntries.find(
        (entry) => entry.sectionId === sectionId,
      ).entries;
      const targetEntry = innerEntries.find(
        (entry) => entry.entryId === entryId,
      );

      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in data) {
        targetEntry[key] = data[key];
      }
    },
    updateHeaderText: (state, action) => {
      const { sectionId, text } = action.payload;
      const targetData = state.entries.find(
        (entry) => entry.sectionId === sectionId,
      );
      targetData.headerText = text;
    },
    updateEntryData: (state, action) => {
      const { sectionId, data, parentSectionId } = action.payload;

      const parentEntries = state.entries.find(
        (entry) => entry.sectionId === parentSectionId,
      ).entries;
      const targetData = parentEntries.find(
        (entry) => entry.sectionId === sectionId,
      );

      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in data) {
        targetData[key] = data[key];
      }
    },
    deleteData: (state, action) => {
      const { sectionId, parentSectionId } = action.payload;
      const parentEntries = state.entries.find(
        (entry) => entry.sectionId === parentSectionId,
      ).entries;
      const index = parentEntries.findIndex(
        (entry) => entry.sectionId === sectionId,
      );
      parentEntries.splice(index, 1);
    },
    reset: () => initialState,
  },
  extraReducers: {
    [cvActions.reset]: () => initialState,
    [loadCvThunk.fulfilled]: (state, action) => {
      const { editor_content: sectionsData } = action.payload;
      return sectionsData.customSections;
    },
    [injectCvDataThunk.fulfilled]: (state, action) => {
      const { editor_content: sectionsData } = action.payload;
      return sectionsData.customSections;
    },
  },
});

export const { actions } = customSectionSlice;
export const { reducer } = customSectionSlice;
