import { createSlice } from '@reduxjs/toolkit';
import { actions as cvActions } from '../../CV.slice';
import { cvThunks } from '../../CV.thunk';

const { loadCvThunk, injectCvDataThunk } = cvThunks;

const initialState = {
  headerText: 'Additional info',
  entries: [],
};

const additionalInfoSlice = createSlice({
  name: 'additionalInfo',
  initialState,
  reducers: {
    createData: (state, action) => {
      const sectionId = action.payload;
      state.entries.push({
        sectionId,
        data: {
          additionalInfo: '',
        },
      });
    },
    updateHeaderText: (state, action) => {
      state.headerText = action.payload;
    },
    updateEntryData: (state, action) => {
      const { sectionId, data, parent } = action.payload;

      const entryData = state.entries.find(
        (entry) => entry.sectionId === sectionId,
      ).data;
      const targetData = parent ? entryData[parent] : entryData;

      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in data) {
        targetData[key] = data[key];
      }
    },

    reset: () => initialState,
  },
  extraReducers: {
    [cvActions.reset]: () => initialState,
    [loadCvThunk.fulfilled]: (state, action) => {
      const { editor_content: sectionsData } = action.payload;
      return sectionsData.additionalInfo;
    },
    [injectCvDataThunk.fulfilled]: (state, action) => {
      const { editor_content: sectionsData } = action.payload;
      return sectionsData.additionalInfo;
    },
  },
});

export const { actions } = additionalInfoSlice;
export const { reducer } = additionalInfoSlice;
