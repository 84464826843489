import styled from 'styled-components';

export const CompanyAtributesContainer = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const LogoDropZone = styled.div`
  width: 100%;
`;

export const StyledMultiline = styled.div`
  margin-bottom: 20px;
`;

export const ColorContainer = styled.div`
  width: 100%;
`;

export const StyledPicker = styled.div`
  margin: 20px 0;
  width: 100%;
`;

export const FilesListItem = styled.li`
  border: 1px solid #e6ebef;
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  padding: 24px 33px;
  & > span {
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
    font-size: 13px;
  }
  &:last-child {
    border-bottom: 1px solid #e6ebef;
  }
  & > a {
    color: currentColor;
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
    font-size: 13px;
    text-decoration: none;
  }
`;

export const FilesListRemoveButton = styled.span`
  color: #f85454;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const FilesListItemTitle = styled.span`
  align-items: center;
  display: flex;
`;

export const LogoPreview = styled.img`
  margin-top: 15px;
  max-height: 150px;
  max-width: 200px;
`;
