import { AnyAction } from '@reduxjs/toolkit';
import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffectAsync } from '../../../../utils/useEffectAsync';
import { clientListsThunks } from '../../redux/ClientList.thunk';
import { clientListCredentialsThunks } from '../../redux/ClientListCredentials.thunk';
import { MOBILE_BREAKPOINT } from './ClientLoginPage.config';
import { LoginDesktop } from './LoginDesktop';
import { LoginMobile } from './LoginMobile';

export const ClientLoginPage = () => {
  const [password, setPassword] = useState<string>('');
  const [listExists, setListExists] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();

  useEffectAsync(async () => {
    if (params.id) {
      const result = await dispatch(
        clientListsThunks.checkIfClientListExistsThunk(
          params.id,
        ) as unknown as AnyAction,
      );
      if (
        result.type ===
        clientListsThunks.checkIfClientListExistsThunk.fulfilled.type
      ) {
        setListExists(true);
      }
    }
  }, []);

  const handlePasswordChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPassword(e.target.value);
  };

  const submitCode = async (id: string, externalClientCode: string) => {
    await dispatch(
      clientListCredentialsThunks.loginThunk({
        id,
        externalClientCode,
      }) as unknown as AnyAction,
    );
  };

  const screenWidth = window.innerWidth;
  if (params.id) {
    if (screenWidth < MOBILE_BREAKPOINT) {
      return (
        <LoginMobile
          disabled={!listExists}
          password={password}
          handlePasswordChange={handlePasswordChange}
          submitCode={submitCode}
          id={params.id}
          screenWidth={screenWidth}
        />
      );
    }
    return (
      <LoginDesktop
        disabled={!listExists}
        password={password}
        handlePasswordChange={handlePasswordChange}
        submitCode={submitCode}
        id={params.id}
        screenWidth={screenWidth}
      />
    );
  }
};
