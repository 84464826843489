import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const StyledLogo = styled.img`
  border: 1px solid ${COLORS.background.primary};
  border-radius: 50%;
  height: 64px;
  width: 64px;
`;
