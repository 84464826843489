import { createContext } from 'react';
import { WebSocketEventAPI, WebSocketEventCtxProps } from './eventTypes';

export const WebSocketEventContext = createContext<WebSocketEventCtxProps>(
  {} as WebSocketEventCtxProps,
);

export const addNewWebSocketEvent = async (
  ctx: WebSocketEventCtxProps,
  event: WebSocketEventAPI,
) => {
  const newEvents = {
    ...ctx.onMessageEvents,
    [event.name]: { category: event.category, callback: event.callback },
  };

  if (ctx.onMessageEvents[event.name]) {
    return;
  }
  ctx.setOnMessageEvents(newEvents);
  ctx.onMessageEvents = newEvents;
};
