export function findSectionWithParent(sectionId, section, parent) {
  if (sectionId === section.sectionId) {
    const sectionIndex = parent.entries.indexOf(section);
    return {
      sectionIndex,
      section,
      parentSection: parent,
    };
  }

  const children = section?.entries;

  for (let i = 0; i < children?.length || 0; i++) {
    const currentChild = children[i];
    const result = findSectionWithParent(sectionId, currentChild, section);

    if (result !== false) {
      return result;
    }
  }

  return false;
}
