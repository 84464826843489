import { Box } from '@mui/material';
import { BaseModalContainer } from '../../../../../utils/BaseModalContainer.component';
import { EmployeeModalProps } from './EmployeesModal.types';
import { EmployeeModalDispatcher } from './EmployeesModalDispatcher';

export const EmployeesModalFactory = (props: EmployeeModalProps) => {
  const { modalType, onModalClose, employeeId } = props;

  if (modalType === 'none') {
    return null;
  }

  return (
    <BaseModalContainer onClose={onModalClose}>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: '#FFF',
          borderRadius: '20px',
          flexDirection: 'column',
          paddingY: '25px',
          paddingX: '15px',
        }}
      >
        <EmployeeModalDispatcher
          employeeId={employeeId}
          modalToDisplay={modalType}
          onModalClose={onModalClose}
        />
      </Box>
    </BaseModalContainer>
  );
};
