import type { Employees } from './Employees.types';
import type {
  ClientListConfig,
  ClientListProfileState,
  ClientListState,
  ExternalClientCredentials,
} from './Store.clientList.types';
import { CompanySliceInterface } from './Store.company';
import type { CvState } from './Store.CvState.types';
import { EmployeePageState } from './Store.employeePage.types';
import type { Guest } from './Store.guest.types';
import type { HomeLeadProfiles } from './Store.home.types';
import { HrListState } from './Store.hrList.types';
import type { LeadsState } from './Store.leads.types';
import type { Partner } from './Store.partner.types';
import type { UserState } from './Store.user.types';

export interface AppStore {
  user: UserState;
  home: HomeLeadProfiles;
  myVacancies: HomeLeadProfiles;
  employees: Employees;
  guest: Guest;
  cv: CvState;
  leadsRelated: LeadsState;
  partner: Partner;
  apiController: Record<string, unknown>;
  clientListRelated: ClientsList;
  hrLists: HrListState;
  employeePage: EmployeePageState;
  company: CompanySliceInterface;
}

interface ClientsList {
  clientList: ClientListState;
  clientListProfile: ClientListProfileState;
  config: ClientListConfig;
  credentials: ExternalClientCredentials;
}

export type PriorityType = 'low' | 'medium' | 'high' | 'very high';

export enum Currency {
  EUR = '€',
  USD = '$',
  GBP = '￡',
  PLN = 'zł',
}

// https://stackoverflow.com/questions/49285864/
export type ValueOf<T> = T[keyof T];
