import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';

export const StyledDate = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 236px;
`;

export const StyledDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 256px;
  width: 50%;
`;

export const StyledDeleteSubsectionButton = styled.div`
  bottom: 30px;
  color: ${COLORS.accent.primary};
  float: right;
  left: 35px;
  position: relative;
  visibility: hidden;
`;

export const StyledAddSubsectionButtons = styled.div`
  color: ${COLORS.accent.primary};
  display: flex;
  visibility: hidden;
`;

export const StyledDeleteButton = styled.div`
  color: ${COLORS.accent.primary};
  display: inline-block;
  left: 30px;
  position: relative;
  visibility: hidden;
`;

export const StyledData = styled.div`
  border: ${(props) => (props.error ? '3px solid red' : '0')};
  display: flex;
  padding-right: 80px;

  :hover {
    ${StyledDeleteSubsectionButton}, ${StyledAddSubsectionButtons}, ${StyledDeleteButton} {
      visibility: visible;
    }
  }

  @media print {
    border: 0;
    overflow-y: hidden;
  }
`;

export const StyledIcon = styled.div`
  cursor: pointer;
`;

export const StyledTimePeriod = styled.div`
  font-weight: 200;
  width: 300px;

  :empty:before {
    content: attr(placeholder);
    opacity: 0.3;

    @media print {
      opacity: 0;
    }
  }
`;

export const StyledInfo = styled.div`
  margin-left: 76px;
`;

export const StyledMinorInfo = styled.div`
  padding-top: 10px;
`;

export const StyledLargeHeader = styled.div`
  float: left;
  font-weight: 700;
  padding-right: 5px;
`;

export const StyledSmallHeader = styled.span`
  float: left;
  font-weight: 400;
  padding-right: 5px;
`;

export const StyledLargeTextDiv = styled.div`
  flex: 1;
  font-weight: 700;
  width: 650px;

  & > div {
    font-weight: 700;
  }

  :empty:before {
    content: attr(placeholder);
    opacity: 0.3;

    @media print {
      opacity: 0;
    }
  }
`;

export const StyledTextDiv = styled.div`
  flex: 1;
  font-weight: 200;
  width: 650px;

  :empty:before {
    content: attr(placeholder);
    opacity: 0.3;

    @media print {
      opacity: 0;
    }
  }
`;

export const StyledInput = styled.input`
  border-width: 0px;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 200;
  max-width: ${({ present }) => (present ? '90px' : '200px')};
`;

export const StyledOldDate = styled.span`
  color: red;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 400;
  opacity: 0.7;
`;

export const StyledError = styled.span`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: italic;
  font-weight: 200;
  opacity: 0.7;
`;

export const CheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
  margin-left: 0px;
`;

export const StyledLabel = styled.span`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 200;
  opacity: 0.7;
`;

export const StyledPresentLabel = styled.div`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 200;
  min-width: 100px;
`;

export const StyledNumberFormatContainer = styled.div`
  display: flex;
`;
