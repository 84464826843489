import styled from 'styled-components';

export const DropdownWrapper = styled.section`
  min-width: 20px;
`;

export const CandidateName = styled.p`
  display: table-cell;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  max-width: 150px;
  min-width: 150px;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
`;

export const PayRate = styled.p`
  display: table-cell;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  min-width: 110px;
  padding: 0 10px;
  vertical-align: middle;
  white-space: nowrap;
`;

export const StyledWrapper = styled.div`
  display: table-row;
  position: relative;
`;

export const StyledLoader = styled.div`
  position: relative;
`;

export const CellWrapper = styled.div`
  display: table-cell;
  max-height: 25px;
  vertical-align: middle;
`;
