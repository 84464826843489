import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../Tooltip/Tooltip.component';
import { StyledButton } from './IconButton.styles';
import { IconButtonProps } from './IconButton.types';

export const DownloadButton = ({
  action,
  title,
  disabled,
}: IconButtonProps) => {
  const { t } = useTranslation(['common'], { keyPrefix: 'buttons' });

  if (disabled) {
    return (
      <StyledButton disabled={disabled}>
        <FileDownloadOutlinedIcon style={{ fontSize: '16px' }} />
      </StyledButton>
    );
  }

  return (
    <Tooltip title={title || t('download')} position="table">
      <StyledButton onClick={action} disabled={disabled}>
        <FileDownloadOutlinedIcon style={{ fontSize: '16px' }} />
      </StyledButton>
    </Tooltip>
  );
};
