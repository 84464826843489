import styled from 'styled-components';
import { COLORS } from '../../../../theme/colors';

export const StyledAddColumnButton = styled.div`
  visibility: hidden;
  position: absolute;
  top: 20px; //todo: fix this
  left: -30px;
  padding-right: 20px;
  padding-bottom: 30px;
  color: ${COLORS.accent.primary};
`;

export const StyledContainer = styled.div`
  position: relative;

  &:hover ${StyledAddColumnButton} {
    visibility: visible;
  }
`;

export const StyledIcon = styled.div`
  cursor: pointer;
`;
