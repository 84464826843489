export const API_URL =
  process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000/api/';
export const WEBSOCKET_URL =
  process.env.REACT_APP_WEBSOCKET_BASE_URL || 'ws://127.0.0.1:8000/';

export const API_USERS = `users/`;
export const API_HR_USERS = `${API_USERS}choices`;
export const API_SALES_USERS = `${API_HR_USERS}?department=sales`;
export const API_PARTNER_USERS = `${API_HR_USERS}?department=PARTNER`;
export const API_LOGIN_URL = `${API_USERS}login/`;
export const API_REGISTER_URL = `${API_USERS}register/`;
export const API_COMPANY_URL = `${API_URL}${API_USERS}company/`;
export const API_REGISTER_COMPANY_URL = `${API_URL}${API_USERS}register/`;
export const API_CHANGE_PASSWORD = `${API_USERS}change-password/`;
export const API_RESET_PASSWORD = `${API_URL}${API_USERS}reset-password/confirm/`;
export const API_REQUEST_CHANGE_PASSWORD = `${API_URL}${API_USERS}reset-password/`;

export const API_PARTNER = `${API_USERS}partners/`;

export const API_CVS_URL = `${API_URL}cvs/`;
export const API_EMPLOYEES_URL = `${API_URL}employees/`;
export const API_SEARCH_URL = `${API_URL}search/`;
export const API_SUGGESTED_SOURCES_URL = `${API_EMPLOYEES_URL}suggested-sources/`;
export const API_CV_LOAD = `${API_URL}/`;
export const API_GENERATE_DOCUMENT = `${API_CVS_URL}generate-document/`;
export const API_EMPLOYEE_ATTACHMENTS = `${API_EMPLOYEES_URL}attachments/`;

export const API_HR_LISTS_URL = `${API_CVS_URL}hr_lists/`;
export const API_UPDATE_EMPLOYEES_IN_HR_LIST = `${API_URL}cvs/hr_lists/`;
export const API_ADD_EMPLOYEE_TO_HR_LIST = `${API_URL}cvs/hr_lists/`;
export const API_REJECTION_REASONS = `${API_CVS_URL}client_rejections/`;

export const API_ALL_USERS = `${API_URL}${API_USERS}`;
export const API_COMPANY_MEMBERS = `${API_URL}${API_USERS}company-member/`;
export const API_COMPANY_MEMBERS_INVITE = `${API_URL}${API_USERS}invitation/`;

export const API_LANGUAGES_LIST = `${API_EMPLOYEES_URL}languages/`;
export const API_COUNTRIES_LIST = `${API_URL}${API_USERS}country/`;
export const API_COMPANY_SIZES_LIST = `${API_URL}${API_USERS}company-size/`;

export const API_LEADS = `${API_URL}leads/`;
export const API_LEADS_ATTACHMENTS = `${API_LEADS}attachments/`;
export const API_LEAD_TAGS = `${API_LEADS}lead_tags/`;
export const API_PROFILE_TAGS = `${API_LEADS}profile_tags/`;
export const API_MAIN_TECHNOLOGIES = `${API_LEADS}main_technology_tags/`;
export const API_INDUSTRY_TAGS = `${API_LEADS}lead_industry_tags/`;
export const API_PROFILE_REASON_TAGS = `${API_LEADS}lead_profile_status_reason_tags/`;
export const API_CANDIDATE_REASON_TAGS = `${API_LEADS}status_reason_tags/`;

export const API_HOME_PAGE = `${API_URL}home/`;
export const API_HOME_PAGE_STATS = `${API_HOME_PAGE}stats/`;

export const API_EVENT_TICKET = `${API_URL}events/ticket/`;
export const WEBSOCKET_EVENTS_ENDPOINT = `${WEBSOCKET_URL}ws/events`;
export const API_NOTIFICATIONS = `${API_URL}notifications/`;

// client lists
export const API_CLIENT_LIST = `${API_URL}client_lists/`;
export const API_PARTNER_LISTS = `${API_CLIENT_LIST}partner/`;
