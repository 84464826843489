import { createSlice } from '@reduxjs/toolkit';
import { actions as cvActions } from '../../CV.slice';
import { cvThunks } from '../../CV.thunk';

const { loadCvThunk, injectCvDataThunk } = cvThunks;

const initialState = {
  headerText: '',
  entries: [],
};

const technologiesSlice = createSlice({
  name: 'technologies',
  initialState,
  reducers: {
    createTechnologyData: (state, action) => {
      const { sectionId, parentSectionId, defaultData } = action.payload;

      const parent = state.entries.find(
        (entry) => entry.sectionId === parentSectionId,
      );
      const entries = parent ? parent.entries : state.entries;

      if (parent) {
        entries.push({
          sectionId,
          data: {
            technologyName: defaultData?.technologyName || '',
          },
        });
        return;
      }

      entries.push({
        sectionId: parentSectionId,
        entries: [
          {
            sectionId,
            data: {
              technologyName: defaultData?.technologyName || '',
            },
          },
        ],
        data: {
          technologyGroupName: '',
        },
      });
    },
    createTechnologyGroupData: (state, action) => {
      const { sectionId, defaultData } = action.payload;

      const technologyGroup = state.entries.find(
        (entry) => entry.sectionId === sectionId,
      );

      if (technologyGroup) {
        technologyGroup.data.technologyGroupName =
          defaultData?.technologyGroupName;
        return;
      }

      state.entries.push({
        sectionId,
        data: {
          technologyGroupName: defaultData?.technologyGroupName,
        },
      });
    },
    updateHeaderText: (state, action) => {
      state.headerText = action.payload.data;
    },
    updateEntryData: (state, action) => {
      const { parentSectionId, sectionId, data } = action.payload;
      const parent = parentSectionId
        ? state.entries.find((entry) => entry.sectionId === parentSectionId)
            .entries
        : state.entries;
      const entryData = parent.find(
        (entry) => entry.sectionId === sectionId,
      ).data;
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in data) {
        entryData[key] = data[key];
      }
    },
    deleteData: (state, action) => {
      const { parentSectionId, sectionId } = action.payload;
      const parent = parentSectionId
        ? state.entries.find((entry) => entry.sectionId === parentSectionId)
            ?.entries
        : state.entries;

      if (!parent) {
        return;
      }

      const index = parent.findIndex((entry) => entry.sectionId === sectionId);
      parent.splice(index, 1);
    },
    reset: () => initialState,
  },
  extraReducers: {
    [cvActions.reset]: () => initialState,
    [loadCvThunk.fulfilled]: (state, action) => {
      const { editor_content: sectionsData } = action.payload;
      return sectionsData.technologies;
    },
    [injectCvDataThunk.fulfilled]: (state, action) => {
      const { editor_content: sectionsData } = action.payload;
      return sectionsData.technologies;
    },
  },
});

export const { actions } = technologiesSlice;
export const { reducer } = technologiesSlice;
