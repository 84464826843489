import styled from 'styled-components';
import { COLORS } from '../../../../theme/colors';

export const StyledAddCustomInfoButton = styled.div`
  color: ${COLORS.accent.primary};
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
`;

export const StyledIcon = styled.div`
  cursor: pointer;
`;

export const StyledContainer = styled.div`
  display: inline-block;
  position: relative;

  &:hover {
    ${StyledAddCustomInfoButton} {
      visibility: visible;
    }
  }
`;

export const StyledPositionDescription = styled.div`
  font-weight: 200;
  width: 600px;

  :empty:before {
    content: attr(placeholder);
    font-weight: 200;
    opacity: 0.3;

    @media print {
      opacity: 0;
    }
  }
`;
