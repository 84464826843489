import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 150px;
  position: relative;
  width: 100%;
  @media (max-width: 686px) {
    width: 100%;
    max-width: 686px;
  }
`;

export const StyledInput = styled.input`
  border: 1px solid ${COLORS.accent.border};
  border-radius: 4px;
  font-family: 'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 50px;
  padding: 5px 16px 5px 55px;
  width: 100%;

  &::placeholder {
    color: ${COLORS.stroke.secondary};
    font-weight: 400;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 686px) {
    width: 100%;
    max-width: 686px;
  }
`;

export const InputIcon = styled.span`
  bottom: 7px;
  left: 14px;
  position: absolute;
`;
