import CancelIcon from '@mui/icons-material/Cancel';
import { StyledChip, StyledIconButton } from './CandidateChip.styles';

type Props = {
  label: string;
  handleClick?: (option: any) => void;
  option?: any;
  color?: string;
  background?: string;
};

export const CandidateChip = ({
  label,
  handleClick,
  option,
  color,
  background,
  ...props
}: Props) => {
  return (
    <StyledChip
      label={label}
      onDelete={handleClick ? () => handleClick(option) : undefined}
      deleteIcon={
        <StyledIconButton>
          <CancelIcon
            sx={{
              fontSize: 16,
            }}
          />
        </StyledIconButton>
      }
      customcolor={color}
      custombackground={background}
      {...props}
    />
  );
};
