import { createAsyncThunk } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import { API_CVS_URL, API_GENERATE_DOCUMENT } from '../../config/urls';
import { getAuthHeader, handleResponse } from '../../utils/api';
import { raiseToast } from '../../utils/raiseToasts';
import { sanitize } from '../../utils/sanitizeFilename';
import {
  CommonPdfThunkArgs,
  CreateDocx,
  DownloadPdfThunkArgs,
  SaveNewCvArgs,
  UpdateCvThunkArgs,
} from './CV.thunk.types';
import i18n from '../../i18n';

const loadCvThunk = createAsyncThunk<unknown, CommonPdfThunkArgs>(
  'cv/load',
  async (data, { getState, dispatch }) => {
    const request = fetch(`${API_CVS_URL}${data.cvId}/state`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);

const injectCvDataThunk = createAsyncThunk('cv/inject', async (data) => {
  return data;
});

const createDocxThunk = createAsyncThunk<unknown, CreateDocx>(
  'cv/save',
  async ({ data, filename }, { dispatch }): Promise<CreateDocx> => {
    const request = fetch(`${API_GENERATE_DOCUMENT}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        converter: 'docx',
        file_format: 'docx',
        structure: 'DEFAULT',
        validation: false,
        template_name: 'idego',
        content: data,
      }),
    });

    const response = await handleResponse(request, dispatch);

    try {
      saveAs(response, sanitize(filename, '') + '.docx');
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(e);
    }
    return response;
  },
);

const saveNewCvThunk = createAsyncThunk<unknown, SaveNewCvArgs>(
  'cv/save',
  async (data, { getState, dispatch }) => {
    const request = fetch(`${API_CVS_URL}`, {
      method: 'POST',
      headers: {
        ...getAuthHeader(getState()),
      },
      body: JSON.stringify({
        editor_config: data.editor_config,
        editor_content: data.editor_content,
        employee: data.employee,
        is_shared: data.is_shared,
      }),
    });

    const result = await handleResponse(request, dispatch);
    const message = i18n.t('raiseToastMessage.cv_saved', {
      ns: 'common',
    });
    raiseToast.success(message);
    return result;
  },
);

const updateCvThunk = createAsyncThunk<unknown, UpdateCvThunkArgs>(
  'cv/update',
  async (
    { cvId, editor_config, editor_content, is_shared, optionalToastMessage },
    { getState, dispatch },
  ) => {
    const request = fetch(`${API_CVS_URL}${cvId}/state`, {
      method: 'PUT',
      headers: {
        ...getAuthHeader(getState()),
      },
      body: JSON.stringify({
        editor_config,
        editor_content,
        is_shared,
      }),
    });

    await handleResponse(request, dispatch);

    const toastMessage =
      optionalToastMessage ??
      i18n.t('raiseToastMessage.cv_updated', {
        ns: 'common',
      });
    raiseToast.success(toastMessage);
  },
);

const downloadPdfThunk = createAsyncThunk<unknown, DownloadPdfThunkArgs>(
  'CV/downloadPdf',
  async ({ html, filename }, { dispatch }) => {
    const request = await fetch(API_GENERATE_DOCUMENT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        converter: 'pdf',
        content: html,
      }),
    });
    const response = await handleResponse(request, dispatch);
    try {
      saveAs(response, sanitize(filename, '') + '.pdf');
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(e);
    }
  },
);

const deleteCVThunk = createAsyncThunk<unknown, CommonPdfThunkArgs>(
  'cv/delete',
  async (data, { getState, dispatch }) => {
    const request = fetch(`${API_CVS_URL}${data.cvId}/`, {
      method: 'DELETE',
      headers: getAuthHeader(getState()),
    });

    const result = await handleResponse(request, dispatch);
    const message = i18n.t('raiseToastMessage.cv_deleted', {
      ns: 'common',
    });
    raiseToast.success(message);
    return result;
  },
);

const copyCVThunk = createAsyncThunk<unknown, CommonPdfThunkArgs>(
  'cv/copy',
  async (data, { getState, dispatch }) => {
    const request = fetch(`${API_CVS_URL}${data.cvId}/copy`, {
      method: 'POST',
      headers: getAuthHeader(getState()),
    });

    const result = await handleResponse(request, dispatch);
    const message = i18n.t('raiseToastMessage.cv_copied', {
      ns: 'common',
    });
    raiseToast.success(message);
    return result;
  },
);

export const cvThunks = {
  downloadPdfThunk,
  loadCvThunk,
  saveNewCvThunk,
  updateCvThunk,
  deleteCVThunk,
  copyCVThunk,
  createDocxThunk,
  injectCvDataThunk,
};
