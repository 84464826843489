import { Typography } from '@mui/material';
import {
  ErrorsWrapper,
  StyledOptionItem,
} from './CandidateOptionItemWithStatus.styles';
import { CandidateOptionItemWithStatusProps } from './CandidateOptionItemWithStatus.types';

export const CandidateOptionItemWithStatus = ({
  option,
  props,
}: CandidateOptionItemWithStatusProps) => (
  <StyledOptionItem
    key={option.id}
    color={!option.experience_valid && option.cvs.length > 0 ? 'red' : 'black'}
    {...props}
  >
    {`${option.first_name || option.profile} ${option.last_name} - ${
      option.readable_id
    }`}
    <ErrorsWrapper>
      {option?.experience_valid === false && option.cvs.length > 0 && (
        <Typography>!</Typography>
      )}
      {option.cvs.length === 0 && (
        <Typography sx={{ fontSize: 12 }}>no CVs</Typography>
      )}
      {option?.is_active === false && (
        <Typography sx={{ fontSize: 12 }}>inactive</Typography>
      )}
    </ErrorsWrapper>
  </StyledOptionItem>
);
