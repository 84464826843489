import { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TechnologyHeader } from '../../../../../components/CV/Technologies/TechnologyHeader/TechnologyHeader.component';
import { SECTION_TYPE } from '../../../CV.config';
import { actions as cvActions } from '../../../CV.slice';
import { actions as technologyActions } from '../Technologies.slice';

export const TechnologyHeaderContainer = forwardRef(
  (
    {
      editable,
      sectionWithParent,
      handleDrag,
      handleDrop,
      handleDragOver,
      children,
      updateElement,
      removeSectionRef,
      setTextFormattingPanelVisibility,
    },
    ref,
  ) => {
    const dispatch = useDispatch();

    const {
      section: { sectionId, defaultData },
      parentSection: { parentSectionId },
    } = sectionWithParent;

    const technologyGroup = useSelector((state) =>
      state.cv.present.data.technologies.entries.find(
        (entry) => entry.sectionId === sectionId,
      ),
    );
    const technologyGroupName = technologyGroup?.data.technologyGroupName;

    useEffect(() => {
      if (!technologyGroup) {
        dispatch(
          technologyActions.createTechnologyGroupData({
            sectionId,
            defaultData,
          }),
        );
      }
    }, [technologyGroup]);

    const handleEditData = (data) => {
      dispatch(
        technologyActions.updateEntryData({
          sectionId,
          data: {
            technologyGroupName: data,
          },
        }),
      );
    };

    const handleRemoveEntry = () => {
      dispatch(
        cvActions.deleteEntry({
          sectionId,
          parentSectionId,
        }),
      );
      dispatch(technologyActions.deleteData({ sectionId }));
    };

    const handleAddEntry = () => {
      dispatch(
        cvActions.addEntry({
          sectionId,
          sectionType: SECTION_TYPE.TECHNOLOGY_ENTRY,
        }),
      );
    };

    return (
      <TechnologyHeader
        ref={ref}
        editable={editable}
        sectionId={sectionId}
        removeSectionRef={removeSectionRef}
        technologyGroupName={technologyGroupName}
        handleDrag={handleDrag}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        handleEditData={handleEditData}
        handleRemoveEntry={handleRemoveEntry}
        handleAddEntry={handleAddEntry}
        updateElement={updateElement}
        setTextFormattingPanelVisibility={setTextFormattingPanelVisibility}
      >
        {children}
      </TechnologyHeader>
    );
  },
);
