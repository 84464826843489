import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled as materialUIStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { DialogButton } from '../../../Buttons/DialogButton/DialogButton.component';

export const StyledCurrency = styled.label`
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 15px;
  padding-top: 32px;
`;

export const RateOfPayContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
  width: calc(100% - 10px);
`;

export const MainTechnologiesContainer = styled.section`
  margin-top: 20px;
  @media (max-width: 699px) {
    margin-top: 45px;
  }
`;

export const StyledSelect = materialUIStyled(Select)({
  background: 'white',
  width: '100%',
  minWidth: '300px',
  border: '1px solid #d7ddea',
  borderRadius: 3,

  '& .MuiSelect-select': {
    fontSize: 13,
    fontFamily: 'Neue Haas Grotesk Display Pro',
    padding: '12px 15px',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
    marginRight: '0px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    height: 45,
  },
  '& .MuiOutlinedInput-root': {
    padding: '5px 0px 0px 9px !important',
  },
});

export const CurrencySelect = materialUIStyled(Select)({
  background: 'white',
  minWidth: '70px',
  height: '40px',
  border: '1px solid #d7ddea',
  borderRadius: 3,

  '& .MuiSelect-select': {
    fontSize: 13,
    fontFamily: 'Neue Haas Grotesk Display Pro',
    padding: '12px 15px',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
    marginRight: '0px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    height: 20,
  },
  '& .MuiOutlinedInput-root': {
    padding: '0px 0px 0px 9px !important',
  },
});

export const StyledMenuItem = materialUIStyled(MenuItem)({
  background: 'white',
  width: '100%',
  minWidth: '300px',
  height: 35,
  fontFamily: 'Poppins',
  fontSize: 12,
});

export const CurrencyMenuItem = materialUIStyled(MenuItem)({
  background: 'white',
  minWidth: '70px',
  height: 35,
  fontFamily: 'Poppins',
  fontSize: 12,
});

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 13px;
  margin-bottom: 20px;
`;

export const StyledFormTitle = styled.h3`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  overflow: hidden;
  padding-bottom: 12px;
  padding-right: 6px;
  text-overflow: ellipsis;
`;

export const StyledFormContainer = styled.form`
  padding-left: 25px;
`;

export const StyledFormRowContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
  padding-top: 10px;
  width: 100%;
`;

export const StyledEmployeeFormContainer = styled.div`
  max-height: 70vh;
  overflow-x: clip;
  overflow-y: auto;
  padding-bottom: 40px;
  padding-right: 25px;
`;
export const StyledFormFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
`;

//export const StyledSubmitButon = styled.input.attrs({ type: 'submit' })`
export const StyledSubmitButon = materialUIStyled(DialogButton)({});
