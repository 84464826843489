import { modalSizes, wideModals } from '../../Modal.config';
import { StyledModal } from '../../Modal.styles';
import { UpdateCooperationStatusModalContent } from '../UpdateCooperationStatusModalContent/UpdateCooperationStatusModalContent.component';
import { InviteCompanyModalContent } from '../InviteCompanyModalContent/InviteCompanyModalContent.component';
import { StyledModalWrapper } from './PartnerCompaniesModal.styles';
import { PartnerCompaniesModalProps } from './PartnerCompaniesModal.types';

export const PartnerCompaniesModal = ({
  modalFor,
  company,
  sendInvitation,
  openModal,
  closeModal,
  stopCooperation,
}: PartnerCompaniesModalProps) => {
  const modalWidth =
    modalFor && wideModals.includes(modalFor)
      ? modalSizes.wide
      : modalSizes.narrow;

  const modalsOptions = {
    inviteCompany: (
      <InviteCompanyModalContent
        handleSubmit={sendInvitation}
        closeModal={closeModal}
      />
    ),
    updateCooperationStatus: company && (
      <UpdateCooperationStatusModalContent
        company={company}
        handleSubmit={stopCooperation}
        closeModal={closeModal}
      />
    ),
  };
  return (
    <StyledModal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="users-modal-title"
      aria-describedby="users-modal-description"
      width={modalWidth}
    >
      <StyledModalWrapper>{modalsOptions[modalFor]}</StyledModalWrapper>
    </StyledModal>
  );
};
