import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAuthHeader,
  getExternalClientAuthHeader,
  handleResponse,
} from '../../../utils/api';
import {
  generateClientListMessagesUrl,
  generateExternalClientListOptionalMessageUrl,
  generatePartnerListOptionalMessageUrl,
} from '../../../utils/urlGenerators';

const getClientListMessagesThunk = createAsyncThunk(
  'clientListMessage/getClientListMessages',
  async (id: string, { getState, dispatch }) => {
    const request = fetch(generateClientListMessagesUrl(id), {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const sendOptionalMessagePartnerThunk = createAsyncThunk(
  'clientListMessage/sendOptionalMessagePartner',
  async (
    data: { listId: string; partnerId: number; message: { note: string } },
    { getState, dispatch },
  ) => {
    const request = fetch(
      generatePartnerListOptionalMessageUrl(data.listId, data.partnerId),
      {
        method: 'POST',
        headers: getAuthHeader(getState()),
        body: JSON.stringify(data.message),
      },
    );
    return await handleResponse(request, dispatch);
  },
);

const sendOptionalMessageClientThunk = createAsyncThunk(
  'clientListMessage/sendOptionalMessageClient',
  async (
    data: { listId: string; message: { note: string } },
    { dispatch, getState },
  ) => {
    const request = fetch(
      generateExternalClientListOptionalMessageUrl(data.listId),
      {
        method: 'POST',
        headers: getExternalClientAuthHeader(getState()),
        body: JSON.stringify(data.message),
      },
    );
    return await handleResponse(request, dispatch);
  },
);

export const clientListMessageThunks = {
  getClientListMessagesThunk,
  sendOptionalMessagePartnerThunk,
  sendOptionalMessageClientThunk,
};
