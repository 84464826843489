import styled, { css } from 'styled-components';
import { TypographyProps } from './Typography.types';
import { SIZES } from '../../../theme/sizes';
import { COLORS } from '../../../theme/colors';

const typography = css<TypographyProps>`
  color: ${COLORS.typography.main};
  ${({ color }) => color && `color: ${color};`}
  font-family: "Poppins", sans-serif;
  font-weight: ${({ weight }) => SIZES.fontWeight[weight || 'regular']};
  margin: 0;
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
`;

export const Header1 = styled.h1`
  ${typography}
  font-size: 21px;
  font-weight: 600;
  line-height: 44px;

  ${SIZES.media.phone} {
    font-size: 32px;
  }
`;

export const Header2 = styled.h2`
  ${typography}
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  @media screen and (max-width: ${SIZES.deviceSize.smallPhone}px) {
    font-size: 18px;
    line-height: 36px;
  }
`;

export const Header3 = styled.h3`
  ${typography}
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
`;

export const Header4 = styled.h4`
  ${typography}
  font-size: 18px;
  line-height: 29px;
`;

export const Subtitle = styled.h4`
  ${typography}
  font-size: 16px;
  line-height: 24px;
`;

export const Body = styled.p`
  ${typography}
  font-size: 12px;
  line-height: 24px;

  ${SIZES.media.phone} {
    font-size: 14px;
  }
`;

export const Caption = styled.span`
  ${typography}
  font-size: 10px;
  line-height: 22px;

  @media screen and (max-width: ${SIZES.deviceSize.tablets}px) {
    font-size: 12px;
    line-height: 30px;
  }
`;

export const Overline = styled.span`
  ${typography}
  font-size: 12px;
  line-height: 14.4px;
`;

export const Link = styled.span`
  ${typography}
  border-bottom: 1px solid ${COLORS.accent.primary};
  color: ${COLORS.accent.primary};
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: ${SIZES.fontWeight.bold};
  line-height: 24px;
`;
