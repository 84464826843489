import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_CLIENT_LIST, API_PARTNER_LISTS } from '../../../config/urls';
import { ClientListStatuses } from '../../../store/types/Store.clientList.types';
import {
  getAuthHeader,
  getExternalClientAuthHeader,
  handleResponse,
} from '../../../utils/api';
import {
  generateClientListCvsUrl,
  generateClientListExistsUrl,
  generateExternalClientListConfirmFeedbackUrl,
  generateExternalClientListUrl,
  generatePartnerListConfirmFeedbackUrl,
  generatePartnerListUrl,
} from '../../../utils/urlGenerators';

const getClientListsThunk = createAsyncThunk(
  'clientList/getClientLists',
  async (idHrList: string, { getState, dispatch }) => {
    const request = fetch(`${API_CLIENT_LIST}?related_hr_list=${idHrList}`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const checkIfClientListExistsThunk = createAsyncThunk(
  'clientList/checkIfClientListExists',
  async (id: string, { getState, dispatch }) => {
    const request = fetch(generateClientListExistsUrl(id), {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const addClientToListThunk = createAsyncThunk(
  'clientList/addClientToList',
  async (
    data: {
      body: {
        owner?: number;
        name?: string;
        related_partner?: number;
        related_hr_list: string;
        validate_experience: boolean;
      };
    },
    { getState, dispatch },
  ) => {
    const request = fetch(API_CLIENT_LIST, {
      method: 'POST',
      headers: getAuthHeader(getState()),
      body: JSON.stringify(data.body),
    });
    return await handleResponse(request, dispatch);
  },
);

const editClientThunk = createAsyncThunk(
  'clientList/editClient',
  async (
    data: {
      body: {
        owner?: number;
        name?: string;
        related_partner?: number;
      };
      clientId: string;
    },
    { getState, dispatch },
  ) => {
    const request = fetch(`${API_CLIENT_LIST}${data.clientId}/`, {
      method: 'PATCH',
      headers: getAuthHeader(getState()),
      body: JSON.stringify(data.body),
    });
    return await handleResponse(request, dispatch);
  },
);

const addCandidateThunk = createAsyncThunk(
  'clientList/addEmployeeToClientList',
  async (data: { listId: string; id: string }, { getState, dispatch }) => {
    const request = fetch(generateClientListCvsUrl(data.listId), {
      method: 'POST',
      headers: getAuthHeader(getState()),
      body: JSON.stringify({
        cv: data.id,
      }),
    });
    return await handleResponse(request, dispatch);
  },
);

const updateClientListStatusThunk = createAsyncThunk(
  'clientList/updateStatusClientList',
  async (
    data: { id: string; status: ClientListStatuses },
    { getState, dispatch },
  ) => {
    const request = fetch(`${API_CLIENT_LIST}${data.id}/`, {
      method: 'PATCH',
      headers: getAuthHeader(getState()),
      body: JSON.stringify({
        status: data.status,
      }),
    });
    return await handleResponse(request, dispatch);
  },
);

const getPartnerListsThunk = createAsyncThunk(
  'clientList/getPartnerLists',
  async (partnerId: number, { getState, dispatch }) => {
    const request = fetch(`${API_PARTNER_LISTS}${partnerId}`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const getPartnerListThunk = createAsyncThunk(
  'clientList/getPartnerList',
  async (
    data: { listId: string; partnerId: number },
    { getState, dispatch },
  ) => {
    const request = fetch(generatePartnerListUrl(data.listId, data.partnerId), {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const getExternalClientListDetailsThunk = createAsyncThunk(
  'clientList/getExternalClientListDetails',
  async (id: string, { getState }) => {
    const request = fetch(generateExternalClientListUrl(id), {
      method: 'GET',
      headers: getExternalClientAuthHeader(getState()),
    });
    return await handleResponse(request);
  },
);

const confirmFeedbackPartnerThunk = createAsyncThunk(
  'clientList/confirmFeedbackPartner',
  async (
    data: { listId: string; partnerId: number },
    { getState, dispatch },
  ) => {
    const request = fetch(
      generatePartnerListConfirmFeedbackUrl(data.listId, data.partnerId),
      {
        method: 'POST',
        headers: getAuthHeader(getState()) as HeadersInit,
      },
    );
    return await handleResponse(request, dispatch);
  },
);

const confirmFeedbackClientThunk = createAsyncThunk(
  'clientList/confirmFeedbackClient',
  async (listId: string, { getState }) => {
    const request = fetch(
      generateExternalClientListConfirmFeedbackUrl(listId),
      {
        method: 'POST',
        headers: getExternalClientAuthHeader(getState()),
      },
    );
    return await handleResponse(request);
  },
);

const confirmSharingListWithPartnerThunk = createAsyncThunk(
  'clientList/confirmSharingListWithPartner',
  async (
    data: { id: string; status: ClientListStatuses },
    { getState, dispatch },
  ) => {
    const request = fetch(`${API_CLIENT_LIST}${data.id}/`, {
      method: 'PATCH',
      headers: getAuthHeader(getState()) as HeadersInit,
      body: JSON.stringify({ status: data.status }),
    });
    return await handleResponse(request, dispatch);
  },
);

export const clientListsThunks = {
  getClientListsThunk,
  checkIfClientListExistsThunk,
  addClientToListThunk,
  editClientThunk,
  addCandidateThunk,
  updateClientListStatusThunk,
  getPartnerListsThunk,
  getPartnerListThunk,
  getExternalClientListDetailsThunk,
  confirmFeedbackPartnerThunk,
  confirmFeedbackClientThunk,
  confirmSharingListWithPartnerThunk,
};
