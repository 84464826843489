import styled from 'styled-components';

export const NoResultWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  justify-content: center;
  margin: 100px auto 50px auto;
  padding: 0 30px;
  z-index: 1;
  & svg {
    color: #b5bfc8;
    margin: auto;
  }
`;

export const MessageWrapper = styled.span`
  color: #b5bfc8;
  font-size: 15px;
  margin: auto;
  text-align: center;
`;
