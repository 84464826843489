import { createAsyncThunk } from '@reduxjs/toolkit';
import { LeadIdType } from '../../../store/types/Store.home.types';
import { getAuthHeader, handleResponse } from '../../../utils/api';
import { raiseToast } from '../../../utils/raiseToasts';
import {
  generateLeadRequestsUrl,
  generateLeadRequestUrl,
} from '../../../utils/urlGenerators';
import i18n from '../../../i18n';

const getLeadRequestsThunk = createAsyncThunk(
  'leadProfiles/getLeadRequests',
  async (data: { leadId: LeadIdType }, { getState, dispatch }) => {
    const URL = generateLeadRequestsUrl(data.leadId);
    const request = fetch(`${URL}`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);

const createLeadRequestThunk = createAsyncThunk(
  'leadProfiles/createLeadRequest',
  async (
    data: { leadId: LeadIdType; request: BodyInit },
    { getState, dispatch },
  ) => {
    const URL = generateLeadRequestsUrl(data.leadId);
    const request = fetch(`${URL}`, {
      method: 'POST',
      body: JSON.stringify(data.request),
      headers: {
        ...getAuthHeader(getState()),
      },
    });

    return await handleResponse(request, dispatch);
  },
);

const updateLeadRequestThunk = createAsyncThunk(
  'leadProfiles/updateLeadRequest',
  async (
    data: { leadId: LeadIdType; requestId: number; request: BodyInit },
    { getState, dispatch },
  ) => {
    const URL = generateLeadRequestUrl(data.leadId, data.requestId);
    const request = fetch(`${URL}`, {
      method: 'PATCH',
      body: JSON.stringify(data.request),
      headers: {
        ...getAuthHeader(getState()),
      },
    });

    return await handleResponse(request, dispatch);
  },
);

const deleteLeadRequestThunk = createAsyncThunk(
  'leadProfiles/deleteLeadRequest',
  async (
    data: { leadId: LeadIdType; requestId: number },
    { getState, dispatch },
  ) => {
    const URL = generateLeadRequestUrl(data.leadId, data.requestId);
    const request = fetch(`${URL}`, {
      method: 'DELETE',
      headers: {
        ...getAuthHeader(getState()),
      },
    });
    const response = await handleResponse(request, dispatch);

    if (response) {
      const message = i18n.t('raiseToastMessage.lead_request_deleted', {
        ns: 'common',
      });
      raiseToast.success(message);
    }
  },
);

export const leadProfilesThunks = {
  getLeadRequestsThunk,
  createLeadRequestThunk,
  updateLeadRequestThunk,
  deleteLeadRequestThunk,
};
