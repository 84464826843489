import { createSlice } from '@reduxjs/toolkit';

const apiControllerSlice = createSlice({
  name: 'apiController',
  initialState: {
    responseCode: undefined,
  },
  reducers: {
    setResponseCode: (state, action) => {
      state.responseCode = action.payload;
    },
  },
});

export const actions = { ...apiControllerSlice.actions };
export const { reducer } = apiControllerSlice;
