import styled from 'styled-components';

export const OptionItem = styled.li`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 13px;
  font-weight: 400;
  height: 10;
  margin: 0;
`;

export const CandidatesList = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 10px 5px;
  margin-bottom: 15px;
  max-height: 150px;
  max-width: fit-content;
  overflow-y: auto;
`;
