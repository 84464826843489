import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthHeader, handleResponse } from '../../../../utils/api';
import { generateEmployeePageUrl } from '../../../../utils/urlGenerators';
import { EmployeeTableDataInterface } from '../../../../store/types/Store.candidates.types';
import { API_EMPLOYEES_URL } from '../../../../config/urls';

const getEmployeePageDataThunk = createAsyncThunk(
  'employeePage/getEmployeePageData',
  async (employeeId: string, { getState, dispatch }) => {
    const request = fetch(generateEmployeePageUrl(employeeId), {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

export interface UpdateEmployeeObservedStatusThunkArgs {
  employeeId: string;
  payload: {
    observed: boolean;
  };
}

const updateEmployeeObservedStatusThunk = createAsyncThunk<
  EmployeeTableDataInterface,
  UpdateEmployeeObservedStatusThunkArgs
>('employees/updateEmployeeActivity', async (data, { getState, dispatch }) => {
  const request = fetch(`${API_EMPLOYEES_URL}${data.employeeId}/observe/`, {
    method: 'PATCH',
    body: JSON.stringify(data.payload),
    headers: {
      ...getAuthHeader(getState()),
    },
  });

  return await dispatch(getEmployeePageDataThunk(data.employeeId)).unwrap();
});

export const employeePageThunks = {
  getEmployeePageDataThunk,
  updateEmployeeObservedStatusThunk,
};
