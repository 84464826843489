import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import { styled as styledMaterialUI } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';

export const StyledBox = styledMaterialUI(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media (max-width: 668px)': {
    width: '100%',
  },
  padding: '20px',
});

export const StyledHeadline = styled.p`
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0;
  margin-top: 3px;
  width: 215px;
`;

export const StyledLeadInfoItem = styled.p`
  align-items: center;
  display: flex;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 14px;
  gap: 15px;
`;

export const StyledLink = styledMaterialUI(Link)({
  color: 'inherit',
  fontSize: '14px',
  textDecoration: 'none',
  fontFamily: '"Neue Haas Grotesk Display Pro", sans-serif',
});

export const ControlRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const StyledSwitch = styledMaterialUI(Switch)({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: COLORS.accent.primary,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: COLORS.accent.primary,
  },
  '& .MuiSwitch-switchBase': {
    color: COLORS.error.main,
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: COLORS.error.main,
  },
});

export const StyledFormControlLabel = styledMaterialUI(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
    textTransform: 'capitalize',
    marginLeft: '7px',
    fontFamily: '"Neue Haas Grotesk Display Pro", sans-serif',
  },
});

export const LeadInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightControls = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 0.5rem;
`;

export const Line = styled.hr`
  border-top: 1px solid #eff3fa;
  margin-bottom: 10px;
  margin-top: 3px;
  width: 215px;
  @media (max-width: 686px) {
    width: 100%;
  }
`;
