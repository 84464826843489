import styled from 'styled-components';
import { COLORS } from '../../../../theme/colors';

export const StyledPositionSection = styled.div`
  border: ${(props) => (props.error ? '3px solid red' : '0')};
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 60px;

  :hover div {
    visibility: visible;
  }

  @media print {
    border: 0;
    overflow-y: hidden;
  }
`;

export const StyledPosition = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
  margin-right: 80px;
  :empty:before {
    content: attr(placeholder);
    font-weight: 200;
    opacity: 0.3;

    @media print {
      opacity: 0;
    }
  }
`;

export const StyledAddCustomInfoButton = styled.div`
  color: ${COLORS.accent.primary};
  display: inline;
  float: right;
  margin-right: 250px;
  margin-top: 9px;
  visibility: hidden;
`;

export const StyledIcon = styled.div`
  cursor: pointer;
`;
