import { AnyAction } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  LeadIdType,
  LeadProfileIdType,
} from '../../../../store/types/Store.home.types';
import { leadProfileCandidatesThunks } from '../../../Leads/redux/LeadProfileCandidates.thunk';
import { leadProfilesThunks } from '../../../Leads/redux/LeadProfiles.thunk';
import { vacanciesPageThunks } from '../../Vacancies.thunk';
import { HandleDeleteCandidateModalType } from '../VacanciesPageHrSales.types';
import { UseHandleSuggestedCandidateProps } from './useHandleSuggestedCandidate.types';

export const useHandleSuggestedCandidate = ({
  setModalFor,
  setOpenDetailModal,
}: UseHandleSuggestedCandidateProps) => {
  const [candidateToDelete, setCandidateToDelete] = useState<{
    name: string;
    id: number;
    leadProfileId: LeadProfileIdType;
    leadId: LeadIdType;
  }>();
  const dispatch = useDispatch();

  const deleteSuggestedCandidate = async () => {
    if (candidateToDelete) {
      await dispatch(
        leadProfileCandidatesThunks.deleteCandidateThunk({
          lead_id: candidateToDelete?.leadId,
          lead_profile_id: candidateToDelete?.leadProfileId,
          candidate_id: candidateToDelete?.id,
        }) as unknown as AnyAction,
      );
      dispatch(
        leadProfilesThunks.getLeadRequestsThunk({
          leadId: candidateToDelete.leadId,
        }) as unknown as AnyAction,
      );
      dispatch(
        leadProfileCandidatesThunks.getLeadRequestCandidatesThunk({
          leadId: candidateToDelete.leadId,
          requestId: Number(candidateToDelete?.leadProfileId),
        }) as unknown as AnyAction,
      );
      dispatch(
        vacanciesPageThunks.getHomePageDataThunk() as unknown as AnyAction,
      );
    } else {
      console.error('Lack of data candidate to delete');
    }
  };

  const handleDeleteCandidateModal: HandleDeleteCandidateModalType = (
    leadProfileId,
    leadId,
    name,
    id,
  ) => {
    setModalFor('deleteSuggestedCandidate');
    setCandidateToDelete({ leadProfileId, leadId, name, id });
    setOpenDetailModal(true);
  };
  return {
    deleteSuggestedCandidate,
    handleDeleteCandidateModal,
    candidateToDelete,
  };
};
