import { createSlice } from '@reduxjs/toolkit';
import { Lead } from '../../../store/types/Store.leads.types';
import { leadsListThunks } from './LeadsList.thunk';

const { getLeadsThunk } = leadsListThunks;

const initialState: Lead[] = [];

const leadsListSlice = createSlice({
  name: 'leadsList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLeadsThunk.fulfilled, (state, action) => {
      state.length = 0;
      action.payload.forEach((data: Lead) => {
        state.push({
          id: data.id,
          name: data.name,
          sales_owner: data.sales_owner,
          industry: data.industry,
          website: data.website,
          updated_at: data.updated_at,
          created_at: data.created_at,
          status: data.status,
          profiles: data.profiles,
          candidate_statuses: data.candidate_statuses,
          details_fetched: data.details_fetched,
          requests_num: data.requests_num,
          status_history: data.status_history,
        });
      });
    });
  },
});

export const actions = { ...leadsListSlice.actions };
export const { reducer } = leadsListSlice;
