import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { LeadsTableExpendableRow } from '../../../../containers/Leads/LeadsTable/LeadsTableExpendableRow';
import { ExpandableHomeRow } from '../../../../containers/Vacancies/VacanciesPageHrSales/CreateRow/ExpandableHomeRow';
import { ExpandableRowLink } from '../../../../containers/Vacancies/VacanciesPageHrSales/CreateRow/ExpandableRowLink';
import { ArrowIcon } from './ArrowIcon';
import { HomeRowHeader } from './HomeRowHeader';
import { LeadsRowHeader } from './LeadsRowHeader';
import { StyledCell, StyledTableRow } from './Row.styles';
import { RowProps } from './Row.types';
import { AppStore } from '../../../../store/types/Store.types';
import { UserData } from '../../../../store/types/Store.user.types';
import { AppPaths } from '../../../../config/AppPaths';
import { MyProfilePaths } from '../../../../config/MyProfilePaths';

export const Row = ({ tableData, idOpenedLead, homeLeads, type }: RowProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(
    idOpenedLead === tableData.row.id,
  );
  const user = useSelector<AppStore, UserData | undefined | null>(
    (state) => state.user.user,
  );

  const leadProfile =
    homeLeads &&
    homeLeads.find((lProfile) => lProfile.lead_profile_id === tableData.row.id);
  const isMyCompany = leadProfile?.company.id === user?.company?.pk;
  const isMyProjectsPage = useMatch(
    `${AppPaths.myProfile}/${MyProfilePaths.myProjects}`,
  );

  return (
    <>
      <StyledTableRow
        id={`id${tableData.row.id}`}
        key={tableData.row.id}
        onClick={() => setIsOpen((prevSetIsOpen) => !prevSetIsOpen)}
        isOpen={isOpen}
      >
        <ArrowIcon {...{ isOpen, setIsOpen }} />
        {tableData.type === 'home'
          ? tableData.columns.map((column, index) => (
              <HomeRowHeader
                key={`${tableData.row.id}-${index}`}
                {...{ tableData, column }}
              />
            ))
          : tableData.columns.map((column, index) => (
              <LeadsRowHeader
                key={`${tableData.row.id}-${index}`}
                {...{ tableData, column, index }}
              />
            ))}

        {(isMyCompany || isMyProjectsPage) && (
          <StyledCell width={100}>
            <ExpandableRowLink
              {...{
                tableDataType: tableData.type,
                leadId: leadProfile?.lead_id || Number(tableData.row.id),
                leadProfileId: leadProfile?.lead_profile_id,
              }}
            />
          </StyledCell>
        )}
      </StyledTableRow>
      {tableData.type === 'home' && leadProfile && (
        <ExpandableHomeRow {...{ leadProfile, isOpen, type }} />
      )}
      {tableData.type === 'leads' && (
        <LeadsTableExpendableRow
          leadId={Number(tableData.row.id)}
          {...{ isOpen }}
        />
      )}
    </>
  );
};
