import Box from '@mui/material/Box';
import { styled as styledMaterialUI } from '@mui/material/styles';
import styled from 'styled-components';

export const Line = styled.hr`
  border-top: 1px solid #efefef;
  margin: 0px 0 5px 5px;
  width: 489px;
  @media (max-width: 699px) {
    width: 100%;
    margin-left: 0;
  }
`;
export const HeadContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  @media (max-width: 699px) {
    width: 100%;
  }
`;
export const StyledBox = styledMaterialUI(Box)({
  margin: '0 35px',
  '@media (max-width: 699px)': {
    margin: 0,
    width: '100%',
  },
  padding: '20px',
});

export const DescriptionBox = styledMaterialUI(Box)({
  width: 489,
  overflow: 'auto',
  fontFamily: 'Poppins',
  fontSize: '12px',
  lineHeight: '20px',
  margin: '17px 0 50px 5px',
  color: '#101840',
  '@media (max-width: 699px)': {
    width: '100%',
    height: 'fit-content',
    margin: '17px 0 20px 0',
  },

  '& p': {
    // eslint-disable-next-line radar/no-duplicate-string
    fontFamily: 'Neue Haas Grotesk Display Pro',
    fontSize: '13px',
    padding: 0,
    margin: 0,

    '& strong': {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '13px',
      fontWeight: 600,
    },

    '& em': {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '13px',
    },

    '& u': {
      fontFamily: 'Neue Haas Grotesk Display Pro',
      fontSize: '13px',
    },
  },

  '& li': {
    fontFamily: 'Neue Haas Grotesk Display Pro',
    fontSize: '13px',
  },

  '& ul': {
    paddingLeft: '5px',
  },
});

export const AttachedFileContainer = styled.section`
  align-items: center;
  display: flex;
  gap: 7px;
`;

export const AttachedFileLabel = styled.p`
  color: #9fa3b3;
  font-family: 'Poppins';
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 0;
`;
