import { TableRow } from '@mui/material';
import React from 'react';
import { StyledBaseTableCell } from './BaseTable.styles';
import { BaseTableColumnParam, BaseTableRowDataType } from './BaseTable.types';

export const BaseTableRow = React.memo(
  (props: {
    row: BaseTableRowDataType[];
    columns: BaseTableColumnParam[];
    onRowClick: () => any;
    customCellStyles?: React.CSSProperties;
  }) => (
    <TableRow onClick={props.onRowClick && (() => props.onRowClick())}>
      {props.row.map((cell, idx) => (
        <StyledBaseTableCell
          style={{
            ...props.customCellStyles,
            ...props.columns[idx].customStyles,
          }}
        >
          {cell}
        </StyledBaseTableCell>
      ))}
    </TableRow>
  ),
);
