import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

const FONT_FAMILY = '"Poppins", sans-serif';

export const useStyles = makeStyles(() => ({
  dropdown: {
    '& .MuiMenuItem-root': {
      fontSize: 14,
      fontFamily: FONT_FAMILY,
    },
  },
}));

export const StyledPaper = styled(Paper)({
  margin: '0 auto',
  width: '100%',
  borderRadius: 0,
  height: 'auto',
});

export const StyledTableHead = styled(TableHead)({
  backgroundColor: '#f4f5f7',
});

export const StyledTableCell = styled(TableCell)({
  fontSize: '10px',
  minWidth: 1264,
  '@media (max-width: 699px)': {
    display: 'block',
  },
});

export const StyledTableRow = styled(TableRow)({
  height: '20px',
  '@media (max-width: 699px)': {
    display: 'flex',
  },
});

export const StyledPagination = styled(TablePagination)({
  color: '#8F9DAC',
  fontWeight: 'normal',

  '& .MuiTablePagination-selectLabel': {
    margin: '0',
    fontSize: '14px',
    fontFamily: FONT_FAMILY,
  },
  '& .MuiTablePagination-input': {
    marginTop: '3px',
    fontSize: '14px',
    fontFamily: FONT_FAMILY,
  },
  '& .MuiTablePagination-selectIcon': {
    fontSize: '20px',
  },
  '& .MuiTablePagination-displayedRows': {
    margin: '0',
    fontSize: '14px',
    fontFamily: FONT_FAMILY,
  },
  '& .MuiTablePagination-selectRoot': {
    fontSize: '14px',
  },
  '& .MuiTablePagination-select': {
    fontSize: '14px',
    fontFamily: FONT_FAMILY,
    marginTop: '4px',
  },
});

export const StyledFooterRow = styled.tr`
  border-top: 1px solid #e5e5e5;
`;
