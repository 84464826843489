import { FormControl, MenuItem, Select, SelectProps } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import {
  StyledMenuItem,
  StyledSpan,
  useStyles,
  StyledInputContainer,
} from './Selector.styles';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import { FormLabel } from '../FormLabel';
import { Option, SelectorProps } from './Selector.types';

export const Selector = <K, T extends object>({
  label = '',
  value,
  name = '',
  action,
  options,
  required = false,
  size = 'medium',
  disabled,
  ...props
}: SelectorProps<SelectProps<T>>) => {
  const classes = useStyles();
  return (
    <StyledInputContainer>
      {label && <FormLabel required={required} htmlFor={label} label={label} />}
      <FormControl className={classes.container} variant="outlined">
        <Select
          className={classes.root}
          label={label}
          name={name}
          disabled={disabled}
          labelId={label}
          // nowrap
          value={value || ''}
          onChange={action}
          IconComponent={ArrowIcon}
          input={
            <InputBase
              value={value ? value.value : ''}
              size={size}
              className={classes.input}
            />
          }
          required={required}
          renderValue={(valueP: Option) => (
            <span>
              <StyledSpan>{valueP.name}</StyledSpan>
            </span>
          )}
          {...props}
        >
          {options.map((option: Option, idx: number) => (
            <MenuItem value={option as any} key={idx}>
              <StyledMenuItem>{option.name}</StyledMenuItem>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </StyledInputContainer>
  );
};
