import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  defaultStatusOfNewRequest,
  statusOfRequestWithReason,
} from '../../../../containers/Leads/Leads.config';
import { postLeadFile } from '../../../../services/Leads';
import {
  BlindCvAttachment,
  Lead,
  LeadProfile,
  LeadProfileStatusType,
  Technology,
} from '../../../../store/types/Store.leads.types';
import { handleResponse } from '../../../../utils/api';
import { useLeadRequestFormHandlersProps } from '../LeadRequestForm.types';
import { getHrOwner } from '../helpers/getHrOwner';
import { useDedicatedHandler } from '../helpers/useDedicatedHandler';
import { Option } from '../../../common/Selector/Selector.types';
import { useLeadRequestFormConfig } from '../useLeadRequestFormConfig';

export const useLeadRequestFormHandlers = ({
  requestFetched,
  userState,
  allMainTechnologies,
}: useLeadRequestFormHandlersProps) => {
  const dispatch = useDispatch();
  const [owner, setOwner] = useState(getHrOwner({ requestFetched, userState }));
  const files = requestFetched?.files || [];

  const [name, setName] = useState<string | undefined>(requestFetched?.name);
  const [reason, setReason] = useState<string | undefined>(
    requestFetched?.reason,
  );
  const [lead, setLead] = useState<Lead | undefined>(undefined);
  const [mainTechnologies, setMainTechnologies] = useState<Technology[]>([]);
  const [description, setDescription] = useState<string>();
  const [visibility, setVisibility] = useState<Option>();
  const [clickedStatusOption, setClickedStatusOption] =
    useState<LeadProfileStatusType>(defaultStatusOfNewRequest);
  const [request, setRequest] = useState<Partial<LeadProfile>>({});
  const [showFileLoader, setShowFileLoader] = useState(false);

  const { visibilityOptions } = useLeadRequestFormConfig();

  const setDefaultRequestData = () => {
    setRequest({
      hr_owner: owner,
      lead_profile_status: clickedStatusOption,
    });
  };

  const getCurrentTechnologies = (leadTechnologies: number[]) =>
    leadTechnologies.map((technologyId) => {
      return {
        id: technologyId,
        name:
          allMainTechnologies?.find(
            (existTechnology) => existTechnology.id === technologyId,
          )?.name || 'Lack of name',
      };
    });

  const updateFetchedRequestDisplay = () => {
    if (requestFetched) {
      const tempMainTechnologies = requestFetched.main_technologies
        ? getCurrentTechnologies(requestFetched.main_technologies)
        : requestFetched.main_technologies || [];
      const currentVisibility = visibilityOptions.find(
        (option) => option.value === (requestFetched?.visibility as string),
      );

      setRequest({
        ...(requestFetched.leadId ? { leadId: requestFetched.leadId } : {}),
        hr_owner: requestFetched.hr_owner,
        lead_profile_status:
          requestFetched.lead_profile_status || defaultStatusOfNewRequest,
        reason: requestFetched.reason,
        name: requestFetched.name,
        description: requestFetched.description,
        priority: requestFetched.priority,
        main_technologies: tempMainTechnologies.map(
          (technology: Technology) => technology.id,
        ),
        visibility: currentVisibility,
        files,
      });
      currentVisibility && setVisibility(currentVisibility);
      setName(requestFetched.name);
      setOwner(requestFetched.hr_owner);
      setClickedStatusOption(
        requestFetched.lead_profile_status || defaultStatusOfNewRequest,
      );
      setDescription(requestFetched.description);
      setMainTechnologies(tempMainTechnologies);

      if (requestFetched.lead_profile_status === statusOfRequestWithReason) {
        setReason(requestFetched.reason);
      }
    }
  };

  useEffect(() => {
    updateFetchedRequestDisplay();
  }, [requestFetched]);

  const handleInputChange = (e: React.BaseSyntheticEvent) => {
    setRequest({
      ...request,
      [e.target.name]: e.target.value,
    });
  };

  const handleVisibilityChange = (value: Option) => {
    setRequest({
      ...request,
      visibility: value,
    });
    setVisibility(value);
  };

  const handleChange = (e: React.BaseSyntheticEvent<object, any, any>) => {
    useDedicatedHandler({
      type: e.target.id,
      e,
      setOwner,
      setName,
      setReason,
    });
    setRequest({
      ...request,
      [e.target.id]: e.target.innerText || undefined,
    });
  };

  const handleLeadChange = (newValue: any) => {
    setLead(newValue);
    setRequest({
      ...request,
      leadId: newValue.id,
    });
  };

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
    setRequest({
      ...request,
      description: value,
    });
  };

  const getRequestFiles = (
    prevRequest: Partial<LeadProfile>,
    newFile: BlindCvAttachment,
  ) => {
    if (prevRequest.files && prevRequest.files.length) {
      return [...prevRequest.files, newFile];
    }
    return [newFile];
  };

  const handleFileUpload = async (filesToUpload: BlindCvAttachment[]) => {
    setShowFileLoader(true);
    try {
      const response = await handleResponse(
        postLeadFile(filesToUpload),
        dispatch,
      );
      response &&
        setRequest((prevRequest) => ({
          ...prevRequest,
          files: getRequestFiles(prevRequest, response),
        }));
    } catch (error) {
      console.error(error);
    } finally {
      setShowFileLoader(false);
    }
  };

  const handleFileRemoval = (indexToRemove: number) => {
    request &&
      request.files &&
      setRequest({
        ...request,
        files: [...request.files].filter((_, index) => index !== indexToRemove),
      });
  };

  const handleMainTechnologiesChange = (
    _e: unknown,
    newValue: Technology[],
  ) => {
    setRequest({
      ...request,
      main_technologies: newValue.map(({ id }) => id),
    });
    setMainTechnologies(newValue);
  };

  return {
    setDefaultRequestData,
    updateFetchedRequestDisplay,
    owner,
    files,
    name,
    reason,
    setReason,
    mainTechnologies,
    description,
    visibility,
    clickedStatusOption,
    setClickedStatusOption,
    request,
    setRequest,
    showFileLoader,
    getCurrentTechnologies,
    handleInputChange,
    handleFileUpload,
    handleFileRemoval,
    handleMainTechnologiesChange,
    handleDescriptionChange,
    handleChange,
    handleVisibilityChange,
    lead,
    handleLeadChange,
  };
};
