import {
  StatusContainer,
  StyledActiveStatusIcon,
  StyledArchiveStatusIcon,
  StyledStatus,
} from '../LeadsTable.styles';
import { LeadStatusProps } from './LeadStatus.types';

export const LeadStatus = ({ status }: LeadStatusProps) => (
  <StatusContainer>
    {status === 'ACTIVE' ? (
      <StyledActiveStatusIcon />
    ) : (
      <StyledArchiveStatusIcon />
    )}
    <StyledStatus>{status.toLowerCase()}</StyledStatus>
  </StatusContainer>
);
