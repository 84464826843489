export const SECTION_TYPE = {
  POSITION: 'position',
  TECHNOLOGIES_HEADER: 'technologiesHeader',
  TECHNOLOGIES_ENTRY: 'technologiesEntry',
  TECHNOLOGY_HEADER: 'technologyHeader',
  TECHNOLOGY_ENTRY: 'technologyEntry',
  EXPERIENCE_HEADER: 'experienceHeader',
  EXPERIENCE_ENTRY: 'experienceEntry',
  LANGUAGES_HEADER: 'languages',
  LANGUAGES_ENTRY: 'languagesEntry',
  EDUCATION_HEADER: 'educationHeader',
  EDUCATION_ENTRY: 'educationEntry',
  POSITION_HEADER: 'positionHeader',
  POSITION_ENTRY: 'positionEntry',
  ADDITIONAL_INFO_HEADER: 'additionalInfoHeader',
  ADDITIONAL_INFO_ENTRY: 'additionalInfoEntry',
  SIDE_PROJECTS_ENTRY: 'sideProjectsEntry',
  SIDE_PROJECTS_HEADER: 'sideProjectsHeader',
  CUSTOM_SECTION_HEADER: 'customSectionHeader',
  CUSTOM_SECTION_ENTRY: 'customSectionEntry',
  CERTIFICATES_HEADER: 'certificatesHeader',
  CERTIFICATES_ENTRY: 'certificatesEntry',
};

export const SECTION_HEADERS = new Set([
  SECTION_TYPE.LANGUAGES_HEADER,
  SECTION_TYPE.TECHNOLOGIES_HEADER,
  SECTION_TYPE.EXPERIENCE_HEADER,
  SECTION_TYPE.EDUCATION_HEADER,
  SECTION_TYPE.POSITION_HEADER,
  SECTION_TYPE.ADDITIONAL_INFO_HEADER,
  SECTION_TYPE.SIDE_PROJECTS_HEADER,
  SECTION_TYPE.CUSTOM_SECTION_HEADER,
  SECTION_TYPE.CERTIFICATES_HEADER,
]);
