export const AppPaths = {
  login: '/login',
  logout: '/logout',
  register: '/register',
  registerCompanyWithToken: '/register-company/:token',
  registerCompany: '/register-company',
  myProfile: '/my-profile',
  candidates: '/candidates',
  vacancies: '/vacancies',
  sharedListId: '/shared-list/:id',
  sharedListPartnerId: '/shared-list/partner/:id',
  employeeId: '/employee/:id',
  createEmployee: '/employee/create',
  editEmployee: '/employee/:id/edit',
  employeeCv: '/cv',
  employeeCvId: '/cv/:id',
  listsOfCandidatesForSharing: '/lists-of-candidates-for-sharing',
  users: '/users',
  usersUsers: '/users/users',
  usersPartners: '/users/partners',
  clientListCvPreview: '/cv-preview/list/:listId/cv/:cvId/',
  hrCvPreview: '/cv-preview/hr-list/:listId/cv/:cvId/',
  leadDetail: '/vacancies/:id/',
  leads: '/leads',
  sharedEmployeeList: '/shared-employee-list/:id',
  sharedEmployeeListClientId: '/shared-employee-list/:listId/:clientId/',
  home: '/',
  cvs: '/cvs',
  notifications: '/notifications',
  passwordResetRequest: '/password-reset-request',
  resetPasswordLinkSent: '/reset-password-link-sent',
  changePassword: '/change-password',
  resetPasswordConfirmed: '/reset-password-confirmed',
  assistanceRequest: '/assistance-request',
  registerRequestConfirm: '/register/confirm/:token',
  acceptCompanyInvitation: '/accept-company-invitation/:uuid',
  privacyPolicy: '/privacy-policy',
  termsAndConditions: '/terms-and-conditions',
};
