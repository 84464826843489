import { createSlice } from '@reduxjs/toolkit';
import { PersistedStoreKeys } from '../../../config/PersistedStoreKeys';
import { ExternalClientCredentials } from '../../../store/types/Store.clientList.types';
import { clientListCredentialsThunks } from './ClientListCredentials.thunk';

const { loginThunk, logoutThunk } = clientListCredentialsThunks;

const initialState: ExternalClientCredentials = {
  token:
    localStorage.getItem(PersistedStoreKeys.externalClientToken) ?? undefined,
  id: localStorage.getItem(PersistedStoreKeys.externalClientId) ?? undefined,
};

const clientListCredentialsSlice = createSlice({
  name: 'clientListCredentials',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      state.id = action.payload?.id;
      state.token = action.payload?.token;
    });
    builder.addCase(logoutThunk.fulfilled, (state) => {
      state.id = undefined;
      state.token = undefined;
    });
  },
});

export const actions = { ...clientListCredentialsSlice.actions };
export const { reducer } = clientListCredentialsSlice;
