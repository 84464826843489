import { styled as materialUIStyled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';

export const StyledFormTitle = styled.h2`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 24px;
  font-weight: 400;
  line-height: 41.18px;
  margin: 10px 20px;
  text-align: center;
`;

export const StyledDescription = styled.p`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  margin: 0;
  overflow: hidden;
`;

export const StyledStatusContainer = styled.section`
  align-items: center;
  display: flex;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 16px;
  font-weight: 400;
  gap: 10px;
  justify-content: center;
  line-height: 25px;
  margin-bottom: 20px;
`;

export const StyledNotesContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`;

export const StyledTextField = materialUIStyled(TextField)({
  '@media (max-width: 600px)': {
    margin: '0 10px',
  },
  '& .MuiOutlinedInput-input': {
    fontSize: '13px',
    fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    fontWeight: '400',
  },
  '& .MuiInputLabel-root': {
    fontSize: '13px',
    top: '6px',
  },
});

export const StatusHistoryContainer = styled.section`
  align-items: center;
  display: flex;
  flex-basis: 40%;
  flex-direction: column;
  @media (max-width: 568px) {
    flex-basis: 100%;
  }
`;

export const AdditionalHeadline = styled.p`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 12px;
  text-align: center;
`;

export const HistoryStatusList = styled.ul`
  margin-left: 10px;
`;

export const HistoryStatusItem = styled.li`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  margin: 0;
`;

export const StatusDate = styled.span`
  font-weight: 400;
`;

export const BottomContainer = styled.section`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
  margin: 0 22px;
`;

export const NotesWrapper = styled.section`
  flex-basis: 50%;
  max-width: 300px;
  @media (max-width: 568px) {
    flex-basis: 100%;
  }
`;

export const NoHistory = styled.p`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  margin: 0;
  opacity: 0.5;
`;

export const StyledTextContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-end;
  width: 100%;
`;

export const NotesDisplay = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  width: 100%;
`;

export const Label = styled.div`
  font-size: 13px;
  text-align: left;
  width: 100%;
`;
