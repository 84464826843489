import { Tooltip } from '../Tooltip/Tooltip.component';
import { priorityLabels } from './config';
import { IndicatorContainer, Rectangle } from './PriorityIndicator.styles';
import { PriorityIndicatorProps } from './PriorityIndicator.types';

export const PriorityIndicator = ({ priority }: PriorityIndicatorProps) => {
  const currentIndex = Object.keys(priorityLabels).findIndex(
    (el) => el === priority,
  );
  return (
    <Tooltip title={`${priorityLabels[priority]} priority`} position="regular">
      <IndicatorContainer>
        {Object.keys(priorityLabels).map((currentPriority, index) => {
          return (
            <Rectangle
              key={`${index}-${currentPriority}`}
              color={
                currentIndex >= index ? '#101840' : 'rgba(16, 24, 64, 0.2)'
              }
            />
          );
        })}
      </IndicatorContainer>
    </Tooltip>
  );
};
