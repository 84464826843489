export const STRING_LIMIT = 24;

export const getTopFilterOptions = (t) => {
  return {
    current: {
      name: 'current',
      match: (el) => el.status !== 'ARCHIVED',
      label: t('current'),
    },
    archived: {
      name: 'archived',
      match: (el) => el.status === 'ARCHIVED',
      label: t('archived'),
    },
    all: { name: 'all', label: t('all'), match: (el) => true },
  };
};

export const searchInColumns = [
  'first_name',
  'last_name',
  'email',
  'profile',
  'availability',
];
