import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';

export const StyledData = styled.div`
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 80px;

  :hover div {
    visibility: visible;
  }

  @media print {
    border: 0;
    overflow-y: hidden;
  }
`;

export const StyledDeleteButton = styled.div`
  color: ${COLORS.accent.primary};
  display: inline-block;
  left: 30px;
  position: relative;
  visibility: hidden;
`;

export const StyledDeleteSubsectionButton = styled.div`
  bottom: 30px;
  color: ${COLORS.accent.primary};
  float: right;
  left: 35px;
  position: relative;
  visibility: hidden;
`;

export const StyledIcon = styled.div`
  cursor: pointer;
  margin-right: 10px;
`;

export const StyledTimePeriod = styled.div`
  font-weight: 200;
  width: 300px;

  :empty:before {
    content: attr(placeholder);
    opacity: 0.3;

    @media print {
      opacity: 0;
    }
  }
`;

export const StyledInfo = styled.div`
  margin-left: 76px;
`;

export const StyledMinorInfo = styled.div`
  padding-top: 10px;
`;

export const StyledSmallHeader = styled.span`
  float: left;
  font-weight: 400;
  padding-right: 5px;

  :empty:before {
    content: attr(placeholder);
  }
`;

export const StyledTextDiv = styled.div`
  flex: 1;
  font-weight: 200;
  width: 650px;

  :empty:before {
    content: attr(placeholder);
    opacity: 0.3;
  }
`;

export const StyledAddSubsectionButtons = styled.div`
  color: ${COLORS.accent.primary};
  display: flex;
  height: 0px;
  position: absolute;
  visibility: hidden;
`;
