import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../Tooltip/Tooltip.component';
import { StyledButton } from './IconButton.styles';
import { IconButtonProps } from './IconButton.types';

export const DeleteButton = ({
  action,
  title,
  disabled,
  size,
}: IconButtonProps) => {
  const { t } = useTranslation(['common'], { keyPrefix: 'buttons' });
  if (disabled) {
    return (
      <StyledButton disabled={disabled}>
        <DeleteIcon style={{ fontSize: size || '16px' }} />
      </StyledButton>
    );
  }

  return (
    <Tooltip title={title || t('delete')} position="table">
      <StyledButton onClick={action} disabled={disabled}>
        <DeleteIcon style={{ fontSize: size || '16px' }} />
      </StyledButton>
    </Tooltip>
  );
};
