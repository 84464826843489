import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { employeePageThunks } from '../../../../../../containers/Employee/EmployeePage/redux/EmployeePage.thunk';
import { leadProfileCandidatesThunks } from '../../../../../../containers/Leads/redux/LeadProfileCandidates.thunk';
import { AppDispatch } from '../../../../../../store/store';
import { DevRequestEmployeePage } from '../../../../../../store/types/Store.employeePage.types';

export const useEmployeePageHrNotesHandler = (
  devRequestDetails?: DevRequestEmployeePage,
) => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const currentHrNotes = devRequestDetails?.hr_notes ?? '';
  const profileId = devRequestDetails?.profile.profile_id;
  const leadId = devRequestDetails?.lead_id;
  const candidateId = devRequestDetails?.profile.candidate_id;

  const updateCandidateHrNotes = async (hrNotes?: string) => {
    if (leadId && profileId && id && candidateId) {
      await dispatch(
        leadProfileCandidatesThunks.updateCandidateNotesThunk({
          lead_id: leadId,
          candidate_id: candidateId,
          lead_profile_id: profileId,
          notes: {
            employee: id,
            hr_notes: hrNotes,
          },
        }),
      );
      await dispatch(employeePageThunks.getEmployeePageDataThunk(id));
    }
  };

  const handleHrSaving = (notes: string) => {
    if (notes !== currentHrNotes && notes) {
      updateCandidateHrNotes(notes);
    }
  };

  return {
    handleHrSaving,
  };
};
