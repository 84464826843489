import { EditForm } from '../../../../User/EditForm/EditForm.component';

export const EditingModalContent = ({
  handleSubmit,
  handleBack,
  userToEdit,
}) => (
  <EditForm
    handleSubmit={handleSubmit}
    handleBack={handleBack}
    userToEdit={userToEdit}
  />
);
