import { StyledModal } from '../../common/Modals/Modal.styles';
import { CompanyDetailsModalProps } from './CompanyDetailsModal.types';
import { StyledModalWrapper } from './CompanyDetailsModal.styles';
import { DetailsModalContent } from '../DetailsModalContent/DetailsModalContent.component';

export const CompanyDetailsModal = ({
  openModal,
  companyId,
  setOpenModal,
}: CompanyDetailsModalProps) => {
  const onModalCloseClick = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    setOpenModal(false);
  };
  return (
    <StyledModal
      open={openModal}
      onClose={onModalCloseClick}
      aria-labelledby="company-details-modal-title"
      aria-describedby="company-details-description"
      width="700px"
    >
      <StyledModalWrapper>
        <DetailsModalContent
          companyId={companyId}
          closeModal={onModalCloseClick}
        />
      </StyledModalWrapper>
    </StyledModal>
  );
};
