import { forwardRef, useState } from 'react';
import { sectionTypeToComponent } from '../../../containers/CV/CV.options';
// eslint-disable-next-line import/no-cycle
import { SidebarContainer } from '../../../containers/CV/Sidebar/SidebarContainer.component';
import { findSectionWithParent } from '../../../utils/recursiveSearch';
import { Footer } from '../Footer/Footer.component';
import { Header } from '../Header/Header.component';
import {
  StyledContainer,
  StyledPage,
  StyledPageContent,
  StyledPages,
  StyledPageSections,
} from './Editor.styles';

const updateElement = (sectionId, element) => {
  element.sectionId = sectionId;
};

export const Editor = forwardRef(
  (
    {
      sectionConfig,
      updateSectionHeight,
      setTextFormattingPanelVisibility,
      handleDrag,
      handleDrop,
      editable = true,
      pages,
      employeeContext,
      cvId,
    },
    ref,
  ) => {
    const [sectionRefs, setSectionRefs] = useState({});
    const handleRef = (sectionId) => (element) => {
      if (!element || sectionRefs[sectionId]) {
        return;
      }
      updateElement(sectionId, element);
      setSectionRefs((prevRefs) => ({ ...prevRefs, [sectionId]: element }));
    };

    const removeSectionRef = (sectionId) => {
      setSectionRefs((prevRefs) => {
        const newState = { ...prevRefs };
        delete newState[sectionId];
        return newState;
      });
    };

    const getSectionComponent = (section) => {
      const Component = sectionTypeToComponent.get(section.type).component;
      const sectionWithParent = findSectionWithParent(
        section.sectionId,
        sectionConfig,
      );
      const sectionRef = sectionRefs[section.sectionId];

      return (
        <Component
          ref={handleRef(section.sectionId)}
          removeSectionRef={removeSectionRef}
          element={sectionRef}
          key={section.sectionId}
          editable={editable}
          sectionWithParent={sectionWithParent}
          handleDrag={handleDrag}
          handleDrop={handleDrop}
          handleDragOver={(e) => (editable ? e.preventDefault() : undefined)}
          updateSectionHeight={updateSectionHeight(
            sectionWithParent.section.sectionId,
          )}
          setTextFormattingPanelVisibility={setTextFormattingPanelVisibility}
        >
          {() => {
            if (!section.entries?.length) {
              return;
            }

            return section.entries.map(getSectionComponent);
          }}
        </Component>
      );
    };

    const renderPages = (pagesP) => {
      return pagesP?.allPages.map((pageOfSections, index) => {
        return (
          <StyledPage key={index}>
            <StyledPageContent>
              <Header page={index + 1} />
              <StyledPageSections>
                {pageOfSections.map(getSectionComponent)}
              </StyledPageSections>
              <Footer page={index + 1} nPages={pagesP.allPages.length} />
            </StyledPageContent>
          </StyledPage>
        );
      });
    };

    return (
      <StyledContainer $spacing={editable}>
        {employeeContext && editable && (
          <SidebarContainer sectionConfig={sectionConfig} cvId={cvId} />
        )}
        <StyledPages ref={ref}>{renderPages(pages)}</StyledPages>
      </StyledContainer>
    );
  },
);
