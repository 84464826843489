import styled from 'styled-components';

export const ToastText = styled.p`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
`;

export const ToastTitle = styled.p`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
`;

export const StyledContent = styled.div`
  align-items: center;
  color: #101840;
  display: flex;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 16px;
  gap: 15px;
  padding: 5px;
`;

export const StyledContentWithHeader = styled.div`
  color: #101840;
  display: flex;
  flex-direction: column;
`;
