import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ButtonWrapper } from './DetailSmallButton.styles';

export const DetailSmallButton = () => {
  return (
    <ButtonWrapper color="#3cc2d9">
      <ExpandMoreIcon
        sx={{
          transform: 'rotate(-90deg)',
          fontSize: '20px',
          fontWeight: '500',
          color: '#3cc2d9',
        }}
      />
    </ButtonWrapper>
  );
};
