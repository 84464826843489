import { ButtonGroup } from '@mui/material';
import React from 'react';
import { EditButton } from '../../../../components/common/IconButtons/EditButton';
import { ButtonsWrapper } from './ActionColumn.styles';

export const ActionColumn = React.forwardRef(({ onEditUserHandler }, ref) => (
  <ButtonsWrapper>
    <ButtonGroup variant="outlined" ref={ref}>
      <EditButton action={onEditUserHandler} />
    </ButtonGroup>
  </ButtonsWrapper>
));
