import {
  API_CLIENT_LIST,
  API_CVS_URL,
  API_LEADS,
  API_PARTNER,
  API_URL,
} from '../config/urls';
import { CvIdType } from '../containers/CV/CV.types';
import { ClientListId } from '../store/types/Store.clientList.types';
import { LeadIdType, LeadProfileIdType } from '../store/types/Store.home.types';

export const generateClientListCvsUrl = (listId: string) =>
  `${API_CLIENT_LIST}${listId}/cvs`;

export const generateClientListCandidateNotesUrl = (
  listId: string,
  cvId: string,
) => `${API_CLIENT_LIST}${listId}/cvs/${cvId}/notes`;

export const generateClientListCandidateHistoryUrl = (
  listId: string,
  cvId: string,
) => `${API_CLIENT_LIST}${listId}/cvs/${cvId}/history`;

export const generateClientListCvUrl = (listId: string, cvId: string) =>
  `${API_CLIENT_LIST}${listId}/cvs/${cvId}/history`;

export const generateClientListCandidatesUrl = (listId: string) =>
  `${API_CLIENT_LIST}${listId}/cvs/employees`;

export const generateClientListMessagesUrl = (listId: string) =>
  `${API_CLIENT_LIST}${listId}/notes`;

export const generatePartnerListUrl = (listId: string, partnerId: number) =>
  `${API_CLIENT_LIST}${listId}/partner/${partnerId}`;

export const generatePartnerListCvsUrl = (listId: string, partnerId: number) =>
  `${API_CLIENT_LIST}${listId}/cvs/partner/${partnerId}`;

export const generatePartnerListConfirmFeedbackUrl = (
  listId: string,
  partnerId: number,
) => `${API_CLIENT_LIST}${listId}/confirm/partner/${partnerId}`;

export const generatePartnerListUpdateUrl = (
  listId: string,
  cvId: string,
  partnerId: number,
) => `${API_CLIENT_LIST}${listId}/cvs/${cvId}/partner/${partnerId}`;

export const generatePartnerListCandidateNoteUrl = (
  listId: string,
  cvId: string,
  partnerId: number,
) => `${API_CLIENT_LIST}${listId}/cvs/${cvId}/notes/partner/${partnerId}`;

export const generatePartnerListOptionalMessageUrl = (
  listId: string,
  partnerId: number,
) => `${API_CLIENT_LIST}${listId}/notes/partner/${partnerId}`;

export const generatePartnerListAllEmployeesUrl = (
  listId: string,
  partnerId: number,
) => `${API_CLIENT_LIST}${listId}/cvs/employees/partner/${partnerId}`;

export const generatePartnerListEmployeeDetailsUrl = (
  listId: string,
  cvId: string,
  partnerId: number,
) => `${API_CLIENT_LIST}${listId}/cvs/${cvId}/employees/partner/${partnerId}`;

export const generateExternalClientListUrl = (listId: string) =>
  `${API_CLIENT_LIST}${listId}/public`;

export const generateExternalClientListCvsUrl = (listId: string) =>
  `${API_CLIENT_LIST}${listId}/cvs/public`;

export const generateExternalClientListUpdateUrl = (
  listId: string,
  cvId: string,
) => `${API_CLIENT_LIST}${listId}/cvs/${cvId}/public`;

export const generateExternalClientListConfirmFeedbackUrl = (listId: string) =>
  `${API_CLIENT_LIST}${listId}/confirm/public`;

export const generateExternalClientListCandidateNoteUrl = (
  listId: string,
  cvId: string,
) => `${API_CLIENT_LIST}${listId}/cvs/${cvId}/notes/public`;

export const generateExternalClientListOptionalMessageUrl = (listId: string) =>
  `${API_CLIENT_LIST}${listId}/notes/public`;

export const generateExternalClientListAllEmployeesUrl = (listId: string) =>
  `${API_CLIENT_LIST}${listId}/cvs/employees/public`;

export const generateExternalClientListEmployeeDetailsUrl = (
  listId: string,
  cvId: CvIdType,
) => `${API_CLIENT_LIST}${listId}/cvs/${cvId}/employees/public`;

export const generatePartnerUpdateUrl = (id: number) =>
  `${API_URL}${API_PARTNER}${id}/`;

export const generateLeadRequestsUrl = (id: LeadIdType) =>
  `${API_URL}leads/${id}/profiles/`;

export const generateLeadRequestUrl = (
  leadId: LeadIdType,
  requestId: LeadProfileIdType,
) => `${API_URL}leads/${leadId}/profiles/${requestId}/`;

export const generateLeadRequestCandidatesUrl = (
  leadId: LeadIdType,
  requestId: LeadProfileIdType,
) => `${API_URL}leads/${leadId}/profiles/${requestId}/candidates/`;

export const generateLeadRequestCandidateDetailsUrl = (
  leadId: LeadIdType,
  requestId: LeadProfileIdType,
  candidateId: number,
) => `${API_LEADS}${leadId}/profiles/${requestId}/candidates/${candidateId}/`;

export const generateLeadRequestCandidateStatusHistoryUrl = (
  leadId: LeadIdType,
  requestId: LeadProfileIdType,
  candidateId: number,
) =>
  `${API_LEADS}${leadId}/profiles/${requestId}/candidates/${candidateId}/status/`;

export const generateClientListProfilesUrl = (clientListId: ClientListId) =>
  `${API_CLIENT_LIST}${clientListId}/cvs`;

export const generateClientListProfileUrl = (
  clientListId: ClientListId,
  cvId: CvIdType,
) => `${API_CLIENT_LIST}${clientListId}/cvs/${cvId}`;

export const generateClientListEmployeesUrl = (clientListId: ClientListId) =>
  `${API_CLIENT_LIST}${clientListId}/cvs/employees`;

export const generateClientListExistsUrl = (listId: string) =>
  `${API_CLIENT_LIST}exists?id=${listId}`;

export const generateEmployeePageUrl = (employeeId: string) =>
  `${API_URL}candidates/${employeeId}/`;

export const generateEmployeePageCvsValidationUrl = (employeeId: string) =>
  `${API_CVS_URL}experience-valid/?employee_id=${employeeId}`;
