import { Tooltip as MUITooltip, tooltipClasses } from '@mui/material';
import { styled as MUIstyled } from '@mui/material/styles';

const TOOLTIP_POSITION = {
  sharingCv: -40,
  table: -15,
  close: -10,
  regular: -5,
  'icon-button': 0,
};

export const Tooltip = MUIstyled(({ position, className, ...props }) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(({ position }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    position: 'relative',
    top: TOOLTIP_POSITION[position],
  },
}));
