import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AnyAction } from '@reduxjs/toolkit';
import { Box } from '@mui/material';
import { CandidateChip } from '../../../../../components/common/Chips/CandidateChip';
import { SearchField } from '../../../../../components/common/SearchField/SearchField';
import { Table } from '../../../../../components/common/Table/Table.component';
import { AddButton } from '../../../../../components/common/Buttons/AddButton';
import { Tooltip } from '../../../../../components/common/Tooltip/Tooltip.component';
import { getDateString, getDateTimeString } from '../../../../../Helpers';
import { useEffectAsync } from '../../../../../utils/useEffectAsync';
import {
  getTopFilterOptions,
  modalTypes,
  searchInColumns,
} from './PartnerCompaniesTable.config';
import { StyledTable, StyledSwitch } from './PartnerCompaniesTable.styles';
import {
  TableFilter,
  filterRows,
  countTopFilters,
} from '../../../../../components/common/TableFilter/TableFilter';
import {
  HeadControls,
  MainHeadline,
  FiltersContainer,
  SearchContainer,
  StyledEmail,
  StyledEmptyResult,
} from '../../MyProfile.styles';
import { Counter } from '../../../../../components/common/Counter/Counter';
import { Typography } from '../../../../../components/common/Typography/Typography.component';
import { COLORS } from '../../../../../theme/colors';
import { AppStore } from '../../../../../store/types/Store.types';
import { Company, UserData } from '../../../../../store/types/Store.user.types';
import { Button } from '../../../../../components/common/Button/Button.component';
import { ReactComponent as PhoneIcon } from '../../../../../assets/icons/phone.svg';
import { CompanyDetailsModal } from '../../../../../components/Company/CompanyDetailsModal/CompanyDetailsModal.component';
import { PartnerCompaniesModal } from '../../../../../components/common/Modals/Companies/PartnerCompaniesModal/PartnerCompaniesModal.component';
import { PartnerCompany } from '../../../../../components/common/Modals/Companies/PartnerCompaniesModal/PartnerCompaniesModal.types';
import { companyThunks } from '../../../../Company/Company.thunk';
import { Option } from '../../../../../components/common/Selector/Selector.types';

export const PartnerCompaniesTable = () => {
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.partnerCompanies',
  });
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [modalFor, setModalFor] = useState<
    'inviteCompany' | 'updateCooperationStatus' | null
  >(null);
  const [openModal, setOpenModal] = useState(false);
  const filters = { search: '' };
  const partnerCompanies = useSelector<AppStore, Partial<Company>[]>(
    (state) => state.company.partnerCompanies,
  );
  const user = useSelector<AppStore, UserData | null>(
    (state) => state.user?.user || null,
  );
  const isCompanyAdmin = user?.is_company_administrator || false;
  const [searchValue, setSearchValue] = useState(filters.search ?? '');
  const [topFilterValue, setTopFilterValue] = useState('current');
  const [openCompanyDetailsModal, setOpenCompanyDetailsModal] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null,
  );
  const [partnerCompany, setPartnerCompany] = useState<PartnerCompany | null>(
    null,
  );
  const topFilterOptions = getTopFilterOptions(t);

  const onContactDetailsClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    companyId: number,
  ) => {
    event.stopPropagation();
    setOpenCompanyDetailsModal(true);
    setSelectedCompanyId(companyId);
  };

  const columns = [
    {
      field: 'name',
      headerName: t('company_name'),
      type: 'string',
      align: 'left',
      headerAlign: 'left',
      flex: 1.2,
      minWidth: 180,
      cellClassName: 'name-cell',
      renderCell: ({ row: { name } }: any) => (
        <Typography variant="overline" color={COLORS.typography.body}>
          {name}
        </Typography>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1.2,
      minWidth: 140,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ row }: any) => (
        <StyledEmail href={`mailto:${row.email}`}>{row.email}</StyledEmail>
      ),
    },
    {
      field: 'created_at',
      headerName: t('dateJoined'),
      align: 'left',
      type: 'date',
      headerAlign: 'left',
      flex: 0.9,
      minWidth: 80,
      renderCell: ({ row }: any) => (
        <Tooltip title={getDateTimeString(row.created_at)}>
          <Typography variant="overline" color={COLORS.typography.secondary}>
            {getDateString(row.created_at)}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'contacts',
      headerName: t('contact'),
      flex: 1,
      minWidth: 130,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ row: { companyId } }: any) => (
        <Button
          size="small"
          variant="secondary"
          action={(e) => onContactDetailsClick(e, companyId)}
        >
          <Box
            display="flex"
            gap="10px"
            alignItems="center"
            sx={{ fontSize: '14px' }}
          >
            <PhoneIcon fill="white" />
            {t('contact')}
          </Box>
        </Button>
      ),
    },
    {
      field: 'action',
      headerName: t('actions', { ns: 'common', keyPrefix: 'columns' }),
      flex: 0.3,
      minWidth: 100,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ row: { companyId, isActive, name } }: any) => {
        return (
          <>
            {isCompanyAdmin && (
              <StyledSwitch
                disabled={!user?.is_company_administrator}
                checked={isActive}
                onChange={() =>
                  handleUpdateCooperationStatus(isActive, companyId, name)
                }
                name="status"
                size="small"
              />
            )}
          </>
        );
      },
    },
  ];

  const updateRows = () => {
    setRows(
      filterRows(
        partnerCompanies,
        searchInColumns,
        searchValue,
        topFilterOptions,
        topFilterValue,
      ).map((p: any, i: number) => createRow(p, i)),
    );
  };
  useEffect(() => {
    if (partnerCompanies?.length) {
      updateRows();
      loading && setLoading(false);
    }
  }, [partnerCompanies, searchValue, topFilterValue]);

  useEffectAsync(async () => {
    window.scrollTo(0, 0);
    await dispatch(
      companyThunks.getPartnerCompaniesThunk() as unknown as AnyAction,
    );
  }, []);

  const sendInvitation = async (vatNumber: string, country: Option) => {
    const result = await dispatch(
      companyThunks.sendInvitationToExistCompanyThunk({
        vat_number: vatNumber,
        country: country.value,
      }) as unknown as AnyAction,
    );
    if (
      result.type ===
      companyThunks.sendInvitationToExistCompanyThunk.fulfilled.type
    ) {
      await dispatch(
        companyThunks.getPartnerCompaniesThunk() as unknown as AnyAction,
      );
      setOpenModal(false);
    }
  };

  const stopCooperation = async (id: number) => {
    if (isCompanyAdmin) {
      const result = await dispatch(
        companyThunks.stopPartnerCooperationThunk({
          id,
        }) as unknown as AnyAction,
      );
      if (
        result.type === companyThunks.stopPartnerCooperationThunk.fulfilled.type
      ) {
        await dispatch(
          companyThunks.getPartnerCompaniesThunk() as unknown as AnyAction,
        );
        setOpenModal(false);
      }
    }
  };

  const handleInviteCompany = () => {
    if (isCompanyAdmin) {
      setModalFor(modalTypes.inviteCompany);
      setOpenModal(true);
    }
  };

  const handleUpdateCooperationStatus = (
    isActive: boolean,
    companyId: number,
    companyName: string,
  ) => {
    setModalFor(modalTypes.updateCooperationStatus);
    setOpenModal(true);
    setPartnerCompany({ id: companyId, status: isActive, name: companyName });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const createRow = (companyData: Company, idx: number) => {
    return {
      id: idx,
      companyId: companyData.pk,
      name: companyData.name,
      email: companyData.email,
      contacts: companyData.pk,
      isActive: true,
      created_at: companyData.created_at,
      action: companyData,
    };
  };
  return (
    <StyledTable>
      {modalFor !== null && (
        <PartnerCompaniesModal
          modalFor={modalFor}
          openModal={openModal}
          closeModal={handleCloseModal}
          company={partnerCompany}
          sendInvitation={sendInvitation}
          stopCooperation={stopCooperation}
        />
      )}
      {selectedCompanyId !== null && (
        <CompanyDetailsModal
          openModal={openCompanyDetailsModal}
          setOpenModal={setOpenCompanyDetailsModal}
          companyId={selectedCompanyId}
        />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <MainHeadline>
          {t('partner_companies', { keyPrefix: 'my_profile' })}
          <Counter number={partnerCompanies.length} color="#FFFFFF" />
        </MainHeadline>
        <Box display="flex" gap="10px">
          <SearchContainer>
            <SearchField initialValue={searchValue} onSubmit={setSearchValue} />
          </SearchContainer>
          <AddButton
            disabled={!isCompanyAdmin}
            marginLeft="1"
            action={handleInviteCompany}
          >
            {t('invite')}
          </AddButton>
        </Box>
      </Box>
      {rows.length > 0 ? (
        <Table
          columns={columns}
          rows={rows}
          checkboxSelection={false}
          density="standard"
          loading={loading}
        />
      ) : (
        <StyledEmptyResult>
          {t('no_data_found', { ns: 'common', keyPrefix: 'messages' })}
        </StyledEmptyResult>
      )}
    </StyledTable>
  );
};
