import styled from 'styled-components';

export const AvailabilityWrapper = styled.div`
  display: table-cell;
  margin: 0;
  min-width: 150px;
  padding: 0;
  vertical-align: middle;
`;

export const TooltipContentWrapper = styled.span`
  display: block;
  height: 100%;
`;
