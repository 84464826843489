import type { Currency } from 'store/types/Store.types';
import { FileWithPath } from 'react-dropzone';
import {
  CvIdType,
  EditorContentSectionTypes,
} from '../../containers/CV/CV.types';
// eslint-disable-next-line import/no-cycle
import { EmployeeViewType } from '../../containers/Employee/DEPR_Employee.types';
// eslint-disable-next-line import/no-cycle
import { EmployeeFilters, EmployeeReadableDTO } from './Employees.types';
import type { SuggestedCandidate } from './Store.home.types';
import type { PriorityType } from './Store.types';
import { Option } from '../../components/common/Selector/Selector.types';

export type EmployeeIdType = string;

export interface CandidateAttachment {
  created_at: string;
  file: string;
  name?: string;
  id: number;
  lead_profile?: string | null;
  updated_at: string;
}

export interface Candidate {
  id: string;
  created_at: string;
  updated_at: string;
  cvs?: Cv[];
  hr_notes?: string;
  partner_notes?: string;
  created_by_username: string;
  lead_profile: LeadProfile;
  employee_data: EmployeeData;
  first_name: string;
  last_name: string;
  email?: string;
  availability?: string;
  availability_from?: string;
  availability_to?: string;
  rate_of_pay_from?: number;
  rate_of_pay_to?: number;
  rate_of_pay_currency?: Currency;
  candidate_status: CandidateStatusType;
  current_status?: { status: CandidateStatusType; reason?: string };
  candidate_id?: number;
  owned_by_partner?: boolean;
  files?: CandidateAttachment[] | File[];
  files_to_create?: FileWithPath[] | File[];
  files_to_delete?: number[];
  career_start_date?: any;
  native_language?: Option;
  english_level?: Option;
  additional_language?: Option;
  additional_language_level?: Option;
  links?: string[];
  visibility?: Option;
  seniority?: Option;
  employment_type?: Option;
  priority?: Option;
  description?: string;
  profile?: string;
  project_types?: string[];
  technologies?: number[];
  currency?: Option;
  is_active?: boolean;
}

type LeadProfileStatusType = 'ACTIVE' | 'NOT ACTIVE' | 'WON' | 'LOST';

interface LeadProfile {
  id: number;
  name: string;
  priority: PriorityType;
  description: string;
  lead_profile_status: LeadProfileStatusType;
  suggested_candidates: SuggestedCandidate[];
  reason: string;
  hr_owner: string;
  created_at: string;
  main_technologies: string[];
  files: string[];
}

export interface Technology {
  name: string;
  logo?: string;
}

export interface Cv {
  id: CvIdType;
  author_username: string;
  change_date: string;
  employee: string;
  employee_obj: EmployeeData;
  editor_config: Record<string, unknown>[];
  editor_content: EditorContentSectionTypes;
  is_shared: boolean;
  cv_schema_type: string;
  profile_name: string;
}

export interface Employee extends EmployeeReadableDTO {
  readable_id: string;
  cvs: Cv[];
  technologies: string[];
  project_types: string[];
  is_active: boolean;
  technology_tags: number[];
  career_start_date: string;
  source?: string | null;
  company?: Company;
  visibility?: EmployeeVisibilityStatus;
  files?: File[];
}

export interface EmployeeData extends Employee {
  notes?: string;
  name?: string;
  experience_valid?: boolean;
}

export interface PublicEmployeeData {
  name?: string;
  availability: string;
  experience?: string;
  id: string;
  profile: string;
  rate_of_pay_currency: Currency | '';
  rate_of_pay_from?: number | ''; // this is deprecated and will dissapear some day
  rate_of_pay_to: number | '';
  readable_id: string;
  first_name?: string;
  last_name?: string;
  technologies?: Technology[];
  english_level: EnglishLevelType | '';
}

export interface DEPR_EmployeeDataWithExpirienceValid extends Employee {
  experience_valid: boolean;
}

export type EnglishLevelType = 'A1' | 'A2' | 'B1' | 'B2' | 'C1' | 'C2';
export type Company = {
  pk: number;
  name: string;
  logo: string;
  partner_companies?: number[];
  phone_number: string | null;
  country: { code: string; name: string } | null;
  vat_number?: string | null;
  email?: string | null;
  additional_email: string | null;
  website: string | null;
  link_to_clutch: string | null;
  company_size: number | null;
  address: string | null;
  created_at: string | null;
};

export type CandidateStatusType =
  | 'MATCHED'
  | 'SENT'
  | 'IN THE PROCESS'
  | 'REJECTED'
  | 'LOST'
  | 'ACCEPTED IN ANOTHER PROCESS'
  | 'ACCEPTED';

export interface EmployeeStatisticsType {
  all: number;
  active: number;
}

export enum EmployeeVisibilityStatus {
  COMPANY = 'OWN_COMPANY',
  PARTNERS = 'OWN_AND_PARTNER_COMPANIES',
  EVERYONE = 'EVERYONE',
}

// 1 to 1 rewrite from request reponse
export interface EmployeeTableDataInterface {
  id: EmployeeIdType;
  first_name: string;
  last_name?: string;
  availability: string | null;
  availability_to: string | null;
  career_start_date?: string | null;
  english_level: EnglishLevelType | null;
  profile: string;
  is_active?: boolean;
  rate_of_pay_currency: Currency | string;
  rate_of_pay_from: number | null;
  rate_of_pay_to: number | null;
  recruitee_link?: string | null;
  source: string | null;
  supervisor?: string;
  technologies?: string[];
  technology_tags?: number[];
  project_types?: string[];
  updated_at?: string;
  readable_id: string;
  email?: string;
  cvs: CvGeneralData[];
  company: Company;
  visibility: 'EVERYONE' | 'OWN_AND_PARTNER_COMPANIES' | 'OWN_COMPANY';
  seniority:
    | 'JUNIOR'
    | 'JUNIOR+'
    | 'REGULAR-'
    | 'REGULAR'
    | 'REGULAR+'
    | 'SENIOR-'
    | 'SENIOR'
    | null;
  employment_type: 'PART_TIME' | 'FULL_TIME' | null;
  employee_fit?: number;
  observed?: boolean;
}

export interface EmployeeTableDataInterfaceWithExperienceValid
  extends EmployeeTableDataInterface {
  type: EmployeeViewType.HR;
  experience_valid: boolean;
  cvs: {
    id: string;
    is_shared: boolean;
    profile_name: string;
    experience_valid: boolean;
  }[];
}

export interface CvGeneralData {
  id: string;
  is_shared: boolean;
  profile_name: string;
}

export interface EmployeeSliceInterface {
  people: {
    count: number;
    items_per_page: number;
    results: EmployeeTableDataInterface[];
  };
  watched: EmployeeTableDataInterface[];
  statistics: EmployeeStatisticsType | null;
  employee: EmployeeTableDataInterface;
  filters: EmployeeFilters;
  employeesLoaded: boolean;
  peopleWithValidExp?: EmployeeTableDataInterfaceWithExperienceValid[];
  possibleCandidates?: EmployeeTableDataInterfaceWithExperienceValid[];
  suggestedSources: string[];
  publicPeople: PublicEmployeeData[];
}
