import { AnyAction } from '@reduxjs/toolkit';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CollapseTable } from '../../../../components/common/CollapseTable';
import { Counter } from '../../../../components/common/Counter/Counter';
import { Loader } from '../../../../components/common/Loader';
import { LeadDetailModal } from '../../../../components/common/Modals/LeadDetail/LeadDetailModal/LeadDetailModal';
import { ModalLeadDetailsType } from '../../../../components/common/Modals/LeadDetail/LeadDetailModal/LeadDetailModal.types';
import {
  Candidate,
  CandidateStatusType,
} from '../../../../store/types/Store.candidates.types';
import {
  HomeLeadProfile,
  LeadIdType,
  LeadProfileIdType,
  SuggestedCandidate,
} from '../../../../store/types/Store.home.types';
import { Lead, ReasonTag } from '../../../../store/types/Store.leads.types';
import { AppStore } from '../../../../store/types/Store.types';
import { useEffectAsync } from '../../../../utils/useEffectAsync';
import { candidateReasonTagsThunks } from '../../../Leads/redux/tags/CandidateReasonTags.thunk';
import { myVacanciesPageThunks } from './MyVacancies.thunk';

import {
  getHomeColumnsAmount,
  getTopFilterOptions,
  searchInColumns,
} from './config';
import { CreateRow } from '../../../Vacancies/VacanciesPageHrSales/CreateRow';
import { CreatedRowHome } from '../../../Vacancies/VacanciesPageHrSales/CreateRow/CreateRow.types';
import { VacanciesPageContext } from '../../../Vacancies/VacanciesPageHrSales/VacanciesPageContext/VacanciesPageContext';
import { HandleShowChangeStatusWithReasonType } from '../../../Vacancies/VacanciesPageHrSales/VacanciesPageContext/VacanciesPageContext.types';
import { StyledLeadsContainer, StyledLeadTable } from './MyVacancies.styles';
import {
  HeadControls,
  MainHeadline,
  SearchContainer,
  FiltersContainer,
} from '../MyProfile.styles';
import { useHandleSuggestedCandidate } from '../../../Vacancies/VacanciesPageHrSales/useHandleSuggestedCandidate/useHandleSuggestedCandidate';

import {
  countTopFilters,
  filterRows,
  TableFilter,
} from '../../../../components/common/TableFilter/TableFilter';

import { SearchField } from '../../../../components/common/SearchField/SearchField';
import { AddButton } from '../../../../components/common/Buttons/AddButton';
import { LeadsModal } from '../../../../components/common/Modals/Leads/LeadsModal';
import { RootState } from '../../../../store/store';
import { leadsListThunks } from '../../../Leads/redux/LeadsList.thunk';
import { leadProfilesThunks } from '../../../Leads/redux/LeadProfiles.thunk';
import { HomeColumn } from '../../../../components/common/CollapseTable/CollapseTable.types';
import { ActionColumn } from './ActionColumn/ActionColumn.component';
import { ModalLeadsTableType } from '../../../../components/common/Modals/Leads/LeadsModal/LeadsModal.types';

export const MyVacancies = () => {
  const { t } = useTranslation(['user'], {
    keyPrefix: 'my_profile.vacancies',
  });
  const dispatch = useDispatch();
  const leadProfiles = useSelector<AppStore, HomeLeadProfile[]>(
    (state) => state.myVacancies.leadProfiles,
  );
  const leads = useSelector<AppStore, Lead[]>(
    (state) => state.leadsRelated.list,
  );
  const profileTags = useSelector<AppStore, string[]>((state) =>
    state.leadsRelated.tags.profile?.map((tag: any) => tag.name),
  );
  const candidateReasonTags = useSelector<AppStore, ReasonTag[]>(
    (state) => state.leadsRelated.tags.candidate_reason,
  );
  const hrUsers = useSelector<RootState, any[]>((state) => state.user.HRUsers);
  const profileReasonTags = useSelector<AppStore, string[]>((state) =>
    state.leadsRelated.tags.profile_reason?.map((tag: any) => tag.reason),
  );
  const [isLoading, setLoading] = useState({
    stats: true,
    home: true,
  });
  const [searchValue, setSearchValue] = useState('');
  const columns: HomeColumn[] = [
    {
      id: 'profile',
      numeric: false,
      disablePadding: true,
      label: 'profile',
      sortable: true,
      align: 'left',
      isShowTooltip: true,
      type: 'string',
      minWidth: '190px',
      maxWidth: '190px',
      color: '#000000',
      flexBasisMobView: '80%',
      order: 3,
    },
    {
      id: 'date',
      numeric: true,
      disablePadding: true,
      label: 'date',
      sortable: true,
      align: 'left',
      isShowTooltip: true,
      type: 'date',
      minWidth: '90px',
      padding: '6px 6px 6px 16px',
      flexBasisMobView: '26%',
      order: 4,
    },
    {
      id: 'company',
      numeric: false,
      disablePadding: true,
      label: 'company',
      sortable: true,
      align: 'left',
      isShowTooltip: false,
      type: 'string',
      minWidth: '170px',
      maxWidth: '170px',
      flexBasisMobView: '64%',
      order: 5,
    },
    {
      id: 'companyContactDetails',
      numeric: false,
      disablePadding: true,
      label: 'contact',
      sortable: false,
      align: 'left',
      isShowTooltip: false,
      type: 'string',
      minWidth: '105px',
      maxWidth: '105px',
      flexBasisMobView: '64%',
      order: 6,
    },
    {
      id: 'priority',
      numeric: false,
      disablePadding: true,
      label: 'priority',
      sortable: true,
      align: 'left',
      isShowTooltip: false,
      minWidth: '90px',
      padding: '6px 16px 6px 0',
      flexBasisMobView: '30%',
      order: 7,
    },
    {
      id: 'candidatesNum',
      numeric: true,
      disablePadding: true,
      label: 'candidates',
      sortable: true,
      align: 'left',
      isShowTooltip: false,
      type: 'number',
      color: '#000000',
      minWidth: '40px',
      maxWidth: '40px',
      padding: '6px 0 6px 16px',
      flexBasisMobView: '17%',
      order: 8,
    },
    {
      id: 'hrOwner',
      numeric: false,
      disablePadding: true,
      label: 'hr_owner',
      sortable: true,
      align: 'left',
      isShowTooltip: false,
      flexBasisMobView: '51%',
      order: 9,
    },
    {
      id: 'action',
      align: 'right',
      disablePadding: true,
      flexBasisMobView: '20%',
      order: 10,
      isShowTooltip: false,
      numeric: false,
      label: 'actions',
      sortable: false,
      renderCell: ({ value, row }) => {
        return (
          <>
            {row.action.belonging === 'my' ? (
              <ActionColumn
                visibility={row.action.visibility}
                onEditHandler={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();
                  setCurrentLeadId(row.action.lead_id);
                  setCurrentLeadProfileId(row.action.lead_profile_id);
                  editProfile();
                }}
                onDeleteHandler={(
                  event: React.MouseEvent<HTMLButtonElement>,
                ) => {
                  event.stopPropagation();
                  handleDeleteLeadProfileModal(
                    row.action.lead_profile_id,
                    row.action.lead_id,
                  );
                }}
                onPublishHandler={(publishStatus: string) => {
                  changeVisibility(
                    row.action.lead_id,
                    row.action.lead_profile_id,
                    publishStatus,
                  );
                }}
              />
            ) : (
              '-'
            )}
          </>
        );
      },
    },
  ];
  const translatedColumns = columns.map((column) => ({
    ...column,
    label: t(column.label),
  }));
  const [modalFor, setModalFor] = useState<ModalLeadDetailsType>();
  const [modalForLeads, setModalForLeads] = useState<ModalLeadsTableType>();
  const [isOpenProfileModal, setIsOpenProfileModal] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [currentLeadProfile, setCurrentLeadProfile] =
    useState<HomeLeadProfile>();
  const [candidateInfo, setCandidateInfo] = useState<
    SuggestedCandidate | Candidate
  >();
  const [suggestedCandidatesIds, setSuggestedCandidatesIds] = useState<
    string[]
  >([]);
  const [currentCandidateId, setCurrentCandidateId] = useState<number>();
  const [currentLeadId, setCurrentLeadId] = useState<number | null>();
  const [currentLeadProfileId, setCurrentLeadProfileId] = useState<number>();

  const [statusChangeInfo, setStatusChangeInfo] = useState<{
    oldStatus: CandidateStatusType;
    newStatus: string;
  }>();
  const [mappedListProfiles, setMappedListProfiles] = useState<
    CreatedRowHome[]
  >([]);
  const candidates = useSelector<AppStore, SuggestedCandidate[] | undefined>(
    (state) =>
      state.myVacancies.leadProfiles.find(
        (elem) => elem.lead_profile_id === currentLeadProfile?.lead_profile_id,
      )?.candidates,
  );

  const topFilterOptions = getTopFilterOptions(t);
  const [topFilterValue, setTopFilterValue] = useState('my');
  const {
    deleteSuggestedCandidate,
    handleDeleteCandidateModal,
    candidateToDelete,
  } = useHandleSuggestedCandidate({ setModalFor, setOpenDetailModal });
  const [profileToDelete, setProfileToDelete] = useState<{
    leadProfileId: LeadProfileIdType;
    leadId: LeadIdType;
  }>();
  const updateRows = () => {
    setMappedListProfiles(
      filterRows(
        leadProfiles,
        searchInColumns,
        searchValue,
        topFilterOptions,
        topFilterValue,
      ).map((p: HomeLeadProfile) => CreateRow(p)),
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    updateRows();
  }, [leadProfiles, searchValue, topFilterValue]);

  const handleAddSuggestedCandidateButton = (
    newLeadProfile: HomeLeadProfile,
  ) => {
    const newSuggestedCandidatesIds = candidates?.length
      ? candidates.map(
          (candidate) =>
            candidate.employee ||
            candidate.employee_id ||
            candidate.employee_data.id,
        )
      : [];
    setSuggestedCandidatesIds(newSuggestedCandidatesIds);
    setCurrentLeadProfile(newLeadProfile);
    setModalFor('addSuggestedCandidates');
    setOpenDetailModal(true);
  };
  const loadVacancies = async () => {
    await Promise.all([
      dispatch(
        candidateReasonTagsThunks.getCandidateReasonTagsThunk() as unknown as AnyAction,
      ),
      await dispatch(
        myVacanciesPageThunks.getMyVacanciesDataThunk() as unknown as AnyAction,
      ),
      dispatch(leadsListThunks.getLeadsThunk() as unknown as AnyAction),
    ]);
    setLoading((prevLoading) => ({ ...prevLoading, home: false }));
  };
  useEffectAsync(async () => {
    loadVacancies();
  }, []);

  const handleShowCandidatesDetails = (
    candidate: SuggestedCandidate,
    leadProfile: HomeLeadProfile,
  ) => {
    setCurrentLeadProfile(leadProfile);
    candidate.candidate_id && setCurrentCandidateId(candidate.candidate_id);
    setCandidateInfo(candidate);
    setModalFor('candidateDetails');
    setOpenDetailModal(true);
  };

  const handleShowChangeStatusWithReason: HandleShowChangeStatusWithReasonType =
    (oldStatus, newStatus, suggestedCandidate, leadProfile) => {
      suggestedCandidate && setCandidateInfo(suggestedCandidate);
      setStatusChangeInfo({ newStatus, oldStatus });
      setModalFor('reasonChangeStatus');
      leadProfile && setCurrentLeadProfile(leadProfile);
      setOpenDetailModal(true);
    };

  const handleDeleteLeadProfile: any = async () => {
    if (profileToDelete) {
      const result = await dispatch(
        leadProfilesThunks.deleteLeadRequestThunk({
          leadId: profileToDelete.leadId,
          requestId: profileToDelete.leadProfileId,
        }) as unknown as AnyAction,
      );
      if (
        result.type === leadProfilesThunks.deleteLeadRequestThunk.fulfilled.type
      ) {
        loadVacancies();
      }
    }
  };
  const handleDeleteLeadProfileModal: any = (
    leadProfileId: number,
    leadId: number,
  ) => {
    setProfileToDelete({ leadProfileId, leadId });
    setModalFor('deleteLeadProfile');
    setTimeout(() => {
      setOpenDetailModal(true);
    }, 100);
  };
  const addProfile = () => {
    setModalForLeads('addingRequestModal');
    setIsOpenProfileModal(true);
    setCurrentLeadId(null);
  };

  const editProfile = () => {
    setModalForLeads('editingRequestModal');
    setTimeout(() => {
      setIsOpenProfileModal(true);
    }, 100);
  };

  const changeVisibility = async (
    leadId: number,
    leadProfileId: number,
    newValue: string,
  ) => {
    const data = leadProfileId &&
      leadId && {
        request: { visibility: newValue } as unknown as BodyInit,
        requestId: leadProfileId,
        leadId,
      };
    if (data) {
      const result: any = await dispatch(
        leadProfilesThunks.updateLeadRequestThunk(data) as unknown as AnyAction,
      );

      if (
        result.type === leadProfilesThunks.updateLeadRequestThunk.fulfilled.type
      ) {
        dispatch(
          myVacanciesPageThunks.getMyVacanciesDataThunk() as unknown as AnyAction,
        );
      }
    }
  };

  const handleCloseProfileModal = () => {
    setIsOpenProfileModal(false);
  };
  const vacanciesHrContextValue = useMemo(
    () => ({
      handleAddSuggestedCandidateButton,
      handleDeleteCandidateModal,
      handleShowCandidatesDetails,
      handleShowChangeStatusWithReason,
      deleteSuggestedCandidate,
      handleDeleteLeadProfileModal,
      handleDeleteLeadProfile,
      editProfile,
    }),
    [candidateToDelete, profileToDelete, currentLeadId, currentLeadProfileId],
  );
  return (
    <VacanciesPageContext.Provider value={vacanciesHrContextValue}>
      {isLoading.home ? (
        <Loader />
      ) : (
        <>
          <HeadControls>
            <MainHeadline>
              {t('my_vacancies', { keyPrefix: 'my_profile' })}
            </MainHeadline>
            <Counter number={leadProfiles.length} color="#FFFFFF" />
          </HeadControls>
          <HeadControls>
            <FiltersContainer>
              <TableFilter
                filterOptions={topFilterOptions}
                filterValue={topFilterValue}
                filterCounts={countTopFilters(
                  filterRows(
                    leadProfiles,
                    searchInColumns,
                    searchValue,
                    topFilterOptions,
                    topFilterValue,
                    false,
                  ),
                  topFilterOptions,
                )}
                changeHandler={setTopFilterValue}
              />
            </FiltersContainer>
            <SearchContainer>
              <SearchField
                initialValue={searchValue}
                onSubmit={setSearchValue}
              />
            </SearchContainer>
            <AddButton action={addProfile} marginLeft="1">
              {t('add_vacancy')}
            </AddButton>
          </HeadControls>
          {modalFor && (
            <LeadDetailModal
              name={candidateToDelete?.name || ''}
              modalFor={modalFor!}
              openModal={openDetailModal}
              closeModal={() => setOpenDetailModal(false)}
              homeLeadProfile={currentLeadProfile}
              candidateInfo={candidateInfo}
              reasonOfChangingStatus={
                (candidateInfo as SuggestedCandidate)?.candidate_reason || ''
              }
              employeeId={(candidateInfo as SuggestedCandidate)?.employee_id}
              candidateStatus={candidateInfo?.current_status?.status}
              suggestedCandidatesIds={suggestedCandidatesIds}
              candidateId={currentCandidateId}
              reasonTags={candidateReasonTags}
              newStatus={statusChangeInfo?.newStatus as CandidateStatusType}
              oldStatus={statusChangeInfo?.oldStatus}
              leadId={Number(currentLeadProfile?.lead_id)}
              profileId={Number(currentLeadProfile?.lead_profile_id)}
            />
          )}
          {modalForLeads && (
            <LeadsModal
              profileTags={profileTags}
              modalFor={modalForLeads}
              isModalOpen={isOpenProfileModal}
              closeModal={handleCloseProfileModal}
              hrOwners={hrUsers.map((usr) => usr.username)}
              reasonTags={profileReasonTags}
              leads={leads.filter((lead) => lead.status === 'ACTIVE')}
              leadProfileId={currentLeadProfileId}
              leadId={currentLeadId || null}
              leadIdToEdit={currentLeadId || undefined}
            />
          )}
          <StyledLeadTable>
            <StyledLeadsContainer>
              <CollapseTable
                tableData={{
                  columns: translatedColumns,
                  rows: mappedListProfiles,
                  type: 'home',
                }}
                defaultSortKey="priority"
                homeLeads={leadProfiles}
                columnAmount={getHomeColumnsAmount(columns)}
                type="my-candidates"
              />
            </StyledLeadsContainer>
          </StyledLeadTable>
        </>
      )}
    </VacanciesPageContext.Provider>
  );
};
