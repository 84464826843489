import { getDateString, getDateTimeString } from '../../../../../../Helpers';
import { Loader } from '../../../../Loader';
import { Tooltip } from '../../../../Tooltip/Tooltip.component';
import {
  AdditionalHeadline,
  HistoryStatusItem,
  HistoryStatusList,
  NoHistory,
  StatusDate,
  StatusHistoryContainer,
} from '../CandidateDetailsModalContent.styles';
import { StatusHistoryProps } from './StatusHistory.types';

export const StatusHistory = ({
  displayedStatusHistory,
}: StatusHistoryProps) => {
  if (displayedStatusHistory.length === 0) {
    return <Loader />;
  }
  return (
    <StatusHistoryContainer>
      <AdditionalHeadline>Status history</AdditionalHeadline>
      {displayedStatusHistory.length ? (
        <HistoryStatusList>
          {displayedStatusHistory.map((el, index) => (
            <HistoryStatusItem key={`${index}-${el.created_at}`}>
              {`${el.showedName}: `}
              <Tooltip
                title={getDateTimeString(el.created_at)}
                position="regular"
              >
                <StatusDate>{getDateString(el.created_at)}</StatusDate>
              </Tooltip>
            </HistoryStatusItem>
          ))}
        </HistoryStatusList>
      ) : (
        <NoHistory>This candidate has no history.</NoHistory>
      )}
    </StatusHistoryContainer>
  );
};
