import { createSlice } from '@reduxjs/toolkit';
import { NotificationState } from '../../interfaces/Notifications.types';
import { notificationsThunks } from './Notification.thunks';

const { getMyNotifications, markAllNotificationsAsRead } = notificationsThunks;

const initialState: NotificationState = {
  notificationArray: [],
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    optimisticNotificationUpdate: (state, action) => {
      const { notificationIndex, newStatus } = action.payload;
      state.notificationArray[notificationIndex].isRead = newStatus;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyNotifications.fulfilled, (state, action) => {
      state.notificationArray = action.payload.map((el: any) => ({
        id: el.id,
        title: el.title,
        referenceLink: el.reference_link,
        variant: el.variant,
        dateString: el.write_at,
        isRead: el.is_read,
      }));
    });
    builder.addCase(markAllNotificationsAsRead.pending, (state, _) => {
      state.notificationArray.forEach((el) => {
        el.isRead = true;
      });
    });
  },
});

export const actions = { ...notificationSlice.actions };
export const { reducer } = notificationSlice;
