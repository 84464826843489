import { createSlice } from '@reduxjs/toolkit';
import { companyThunks } from './Company.thunk';
import { CompanySliceInterface } from '../../store/types/Store.company';

const {
  registerCompanyThunk,
  updateCompanyInfoThunk,
  getCompanySizesThunk,
  getCountriesThunk,
  getCompanyInfoThunk,
  getPartnerCompaniesThunk,
} = companyThunks;

const initialState: CompanySliceInterface = {
  company: {
    company_name: '',
    country: null,
    company_size: null,
    full_name: '',
    password: '',
    password2: '',
    vat_number: '',
    email: '',
    logo: '',
    phone_number: '',
    name: '',
    pk: 0,
    additional_email: '',
    link_to_clutch: '',
  },
  countries: {},
  companySizyOptions: [],
  partnerCompanies: [],
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: {
    [registerCompanyThunk.fulfilled as unknown as string]: (state, action) => {
      const data = action.payload;
      state.company = data;
    },
    [getCompanyInfoThunk.fulfilled as unknown as string]: (state, action) => {
      const data = action.payload;
      state.company = data;
    },
    [getPartnerCompaniesThunk.fulfilled as unknown as string]: (
      state,
      action,
    ) => {
      const data = action.payload;
      state.partnerCompanies = data;
    },
    [updateCompanyInfoThunk.fulfilled as unknown as string]: (
      state,
      action,
    ) => {
      const data = action.payload;
      state.company = data;
    },
    [getCompanySizesThunk.fulfilled as unknown as string]: (state, action) => {
      const data = action.payload;
      state.companySizyOptions = data;
    },
    [getCountriesThunk.fulfilled as unknown as string]: (state, action) => {
      const data = action.payload;
      state.countries = data;
    },
  },
});

export const actions = { ...companySlice.actions };
export const { reducer } = companySlice;
