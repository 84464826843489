import { AnyAction } from '@reduxjs/toolkit';
import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UpdateLeadRequestThunkArgs } from '../../../../../../containers/Leads/Leads.types';
import { leadProfilesThunks } from '../../../../../../containers/Leads/redux/LeadProfiles.thunk';
import { LeadProfile } from '../../../../../../store/types/Store.leads.types';
import { AppStore } from '../../../../../../store/types/Store.types';
import { raiseToast } from '../../../../../../utils/raiseToasts';
import { LeadRequestForm } from '../../../../../Lead/LeadRequestForm';
import { buttonsColors } from '../../../../Button/Button.config';
import { RequestEditingModalContentProps } from './RequestEditingModalContent.types';
import { myVacanciesPageThunks } from '../../../../../../containers/User/MyProfile/MyVacancies/MyVacancies.thunk';
import { Option } from '../../../../Selector/Selector.types';

export const RequestEditingModalContent = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<RequestEditingModalContentProps>
>(({ closeModal, hrOwners, nameTags, reasonTags, leadId, requestId }, ref) => {
  const { t } = useTranslation(['common', 'vacancies'], {
    keyPrefix: 'buttons',
  });
  const dispatch = useDispatch();
  const requestFetched = useSelector<AppStore, LeadProfile | undefined>(
    (state) =>
      state.leadsRelated.profiles.find((elem) => elem.id === requestId),
  );
  const request = requestFetched ? requestFetched : {};

  useEffect(() => {
    dispatch(
      leadProfilesThunks.getLeadRequestsThunk({
        leadId,
      }) as unknown as AnyAction,
    );
  }, [leadId, requestId]);

  const handleSubmit = async (data: UpdateLeadRequestThunkArgs) => {
    const result = await dispatch(
      leadProfilesThunks.updateLeadRequestThunk(data) as unknown as AnyAction,
    );

    if (
      result.type === leadProfilesThunks.updateLeadRequestThunk.fulfilled.type
    ) {
      dispatch(
        leadProfilesThunks.getLeadRequestsThunk({
          leadId,
        }) as unknown as AnyAction,
      );
      dispatch(
        myVacanciesPageThunks.getMyVacanciesDataThunk() as unknown as AnyAction,
      );
      closeModal();
    }
  };

  const getButtonsConfig = (leadRequest: Partial<LeadProfile>) => {
    const data = requestId && {
      request: {
        ...leadRequest,
        visibility: (leadRequest.visibility as Option)?.value,
      } as unknown as BodyInit,
      requestId,
      leadId,
    };
    return {
      primaryButton: {
        label: t('save'),
        color: buttonsColors.default,
        action: () =>
          data
            ? handleSubmit(data)
            : raiseToast.error(
                t('something_went_wrong', {
                  ns: 'common',
                  keyPrefix: 'raiseToastMessage',
                }),
              ),
      },
    };
  };

  return (
    <LeadRequestForm
      ref={ref}
      dialogTitle={t('edit_request', {
        ns: 'vacancies',
        keyPrefix: 'vacancies',
      })}
      getButtonsConfig={getButtonsConfig}
      handleClose={closeModal}
      requestFetched={request}
      hrOwners={hrOwners}
      nameTags={nameTags}
      reasonTags={reasonTags}
    />
  );
});
