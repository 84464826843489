import Box from '@mui/material/Box';
import {
  styled as styledMaterialUI,
  styled as materialUIStyled,
} from '@mui/material/styles';
import styled from 'styled-components';
import Link from '@mui/material/Link';
import { COLORS } from '../../../theme/colors';

export const MyProfileContainer = materialUIStyled(Box)({
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
});

export const MyProfileMain = materialUIStyled(Box)({
  mt: 4,
  mb: 4,
  padding: '40px 80px 40px 40px',
});

export const MainHeadline = styled.h1`
  align-items: center;
  color: #000000;
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  gap: 5px;
  line-height: 22px;
  margin: 0;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex: 1;
  padding-top: 10px;
`;

export const SearchContainer = styled.div`
  min-width: 300px;
`;

export const HeadControls = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
`;

export const StyledEmail = styled.a`
  color: ${COLORS.typography.secondary};
  font-size: 12px;
  font-weight: 500;
`;

export const StyledLink = styledMaterialUI(Link)({
  color: 'inherit',
  fontSize: '14px',
  textDecoration: 'none',
});

export const StyledEmptyResult = styled.div`
  background: ${COLORS.background.secondary};
  margin-top: 20px;
  padding: 50px;
  text-align: center;
  width: 100%;
`;
