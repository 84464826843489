import { useTranslation } from 'react-i18next';
import { PriorityKeyType } from '../../CollapseTable/CollapseTable.types';

export const usePriorityOptionsConfig = () => {
  const { t } = useTranslation(['common'], { keyPrefix: 'priorityOptions' });

  const priorityOptions: PriorityOptionsType[] = [
    {
      label: t('low'),
      priority: 'low',
    },
    {
      label: t('medium'),
      priority: 'medium',
    },
    {
      label: t('high'),
      priority: 'high',
    },
    {
      label: t('very_high'),
      priority: 'very high',
    },
  ];
  return { priorityOptions };
};

export type PriorityOptionsType = {
  label: string;
  priority: PriorityKeyType;
};
