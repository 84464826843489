import { Checkbox, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { StyledBaseTableHeaderCell } from './BaseTable.styles';
import {
  BaseTableHeaderCellProps,
  BaseTableHeaderProps,
  ComparatorType,
} from './BaseTable.types';

export const BaseTableHeaderCell = (
  props: BaseTableHeaderCellProps,
): JSX.Element => {
  const { column, customHeaderCellStyles, orderBy, order, sortHandler } = props;
  const {
    field,
    align,
    disablePadding,
    sortable,
    headerName,
    renderHeader,
    sortComparator,
    customStyles,
  } = column;
  const fieldContent = headerName ?? field ?? 'no name';

  if (renderHeader) {
    return (
      <StyledBaseTableHeaderCell
        align={align}
        key={field}
        style={{
          ...customStyles,
          ...customHeaderCellStyles,
        }}
        padding={disablePadding ? 'none' : 'normal'}
        sortDirection={orderBy === field ? order : false}
      >
        {renderHeader({
          fieldContent,
          key: `${field}-header-value`,
        })}
      </StyledBaseTableHeaderCell>
    );
  }

  if (sortable !== false) {
    return (
      <StyledBaseTableHeaderCell
        align={align}
        key={field}
        style={{
          ...customStyles,
          ...customHeaderCellStyles,
        }}
        padding={disablePadding ? 'none' : 'normal'}
        sortDirection={orderBy === field ? order : false}
      >
        <TableSortLabel
          active={orderBy === field}
          direction={orderBy === field ? order : 'asc'}
          onClick={sortHandler(field, sortComparator)}
        >
          {fieldContent}
        </TableSortLabel>
      </StyledBaseTableHeaderCell>
    );
  }
  return (
    <StyledBaseTableHeaderCell
      align={align}
      key={field}
      padding={disablePadding ? 'none' : 'normal'}
      variant="body"
      style={customHeaderCellStyles}
    >
      {fieldContent}
    </StyledBaseTableHeaderCell>
  );
};

export const BaseTableHeader = ({
  onSortEvent,
  headerProps,
  isSelectable,
  customHeaderCellStyles,
  checkedSelectionState,
  onSelectAllChange,
  columns,
  order,
  orderBy,
}: BaseTableHeaderProps) => {
  const createSortHandle =
    (property: string, customComparator?: ComparatorType) => () => {
      onSortEvent(property, customComparator);
    };

  return (
    <TableHead {...headerProps}>
      <TableRow>
        {isSelectable && (
          <StyledBaseTableHeaderCell style={customHeaderCellStyles}>
            <Checkbox
              color="primary"
              checked={checkedSelectionState}
              onChange={onSelectAllChange}
            />
          </StyledBaseTableHeaderCell>
        )}
        {columns.map((column, index) => (
          <BaseTableHeaderCell
            key={index}
            column={column}
            order={order}
            orderBy={orderBy}
            sortHandler={createSortHandle}
            customHeaderCellStyles={customHeaderCellStyles}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};
