import { DisabledLabel, StyledLabel } from './FormLabel.styles';
import { FormLabelProps } from './FormLabel.types';

export const FormLabel = ({
  label,
  htmlFor,
  disabled,
  required,
  error = false,
}: FormLabelProps) => {
  if (disabled) {
    return (
      <DisabledLabel error={!!error} htmlFor={htmlFor}>
        {label}
      </DisabledLabel>
    );
  }
  if (required) {
    return (
      <StyledLabel error={!!error} htmlFor={htmlFor}>
        <span>
          {label}
          <span style={{ color: 'red', fontSize: '0.8rem' }}>*</span>
        </span>
      </StyledLabel>
    );
  }

  return (
    <StyledLabel error={!!error} htmlFor={htmlFor}>
      {label}
    </StyledLabel>
  );
};
