import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  MainContainer,
  StyledEmail,
  SocialMediaContainer,
  StyledIcon,
  BottomContainer,
  StyledLink,
} from './Footer.styles';
import { ReactComponent as Logo } from '../images/navbar_logo.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/icons/linkedin.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/fb.svg';
import { AppStore } from '../../../store/types/Store.types';
import { UserState } from '../../../store/types/Store.user.types';
import { AppPaths } from '../../../config/AppPaths';

export const Footer: any = () => {
  const user = useSelector<AppStore, UserState>((state) => state.user);
  const location = useLocation();
  const isCvPage = location.pathname.includes('cv');
  const { t } = useTranslation(['common']);

  return (
    <>
      {!isCvPage && user.token && user.user && (
        <MainContainer>
          <Logo width={170} height={38} />
          <StyledEmail href="mailto:hello@benchly.it">
            hello@benchly.it
          </StyledEmail>
          <SocialMediaContainer>
            <StyledIcon
              href="https://www.linkedin.com/company/benchlyit/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedinIcon />
            </StyledIcon>
            <StyledIcon
              href="https://www.facebook.com/profile.php?id=61552907889830"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </StyledIcon>
          </SocialMediaContainer>
          <BottomContainer>
            <StyledLink to={AppPaths.privacyPolicy}>
              {t('privacy_policy')}
            </StyledLink>
            <StyledLink to={AppPaths.termsAndConditions}>
              {t('terms_and_conditions')}
            </StyledLink>
          </BottomContainer>
        </MainContainer>
      )}
    </>
  );
};
