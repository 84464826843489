import { useTranslation } from 'react-i18next';

export const useNotificationConfig = () => {
  const { t } = useTranslation(['common'], { keyPrefix: 'notifications' });
  const variantToNotificationTypeLookup: { [value: string]: string } = {
    feedback: t('types.feedback'),
    administration: t('types.administration'),
    information: t('types.information'),
  };
  return { variantToNotificationTypeLookup };
};
