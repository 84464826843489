import { createSlice } from '@reduxjs/toolkit';
import { actions as cvActions } from '../../CV.slice';
import { cvThunks } from '../../CV.thunk';

const { loadCvThunk, injectCvDataThunk } = cvThunks;

const initialState = {
  headerText: 'Side projects',
  entries: [],
};

const sideProjectsSlice = createSlice({
  name: 'sideProjects',
  initialState,
  reducers: {
    createData: (state, action) => {
      const sectionId = action.payload;
      state.entries.push({
        sectionId,
        data: {
          timePeriod: '',
          projectName: '',
          projectDescription: '',
          technologies: '',
        },
      });
    },
    updateHeaderText: (state, action) => {
      state.headerText = action.payload;
    },
    updateEntryData: (state, action) => {
      const { sectionId, data } = action.payload;

      const entryData = state.entries.find(
        (entry) => entry.sectionId === sectionId,
      ).data;
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in data) {
        entryData[key] = data[key];
      }
    },
    createSubData: (state, { payload }) => {
      const { sectionId, propertyName, initialData } = payload;
      const entryData = state.entries.find(
        (entry) => entry.sectionId === sectionId,
      );
      entryData.data[propertyName] = initialData;
    },
    deleteSubData: (state, { payload }) => {
      const { sectionId, propertyName } = payload;
      const entryData = state.entries.find(
        (entry) => entry.sectionId === sectionId,
      );
      delete entryData.data[propertyName];
    },
    deleteData: (state, action) => {
      const sectionId = action.payload;
      const index = state.entries.findIndex(
        (entry) => entry.sectionId === sectionId,
      );
      state.entries.splice(index, 1);
    },
    reset: () => initialState,
  },
  extraReducers: {
    [cvActions.reset]: () => initialState,
    [loadCvThunk.fulfilled]: (state, action) => {
      const { editor_content: sectionsData } = action.payload;
      return sectionsData.sideProjects;
    },
    [injectCvDataThunk.fulfilled]: (state, action) => {
      const { editor_content: sectionsData } = action.payload;
      return sectionsData.sideProjects;
    },
  },
});

export const { actions } = sideProjectsSlice;
export const { reducer } = sideProjectsSlice;
