import { isPartner } from '../../../../Helpers';
import { getHrOwnerProps } from '../LeadRequestForm.types';

export const getHrOwner = ({
  requestFetched,
  userState,
}: getHrOwnerProps): string | null | undefined => {
  if (requestFetched) {
    return requestFetched.hr_owner;
  }
  if (isPartner(userState?.user)) {
    return undefined;
  }
  return userState?.user?.username;
};
