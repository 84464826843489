import styled from 'styled-components';

export const StyledRow = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 24px;
  width: 100%;

  @media (max-width: 699px) {
    height: 150px;
    flex-direction: column;
    gap: 0px;
  }
`;

export const ChipsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

export const StyledLabelContainer = styled.div`
  align-items: flex-start;
  display: flex;
  height: 60px;
  padding-top: 4px;
`;

export const PriorityContainer = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 17px;
  width: 300px;
  @media (max-width: 699px) {
    margin-top: 25px;
  }
`;

export const StatusContainer = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 11px;
  width: 300px;

  @media (max-width: 699px) {
    margin-top: 25px;
  }
`;
