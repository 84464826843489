import { COLORS } from '../../../theme/colors';

export const buttonsColors = {
  red: COLORS.error.main,
  green: COLORS.success.main,
  blue: COLORS.accent.primary,
  default: COLORS.accent.primary,
  grey: COLORS.stroke.main,
};

export const textColor = {
  white: '#ffffff',
};
