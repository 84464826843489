import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginForm } from '../../../components/User/LoginForm/LoginForm.component';
import { AppPaths } from '../../../config/AppPaths';
import { userThunks } from '../User.thunk';

const Login = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate(AppPaths.home);
    }
  }, [token]);

  const handleSubmit = (data) => {
    dispatch(userThunks.loginThunk(data));
  };

  return <LoginForm handleSubmit={handleSubmit} />;
};

export default Login;
