import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';

export const StyledContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 20px;
  margin: 0;
`;

export const FilesListItem = styled.li`
  align-items: center;
  background-color: ${COLORS.background.primary};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 15px 17px;
  width: 48%;
  & > a {
    color: ${COLORS.typography.secondary};
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
  }
`;

export const FilesListRemoveButton = styled.span`
  line-height: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const FilesListItemTitle = styled.span`
  align-items: center;
  display: flex;
`;
