import { createAsyncThunk } from '@reduxjs/toolkit';
import { LeadsFilters } from '../../../store/types/Store.leads.types';

const saveStateThunk = createAsyncThunk(
  'leadsFilters/saveState',
  async (data: LeadsFilters) => {
    localStorage.setItem('leadsFilters', JSON.stringify(data));
    return data;
  },
);

export const leadsFiltersThunks = {
  saveStateThunk,
};
