import { useState } from 'react';
import { Lead } from '../../../../store/types/Store.leads.types';
import { findMatchingTag } from '../helpers/findMatchingTag';
import { useDedicatedHandler } from '../helpers/useDedicatedHandler';
import { useLeadFormHandlersProps } from '../LeadForm.types';
import { SalesOwner } from '../../../../store/types/Store.home.types';

export const useLeadFormHandlers = ({
  leadFetched,
  companyTags,
  currentUserState,
}: useLeadFormHandlersProps) => {
  const [lead, setLead] = useState<Partial<Lead>>();
  const [company, setCompany] = useState<string>(leadFetched?.name || '');
  const [industry, setIndustry] = useState<string>(leadFetched?.industry || '');
  const [salesOwner, setSalesOwner] = useState<SalesOwner>(
    leadFetched?.sales_owner || {
        id: currentUserState.user?.id || null,
        username: currentUserState.user?.username || '',
      } || {
        id: null,
        username: '',
      },
  );
  const [similarCompany, setSimilarCompany] = useState<string>();

  const handleChange = (e: React.BaseSyntheticEvent<object, any, any>) => {
    useDedicatedHandler({
      type: e.target.id,
      e,
      setCompany,
      setIndustry,
    });
    setLead({
      ...lead,
      [e.target.id]: e.target.innerText || undefined,
    });
  };

  const handleInputChange = (e: React.BaseSyntheticEvent) => {
    setLead({
      ...lead,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === 'name' && e.target.value.length) {
      setSimilarCompany(findMatchingTag(companyTags, e.target.value));
      setCompany(e.target.value);
    }
  };

  const handleSalesOwnerChange = (newSalesOwner: SalesOwner) => {
    setLead({
      ...lead,
      sales_owner: newSalesOwner,
    });
    setSalesOwner(newSalesOwner);
  };

  const changeNameForSimilar = () => {
    setLead({
      ...lead,
      name: similarCompany,
    });
    similarCompany && setCompany(similarCompany);
    setSimilarCompany(undefined);
  };

  const applyFetchedLeadData = () => {
    setLead(
      leadFetched?.sales_owner
        ? leadFetched
        : {
            sales_owner: {
              id: currentUserState.user?.id || null,
              username: currentUserState.user?.username || '',
            },
          },
    );
    setCompany(leadFetched?.name || '');
    setIndustry(leadFetched?.industry || '');
    setSalesOwner(
      leadFetched?.sales_owner || {
          id: currentUserState.user?.id || null,
          username: currentUserState.user?.username || '',
        } ||
        '',
    );
  };

  return {
    lead,
    company,
    industry,
    salesOwner,
    handleChange,
    handleInputChange,
    handleSalesOwnerChange,
    similarCompany,
    setSimilarCompany,
    changeNameForSimilar,
    applyFetchedLeadData,
  };
};
