import ownerPhoto from '../../../../../components/common/images/owner_photo.jpeg';
import quoteSign from '../../../../../components/common/images/quote_sign.png';
import { MOBILE_BREAKPOINT } from '../ClientLoginPage.config';
import { QuoteProps } from '../ClientLoginPage.types';
import {
  BottomLine,
  Line,
  OwnerData,
  OwnerFullName,
  OwnerPhoto,
  OwnerTitle,
  OwnerWrapper,
  QuoteBottom,
  QuoteTop,
  QuoteWrapper,
  StyledQuoteSign,
  StyledQuoteText,
} from './Quote.styles';

export const Quote = ({ screenWidth }: QuoteProps) => {
  const lineStroke = screenWidth < MOBILE_BREAKPOINT ? '#FFFFFF' : '#3c4674';
  return (
    <QuoteWrapper>
      <QuoteTop>
        <StyledQuoteSign src={quoteSign} alt="quote-sign" />
        <Line />
      </QuoteTop>
      <StyledQuoteText>
        I`m impressed by how quickly the engineers are able to learn and how
        open they are to new challenges.
      </StyledQuoteText>
      <OwnerWrapper>
        <OwnerPhoto src={ownerPhoto} alt="owner-photo" />
        <OwnerData>
          <OwnerFullName>Piotr Groza</OwnerFullName>
          <OwnerTitle>Founder</OwnerTitle>
        </OwnerData>
      </OwnerWrapper>
      <QuoteBottom>
        <BottomLine
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
        >
          <line x1="0" y1="0" x2="70%" y2="0" stroke={lineStroke} />
          <line x1="70%" y1="0" x2="70%" y2="100%" stroke={lineStroke} />
          <line x1="70%" y1="100%" x2="85%" y2="0" stroke={lineStroke} />
          <line x1="85%" y1="0" x2="100%" y2="0" stroke={lineStroke} />
        </BottomLine>
      </QuoteBottom>
    </QuoteWrapper>
  );
};
