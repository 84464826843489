import {
  faClock,
  faMinusCircle,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef } from 'react';
import { pastePlainText } from '../../../Helpers';
import { WithHeightUpdate } from '../../../utils/WithHeightUpdate';
import { StyledEntryContainer } from '../../common/CV/EntryContainer/EntryContainer.styles';
import {
  StyledAddCustomInfoButton,
  StyledData,
  StyledDeleteInfoButton,
  StyledIcon,
  StyledInfo,
  StyledLargeHeader,
  StyledLargeTextDiv,
  StyledMinorInfo,
  StyledSmallHeader,
  StyledTextDiv,
  StyledTimePeriod,
} from './CustomSectionEntry.styles';

const CustomSectionEntryComponent = forwardRef(
  (
    {
      handleDrop,
      handleDrag,
      handleDragOver,
      editable,
      data,
      setTextFormattingPanelVisibility,
      handleEditData,
      handleRemoveEntry,
      handleDeleteInnerEntry,
      handleCreateInnerEntry,
      handleEditInnerEntryData,
      index,
    },
    ref,
  ) => {
    const [focused, setFocused] = React.useState(false);

    return (
      <StyledEntryContainer
        ref={ref}
        draggable={!focused}
        onDragStart={handleDrag}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        paddingTop={index ? 40 : undefined}
      >
        <StyledData>
          <StyledTimePeriod
            placeholder="MM.YYYY - MM.YYYY"
            style={{ display: data.timePeriod !== null ? 'block' : 'none' }}
            suppressContentEditableWarning
            contentEditable={editable}
            onFocus={() => {
              setTextFormattingPanelVisibility(true);
            }}
            onPaste={(e) => pastePlainText(e)}
            onBlur={
              editable
                ? (e) => {
                    setTextFormattingPanelVisibility(false);
                    handleEditData({ timePeriod: e.currentTarget.innerHTML });
                  }
                : undefined
            }
            spellCheck={false}
            dangerouslySetInnerHTML={{
              __html: data.timePeriod,
            }}
          />
          <StyledInfo>
            {data.entries?.map((entry, idx) => {
              if (idx === 0) {
                return (
                  <div key={entry.entryId}>
                    <StyledLargeHeader
                      placeholder="Lorem ipsum dolor sit:"
                      suppressContentEditableWarning
                      contentEditable={editable}
                      onFocus={() => {
                        setTextFormattingPanelVisibility(true);
                      }}
                      onPaste={(e) => pastePlainText(e)}
                      onBlur={(e) => {
                        setTextFormattingPanelVisibility(false);
                        handleEditInnerEntryData(entry.entryId, {
                          firstPart: e.currentTarget.innerHTML,
                        });
                      }}
                      spellCheck={false}
                      dangerouslySetInnerHTML={{
                        __html: entry.firstPart,
                      }}
                    />
                    <StyledLargeTextDiv
                      placeholder="Lorem ipsum dolor sit amet consectetur."
                      style={{
                        width: data.timePeriod !== null ? '650px' : '900px',
                      }}
                      suppressContentEditableWarning
                      contentEditable={editable}
                      onFocus={() => {
                        setTextFormattingPanelVisibility(true);
                      }}
                      onPaste={(e) => pastePlainText(e)}
                      onBlur={(e) => {
                        setTextFormattingPanelVisibility(false);
                        handleEditInnerEntryData(entry.entryId, {
                          secondPart: e.currentTarget.innerHTML,
                        });
                      }}
                      spellCheck={false}
                      dangerouslySetInnerHTML={{
                        __html: entry.secondPart,
                      }}
                    />
                    {editable && (
                      <StyledDeleteInfoButton>
                        <StyledIcon
                          onClick={() => handleDeleteInnerEntry(entry.entryId)}
                        >
                          <FontAwesomeIcon icon={faMinusCircle} />
                        </StyledIcon>
                      </StyledDeleteInfoButton>
                    )}
                  </div>
                );
              }
              return (
                <StyledMinorInfo key={entry.entryId}>
                  <StyledSmallHeader
                    placeholder="Lorem ipsum dolor sit:"
                    suppressContentEditableWarning
                    contentEditable={editable}
                    onFocus={() => {
                      setTextFormattingPanelVisibility('bulletable');
                    }}
                    onPaste={(e) => pastePlainText(e)}
                    onBlur={(e) => {
                      setTextFormattingPanelVisibility(false);
                      handleEditInnerEntryData(entry.entryId, {
                        firstPart: e.currentTarget.innerHTML,
                      });
                    }}
                    spellCheck={false}
                    dangerouslySetInnerHTML={{
                      __html: entry.firstPart,
                    }}
                  />
                  <StyledTextDiv
                    placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit.
                              Voluptas dolores nulla qui voluptatem distinctio
                              voluptates assumenda veritatis eos neque repellat."
                    style={{
                      width: data.timePeriod !== null ? '650px' : '900px',
                    }}
                    suppressContentEditableWarning
                    contentEditable={editable}
                    onFocus={() => {
                      setTextFormattingPanelVisibility('bulletable');
                    }}
                    onPaste={(e) => pastePlainText(e)}
                    onBlur={(e) => {
                      setTextFormattingPanelVisibility(false);
                      handleEditInnerEntryData(entry.entryId, {
                        secondPart: e.currentTarget.innerHTML,
                      });
                    }}
                    spellCheck={false}
                    dangerouslySetInnerHTML={{
                      __html: entry.secondPart,
                    }}
                  />
                  {editable && (
                    <StyledDeleteInfoButton>
                      <StyledIcon
                        onClick={() => handleDeleteInnerEntry(entry.entryId)}
                      >
                        <FontAwesomeIcon icon={faMinusCircle} />
                      </StyledIcon>
                    </StyledDeleteInfoButton>
                  )}
                </StyledMinorInfo>
              );
            })}
            {editable && (
              <StyledAddCustomInfoButton>
                <StyledIcon
                  onClick={() => {
                    handleEditData({
                      timePeriod: data.timePeriod !== null ? null : '',
                    });
                  }}
                >
                  <FontAwesomeIcon title="Show time period" icon={faClock} />
                </StyledIcon>
                <StyledIcon onClick={handleRemoveEntry}>
                  <FontAwesomeIcon
                    title="Delete section"
                    icon={faMinusCircle}
                  />
                </StyledIcon>
                <StyledIcon onClick={() => handleCreateInnerEntry()}>
                  <FontAwesomeIcon title="Add section" icon={faPlusCircle} />
                </StyledIcon>
              </StyledAddCustomInfoButton>
            )}
          </StyledInfo>
        </StyledData>
      </StyledEntryContainer>
    );
  },
);

export const CustomSectionEntry = WithHeightUpdate(CustomSectionEntryComponent);
