import { Chip } from '@mui/material';
import { styled as materialUIStyled } from '@mui/material/styles';
import styled from 'styled-components';

export const StyledNotificationGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledNotificationSeparatorContainer = styled.div`
  align-items: center;
  background-color: #f4f5f7;
  color: #9fa3b3;
  display: flex;
  font-family: 'Poppins';
  font-size: 11px;
  padding-left: 20px;
`;

export const StyledNotificationPopperContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  min-width: 350px;
  padding: 0px !important;
`;

export const StyledNotificationPopperFooterButton = styled.button`
  background-color: #f5fcfd;
  border: none;
  color: #3cc2d9;
  :hover {
    background-color: #e0f6f9;
    cursor: pointer;
  }
  :focus {
    border: none;
  }
  font-family: 'Poppins';
  font-size: 13px;
  text-transform: none;
  width: 100%;
`;

export const StyledNotificationPopperHeaderButton = styled.button`
  background: none;
  border: none;
  color: #9fa3b3;
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0em;
  margin: auto 20px auto auto;
  padding: 0px;
  text-transform: none;
  :focus {
    border: none;
  }
  :hover {
    color: #afc3d3;
    cursor: pointer;
  }
`;

export const StyledNotificationTitle = styled.span<{
  elementHovered?: boolean;
}>`
  color: #26a8be;
  // color: ${({ elementHovered }) => (elementHovered ? '#26A8BE' : '')};
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
`;

export const StyledNotificationContainer = styled.div<{
  isRead?: boolean;
  height: string;
}>`
  align-items: center;
  display: flex;
  height: ${({ height }) => height};
  opacity: ${({ isRead }) => (isRead ? '50%' : '')};
  padding-left: 20px;
`;

export const StyledNotificationContentContainer = styled.div<{
  wide?: boolean;
}>`
  // cursor: pointer;
  display: flex;
  flex-direction: ${({ wide }) => (wide ? 'row' : 'column')};
  width: 100%;
`;

export const StyledNotificationContentContainerWithoutTitle = styled.div<{
  wide?: boolean;
}>`
  display: flex;
  flex-direction: row;
  margin-left: ${({ wide }) => (wide ? 'auto' : '0px')};
`;

export const StyledChip = materialUIStyled(Chip)({
  fontSize: '12px',
  fontWeight: '400',
  color: '#9FA3B3',
  height: '20px',
  fontFamily: 'Poppins',
  marginTop: '5px',
  backgroundColor: '#F6F7F9',
});

export const DateStringTextContainer = styled.span`
  color: #9fa3b3;
  font-family: 'Poppins';
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
`;

export const NotificationPageDateSelectionButton = styled.button`
  background-color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 2px 1px 3px grey;
  color: #101840;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  :hover {
    background-color: #fafafa;
    cursor: pointer;
  }
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const StyledNotificationPageFilterName = styled.span`
  color: #101840;
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
`;

export const StyledNotificationPageFilterContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledNotificationPageFilterTitleContainer = styled.div`
  align-items: center;
  background-color: #f4f5f7;
  display: flex;
  height: 25px;
  width: 100%;
`;

export const StyledNotificationPageFilterTitle = styled.span`
  color: #9fa3b3;
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 500;
  margin-left: 25px;
`;

export const StyledNotificationPageHeaderContainer = styled.div`
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
`;

export const StyledNotificationPageHeaderText = styled.span`
  font-family: 'Poppins';
  font-weight: 600;
`;

export const NotificationsListContainer = styled.div`
  max-width: 350px;
  overflow-y: hidden;
  overflox-x: hidden;

  :hover {
    overflow-y: scroll;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0a0a0;
    border-radius: 25px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0px 25px 25px 0px;
  }
`;
