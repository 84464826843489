import { createSlice, current } from '@reduxjs/toolkit';
import { myVacanciesPageThunks } from './MyVacancies.thunk';
const { getMyVacanciesDataThunk, getMyVacanciesCandidatesThunk } =
  myVacanciesPageThunks;

const initialState = {
  leadProfiles: [],
  stats: {
    main_technologies_count: {},
    active_lead_profiles: 0,
    active_leads: 0,
    candidates_status_count: {},
  },
};

const myVacanciesSlice = createSlice({
  name: 'myVacancies',
  initialState,
  reducers: {},
  extraReducers: {
    [getMyVacanciesDataThunk.fulfilled]: (state, { payload }) => {
      state.leadProfiles = payload;
    },
    [getMyVacanciesCandidatesThunk.fulfilled]: (state, action) => {
      const arr = current(state.leadProfiles);
      const found = arr.find(
        (elem) => elem.lead_profile_id === action.meta.arg.requestId,
      );
      const index = arr.indexOf(found);
      if (index > -1) {
        state.leadProfiles[index].candidates = action.payload;
      }
    },
  },
});

export const actions = { ...myVacanciesSlice.actions };
export const { reducer } = myVacanciesSlice;
