import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import IconButton from '@mui/material/IconButton';
import { buttonsColors } from '../../../../components/common/Button/Button.config';
import { Tooltip } from '../../../../components/common/Tooltip/Tooltip.component';

export const DropDownIcon = ({ action, title = 'Change', ...iconOptions }) => (
  <IconButton onClick={action} sx={{ padding: 0 }}>
    <Tooltip title={title} position="regular">
      <ArrowDropDownCircleIcon
        style={{ color: buttonsColors.grey }}
        {...iconOptions}
      />
    </Tooltip>
  </IconButton>
);
