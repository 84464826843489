import { IconButton } from '@mui/material';
import styled from 'styled-components';
import { styled as materialUIStyled } from '@mui/styles';
import { COLORS } from '../../../../theme/colors';

export const StyledIconButton = materialUIStyled(IconButton)({
  '&.Mui-disabled': { backgroundColor: COLORS.accent.secondary, height: 32 },
});

export const StyledCopyButton = materialUIStyled(IconButton)({
  backgroundColor: COLORS.accent.secondary,
  padding: '4px',
});

export const StyledLogo = styled.img`
  border: 1px solid ${COLORS.background.primary};
  border-radius: 50%;
  height: 32px;
  min-height: 32px;
  width: 32px;
`;

export const NoLogo = styled.div`
  background-color ${COLORS.info.secondary};
  border: 1px solid ${COLORS.background.primary};
  border-radius: 50%;
  min-height: 32px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoLogoText = styled.div`
  color: ${COLORS.typography.secondary};
  font-size: 10px;
`;

export const StyledLink = styled.a`
  color: ${COLORS.typography.secondary};
  font-size: 14px;
  font-weight: 500;
`;
