import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TechnologiesEntry } from '../../../../../components/CV/Technologies/TechnologiesEntry/TechnologiesEntry.component';
import { actions as cvActions } from '../../../CV.slice';
import { SECTION_TYPE } from '../../../CV.config';

export const TechnologiesEntryContainer = forwardRef(
  (
    {
      element,
      editable,
      children,
      updateSectionHeight,
      sectionWithParent,
      removeSectionRef,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const data = useSelector(
      (state) => state.cv.present.data.technologies.entries,
    );

    const {
      section: { sectionId, height },
    } = sectionWithParent;

    const handleAddEntry = () => {
      const payload = {
        sectionId,
        sectionType: SECTION_TYPE.TECHNOLOGY_HEADER,
        entries: [
          {
            sectionType: SECTION_TYPE.TECHNOLOGY_ENTRY,
          },
        ],
      };
      dispatch(cvActions.addEntry(payload));
    };

    return (
      <TechnologiesEntry
        ref={ref}
        sectionId={sectionId}
        removeSectionRef={removeSectionRef}
        element={element}
        editable={editable}
        updateSectionHeight={updateSectionHeight}
        handleAddEntry={handleAddEntry}
        data={data}
        height={height}
      >
        {children}
      </TechnologiesEntry>
    );
  },
);
