import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_LEADS } from '../../../config/urls';
import { getAuthHeader, handleResponse } from '../../../utils/api';

const getLeadThunk = createAsyncThunk(
  'leadDetails/getLead',
  async (id: number, { getState, dispatch }) => {
    const request = fetch(`${API_LEADS}${id}/`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);

export const leadDetailsThunks = {
  getLeadThunk,
};
