import { MoonLoader } from 'react-spinners';
import {
  StyledLoaderWrapper,
  StyledMenuItemInline,
} from './CandidateStatusChip.styles';
import { StatusChipProps } from './CandidateStatusChip.types';
import { useLeadsConfig } from '../../../containers/Leads/useLeadsConfig';

export const CandidateStatusChip = ({
  status,
  isLoading,
  size,
}: StatusChipProps) => {
  const { candidateStatuses } = useLeadsConfig();
  const chosenStatus = candidateStatuses.find(
    (candStatus) => candStatus.name === status,
  );

  return (
    <StyledMenuItemInline
      bgColor={chosenStatus?.bgColor || ''}
      {...{ size, isLoading }}
    >
      {isLoading ? (
        <StyledLoaderWrapper>
          <MoonLoader size="16px" />
        </StyledLoaderWrapper>
      ) : (
        <>{chosenStatus?.showedName}</>
      )}
    </StyledMenuItemInline>
  );
};
