import { StyledPagination } from './BaseTable.styles';
import { BaseTablePaginatorProps } from './BaseTable.types';

export const BaseTablePaginator = ({
  setPageNumber,
  onChangePage,
  noOfRows,
  defaultPageLength,
  pageNumber,
  customPaginationStyles,
}: BaseTablePaginatorProps) => {
  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setPageNumber(page - 1);
    onChangePage(page - 1);
  };

  return (
    <StyledPagination
      color="primary"
      boundaryCount={1}
      count={Math.ceil(noOfRows / defaultPageLength)}
      page={pageNumber + 1}
      onChange={handleChangePage}
      style={customPaginationStyles}
    />
  );
};
