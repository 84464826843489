import { AttachFile } from '@mui/icons-material';
import { useState } from 'react';
import { SketchPicker } from 'react-color';
import { Dialog } from '../../common/Dialog/DEPR_Dialog';
import { Dropzone } from '../../common/Dropzone/Dropzone.component';
import {
  FilesListItem,
  FilesListItemTitle,
  FilesListRemoveButton,
} from '../../common/FilesList/FilesList.styles';
import { FormLabel } from '../../common/FormLabel/FormLabel';
import { Input } from '../../common/Input/Input';
import { TextField } from '../../common/TextField';
import {
  ColorContainer,
  LogoDropZone,
  LogoPreview,
  StyledMultiline,
  StyledPicker,
} from './PartnerForm.styles';

export const PartnerForm = ({
  handleSubmit,
  closeModal,
  partnerToEdit,
  ref,
}) => {
  const [partnerData, setPartnerData] = useState({
    partner_name: partnerToEdit?.partner_name ?? '',
    address: partnerToEdit?.address ?? '',
    phone_number: partnerToEdit?.phone_number ?? '',
    logo: partnerToEdit?.logo ?? '',
    main_color: partnerToEdit?.main_color || '#3cc2d9',
  });

  const [inputColor, setInputColor] = useState(
    partnerToEdit?.main_color || '#3cc2d9',
  );

  const handleInputChange = (e) => {
    setPartnerData({
      ...partnerData,
      [e.target.name]: e.target.value,
    });
  };

  const setColor = (color) => {
    setInputColor(color);
    setPartnerData({
      ...partnerData,
      main_color: color,
    });
  };

  const buttonsConfig = {
    primaryButton: {
      label: 'Save',
      action: () => handleSubmit(partnerData),
    },
  };

  const handleFileUpload = async (file) => {
    try {
      setPartnerData({
        ...partnerData,
        logo: file,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleFileRemoval = () => {
    setPartnerData({
      ...partnerData,
      logo: '',
    });
  };

  return (
    <Dialog
      dialogTitle={partnerToEdit ? 'Edit partner' : 'Add new partner'}
      buttonsConfig={buttonsConfig}
      handleClose={closeModal}
      ref={ref}
    >
      <Input
        label="Name"
        name="partner_name"
        required
        placeholder="..."
        value={partnerData.partner_name}
        onChange={handleInputChange}
      />
      <StyledMultiline>
        <TextField
          label="Address"
          name="address"
          required
          multiline
          rows={2}
          placeholder="..."
          value={partnerData.address}
          handleInputChange={handleInputChange}
        />
      </StyledMultiline>
      <Input
        label="Phone number"
        name="phone_number"
        required
        placeholder=".. - ... - ... - ..."
        type="tel"
        pattern="[0-9]{2}-[0-9]{3}-[0-9]{2}-[0-9]{3}"
        value={partnerData.phone_number}
        onChange={handleInputChange}
      />
      <ColorContainer>
        <FormLabel htmlFor="color" label="Color" />
        <StyledPicker>
          <SketchPicker
            color={inputColor}
            onChangeComplete={(color) => setColor(color.hex)}
          />
        </StyledPicker>
      </ColorContainer>
      <LogoDropZone>
        <FormLabel htmlFor="logo" label="Logo" />
        <Dropzone handleFileSelection={handleFileUpload} maxFiles={1} />
        {partnerData.logo && (
          <FilesListItem>
            <a
              href={partnerData.logo}
              target="_blank"
              download={partnerData.logo.name}
              rel="noreferrer"
            >
              <FilesListItemTitle>
                <AttachFile sx={{ marginRight: '12px' }} fontSize="small" />
                {partnerData.logo[0].name
                  ? partnerData.logo[0].name
                  : partnerToEdit.logo.slice(42)}
              </FilesListItemTitle>
              <LogoPreview
                src={partnerData.logo}
                alt="logo"
                onError={(event) => (event.target.style.display = 'none')}
              />
            </a>
            <FilesListRemoveButton onClick={() => handleFileRemoval()}>
              Remove
            </FilesListRemoveButton>
          </FilesListItem>
        )}
      </LogoDropZone>
    </Dialog>
  );
};
