import { useTranslation } from 'react-i18next';
import { statusOfRequestWithReason } from '../../../../containers/Leads/Leads.config';
import { LeadProfileStatusType } from '../../../../store/types/Store.leads.types';
import { ChipSelectLeadStatus } from '../../../common/ChipSelectLeadStatus';
import { FormLabel } from '../../../common/FormLabel';
import {
  StatusContainer,
  StyledLabelContainer,
} from '../LeadRequestForm.styles';
import { LeadStatusProps } from './LeadStatus.types';
import { useLeadStatusOptionsConfig } from '../../../common/Chips/StatusChip/useLeadStatusOptionsConfig';

export const LeadStatus = ({
  setRequest,
  leadRequest,
  setClickedStatusOption,
  setReason,
}: LeadStatusProps) => {
  const { t } = useTranslation(['vacancies'], { keyPrefix: 'vacancies' });
  const { leadStatusOptions } = useLeadStatusOptionsConfig();

  const handleStatusChipChange = (newStatus?: LeadProfileStatusType) => {
    if (newStatus) {
      if (newStatus !== statusOfRequestWithReason) {
        setRequest({
          ...leadRequest,
          lead_profile_status: newStatus,
          reason: undefined,
        });
        setReason(undefined);
      } else {
        setRequest({
          ...leadRequest,
          lead_profile_status: newStatus,
        });
      }

      setClickedStatusOption(newStatus);
    }
  };

  return (
    <StatusContainer>
      <StyledLabelContainer>
        <FormLabel label={t('status')} />
      </StyledLabelContainer>
      <ChipSelectLeadStatus
        options={leadStatusOptions}
        handleLeadStatusChange={handleStatusChipChange}
        actualStatus={leadRequest.lead_profile_status}
      />
    </StatusContainer>
  );
};
