import React from 'react';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Tooltip from '@mui/material/Tooltip';
import { StyledIconButton } from './BulletPointIcon.styles';

export const BulletPointIcon = ({
  action,
  title = 'Bullet point',
  ...iconOptions
}) => (
  <StyledIconButton onClick={action}>
    <Tooltip title={title}>
      <FormatListBulletedIcon style={{ color: 'black' }} {...iconOptions} />
    </Tooltip>
  </StyledIconButton>
);
