import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { buttonsColors } from '../../Button/Button.config';
import { ButtonContainer, ButtonLabel } from './AddNewItemButton.styles';

interface Props {
  action: () => void;
  disabled?: boolean;
}

export const AddNewItemButton = ({ action, disabled, ...props }: Props) => {
  const { t } = useTranslation(['common'], { keyPrefix: 'buttons' });
  return (
    <ButtonContainer disabled={disabled}>
      <Button variant="text" onClick={action} {...props}>
        <AddCircleIcon
          style={{ color: buttonsColors.default, fontSize: '18px' }}
        />
        <ButtonLabel>{t('add_new')}</ButtonLabel>
      </Button>
    </ButtonContainer>
  );
};
