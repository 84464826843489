import styled from 'styled-components';

export const StyledEmployeeTable = styled.div`
  align-self: center;
  height: 100%;
  margin: 0 auto;
  padding: 0 65px;
  width: 100%;
`;

export const SearchContainer = styled.div`
  margin-right: 18px;
  max-width: 207px;
  @media (max-width: 686px) {
    width: 100%;
    max-width: 686px;
  }
`;

export const HeadContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 35px;
`;

export const HeadControls = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-end;
`;

export const InnerTooltipText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledStatusBar = styled.div`
  align-items: center;
  background: white !important;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  height: 85px;
  justify-content: flex-end;
  margin-top: -95px;
  padding: 0 65px;
  width: 100%;
  & > * {
    margin-top: 15px;
  }

  @media (max-width: 686px) {
    padding-bottom: 20px;
    padding-top: 200px;
    flex-direction: column;
    height: 150px;
    flex-wrap: nowrap;
  }
`;
