import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import {
  CloseContainer,
  Modal,
  StyledLabel,
} from '../components/common/ClientModals/ClientModal.styles';

export interface BaseModalProps {
  onClose: () => any;
  open?: boolean;
  label?: string;
  children?: JSX.Element;
  modalStyles?: React.CSSProperties;
}

export const BaseModalContainer: React.FC<BaseModalProps> = ({
  open,
  onClose,
  label,
  children,
  modalStyles,
}: BaseModalProps) => {
  return (
    <Modal
      open={open ?? true}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={modalStyles}
      BackdropProps={{
        sx: { backgroundColor: 'rgba(17, 26, 67, 0.7)' },
        onClick: onClose,
      }}
    >
      <Box>
        <CloseContainer isLabelDisplayed={typeof label === 'string'}>
          {label && <StyledLabel>{label}</StyledLabel>}
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ color: '#FFF', fontSize: 'medium' }} />
          </IconButton>
        </CloseContainer>
        {children}
      </Box>
    </Modal>
  );
};
