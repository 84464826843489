import styled from 'styled-components';

export const StyledRow = styled.tr``;

export const ExpandableCell = styled.td`
  vertical-align: top;
`;

export const ExpandableCellContent = styled.div<{ isOpen: boolean }>`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  max-height: 0;
  overflow: hidden;
  transition: max-height 200ms ease-in-out;
  ${({ isOpen }) =>
    isOpen &&
    `
    max-height: fit-content;
  `}
`;
