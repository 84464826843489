import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  border: none;
  border-radius: 3px;
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 400;
  height: 26px;
  padding-top: 2px;
  transition-duration: 0.2s;
  width: 20px;

  &:hover {
    background-color: ${({ color }) =>
      color === '#3cc2d9' ? '#5CD6EB' : color};
    cursor: pointer;
  }
  &:active {
    background-color: ${({ color }) =>
      color === '#3cc2d9' ? '#26A8BE' : color};
  }
`;
