import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isPartner } from '../../../Helpers';
import { AppStore } from '../../../store/types/Store.types';
import { InternalUser, UserState } from '../../../store/types/Store.user.types';
import { AutocompleteInput } from '../../common/AutocompleteInput';
import { Dialog } from '../../common/Dialog/DEPR_Dialog';
import { Input } from '../../common/Input/Input';
import { useLeadFormHandlers } from './hooks/useLeadFormHandlers';
import { LeadFormProps } from './LeadForm.types';
import { SimilarName } from './SimilarName/SimilarName';
import { OptionItem } from './LeadForm.styles';
import { SalesOwner } from '../../../store/types/Store.home.types';

export const LeadForm = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<LeadFormProps>
>(
  ({
    leadFetched,
    dialogTitle,
    getButtonsConfig,
    handleClose,
    companyTags,
    industryTags,
  }) => {
    const { t } = useTranslation(['user', 'common'], {
      keyPrefix: 'my_profile.projects',
    });
    const currentUserState = useSelector<AppStore, UserState>(
      (state) => state.user,
    );
    const hrUsers = useSelector<AppStore, InternalUser[]>(
      (state) => state.user.HRUsers,
    );
    const partnerUsers = useSelector<AppStore, InternalUser[]>(
      (state) => state.user.PartnerUsers,
    );
    const salesUsers = useSelector<AppStore, InternalUser[]>(
      (state) => state.user.SalesUsers,
    );
    const {
      lead,
      company,
      industry,
      salesOwner,
      handleChange,
      handleInputChange,
      handleSalesOwnerChange,
      similarCompany,
      setSimilarCompany,
      changeNameForSimilar,
      applyFetchedLeadData,
    } = useLeadFormHandlers({
      leadFetched,
      companyTags,
      currentUserState,
    });

    const mergedSalesPartnerHRUsernames: SalesOwner[] = useMemo(() => {
      if (isPartner(currentUserState.user)) {
        return [];
      }
      return hrUsers
        .concat(partnerUsers)
        .concat(salesUsers)
        .map((user) => ({ id: user.id, username: user.username }));
    }, [currentUserState, hrUsers, partnerUsers, salesUsers]);

    useEffect(() => {
      applyFetchedLeadData();
    }, [leadFetched]);

    return (
      <Dialog
        dialogTitle={dialogTitle}
        buttonsConfig={lead && getButtonsConfig(lead)}
        handleClose={handleClose}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {/* Without this react element there's such error: 
          This JSX tag's 'children' prop expects a single child of type 'Element', but multiple children were provided. */}
          {similarCompany && (
            <SimilarName
              {...{ similarCompany, changeNameForSimilar, setSimilarCompany }}
            />
          )}
          <AutocompleteInput
            label={t('projectName')}
            name="name"
            freeSolo
            options={companyTags}
            value={leadFetched?.name || ''}
            inputValue={company as string}
            handleInputChange={handleChange}
            handleCustomOption={handleInputChange}
          />
          <AutocompleteInput
            label={t('industry')}
            name="industry"
            freeSolo
            options={industryTags}
            value={industry}
            handleInputChange={handleChange}
            handleCustomOption={handleInputChange}
            required
          />
          <Input
            label="Website"
            name="website"
            placeholder="..."
            type="url"
            value={lead?.website || ''}
            onChange={handleInputChange}
            required
            disabled={undefined}
          />
          <AutocompleteInput
            label={
              isPartner(currentUserState.user) ? t('owner') : t('sales_owner')
            }
            name="sales_owner"
            options={mergedSalesPartnerHRUsernames}
            value={salesOwner}
            getOptionLabel={(option) => {
              if (typeof option === 'object') {
                return option.username;
              }
              return option;
            }}
            disabled={currentUserState.user?.partner ? true : false}
            onChange={(_event, newValue) => {
              handleSalesOwnerChange(newValue as SalesOwner);
            }}
            required
            isOptionEqualToValue={(option: SalesOwner, valueA: SalesOwner) =>
              option.id === valueA.id
            }
            renderOption={(props: any, option: SalesOwner) => (
              <OptionItem
                key={option.id}
                value={option as SalesOwner}
                {...props}
              >
                {option.username}
              </OptionItem>
            )}
          />
        </Box>
      </Dialog>
    );
  },
);
