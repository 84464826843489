import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LeadsModal } from '../../../../components/common/Modals/Leads/LeadsModal';
import { ModalLeadsTableType } from '../../../../components/common/Modals/Leads/LeadsModal/LeadsModal.types';
import { AdditionalLeadInfo } from '../../../../components/Lead/AdditionalLeadInfo/AdditionalLeadInfo.component';
import { RequestDev } from '../../../../components/Lead/RequestDev/RequestDev.component';
import { Statistics } from '../../../../components/Lead/Statistics/Statistics';
import { isPartner } from '../../../../Helpers';
import { AppDispatch, RootState } from '../../../../store/store';
import { Lead } from '../../../../store/types/Store.leads.types';
import { AppStore } from '../../../../store/types/Store.types';
import { UserState } from '../../../../store/types/Store.user.types';
import { leadDetailsThunks } from '../../redux/LeadDetails.thunk';
import { leadsListThunks } from '../../redux/LeadsList.thunk';
import { leadTagsThunks } from '../../redux/tags/LeadTags.thunk';
import {
  ExpandableCell,
  ExpandableCellContent,
  StyledRow,
} from '../LeadsTable.styles';
import { LeadsTableExpendableRowProps } from './LeadsTableExpandableRow.types';

export const LeadsTableExpendableRow = ({
  leadId,
  isOpen,
}: LeadsTableExpendableRowProps) => {
  const lead = useSelector<AppStore, Lead | undefined>((state) =>
    state.leadsRelated.details.find((elem) => elem.id === leadId),
  );
  const [modalFor, setModalFor] = useState<ModalLeadsTableType>();
  const currentUser = useSelector<RootState, UserState>((state) => state.user);
  const hrUsers = useSelector<RootState, any[]>((state) => state.user.HRUsers);
  const profileTags = useSelector<AppStore, string[]>((state) =>
    state.leadsRelated.tags.profile?.map((tag: any) => tag.name),
  );
  const profileReasonTags = useSelector<AppStore, string[]>((state) =>
    state.leadsRelated.tags.profile_reason?.map((tag: any) => tag.reason),
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    isOpen && !lead && dispatch(leadDetailsThunks.getLeadThunk(leadId));
  }, [isOpen]);

  useEffect(() => {
    switch (modalFor) {
      case 'deletingLeadModal':
      case 'editingLeadModal':
        !isPartner(currentUser.user) &&
          dispatch(leadTagsThunks.getLeadTagsThunk());
        break;
      default:
        break;
    }
  }, [modalFor]);

  const handleStatusChange = async () => {
    let newStatus;

    if (lead?.status === 'ACTIVE') {
      newStatus = 'ARCHIVED';
    } else {
      newStatus = 'ACTIVE';
    }

    await dispatch(
      leadsListThunks.updateLeadThunk({ id: leadId, status: newStatus }),
    );

    await Promise.all([
      dispatch(leadsListThunks.getLeadsThunk()),
      dispatch(leadDetailsThunks.getLeadThunk(leadId)),
    ]);
  };
  const isModalOpen = modalFor !== undefined;

  return (
    <>
      {isModalOpen && (
        <LeadsModal
          {...{ modalFor, profileTags, leadId, isModalOpen }}
          closeModal={() => setModalFor(undefined)}
          hrOwners={
            isPartner(currentUser.user)
              ? []
              : hrUsers.map((usr) => usr.username)
          }
          reasonTags={profileReasonTags}
          leadIdToDelete={leadId}
          leadIdToEdit={leadId}
        />
      )}
      <StyledRow>
        <>
          {/* <td></td> */}
          <ExpandableCell colSpan={7}>
            <ExpandableCellContent {...{ isOpen }}>
              {lead && (
                <>
                  <AdditionalLeadInfo
                    leadId={lead.id}
                    leadStatus={lead.status}
                    leadIndustry={lead.industry}
                    leadWebsite={lead.website}
                    createdAt={lead.created_at}
                    salesOwner={lead.sales_owner.username}
                    switchAction={handleStatusChange}
                    editLead={() => setModalFor('editingLeadModal')}
                    deleteLead={() => setModalFor('deletingLeadModal')}
                  />

                  <RequestDev
                    addProfile={() => setModalFor('addingRequestModal')}
                    leadStatus={lead.status}
                    leadId={lead.id}
                    leadProfiles={lead.profiles}
                  />
                </>
              )}

              <Statistics candidates={lead?.candidate_statuses} />
            </ExpandableCellContent>
          </ExpandableCell>
        </>
      </StyledRow>
    </>
  );
};
