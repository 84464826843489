import { useEffect, useState } from 'react';
import { defaultStatusOfNewRequest } from '../../../containers/Leads/Leads.config';
import { LeadProfileStatusType } from '../../../store/types/Store.leads.types';
import { StatusChip } from '../Chips/StatusChip';
import { ChipSelectLeadStatusProps } from './ChipSelect.types';
import { ChipsContainer } from './ChipSelectLeadStatus.styles';
import { getLeadStatusAsChipStatus } from './helpers/getLeadStatusAsChipStatus';

export const ChipSelectLeadStatus = ({
  options,
  handleLeadStatusChange,
  actualStatus,
}: ChipSelectLeadStatusProps) => {
  const [chosenStatus, setChosenStatus] = useState<LeadProfileStatusType>(
    actualStatus || defaultStatusOfNewRequest,
  );

  useEffect(() => {
    chosenStatus && handleLeadStatusChange(chosenStatus);
  }, [chosenStatus]);

  useEffect(() => {
    actualStatus && setChosenStatus(actualStatus);
  }, [actualStatus]);

  return (
    <ChipsContainer>
      {options.map((option, index) => (
        <StatusChip
          key={`${option.status}-${index}`}
          label={option.label}
          handleClick={() => setChosenStatus(option.status)}
          clickable
          isClicked={chosenStatus === option.status}
          status={getLeadStatusAsChipStatus(option.status)}
          backgroundColor={
            actualStatus === option.status ? undefined : '#F4F5F7'
          }
        />
      ))}
    </ChipsContainer>
  );
};
