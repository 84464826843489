import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_HR_LISTS_URL,
  API_UPDATE_EMPLOYEES_IN_HR_LIST,
} from '../../../../config/urls';
import { getAuthHeader, handleResponse } from '../../../../utils/api';
import { raiseToast } from '../../../../utils/raiseToasts';

const getHrListsThunk = createAsyncThunk(
  'hrLists/getHrLists',
  async (_data, { getState, dispatch }) => {
    const request = fetch(`${API_HR_LISTS_URL}`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);

const createHrListThunk = createAsyncThunk(
  'hrLists/createList',
  async (data: { notes: string }, { getState, dispatch }) => {
    const request = fetch(`${API_UPDATE_EMPLOYEES_IN_HR_LIST}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: getAuthHeader(getState()),
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully added the list.');
    }

    return response;
  },
);

const deleteHrListThunk = createAsyncThunk(
  'hrLists/delete',
  async (data: { hrListIdForDelete: string }, { getState, dispatch }) => {
    const request = fetch(`${API_HR_LISTS_URL}${data.hrListIdForDelete}/`, {
      method: 'DELETE',
      headers: getAuthHeader(getState()),
    });

    const result = await handleResponse(request, dispatch);
    raiseToast.success('Successfully deleted list');
    return result;
  },
);

const updateHrListNameThunk = createAsyncThunk(
  'employees/updateHrListName',
  async (data: { hrListId: string; notes: string }, { getState, dispatch }) => {
    const request = fetch(`${API_HR_LISTS_URL}${data.hrListId}/`, {
      method: 'PATCH',
      body: JSON.stringify({ notes: data.notes }),
      headers: getAuthHeader(getState()),
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success("Successfully updated list's name.");
    }
  },
);

export const hrListsThunks = {
  getHrListsThunk,
  createHrListThunk,
  deleteHrListThunk,
  updateHrListNameThunk,
};
