import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DetailItemProps } from './DetailItem.types';
import { Typography } from '../../../common/Typography/Typography.component';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import { COLORS } from '../../../../theme/colors';
import {
  StyledIconButton,
  StyledLogo,
  NoLogo,
  NoLogoText,
  StyledCopyButton,
  StyledLink,
} from './DetailItem.styles';
import { raiseToast } from '../../../../utils/raiseToasts';
import { addDotsToString } from '../../../../Helpers';

const CONTENT_STRING_LIMIT = 40;

export const DetailItem = ({
  label,
  content,
  icon,
  companyLogo,
  link,
  copyable,
}: DetailItemProps) => {
  const { t } = useTranslation(['company'], {
    keyPrefix: 'company_details',
  });
  const getCompanyLogo = (logo: string, companyName: string) =>
    logo.length !== 0 ? (
      <StyledLogo src={logo} alt={`${companyName}-logo`} />
    ) : (
      <>
        {companyName && (
          <NoLogo>
            <NoLogoText>{companyName.slice(0, 1)}</NoLogoText>
          </NoLogo>
        )}
      </>
    );

  const handleCopyText = (
    event: React.MouseEvent<HTMLButtonElement>,
    text: string,
  ) => {
    event.stopPropagation();
    navigator.clipboard.writeText(text);
    raiseToast.success(`${label}: ${t('wasCopied')}.`);
  };

  return (
    <Box display="flex" gap="12px" alignItems="center">
      {icon ? (
        <StyledIconButton disabled>{icon}</StyledIconButton>
      ) : (
        getCompanyLogo(companyLogo || '', content)
      )}
      <Box display="flex" flexDirection="column" gap="5px">
        <Typography
          variant="overline"
          weight="medium"
          color={COLORS.typography.secondary}
        >
          {label}
        </Typography>
        <Box display="flex" gap="12px" alignItems="center">
          {link ? (
            <StyledLink href={link} target="_blank" rel="noreferrer">
              {addDotsToString(content, CONTENT_STRING_LIMIT)}
            </StyledLink>
          ) : (
            <Typography variant="body" weight="medium">
              {addDotsToString(content, CONTENT_STRING_LIMIT)}
            </Typography>
          )}
          {copyable && (
            <StyledCopyButton onClick={(e) => handleCopyText(e, content)}>
              <CopyIcon
                style={{
                  fill: COLORS.accent.primary,
                  transform: 'rotate(90deg)',
                }}
              />
            </StyledCopyButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};
