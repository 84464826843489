import { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdditionalInfoEntry } from '../../../../../components/CV/AdditionalInfo/AdditionalInfoEntry.component';
import { actions as additionalInfoActions } from '../AdditionalInfo.slice';

export const AdditionalInfoEntryContainer = forwardRef(
  (
    {
      element,
      editable,
      sectionWithParent,
      updateSectionHeight,
      removeSectionRef,
      setTextFormattingPanelVisibility,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const {
      section: { sectionId, height },
    } = sectionWithParent;
    const data = useSelector((state) =>
      state.cv.present.data.additionalInfo.entries.find(
        (entry) => entry.sectionId === sectionId,
      ),
    )?.data;

    useEffect(() => {
      if (!data) {
        dispatch(additionalInfoActions.createData(sectionId));
      }
    }, [data]);

    const handleEditData = (dataP) => {
      dispatch(
        additionalInfoActions.updateEntryData({
          sectionId,
          data: dataP,
        }),
      );
    };

    return (
      <AdditionalInfoEntry
        ref={ref}
        element={element}
        sectionId={sectionId}
        removeSectionRef={removeSectionRef}
        editable={editable}
        handleEditData={handleEditData}
        isExperienceSectionDisplayed
        updateSectionHeight={updateSectionHeight}
        setTextFormattingPanelVisibility={setTextFormattingPanelVisibility}
        data={data || {}}
        height={height}
      />
    );
  },
);
