import { styled as materialUIStyled } from '@mui/styles';
import { IconButton } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';

export const StyledContainer = styled.div`
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 24px;
  width: 100%;

  @media (min-width: 700px) {
    min-width: 450px;
    max-width: 700px;
  }

  @media (max-width: 699px) {
    max-width: 450px;
    padding: 25px 30px;
  }
`;

export const StyledEditButton = materialUIStyled(IconButton)({
  backgroundColor: COLORS.accent.secondary,
  padding: '8px',
});
