import styled, { css } from 'styled-components';
import { buttonsColors, textColor } from './Button.config';
import { ButtonSize, ButtonVariant } from './Button.types';
import { COLORS } from '../../../theme/colors';

type ButtonWrapperProps = {
  color?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  variant: ButtonVariant;
  size: ButtonSize;
};

const getBackgroundColor = (
  disabled: boolean,
  variant: ButtonVariant,
  color?: string,
) => {
  if (disabled) return 'rgba(0, 0, 0, 0.2)';
  if (color) return color;
  if (variant === 'text' || variant === 'outlined') {
    return 'transparent';
  }
  if (variant === 'secondary') {
    return COLORS.accent.secondary;
  }
  return buttonsColors.default;
};

const getPadding = (variant: ButtonVariant, size: ButtonSize) => {
  if (variant === 'text') {
    return '10px 0';
  }
  if (size === 'small') {
    return '5px 8.5px';
  }
  return '10px 24px';
};

export const ButtonWrapper = styled.button<ButtonWrapperProps>(
  ({ variant, disabled = false, fullWidth, color, size }) => css`
    background-color: ${getBackgroundColor(disabled, variant, color)};
    border: ${variant === 'outlined'
      ? `1px solid ${COLORS.accent.border}`
      : 'none'};
    border-radius: 8px;
    color: ${variant === 'primary' ? textColor.white : COLORS.accent.primary};
    display: ${fullWidth ? 'block' : 'inline'};
    font-family: 'Poppins';
    font-size: ${size === 'small' ? '12px' : '14px'};
    font-weight: 500;
    line-height: 16.8px;
    padding: ${getPadding(variant, size)};
    pointer-events: ${disabled ? 'none' : 'default'};
    transition-duration: 0.2s;
    width: ${fullWidth ? '100%' : 'fit-content'};

    &:hover {
      background-color: ${variant === 'text' ? 'transparent' : color};
      cursor: pointer;
      color: ${variant === 'primary'
        ? textColor.white
        : COLORS.typography.body};
    }
    &:active {
      background-color: ${color === '#3cc2d9' ? '#26A8BE' : color};
    }
  `,
);

export const InputButtonWrapper = styled.input.attrs({ type: 'submit' })`
  background-color: ${({ color }) => color};
  color: ${textColor.white};
  border-radius: 8px;
  padding: 16px 24px;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 16px;
  border: none;
  transition-duration: 0.2s;
  width: 100%;

  &:hover {
    opacity: 80%;
    cursor: pointer;
  }
`;
