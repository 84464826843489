import { FilterButton } from './TableFilter.styles';
export const countTopFilters = (rows, filterOptions) => {
  const counts = {};
  Object.values(filterOptions).forEach((filter) => {
    counts[filter.name] = rows?.filter(filter.match).length;
  });
  return counts;
};
export const filterRows = (
  allRows,
  filteredColumns,
  searchValue,
  filterOptions,
  filterValue,
  applyTopFilter = true,
) =>
  allRows?.filter((row) => {
    if (applyTopFilter && !filterOptions[filterValue].match(row)) return false;
    const filterMatch = searchValue.toLowerCase();
    return Object.keys(filteredColumns).some((column) =>
      row[filteredColumns[column]]?.toLowerCase().includes(filterMatch),
    );
  });
export const TableFilter = ({
  filterOptions,
  filterValue,
  filterCounts,
  changeHandler,
}) => {
  return (
    <>
      {Object.values(filterOptions).map((filter) => (
        <FilterButton
          size="small"
          variant={filter.name === filterValue ? 'selected' : 'unselected'}
          onClick={() => {
            changeHandler(filter.name);
          }}
        >
          {filter.label} ({filterCounts[filter.name]})
        </FilterButton>
      ))}
    </>
  );
};
