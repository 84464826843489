import styled from 'styled-components';

export const Rectangle = styled.div`
  background-color: ${({ color }) => color};
  height: 16px;
  width: 5.42px;
`;

export const IndicatorContainer = styled.span`
  display: flex;
  gap: 2px;
`;
