import styled from 'styled-components';
import { styled as styledMaterialUI } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { COLORS } from '../../../../../theme/colors';

export const StyledTable = styled.div`
  align-self: center;
  height: 100%;
  margin: 0 auto 0 auto;
  padding: 0;
  width: 100%;
`;

export const StyledSwitch = styledMaterialUI(Switch)({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: COLORS.accent.primary,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: COLORS.accent.primary,
  },
  '& .MuiSwitch-switchBase': {
    color: COLORS.error.main,
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: COLORS.error.main,
  },
});
