import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AnyAction } from '@reduxjs/toolkit';
import { CandidateChip } from '../../../../../components/common/Chips/CandidateChip';
import { SearchField } from '../../../../../components/common/SearchField/SearchField';
import { Table } from '../../../../../components/common/Table/Table.component';
import { AddButton } from '../../../../../components/common/Buttons/AddButton';
import { Tooltip } from '../../../../../components/common/Tooltip/Tooltip.component';
import { getDateString, getDateTimeString } from '../../../../../Helpers';
import { useEffectAsync } from '../../../../../utils/useEffectAsync';
import { ActionColumn } from './ActionColumn/ActionColumn.component';
import {
  getTopFilterOptions,
  modalTypes,
  searchInColumns,
} from './AccountsSettingsTable.config';
import { StyledTable } from './AccountsSettingsTable.styles';
import {
  TableFilter,
  filterRows,
  countTopFilters,
} from '../../../../../components/common/TableFilter/TableFilter';
import {
  HeadControls,
  MainHeadline,
  FiltersContainer,
  SearchContainer,
  StyledEmail,
  StyledEmptyResult,
} from '../../MyProfile.styles';
import { Counter } from '../../../../../components/common/Counter/Counter';
import { userThunks } from '../../../User.thunk';
import { Typography } from '../../../../../components/common/Typography/Typography.component';
import { COLORS } from '../../../../../theme/colors';
import { UsersModal } from '../../../../../components/common/Modals/Users/UsersModal/UsersModal.component';
import { AppStore } from '../../../../../store/types/Store.types';
import {
  CompanyMember,
  MyPersonalData,
  UserData,
} from '../../../../../store/types/Store.user.types';

export const AccountsSettingsTable = () => {
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.accountsSettings',
  });
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [modalFor, setModalFor] = useState('');
  const [userToEdit, setUserToEdit] = useState<CompanyMember | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const filters = { search: '' };
  const companyMembers = useSelector<AppStore, CompanyMember[]>(
    (state) => state.user.companyMembers,
  );
  const user = useSelector<AppStore, UserData | null>(
    (state) => state.user?.user || null,
  );
  const [searchValue, setSearchValue] = useState(filters.search ?? '');
  const [topFilterValue, setTopFilterValue] = useState('current');
  const topFilterOptions = getTopFilterOptions(t);

  const columns = [
    {
      field: 'username',
      headerName: t('username'),
      type: 'string',
      align: 'left',
      headerAlign: 'left',
      flex: 1.2,
      minWidth: 180,
      cellClassName: 'name-cell',
      renderCell: ({ row }: any) => (
        <Typography variant="overline" color={COLORS.typography.body}>
          {row.username}
        </Typography>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1.2,
      minWidth: 140,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ row }: any) => (
        <StyledEmail href={`mailto:${row.email}`}>{row.email}</StyledEmail>
      ),
    },
    {
      field: 'dateJoined',
      headerName: t('dateJoined'),
      align: 'left',
      type: 'date',
      headerAlign: 'left',
      flex: 0.9,
      minWidth: 80,
      renderCell: ({ row }: any) => (
        <Tooltip title={getDateTimeString(row.dateJoined)}>
          <Typography variant="overline" color={COLORS.typography.secondary}>
            {getDateString(row.dateJoined)}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'isCompanyAdministrator',
      headerName: t('permissions'),
      flex: 1,
      minWidth: 130,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ row: { isCompanyAdministrator } }: any) => (
        <CandidateChip
          color={COLORS.typography.body}
          background={COLORS.accent.border}
          label={isCompanyAdministrator ? t('administrator') : t('standard')}
        />
      ),
    },
    {
      field: 'action',
      headerName: t('actions', { ns: 'common', keyPrefix: 'columns' }),
      align: 'right',
      headerAlign: 'center',
      minWidth: 100,
      sortable: false,
      renderCell: ({ value, row }: any) => {
        return (
          <ActionColumn
            isActive={row.isActive}
            userId={row.action.id}
            isCompanyAdmin={user?.is_company_administrator || false}
            onEditHandler={() => {
              setModalFor(modalTypes.editingModal);
              setUserToEdit(value);
              setOpenModal(true);
            }}
            onActivateHandler={(isActive: boolean) => {
              editUser({ is_active: isActive }, row.action.id);
            }}
          />
        );
      },
    },
  ];

  const editUser = async (
    newUserData: { is_active: boolean },
    userId: number,
  ) => {
    const result = await dispatch(
      userThunks.updateUserActivityThunk({
        userId,
        payload: newUserData,
      }) as unknown as AnyAction,
    );
    if (result.type === userThunks.updateUserActivityThunk.fulfilled.type) {
      await dispatch(
        userThunks.getCompanyMembersThunk() as unknown as AnyAction,
      );
      setOpenModal(false);
    }
  };

  const editMyData = async (newUserData: MyPersonalData, userId: number) => {
    const result = await dispatch(
      userThunks.updateMyPersonalData({
        userId: userToEdit?.id || userId,
        payload: newUserData,
      }) as unknown as AnyAction,
    );
    if (result.type === userThunks.updateMyPersonalData.fulfilled.type) {
      await dispatch(
        userThunks.getCompanyMembersThunk() as unknown as AnyAction,
      );
      setOpenModal(false);
    }
  };

  const updateRows = () => {
    setRows(
      filterRows(
        companyMembers,
        searchInColumns,
        searchValue,
        topFilterOptions,
        topFilterValue,
      ).map((p: any, i: number) => createRow(p, i)),
    );
  };
  useEffect(() => {
    if (companyMembers?.length) {
      updateRows();
      loading && setLoading(false);
    }
  }, [companyMembers, searchValue, topFilterValue]);

  useEffectAsync(async () => {
    window.scrollTo(0, 0);
    await dispatch(userThunks.getCompanyMembersThunk() as unknown as AnyAction);
  }, []);

  const handleAddingUser = () => {
    setModalFor(modalTypes.addingModal);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const createRow = (userData: CompanyMember, idx: number) => {
    return {
      id: idx,
      userId: userData.id,
      username: userData.username,
      email: userData.email,
      isActive: userData.is_active,
      isCompanyAdministrator: userData.is_company_administrator,
      dateJoined: userData.date_joined,
      action: userData,
    };
  };
  return (
    <StyledTable>
      <UsersModal
        modalFor={modalFor}
        openModal={openModal}
        closeModal={handleCloseModal}
        userToEdit={userToEdit}
        editUser={editMyData}
        partnerNames={undefined}
      />
      <HeadControls>
        <MainHeadline>
          {t('accounts_settings', { keyPrefix: 'my_profile' })}
          <Counter number={companyMembers.length} color="#FFFFFF" />
        </MainHeadline>
      </HeadControls>
      <HeadControls>
        <FiltersContainer>
          <TableFilter
            filterOptions={topFilterOptions}
            filterValue={topFilterValue}
            filterCounts={countTopFilters(
              filterRows(
                companyMembers,
                searchInColumns,
                searchValue,
                topFilterOptions,
                topFilterValue,
                false,
              ),
              topFilterOptions,
            )}
            changeHandler={setTopFilterValue}
          />
        </FiltersContainer>
        <SearchContainer>
          <SearchField initialValue={searchValue} onSubmit={setSearchValue} />
        </SearchContainer>
        <AddButton marginLeft="1" action={handleAddingUser}>
          {t('add_user')}
        </AddButton>
      </HeadControls>
      {rows.length > 0 ? (
        <Table
          columns={columns}
          rows={rows}
          checkboxSelection={false}
          density="standard"
          loading={loading}
        />
      ) : (
        <StyledEmptyResult>
          {t('no_data_found', { ns: 'common', keyPrefix: 'messages' })}
        </StyledEmptyResult>
      )}
    </StyledTable>
  );
};
