import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { styled as materialUIStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { COLORS } from '../../../../../theme/colors';

const FONT_FAMILY = '"Poppins", sans-serif';

export const StyledAutoComplete = materialUIStyled(Autocomplete)({
  background: 'white',
  minWidth: '250px',
  border: `1px solid ${COLORS.accent.border}`,
  borderRadius: 4,
  flexBasis: 'fit-content',

  '& .MuiAutocomplete-endAdornment': {
    right: 0,
    paddingTop: 0,
  },
  '& .MuiAutocomplete-input': {
    fontSize: 14,
    fontFamily: FONT_FAMILY,
  },
  '& .MuiInputBase-input-MuiOutlinedInput-input': {
    height: 10,
    margin: 0,
    padding: '0px !important',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    height: 45,
  },
  '& .MuiOutlinedInput-root': {
    padding: '0px 0px 0px 9px !important',
  },
  '& .MuiAutocomplete-tag ': {
    fontSize: '12px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '14px',
    fontFamily: FONT_FAMILY,
    color: COLORS.typography.main,
    top: '-15px',
    left: '-14px',
    zIndex: 0,
  },
  '& .MuiChip-root': {
    color: COLORS.typography.main,
    background: 'linear-gradient(0deg, #F6F7F8, #F6F7F8), #F9F9F9;',
    fontSize: '14px',
    fontFamily: FONT_FAMILY,
    textTransform: 'capitalize',
    padding: '12px 5px',
    margin: '5px 2px',
  },
  '& .MuiChip-label': {
    padding: '2px 7px',
  },
});

export const StyledSpan = styled.span`
  font-family: ${FONT_FAMILY};
  font-size: 14px;
`;

export const StyledChip = materialUIStyled(Chip)({
  fontSize: '14px',
  fontFamily: FONT_FAMILY,
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
    marginLeft: '1px',
  },
});

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${FONT_FAMILY};
  font-size: 14px;
  margin-bottom: 20px;
`;
