import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { CreatedRowLead } from '../../../../containers/Leads/LeadsTable/LeadsTable.types';
import { CreatedRowHome } from '../../../../containers/Vacancies/VacanciesPageHrSales/CreateRow/CreateRow.types';
import {
  StyledSpan,
  StyledTableCell,
  StyledTableHead,
} from './EnhancedTableHead.styles';
import { EnhancedTableHeadProps } from './EnhancedTableHead.types';

export const EnhancedTableHead = ({
  columns,
  order,
  orderBy,
  handleRequestSort,
}: EnhancedTableHeadProps) => (
  <StyledTableHead>
    <TableRow>
      <StyledTableCell />
      {columns.map((headCell) => (
        <StyledTableCell
          key={headCell.id}
          align="left"
          sortDirection={orderBy === headCell.id ? order : false}
        >
          {headCell.sortable && headCell.id ? (
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() =>
                handleRequestSort(
                  headCell.id as keyof CreatedRowHome | keyof CreatedRowLead,
                )
              }
            >
              <StyledSpan>{headCell.label}</StyledSpan>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          ) : (
            <StyledSpan>{headCell.label}</StyledSpan>
          )}
        </StyledTableCell>
      ))}
      <StyledTableCell />
    </TableRow>
  </StyledTableHead>
);
