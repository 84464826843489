import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { StyledDropzone, StyledLabelContainer } from './Dropzone.styles';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload-cloud.svg';
import { Typography } from '../Typography/Typography.component';
import { COLORS } from '../../../theme/colors';

export const Dropzone = (props) => {
  const { t } = useTranslation(['employee'], { keyPrefix: 'employee_details' });
  const { handleFileSelection } = props;
  const onDrop = handleFileSelection;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <StyledDropzone {...getRootProps({ isDragActive })}>
      <input {...getInputProps()} />
      <UploadIcon />
      <StyledLabelContainer>
        <Typography variant="body" color={COLORS.typography.secondary}>
          {t('drag_file_here_or')}
        </Typography>
        <Typography
          variant="body"
          weight="semibold"
          color={COLORS.accent.primary}
        >
          {t('choose_files')}
        </Typography>
      </StyledLabelContainer>
    </StyledDropzone>
  );
};
