import { CvIdType } from '../../containers/CV/CV.types';
import { ChipStatusesType } from '../../components/common/Chips/StatusChip/config';
import type { Cv } from './Store.candidates.types';
import type { Technology } from './Store.leads.types';
import { NotesSchema } from './Store.partner.types';
import type { Currency } from './Store.types';

export type ClientListId = string;

export interface ClientListState {
  list: ClientList[];
  messages: NotesSchema[];
  candidateHistory: ClientListCandidateHistory[];
}

export interface ClientListProfileState {
  list: ClientListProfile[];
}

export interface ClientList {
  cvs: CvIdType[];
  id: ClientListId;
  name: string;
  notes: NotesSchema[];
  shared_at: string;
  owner: number;
  owner_name: string;
  status: ClientListStatuses;
  rate_of_pay_currency: Currency;
  related_hr_list: string;
  related_partner: number;
  opened_at: string;
  updated_at: string;
  token: string;
}

export interface ClientListCandidateHistory {
  archived: boolean;
  client_list: string;
  confirmed_by_client: boolean;
  created_at: string;
  notes: NotesSchema[];
  rate_of_pay_currency: Currency;
  rate_of_pay_to: number;
  status: ChipStatusesType;
}

export interface ClientListProfile {
  cv: Cv;
  client_list_id: ClientListId;
  created_at: string;
  rate_of_pay_to?: string | null;
  rate_of_pay_currency?: Currency | null;
  confirmed_by_client: boolean;
  technologies?: Technology[] | null;
  history_exists: boolean;
  archived?: boolean;
  notes: ClientListProfileNoteState[];
  status: ClientListProfileStatuses;
  technology_tags?: Technology[];
}

export interface ClientListConfig {
  is_basket_visible: boolean;
  was_feedback_sent: boolean;
  basket_content: ClientListProfile[];
  tab: ClientListProfileStatuses;
  clientListProfilesChanges: ClientListProfileChangesStore[];
  isEditCandidateModalOpen: boolean;
  filter: string;
}

export interface ExternalClientCredentials {
  id?: string;
  token?: string;
}

export interface ClientListProfileNoteState {
  created_at: string;
  note: string;
}
export interface ClientListProfileChanges {
  employeeId: string; // this identifies clp
  cvData?: { profileName: string; id: CvIdType };
  rateOfPay?: number | null;
  currency?: Currency;
  technologyList?: string[];
}

export interface ClientListProfileChangesWithEmployeeData
  extends ClientListProfileChanges {
  employeeFirstName: string;
  employeeLastName: string;
}

export interface EditCandidateRowData {
  candidateId: string;
  candidate: string;
  rateOfPay: string;
  modifiedRate: {
    employeeId: string;
    rate?: string;
    clpCurrency?: Currency;
    currencyToDisplay?: Currency;
    employeeRate: string;
  };
  techStack: {
    employeeId: string;
    technologies?: string[];
  };
  sharedCv: {
    employeeId: string;
    cvs: {
      profileName: string;
      id: string;
    }[];
    selectedCvId?: string;
  };
}

export interface ClientListProfileChangesStore
  extends ClientListProfileChanges {
  clientListId: ClientListId;
}

export interface ClientListChanges {
  clientListId: ClientListId;
}

export enum ClientListProfileStatuses {
  PROPOSED = 'proposed',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export enum ClientListTabs {
  SUGGESTED = 'suggested',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export enum ClientListStatuses {
  CREATED = 'created',
  SENT = 'sent',
  OPENED = 'opened',
  ANSWERED = 'answered',
  CONFIRMED = 'confirmed',
  RESENT = 'resent',
}
