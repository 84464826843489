import { SvgIcon, SxProps } from '@mui/material';

const NotificationRadioButtonChecked = (props: SxProps | undefined) => {
  return (
    <SvgIcon sx={props} fill="none" viewBox="0 0 20 20">
      <circle cx="10" cy="10" r="9.5" fill="#fff" stroke="#9FA3B3" />
      <circle cx="10" cy="10" r="7" fill="#3CC2D9" />
    </SvgIcon>
  );
};

export default NotificationRadioButtonChecked;
