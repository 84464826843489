export const readFromLocalStorageOrDefault = <T>(
  name: string,
  defaultData: T,
): T => {
  const raw = localStorage.getItem(name);

  if (raw !== null) {
    return JSON.parse(raw) as T;
  }
  return defaultData;
};

export const pxToRem = (px: number) => `${px / 16}rem`;

export const getYearDiff = (date1: Date, date2: Date) => {
  return Math.abs(date2.getFullYear() - date1.getFullYear());
};

export const getPayRate = (
  rateOfPayFrom: string | null,
  rateOfPayTo: string | null,
  rateOfPayCurrency: string,
) => {
  let result = '';
  if (rateOfPayTo === null) result += 'od ';
  if (rateOfPayFrom !== null) result += rateOfPayFrom;
  if (rateOfPayFrom === null) result += 'do ';
  if (rateOfPayFrom !== null && rateOfPayTo !== null) result += ' - ';
  if (rateOfPayTo !== null) result += rateOfPayTo;
  result += rateOfPayCurrency;
  return result;
};

export const slugify = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};
