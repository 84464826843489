import { styled as materialUIStyled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';

export const StyledNotesContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`;

export const StyledTextField = materialUIStyled(TextField)({
  '@media (max-width: 600px)': {
    margin: '0 10px',
  },
  '& .MuiOutlinedInput-input': {
    fontSize: '13px',
    fontFamily: "'Neue Haas Grotesk Display Pro', sans-serif",
    fontWeight: '400',
  },
  '& .MuiInputLabel-root': {
    fontSize: '13px',
    top: '6px',
  },
});

export const NotesWrapper = styled.section`
  flex-basis: 50%;
  @media (max-width: 568px) {
    flex-basis: 100%;
  }
`;

export const NoHistory = styled.p`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  margin: 0;
  opacity: 0.5;
`;

export const StyledTextContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-end;
  width: 100%;
`;

export const NotesDisplay = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  max-width: 300px;
  min-width: 300px;
`;

export const Label = styled.div`
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  width: 100%;
`;

export const StyledDescription = styled.div`
  border: 0.5px solid #abafbd;
  border-radius: 5px;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  margin: 0;
  opacity: 0.6;
  overflow: hidden;
  padding: 10px;
  text-align: left;
  width: 280px;
`;
