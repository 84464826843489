import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { buttonsColors } from '../../Button/Button.config';
import { ButtonContainer, ButtonLabel } from './DeleteItemButton.styles';

interface Props {
  action: () => void;
  disabled?: boolean;
}

export const DeleteItemButton = ({ action, disabled, ...props }: Props) => {
  const { t } = useTranslation(['vacancies'], { keyPrefix: 'vacancies' });
  return (
    <ButtonContainer disabled={disabled}>
      <Button variant="text" onClick={action} {...props}>
        <HighlightOffIcon
          style={{ color: buttonsColors.red, fontSize: '18px' }}
        />
        <ButtonLabel style={{ color: buttonsColors.red }}>
          {t('remove_vacancy')}
        </ButtonLabel>
      </Button>
    </ButtonContainer>
  );
};
