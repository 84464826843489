import { createSlice } from '@reduxjs/toolkit';
import { ClientListProfileNoteState } from '../../../store/types/Store.clientList.types';

const initialState: ClientListProfileNoteState[] = [];

const clientListsProfileNoteSlice = createSlice({
  name: 'clientListProfiles',
  initialState,
  reducers: {},
});

export const actions = { ...clientListsProfileNoteSlice.actions };
export const { reducer } = clientListsProfileNoteSlice;
