import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { statusesWithReason } from '../../../../../../containers/Leads/Leads.config';
import { buttonsColors } from '../../../../Button/Button.config';
import { CandidateStatusChip } from '../../../../CandidateStatusChip';
import { Tooltip } from '../../../../Tooltip/Tooltip.component';
import { StyledStatusContainer } from '../CandidateDetailsModalContent.styles';
import { StatusProps } from './Status.types';

export const Status = ({ currentStatus, reason }: StatusProps) => (
  <StyledStatusContainer>
    Status:
    {currentStatus && (
      <CandidateStatusChip
        status={currentStatus}
        isLoading={false}
        size="medium"
      />
    )}
    {currentStatus &&
      statusesWithReason
        .map((status) => status.toUpperCase())
        .includes(currentStatus) && (
        <Tooltip title={reason} position="regular">
          <HelpOutlineIcon
            sx={{ fontSize: '17px', color: buttonsColors.grey }}
          />
        </Tooltip>
      )}
  </StyledStatusContainer>
);
