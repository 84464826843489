import styled from 'styled-components';

export const StyledAdditionalInfo = styled.div`
  font-weight: 200;
  width: 900px;
  :empty:before {
    content: attr(placeholder);
    font-weight: 200;
    opacity: 0.3;

    @media print {
      opacity: 0;
    }
  }
`;
