import { createSearchParams, generatePath } from 'react-router-dom';
import { AppPaths } from '../../../../../config/AppPaths';
import {
  LeadIdType,
  LeadProfileIdType,
} from '../../../../../store/types/Store.home.types';

export const generateLinkToLeadDetails = (
  leadId: LeadIdType,
  leadProfileId?: LeadProfileIdType,
) => {
  if (leadProfileId) {
    const params = { leadProfile: leadProfileId.toString() };
    return {
      pathname: generatePath(AppPaths.leadDetail, {
        id: leadId,
      }),
      search: `?${createSearchParams(params)}`,
    };
  }
  return {
    pathname: generatePath(AppPaths.leadDetail, {
      id: leadId,
    }),
  };
};
