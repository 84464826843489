import React from 'react';
import {
  StyledCustomLegend,
  StyledNumber,
  LegendContainer,
} from './CustomLegend.styles';

export const CustomLegend = ({ name, number, color }) => {
  return (
    <LegendContainer>
      <StyledCustomLegend color={color}>{`${name.slice(0, 1)}${name
        .slice(1)
        .toLowerCase()}`}</StyledCustomLegend>
      <StyledNumber>{number}</StyledNumber>
    </LegendContainer>
  );
};
