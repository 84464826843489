import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { styled as materialUIStyled } from '@mui/material/styles';
import styled from 'styled-components';

export const StyledLeadsContainer = styled.div`
  margin-bottom: 60px;
  padding: 0 65px;
`;

export const StyledStatus = styled.span`
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 14px;
  font-weight: 400px;
  text-transform: capitalize;
`;

export const StatusContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const StyledActiveStatusIcon = materialUIStyled(FiberManualRecordIcon)({
  fontSize: '14px',
  color: '#8BEEAC',
});

export const StyledArchiveStatusIcon = materialUIStyled(FiberManualRecordIcon)({
  fontSize: '14px',
  color: '#FF6F6F',
});

export const StyledLeadTable = styled.div`
  height: 100%;
  margin: 0 auto;
  width: 100%;
`;

export const StyledLabel = styled.h2`
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  margin: 0px;
  @media screen and (max-width: 699px) {
    font-size: 16px;
  }
`;

export const StyledRow = styled.tr``;

export const ExpandableRowContainer = styled.td``;

export const ExpandableCell = styled.td`
  vertical-align: top;
`;

export const StyledSpan = styled.span`
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
`;

export const ExpandableCellContent = styled.div<{ isOpen: boolean }>`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  max-height: 0;
  overflow: hidden;
  transition: max-height 200ms ease-in-out;
  ${({ isOpen }) =>
    isOpen &&
    `
    max-height: fit-content;
  `}
`;
