import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationStackProps } from './Notifications.types';
import NotificationGroup from './Popper/NotificationGroup';

const NotificationStack = (props: NotificationStackProps) => {
  const { t } = useTranslation(['common'], { keyPrefix: 'notifications' });
  const renderedNotificationGroups = React.useMemo<
    JSX.Element[]
  >((): JSX.Element[] => {
    let offset = 0;
    const arrayOfNotificationComps: JSX.Element[] = [];
    const { elementsDictionary } = props;

    Object.keys(elementsDictionary).forEach((groupName: string) => {
      /* I have to instantiate here a variable "currentOffset", because
        if i call it in callback, it will point to the parent "offset" variable,
        causing a lot of mess
        */
      const currentOffset = offset;
      const {
        onNotificationRead,
        onNotificationClick,
        notificationHeight,
        wide,
        separatorHeight,
      } = props;

      const onReadCallback = (idx: number) => {
        return onNotificationRead
          ? onNotificationRead(idx + currentOffset)
          : undefined;
      };

      arrayOfNotificationComps.push(
        <NotificationGroup
          separatorName={wide ? groupName : t(groupName)}
          elements={elementsDictionary[groupName]}
          key={groupName}
          onNotificationRead={onReadCallback}
          onNotificationClick={onNotificationClick}
          elementHeight={`${notificationHeight}px`}
          separatorHeight={`${separatorHeight}px`}
          wide={wide}
        />,
      );
      offset += elementsDictionary[groupName].length;
    });

    return arrayOfNotificationComps;
  }, [props]);

  return <>{renderedNotificationGroups}</>;
};

export default NotificationStack;
