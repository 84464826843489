import { AppPaths } from '../config/AppPaths';
import { UserData } from '../store/types/Store.user.types';
const HUMAN_RESOURCES = [
  AppPaths.register,
  AppPaths.users,
  AppPaths.usersUsers,
  AppPaths.usersPartners,
];
const SALES = [
  AppPaths.register,
  AppPaths.cvs,
  AppPaths.users,
  AppPaths.usersUsers,
  AppPaths.usersPartners,
];
const CLIENT = [
  AppPaths.register,
  AppPaths.cvs,
  AppPaths.users,
  AppPaths.leads,
  AppPaths.candidates,
  AppPaths.home,
  AppPaths.listsOfCandidatesForSharing,
  AppPaths.users,
  AppPaths.usersUsers,
  AppPaths.usersPartners,
];
const PARTNER = [
  AppPaths.register,
  AppPaths.cvs,
  AppPaths.users,
  AppPaths.candidates,
  AppPaths.users,
  AppPaths.usersUsers,
  AppPaths.usersPartners,
];

export const hasPermission = (path: string, user: UserData) => {
  if (user.is_admin) return true;
  if (user.department === 'Sales') {
    return !SALES.includes(path);
  }
  if (user.department === 'External Client') {
    return !CLIENT.includes(path);
  }
  if (user.department === 'Partner') {
    return !PARTNER.includes(path);
  }
  return !HUMAN_RESOURCES.includes(path);
};
