import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';
import { StyledLinkProps } from './LoginForm.types';
import { SIZES } from '../../../theme/sizes';

export const StyledForm = styled.form`
  margin-top: 30px;
`;

export const StyledForgotPasswordSection = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  margin-top: 16px;
`;

export const StyledRegistrationSection = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-top: 72px;
`;

export const StyledLink = styled.a<StyledLinkProps>`
  color: ${COLORS.accent.primary};
  font-size: 14px;
  font-weight: ${({ weight }) => SIZES.fontWeight[weight || 'regular']};
  line-height: 21px;

  &:hover {
    color: ${COLORS.typography.body};
    text-decoration: none;
  }
`;
