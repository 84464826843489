import { NOW_WORDS } from '../ExperienceEntry.config';

export const checkPresentDate = (date: string) => {
  for (let i = 0; i < NOW_WORDS.length; i++) {
    if (date?.includes(NOW_WORDS[i])) {
      return true;
    }
  }
  return false;
};
