import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { DetailsModalContentProps } from './DetailsModalContent.types';
import {
  CloseContainer,
  StyledLabelWrapper,
  StyledContentContainer,
} from './DetailsModalContent.styles';
import { Typography } from '../../common/Typography/Typography.component';
import { ReactComponent as HandShakeIcon } from '../../../assets/icons/handshake.svg';
import { StyledIconButton } from '../CompanyInfo/DetailItem/DetailItem.styles';
import { Button } from '../../common/Button/Button.component';
import { COLORS } from '../../../theme/colors';
import { CompanyInfo } from '../CompanyInfo/CompanyInfo.component';
import { useEffectAsync } from '../../../utils/useEffectAsync';
import { companyThunks } from '../../../containers/Company/Company.thunk';
import { AppStore } from '../../../store/types/Store.types';
import { CompanyItem } from '../../../store/types/Store.company';
import { Company, UserData } from '../../../store/types/Store.user.types';

export const DetailsModalContent = React.forwardRef<
  HTMLDivElement,
  DetailsModalContentProps
>(({ companyId, closeModal }, ref) => {
  const { t } = useTranslation(['company'], {
    keyPrefix: 'company_details',
  });
  const dispatch = useDispatch();
  const company = useSelector<AppStore, CompanyItem>(
    (state) => state.company.company,
  );
  const user = useSelector<AppStore, UserData | undefined | null>(
    (state) => state.user.user,
  );
  const partnerCompanies = useSelector<AppStore, Partial<Company>[]>(
    (state) => state.company.partnerCompanies,
  );
  const [isInvitationSent, setIsInvitationSent] = useState(false);

  useEffectAsync(async () => {
    await Promise.all([
      dispatch(
        companyThunks.getCompanyInfoThunk(companyId) as unknown as AnyAction,
      ),
      dispatch(
        companyThunks.getPartnerCompaniesThunk() as unknown as AnyAction,
      ),
    ]);
  }, [companyId]);

  const isPartnerCompany = () => {
    return (
      partnerCompanies &&
      partnerCompanies.length !== 0 &&
      partnerCompanies
        .map((partnerCompany) => partnerCompany.pk)
        .includes(companyId)
    );
  };

  const handleInviteCompany = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (user?.is_company_administrator) {
      sendInvitation();
    }
  };

  const isMyCompany = () => user?.company?.pk === company.pk || false;

  const sendInvitation = async () => {
    if (company.vat_number && company.country) {
      const result = await dispatch(
        companyThunks.sendInvitationToExistCompanyThunk({
          vat_number: company.vat_number,
          country: company.country.code,
        }) as unknown as AnyAction,
      );
      if (
        result.type ===
        companyThunks.sendInvitationToExistCompanyThunk.fulfilled.type
      ) {
        await dispatch(
          companyThunks.getPartnerCompaniesThunk() as unknown as AnyAction,
        );
        setIsInvitationSent(true);
      }
    }
  };

  return (
    <>
      <StyledLabelWrapper ref={ref}>
        <Typography
          variant="subtitle"
          weight="medium"
          color={COLORS.typography.secondary}
        >
          {t('contactDetails')}
        </Typography>
        <CloseContainer>
          <IconButton onClick={closeModal}>
            <CloseIcon htmlColor="black" fontSize="small" />
          </IconButton>
        </CloseContainer>
      </StyledLabelWrapper>
      <StyledContentContainer>
        <CompanyInfo companyId={companyId} />
        {!isMyCompany() && !isPartnerCompany() && (
          <Box display="flex" gap="12px">
            <StyledIconButton disabled>
              <HandShakeIcon />
            </StyledIconButton>
            <Button
              disabled={!user?.is_company_administrator || isInvitationSent}
              action={handleInviteCompany}
            >
              {t('inviteToCooperate')}
            </Button>
          </Box>
        )}
      </StyledContentContainer>
    </>
  );
});
