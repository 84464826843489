import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import styled from 'styled-components';

export const StyledListItem = styled.div`
  display: table-row;
  padding: 9px 15px;
  width: 100%;
`;

export const DropdownWrapper = styled.section`
  display: table-cell;
  min-width: 20px;
  vertical-align: middle;
`;

export const CandidateName = styled.p`
  display: table-cell;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  min-width: 150px;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PayRate = styled.p`
  display: table-cell;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 12px;
  min-width: 100px;
  vertical-align: middle;
`;

export const Availability = styled.div`
  display: table-cell;
  margin: 0;
  min-width: 100px;
  padding: 0;
  vertical-align: middle;
`;

export const StyledButton = styled(Button)`
  min-width: 30px;
  padding: 6px 5px;
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
`;

export const StyledButtonGroup = styled(ButtonGroup)({
  display: 'flex',
  '& .MuiButtonGroup-grouped': {
    minWidth: 27,
  },
});

export const TooltipContentWrapper = styled.span`
  display: block;
  height: 100%;
`;
