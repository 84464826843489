import styled from 'styled-components';
import { COLORS } from '../../../../theme/colors';
import { Typography } from '../../Typography/Typography.component';

type ItemProps = {
  disabled: boolean;
};

export const StyledLabelContainer = styled.div`
  margin-bottom: 30px;
`;

export const CheckboxLabel = styled(Typography)`
  font-family: 'Inter';
  font-size: 15px;
`;

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: auto;
`;

export const Item = styled.div<ItemProps>`
  cursor: pointer;
  display: flex;
  margin-right: 20px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  position: relative;

  &:hover {
    label {
      box-shadow: 0px 3px 4px rgba(180, 180, 180, 0.2);
    }
  }
`;

export const CheckboxButtonLabel = styled.label`
  cursor: pointer;
  height: 20px;
  left: 0;
  margin-bottom: 3px;
  margin-top: 1px;
  position: absolute;
  width: 20px;
`;

export const CheckboxButton = styled.input`
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  margin: 0;
  margin-right: 13px;
  min-width: 20px;
  opacity: 0;
  z-index: 1;

  ${({ disabled }) =>
    disabled &&
    ` ~ ${CheckboxButtonLabel} {
        background: ${COLORS.stroke.main};
      }
      cursor: unset;
  `}
`;
