import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '../../../store/store';
import {
  ClientListId,
  ClientListProfile,
  ClientListProfileChanges,
  ClientListProfileStatuses,
} from '../../../store/types/Store.clientList.types';
import {
  getAuthHeader,
  getExternalClientAuthHeader,
  handleResponse,
} from '../../../utils/api';
import {
  generateClientListProfilesUrl,
  generateClientListProfileUrl,
  generateExternalClientListCvsUrl,
  generateExternalClientListUpdateUrl,
  generatePartnerListCvsUrl,
  generatePartnerListUpdateUrl,
} from '../../../utils/urlGenerators';
import { CvIdType } from '../../CV/CV.types';

const getClientListProfiles = createAsyncThunk(
  'clientListProfile/getClientListProfiles',
  async (data: { clientListId: ClientListId }, thunkAPI) => {
    const request = fetch(generateClientListProfilesUrl(data.clientListId), {
      method: 'GET',
      headers: getAuthHeader(thunkAPI.getState()),
    });

    return await handleResponse(request);
  },
);

const getClientListProfile = createAsyncThunk(
  'clientListProfile/getClientListProfile',
  async (data: { clientListId: ClientListId; cvId: CvIdType }, thunkAPI) => {
    const request = fetch(
      generateClientListProfileUrl(data.clientListId, data.cvId),
      {
        method: 'GET',
        headers: getAuthHeader(thunkAPI.getState()),
      },
    );

    return await handleResponse(request);
  },
);

const modifyClientListProfile = createAsyncThunk(
  'clientListProfile/update',
  async (
    data: {
      clientListProfile?: ClientListProfile;
      changes: ClientListProfileChanges;
    },
    thunkAPI,
  ) => {
    if (!data.clientListProfile) {
      console.error('Clp not accessible!');
      return;
    }

    const requestBody: Record<string, number | number[] | string> = {};

    if (data.changes.currency) {
      requestBody.rate_of_pay_currency = data.changes.currency;
    }

    if (
      data.changes.rateOfPay !== undefined &&
      data.changes.rateOfPay !== null
    ) {
      requestBody.rate_of_pay_to = data.changes.rateOfPay;
    }

    if (data.changes.cvData !== undefined) {
      requestBody.cv = data.changes.cvData.id;
    }

    if (data.changes.technologyList !== undefined) {
      const mainTechnologies = (thunkAPI.getState() as RootState).leadsRelated
        .tags.main_technologies;

      requestBody.technology_tags = data.changes.technologyList
        .map((el) => mainTechnologies?.find((needle) => needle.name === el)?.id)
        .filter((el) => el !== undefined) as number[];
    }

    const request = fetch(
      generateClientListProfileUrl(
        data.clientListProfile.client_list_id,
        data.clientListProfile.cv.id,
      ),
      {
        method: 'PATCH',
        headers: getAuthHeader(thunkAPI.getState()),
        body: JSON.stringify(requestBody),
      },
    );

    return await handleResponse(request);
  },
);

const changeClientListProfileStatus = createAsyncThunk(
  'clientListProfile/changeStatusClient',
  async (
    data: {
      clientListId: ClientListId;
      cvId: CvIdType;
      status: ClientListProfileStatuses;
    },
    thunkAPI,
  ) => {
    const request = fetch(
      generateClientListProfileUrl(data.clientListId, data.cvId),
      {
        method: 'PATCH',
        headers: getAuthHeader(thunkAPI.getState()),
        body: JSON.stringify({ status: data.status }),
      },
    );

    return await handleResponse(request);
  },
);

const getExternalClientListProfiles = createAsyncThunk(
  'clientListProfile/getExternalClientListProfiles',
  async (id: string, { getState }) => {
    const request = fetch(generateExternalClientListCvsUrl(id), {
      method: 'GET',
      headers: getExternalClientAuthHeader(getState()),
    });

    return await handleResponse(request);
  },
);

const changeExternalClientListProfileStatus = createAsyncThunk(
  'clientListProfile/changeStatus',
  async (
    data: {
      clientListId: ClientListId;
      cvId: CvIdType;
      status: ClientListProfileStatuses;
    },
    { getState },
  ) => {
    const request = fetch(
      generateExternalClientListUpdateUrl(data.clientListId, data.cvId),
      {
        method: 'PATCH',
        headers: getExternalClientAuthHeader(getState()),
        body: JSON.stringify({
          status: data.status,
          confirmed_by_client: false,
        }),
      },
    );

    return await handleResponse(request);
  },
);

const getPartnerListProfiles = createAsyncThunk(
  'clientListProfile/getPartnerListProfiles',
  async (
    data: { listId: string; partnerId: number },
    { getState, dispatch },
  ) => {
    const request = fetch(
      generatePartnerListCvsUrl(data.listId, data.partnerId),
      {
        method: 'GET',
        headers: getAuthHeader(getState()),
      },
    );
    return await handleResponse(request, dispatch);
  },
);

const changePartnerListProfileStatus = createAsyncThunk(
  'clientListProfile/changeStatusPartner',
  async (
    data: {
      clientListId: ClientListId;
      cvId: CvIdType;
      partnerId: number;
      status: ClientListProfileStatuses;
    },
    thunkAPI,
  ) => {
    const request = fetch(
      generatePartnerListUpdateUrl(
        data.clientListId,
        data.cvId,
        data.partnerId,
      ),
      {
        method: 'PATCH',
        headers: getAuthHeader(thunkAPI.getState()),
        body: JSON.stringify({
          status: data.status,
          confirmed_by_client: false,
        }),
      },
    );

    return await handleResponse(request);
  },
);

const deleteEmployeeFromClientList = createAsyncThunk(
  'clientListProfile/deleteProfile',
  async (
    data: {
      clientListId: ClientListId;
      cvId: CvIdType;
    },
    { getState, dispatch },
  ) => {
    const request = fetch(
      generateClientListProfileUrl(data.clientListId, data.cvId),
      {
        method: 'DELETE',
        headers: getAuthHeader(getState()),
      },
    );
    return await handleResponse(request, dispatch);
  },
);

const addCandidate = createAsyncThunk(
  'clientLists/addEmployeeToClientList',
  async (data: { listId: string; id: string }, { getState, dispatch }) => {
    const request = fetch(generateClientListProfilesUrl(data.listId), {
      method: 'POST',
      headers: getAuthHeader(getState()),
      body: JSON.stringify({
        cv: data.id,
      }),
    });
    return await handleResponse(request, dispatch);
  },
);

export const clientListProfileThunks = {
  getClientListProfiles,
  getExternalClientListProfiles,
  getPartnerListProfiles,
  getClientListProfile,
  changeClientListProfileStatus,
  changeExternalClientListProfileStatus,
  changePartnerListProfileStatus,
  modifyClientListProfile,
  deleteEmployeeFromClientList,
  addCandidate,
};
