import { isPartner } from '../../../../../Helpers';
import { SuggestedCandidate } from '../../../../../store/types/Store.home.types';
import { UserData } from '../../../../../store/types/Store.user.types';

export const getCandidateName = (
  candidate: SuggestedCandidate,
  user: UserData,
): string =>
  isPartner(user)
    ? `${candidate.first_name || candidate.employee_data.first_name} - ${
        candidate.employee_data.readable_id || candidate.candidate_id
      }`
    : `${candidate.first_name || candidate.employee_data.first_name} ${
        candidate.last_name || candidate.employee_data.last_name
      }`;
