import { Divider, Stack } from '@mui/material';
import {
  StyledNotificationGroupContainer,
  StyledNotificationSeparatorContainer,
} from '../Notifications.styles';
import { NotificationGroupProps } from '../Notifications.types';
import SingleNotificationElement from '../SingleNotificationElement';

const NotificationGroup = (props: NotificationGroupProps) => {
  const {
    separatorHeight,
    separatorName,
    elements,
    wide,
    onNotificationRead,
    onNotificationClick,
  } = props;

  return (
    <StyledNotificationGroupContainer>
      <StyledNotificationSeparatorContainer style={{ height: separatorHeight }}>
        {separatorName}
      </StyledNotificationSeparatorContainer>
      <Stack divider={<Divider variant="fullWidth" />}>
        {elements.map((el, idx) => (
          <SingleNotificationElement
            key={idx.toString()}
            wide={wide}
            content={el}
            height="71px"
            onNotificationRead={() =>
              onNotificationRead ? onNotificationRead(idx) : undefined
            }
            onNotificationClick={
              onNotificationClick &&
              (() =>
                onNotificationClick ? onNotificationClick(idx) : undefined)
            }
          />
        ))}
      </Stack>
    </StyledNotificationGroupContainer>
  );
};

export default NotificationGroup;
