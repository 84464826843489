import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';

export const StyledDropzone = styled.div`
  align-items: center;
  border: 1px dashed;
  border-color: ${({ isDragActive }) =>
    isDragActive ? COLORS.accent.primary : COLORS.accent.border};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 25px 0;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledLabelContainer = styled.div`
  display: flex;
  gap: 5px;
`;
