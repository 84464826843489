import React from 'react';
import { StyledModal } from '../../Modal.styles';
import { StyledModalWrapper } from './PartnersModal.styles';
import { wideModals, modalSizes } from '../../Modal.config';
import { AddingModalContent } from '../AddingModalContent/AddingModalContent.component';
import { EditingModalContent } from '../EditingModalContent/EditingModalContent.component';

export const PartnersModal = ({
  modalFor,
  openModal,
  closeModal,
  partnerToEdit,
  editPartner,
}) => {
  const modalWidth = wideModals.includes(modalFor)
    ? modalSizes.wide
    : modalSizes.narrow;

  const modalsOptions = {
    adding: (
      <AddingModalContent openModal={openModal} closeModal={closeModal} />
    ),
    editing: (
      <EditingModalContent
        openModal={openModal}
        closeModal={closeModal}
        partnerToEdit={partnerToEdit}
        handleSubmit={editPartner}
      />
    ),
  };

  return (
    <StyledModal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="partners-modal-title"
      aria-describedby="partners-modal-description"
      width={modalWidth}
    >
      <StyledModalWrapper>{modalsOptions[modalFor]}</StyledModalWrapper>
    </StyledModal>
  );
};
