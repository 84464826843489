import { createSlice } from '@reduxjs/toolkit';
import { idGenerator } from '../../utils/idGenerator';
import { findSectionWithParent } from '../../utils/recursiveSearch';
import { cvThunks } from './CV.thunk';
import { getInitialState } from './CV.utils';

const initialState = getInitialState();

const { loadCvThunk, injectCvDataThunk } = cvThunks;

const getDefaultSection = (sectionType, entries) => ({
  sectionId: idGenerator(),
  type: sectionType,
  height: 0,
  visible: true,
  entries: entries?.map((entry) => ({
    sectionId: idGenerator(),
    type: entry.sectionType,
    height: 0,
  })),
});

export const cvSlice = createSlice({
  name: 'cv',
  initialState,
  reducers: {
    addEntry: (state, action) => {
      const { sectionId, sectionType, entries } = action.payload;
      const targetEntries = findSectionWithParent(
        sectionId,
        state.sectionConfig,
      ).section.entries;

      targetEntries.push(getDefaultSection(sectionType, entries));
    },
    addSection: (state, action) => {
      const { sectionType, entries } = action.payload;
      state.sectionConfig.entries.push(getDefaultSection(sectionType, entries));
    },
    deleteEntry: (state, action) => {
      const { sectionId } = action.payload;
      const sectionWithParent = findSectionWithParent(
        sectionId,
        state.sectionConfig,
      );
      const { entries } = sectionWithParent.parentSection;
      const index = entries.indexOf(sectionWithParent.section);
      entries.splice(index, 1);
    },
    deleteSection: (state, action) => {
      const { sectionId } = action.payload;
      const sectionWithParent = findSectionWithParent(
        sectionId,
        state.sectionConfig,
      );
      const targetIndex = sectionWithParent.parentSection.entries.indexOf(
        sectionWithParent.section,
      );
      sectionWithParent.parentSection.entries.splice(targetIndex, 1);
    },
    updateSectionHeight: (state, action) => {
      const { sectionId, height } = action.payload;
      const targetSection = findSectionWithParent(
        sectionId,
        state.sectionConfig,
      ).section;
      if (targetSection) targetSection.height = height;
    },
    toggleVisibility: (state, action) => {
      const { sectionId } = action.payload;
      const targetSection = findSectionWithParent(
        sectionId,
        state.sectionConfig,
      ).section;
      targetSection.visible = !targetSection.visible;
    },
    reorder: (state, action) => {
      const { draggedSectionId, targetSectionId } = action.payload;

      const draggedSectionWithParent = findSectionWithParent(
        draggedSectionId,
        state.sectionConfig,
      );
      const targetSectionWithParent = findSectionWithParent(
        targetSectionId,
        state.sectionConfig,
      );

      const draggedSectionParent = draggedSectionWithParent.parentSection;
      const targetSectionParent = targetSectionWithParent.parentSection;

      if (draggedSectionParent !== targetSectionParent) {
        return;
      }

      const draggedSectionIndex = draggedSectionParent.entries.indexOf(
        draggedSectionWithParent.section,
      );
      const targetSectionIndex = draggedSectionParent.entries.indexOf(
        targetSectionWithParent.section,
      );

      const temp = draggedSectionParent.entries[draggedSectionIndex];

      if (draggedSectionIndex - targetSectionIndex > 0) {
        for (let i = draggedSectionIndex; i >= targetSectionIndex; i--) {
          draggedSectionParent.entries[i] = draggedSectionParent.entries[i - 1];
        }
      }

      if (draggedSectionIndex - targetSectionIndex < 0) {
        for (let i = draggedSectionIndex; i <= targetSectionIndex; i++) {
          draggedSectionParent.entries[i] = draggedSectionParent.entries[i + 1];
        }
      }

      draggedSectionParent.entries[targetSectionIndex] = temp;
    },
    setEmployeeContext: (state, action) => {
      const { id, fullName } = action.payload;
      state.employeeContext = {
        employeeId: id,
        employeeName: fullName,
      };
    },
    setTextFormattingPanelVisibility: (state, action) => {
      state.textFormattingPanelVisible = action.payload;
    },
    reset: () => getInitialState(),
    undo: () => {},
    redo: () => {},
    clearHistory: () => {},
  },
  extraReducers: {
    [loadCvThunk.fulfilled]: (state, action) => {
      const { editor_config: sectionConfig, employee_obj: employee } =
        action.payload;
      state.sectionConfig.entries = sectionConfig;
      state.employeeContext = {
        employeeId: employee.id,
        employeeName: `${employee.first_name} ${employee.last_name}`,
        employeeExperience: employee.experience,
      };
      state.dataLoaded = true;
    },
    [injectCvDataThunk.fulfilled]: (state, action) => {
      const { editor_config: sectionConfig, employee_obj: employee } =
        action.payload;
      state.sectionConfig.entries = sectionConfig;
      state.employeeContext = {
        employeeId: employee.id,
        employeeName:
          employee.name || `${employee.first_name} ${employee.last_name}`,
        employeeExperience: employee.experience,
      };
      state.dataLoaded = true;
    },
  },
});

export const { actions } = cvSlice;
export const { reducer } = cvSlice;
