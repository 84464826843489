import parse from 'html-react-parser';
import { useState } from 'react';
import { Button } from '../../../../../Button/Button.component';
import { EditButton } from '../../../../../IconButtons/EditButton';
import { TextField } from '../../../../../TextField';
import { useEmployeePageHrNotesHandler } from '../../hooks/useEmloyeePageHrNotesHandler';
import { useHrNotesHandler } from '../../hooks/useHrNotesHandlers';
import {
  NotesDisplay,
  StyledDescription,
  StyledTextContainer,
} from '../Notes.styles';
import { HrNotesProps } from '../Notes.types';

export const HrNotes = ({
  devRequestDetails,
  candidateInfo,
  leadId,
  profileId,
  candidateId,
  employeeId,
  homeLeadProfile,
}: HrNotesProps) => {
  const { handleHrSaving } = devRequestDetails
    ? useEmployeePageHrNotesHandler(devRequestDetails)
    : useHrNotesHandler(
        leadId,
        profileId,
        candidateId,
        employeeId,
        homeLeadProfile,
        candidateInfo,
      );
  const [currentlyEditingHrNotes, setCurrentlyEditingHrNotes] = useState(false);
  const [notes, setNotes] = useState(
    devRequestDetails?.hr_notes || candidateInfo?.hr_notes || '',
  );

  if (currentlyEditingHrNotes) {
    return (
      <StyledTextContainer>
        <TextField
          name="hr_notes"
          placeholder=""
          multiline
          rows={5}
          value={notes}
          handleInputChange={(e) => setNotes(e.target.value)}
        />
        <Button
          action={() => {
            handleHrSaving(notes);
            setCurrentlyEditingHrNotes(false);
          }}
        >
          Save
        </Button>
      </StyledTextContainer>
    );
  }

  return (
    <NotesDisplay>
      <StyledDescription>
        {notes && parse(notes.replace(/(\r\n|\n|\r)/gm, '<br>'))}
      </StyledDescription>
      <EditButton
        action={() => setCurrentlyEditingHrNotes(true)}
        title="Edit notes"
      />
    </NotesDisplay>
  );
};
