import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { VacanciesPageContext } from '../../../../containers/Vacancies/VacanciesPageHrSales/VacanciesPageContext/VacanciesPageContext';
import { addDotsToString } from '../../../../Helpers';
import { Cv } from '../../../../store/types/Store.candidates.types';
import { AppStore } from '../../../../store/types/Store.types';
import { UserState } from '../../../../store/types/Store.user.types';
import { CandidateStatusChip } from '../../../common/CandidateStatusChip';
import { DetailsButton } from '../../../common/IconButtons/DetailsButton';
import { CVModal } from '../../../common/Modals/CVModal/CVModal.component';
import { Tooltip } from '../../../common/Tooltip/Tooltip.component';
import { DropdownWrapper } from '../../../Lead/CandidatesSuggestedByHr/CandidateListItem/CandidateListItem.styles';
import { Availability } from './Availability';
import { ButtonGroup } from './ButtonGroup/ButtonGroup';
import { CandidateSkeleton } from './CandidateSkeleton/CandidateSkeleton';
import {
  CandidateName,
  CellWrapper,
  PayRate,
  StyledWrapper,
} from './CandidateView.styles';
import { CandidateProps, CvModalState } from './CandidateView.types';
import { getCandidateFullName } from './helpers/getCandidateFullName';
import { getCandidateName } from './helpers/getCandidateName';
import { getCandidatePayRate } from './helpers/getCandidatePayRate';
import { StatusMenu } from './StatusMenu/StatusMenu';
import { useChangeStatus } from './useChangeStatus/useChangeStatus';

const STRING_LIMIT_CANDIDATE_NAME = 20;

export const CandidateBar = ({
  candidate,
  leadProfileData,
  setDownloadFinished,
  downloadFinished,
  setCvToDownload,
  type,
}: CandidateProps) => {
  const { handleShowCandidatesDetails, handleDeleteCandidateModal } =
    useContext(VacanciesPageContext);
  const { changeStatus, setChangeStatusInProgress, changeStatusInProgress } =
    useChangeStatus(
      leadProfileData.lead_id,
      leadProfileData.lead_profile_id,
      candidate,
      type,
    );

  const userState = useSelector<AppStore, UserState>((state) => state.user);
  const [cvModal, setCvModal] = useState<CvModalState>({
    isOpen: false,
  });
  const candidateNameDisplay =
    userState?.user && getCandidateName(candidate, userState.user);

  const hanldeOpenModal = () => {
    if (candidate) {
      const sharedCv = candidate.employee_data.cvs.find((cv) => cv.is_shared);
      sharedCv &&
        sharedCv.id &&
        setCvModal({
          cvId: sharedCv.id,
          isOpen: true,
        });
    }
  };
  const hanldeCloseModal = () => setCvModal({ isOpen: false });
  const handleDeleteSuggestedCandidate = () => {
    candidate &&
      handleDeleteCandidateModal(
        leadProfileData.lead_profile_id,
        leadProfileData.lead_id,
        getCandidateFullName(candidate),
        candidate.candidate_id || candidate.id!,
      );
  };
  const handleDownloadCv = (cv: Cv) => {
    setCvToDownload(undefined);
    setDownloadFinished(false);
    setCvToDownload(cv);
  };

  if (!candidate) {
    return <CandidateSkeleton />;
  }
  return (
    <StyledWrapper>
      <DropdownWrapper>
        <StatusMenu
          currentStatus={candidate.current_status?.status!}
          leadProfile={leadProfileData}
          suggestedCandidate={candidate}
          {...{
            setChangeStatusInProgress,
            changeStatus,
            changeStatusInProgress,
          }}
        />
      </DropdownWrapper>
      {candidateNameDisplay &&
      candidateNameDisplay.length > STRING_LIMIT_CANDIDATE_NAME ? (
        <Tooltip title={candidateNameDisplay} position="close">
          <CandidateName>
            {addDotsToString(candidateNameDisplay, STRING_LIMIT_CANDIDATE_NAME)}
          </CandidateName>
        </Tooltip>
      ) : (
        <CandidateName>{candidateNameDisplay}</CandidateName>
      )}
      <PayRate>{getCandidatePayRate(candidate)}</PayRate>
      <Availability candidate={candidate} />
      <CellWrapper>
        <CandidateStatusChip
          status={candidate.current_status?.status!}
          isLoading={changeStatusInProgress}
          {...{ setChangeStatusInProgress, changeStatus }}
          size="small"
        />
      </CellWrapper>
      <ButtonGroup
        candidate={candidate}
        loading={false}
        {...{
          hanldeOpenModal,
          handleDownloadCv,
          handleDeleteSuggestedCandidate,
          downloadFinished,
        }}
      />
      {cvModal.isOpen && (
        <CVModal
          openModal={cvModal.isOpen}
          closeModal={hanldeCloseModal}
          cvId={cvModal.cvId}
        />
      )}
      <CellWrapper>
        <DetailsButton
          action={() => handleShowCandidatesDetails(candidate, leadProfileData)}
        />
      </CellWrapper>
    </StyledWrapper>
  );
};
