import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../store/store';
import { employeeThunks } from '../../../../containers/Employee/Employees.thunk';
import { DeleteFromWatchedModalProps } from './types';
import { StyledArchiveModalContainer, StyledArchiveModalTitle } from './styles';
import { DialogButton } from '../../Buttons/DialogButton/DialogButton.component';

export const DeleteFromWatchedModalContent = ({
  onClose,
  employeeId,
}: DeleteFromWatchedModalProps) => {
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.watched_candidates',
  });
  const dispatch = useDispatch();
  const employeeFetched = useSelector((state: RootState) =>
    state.employees.watched.find((empl) => empl.id === employeeId),
  );

  const handleDeleteConfirm = async () => {
    const data = {
      employeeId,
      payload: { observed: false },
      isMyProfile: true,
    };
    const results = await dispatch(
      employeeThunks.updateEmployeeObservedStatusThunk(
        data,
      ) as unknown as AnyAction,
    );
    if (
      results.type ===
      employeeThunks.updateEmployeeObservedStatusThunk.fulfilled.type
    ) {
      dispatch(
        employeeThunks.getWatchedEmployeesThunk() as unknown as AnyAction,
      );
      onClose();
    }
  };

  const profile = employeeFetched?.profile || '';

  return (
    <StyledArchiveModalContainer>
      <StyledArchiveModalTitle>
        {t('confirm_delete', { name: profile })}
      </StyledArchiveModalTitle>
      <DialogButton action={handleDeleteConfirm}>
        {t('confirm', { ns: 'common', keyPrefix: 'buttons' })}
      </DialogButton>
    </StyledArchiveModalContainer>
  );
};
