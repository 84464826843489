import ReactQuill from 'react-quill';
import styled from 'styled-components';

export const StyledRow = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 24px;
  width: 100%;

  @media (max-width: 699px) {
    height: 150px;
    flex-direction: column;
    gap: 0px;
  }
`;

export const StyledFormHalfRowContainer = styled.div`
  width: 48%;
`;

export const ChipsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

export const StyledLabelContainer = styled.div`
  align-items: flex-start;
  display: flex;
  height: 60px;
  padding-top: 4px;
`;

export const PriorityContainer = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 17px;
  width: 300px;
  @media (max-width: 699px) {
    margin-top: 25px;
  }
`;

export const StatusContainer = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 15px;
  width: 300px;

  @media (max-width: 699px) {
    margin-top: 20px;
  }
`;

export const ReasonContainer = styled.div`
  margin-top: 20px;

  @media (max-width: 699px) {
    margin-top: 0px;
  }
`;

export const StyledTextContainer = styled.div`
  @media (max-width: 699px) {
    margin-top: 75px;
  }
`;

export const MainTechnologiesContainer = styled.section`
  margin-top: 20px;
  width: 300px;
  @media (max-width: 699px) {
    margin-top: 45px;
  }
`;

export const StyledReactQuill = styled(ReactQuill)`
  margin-bottom: 15px;

  .ql-toolbar.ql-snow {
    border: 1px solid #d7ddea;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 0px 10px;
  }

  .ql-container.ql-snow {
    border: 1px solid #d7ddea;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    line-height: 18px;
    min-height: 200px;
  }

  .ql-editor .ql-blank {
    min-height: 200px;
  }

  .ql-editor {
    & p {
      font-family: 'Neue Haas Grotesk Display Pro';
      font-size: 13px;

      & strong {
        font-family: 'Neue Haas Grotesk Display Pro';
        font-size: 13px;
        font-weight: 600;
      }

      & em {
        font-family: 'Neue Haas Grotesk Display Pro';
        font-size: 13px;
      }

      & u {
        font-family: 'Neue Haas Grotesk Display Pro';
        font-size: 13px;
      }
    }

    & li {
      font-family: 'Neue Haas Grotesk Display Pro';
      font-size: 13px;

      & strong {
        font-family: 'Neue Haas Grotesk Display Pro';
        font-size: 13px;
        font-weight: 600;
      }

      & em {
        font-family: 'Neue Haas Grotesk Display Pro';
        font-size: 13px;
      }

      & u {
        font-family: 'Neue Haas Grotesk Display Pro';
        font-size: 13px;
      }
    }

    & ul {
      padding-left: 5px;
    }

    & ol {
      padding-left: 5px;
    }
  }

  a:-webkit-any-link {
    color: #3cc2d9;
    text-decoration: none;
  }
`;

export const OptionItem = styled.li`
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  height: 10;
  margin: 0;
`;
