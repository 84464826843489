import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';
import { pastePlainText } from '../../../../Helpers';
import { WithHeightUpdate } from '../../../../utils/WithHeightUpdate';
import { StyledEntryContainer } from '../../../common/CV/EntryContainer/EntryContainer.styles';
import {
  StyledAddCustomInfoButton,
  StyledContainer,
  StyledIcon,
  StyledPositionDescription,
} from './PositionEntry.styles';

const PositionEntryComponent = forwardRef(
  (
    {
      editable,
      data,
      setTextFormattingPanelVisibility,
      handleEditData,
      handleRemoveEntry,
    },
    ref,
  ) => {
    return (
      <StyledEntryContainer ref={ref}>
        <StyledContainer>
          <StyledPositionDescription
            placeholder={`Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Facere culpa vel quibusdam temporibus ipsam doloremque error optio corporis id nulla!`}
            suppressContentEditableWarning
            contentEditable={editable}
            onFocus={() => {
              setTextFormattingPanelVisibility('bulletable');
            }}
            onPaste={(e) => pastePlainText(e)}
            onBlur={(e) => {
              setTextFormattingPanelVisibility(false);
              handleEditData({
                description: e.currentTarget.innerHTML,
              });
            }}
            spellCheck={false}
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          />
          {editable && (
            <StyledAddCustomInfoButton>
              <StyledIcon onClick={handleRemoveEntry}>
                <FontAwesomeIcon icon={faMinusCircle} />
              </StyledIcon>
            </StyledAddCustomInfoButton>
          )}
        </StyledContainer>
      </StyledEntryContainer>
    );
  },
);

export const PositionEntry = WithHeightUpdate(PositionEntryComponent);
