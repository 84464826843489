import { StatusChip } from '../../../../common/Chips/StatusChip/StatusChip';
import {
  AvailabilityWrapper,
  TooltipContentWrapper,
} from './Availability.styles';
import { AvailabilityProps } from './Availability.types';

import { Tooltip } from '../../../../common/Tooltip/Tooltip.component';

const AVAILABILITY_STRING_LIMIT = 13;

export const Availability = ({ candidate }: AvailabilityProps) => (
  <AvailabilityWrapper>
    {candidate.employee_data.availability &&
    candidate.employee_data.availability?.length > AVAILABILITY_STRING_LIMIT ? (
      <Tooltip title={candidate.employee_data.availability} position="regular">
        <TooltipContentWrapper>
          <StatusChip
            label={
              candidate.availability ||
              candidate.employee_data.availability ||
              'no data'
            }
            status="availability"
          />
        </TooltipContentWrapper>
      </Tooltip>
    ) : (
      <StatusChip
        label={
          candidate.availability ||
          candidate.employee_data.availability ||
          'no data'
        }
        status="availability"
      />
    )}
  </AvailabilityWrapper>
);
