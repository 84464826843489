import { CreatedRowLead } from '../../../../containers/Leads/LeadsTable/LeadsTable.types';
import { OrderBy, SortOrderKey } from '../CollapseTable.types';

export const isTimeStamp = (val: string) => new Date(val).getTime() > 0;

const sorterErrorHandler = (key: OrderBy) =>
  console.error(`Wrong ${key} fromat`);

export const getLeadsComparator = (
  order: SortOrderKey,
  orderBy: keyof CreatedRowLead,
) => {
  // eslint-disable-next-line radar/no-small-switch
  switch (orderBy) {
    case 'date':
      if (order === 'asc') {
        return (a: CreatedRowLead, b: CreatedRowLead) => {
          if (
            !isTimeStamp(a[orderBy] as string) ||
            !isTimeStamp(b[orderBy] as string)
          ) {
            sorterErrorHandler(orderBy);
            return 0;
          }
          return (
            new Date(a[orderBy]).valueOf() - new Date(b[orderBy]).valueOf()
          );
        };
      }
      return (a: CreatedRowLead, b: CreatedRowLead) => {
        if (
          !isTimeStamp(a[orderBy] as string) ||
          !isTimeStamp(b[orderBy] as string)
        ) {
          sorterErrorHandler(orderBy);
          return 0;
        }
        return new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf();
      };
    case 'requestNum':
      return order === 'asc'
        ? (a: CreatedRowLead, b: CreatedRowLead) => a[orderBy] - b[orderBy]
        : (a: CreatedRowLead, b: CreatedRowLead) => b[orderBy] - a[orderBy];
    default:
      if (order === 'asc') {
        return (a: CreatedRowLead, b: CreatedRowLead) => {
          if (
            typeof a[orderBy] !== 'string' ||
            typeof b[orderBy] !== 'string'
          ) {
            sorterErrorHandler(orderBy);
            return 0;
          }

          return (b[orderBy] as string).localeCompare(a[orderBy] as string);
        };
      }
      return (a: CreatedRowLead, b: CreatedRowLead) => {
        if (typeof a[orderBy] !== 'string' || typeof b[orderBy] !== 'string') {
          sorterErrorHandler(orderBy);
          return 0;
        }

        return (a[orderBy] as string).localeCompare(b[orderBy] as string);
      };
  }
};
