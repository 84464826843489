import { AnyAction } from '@reduxjs/toolkit';
import React, { PropsWithChildren, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { leadDetailsThunks } from '../../../../../../containers/Leads/redux/LeadDetails.thunk';
import { leadProfilesThunks } from '../../../../../../containers/Leads/redux/LeadProfiles.thunk';
import { leadsListThunks } from '../../../../../../containers/Leads/redux/LeadsList.thunk';
import { LeadProfile } from '../../../../../../store/types/Store.leads.types';
import { LeadRequestForm } from '../../../../../Lead/LeadRequestForm';
import { ButtonsConfigType } from '../../../DeleteModal/DeleteModal.types';
import { RequestAddingModalContentProps } from './RequestAddingModalContent.types';
import { myVacanciesPageThunks } from '../../../../../../containers/User/MyProfile/MyVacancies/MyVacancies.thunk';
import { Option } from '../../../../Selector/Selector.types';

export const RequestAddingModalContent = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<RequestAddingModalContentProps>
>(({ closeModal, hrOwners, nameTags, reasonTags, leadId, leads }, ref) => {
  const { t } = useTranslation(['common', 'vacancies'], {
    keyPrefix: 'buttons',
  });
  const [processingRequest, setProcessingRequest] = useState(false);
  const [formLeadRequest, setFormLeadRequest] =
    useState<Partial<LeadProfile>>();
  const dispatch = useDispatch();

  const handleSubmit = async (request: Partial<LeadProfile>) => {
    const currentLeadId = leadId || request.leadId;
    if (currentLeadId) {
      const result = await dispatch(
        leadProfilesThunks.createLeadRequestThunk({
          request: {
            ...request,
            visibility: (request.visibility as Option)?.value,
          } as unknown as BodyInit,
          leadId: currentLeadId,
        }) as unknown as AnyAction,
      );

      if (
        result.type === leadProfilesThunks.createLeadRequestThunk.fulfilled.type
      ) {
        closeModal();
        dispatch(
          leadProfilesThunks.getLeadRequestsThunk({
            leadId: currentLeadId,
          }) as unknown as AnyAction,
        );
        leadId &&
          dispatch(leadsListThunks.getLeadsThunk() as unknown as AnyAction);
        request.leadId &&
          dispatch(
            myVacanciesPageThunks.getMyVacanciesDataThunk() as unknown as AnyAction,
          );
        dispatch(
          leadDetailsThunks.getLeadThunk(currentLeadId) as unknown as AnyAction,
        );
      } else {
        setFormLeadRequest(request);
      }
    }
  };

  const getButtonsConfig = (
    request: Partial<LeadProfile>,
  ): ButtonsConfigType => ({
    primaryButton: {
      label: t('save'),
      action: async () => {
        if (!processingRequest) {
          setProcessingRequest(true);
          await handleSubmit(request);
          setProcessingRequest(false);
        }
      },
    },
  });

  return (
    <LeadRequestForm
      ref={ref}
      dialogTitle={t('add_new_request', {
        ns: 'vacancies',
        keyPrefix: 'vacancies',
      })}
      {...{
        getButtonsConfig,
        hrOwners,
        nameTags,
        reasonTags,
        leadId,
        leads,
      }}
      handleClose={closeModal}
      requestFetched={formLeadRequest}
    />
  );
});
