import AddIcon from '@mui/icons-material/Add';
import styled from 'styled-components';
import { COLORS } from '../../../../theme/colors';

type ButtonWrapperProps = {
  marginLeft?: string;
  marginRight?: string;
  variant?: 'primary' | 'secondary';
};

const getBackgroundColor = (
  disabled?: boolean,
  variant?: 'primary' | 'secondary',
) => {
  if (disabled) return 'rgba(0, 0, 0, 0.2)';
  if (variant === 'primary') return COLORS.accent.primary;
  return COLORS.accent.secondary;
};

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
  align-items: center;
  background-color: ${({ variant, disabled }) =>
    getBackgroundColor(disabled, variant)};
  border: none;
  border-radius: 8px;
  color: ${({ variant }) =>
    variant === 'primary'
      ? COLORS.background.secondary
      : COLORS.accent.primary};
  display: flex;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  margin-left: ${({ marginLeft }) => {
    return marginLeft ? '18px' : '0';
  }};
  margin-right: ${({ marginRight }) => {
    return marginRight ? '18px' : '0';
  }};
  padding: 8px 20px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};
  transition-duration: 0.2s;
  white-space: pre;

  &:hover {
    background-color: ${({ variant }) =>
      variant === 'primary' ? COLORS.accent.primaryHover : COLORS.info.main};
    cursor: pointer;
  }
  &:active {
    background-color: ${({ variant }) =>
      variant === 'primary' ? COLORS.accent.primaryHover : COLORS.info.main};
  }
`;

export const StyledAddIcon = styled(AddIcon)`
  font-size: 16px;
  margin-right: 3px;
`;
