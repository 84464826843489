import { Candidates } from '../../../../../components/Vacancies/Candidates';
import { JobDescription } from '../../../../../components/Vacancies/JobDescription/JobDescription.component';
import { columns, getHomeColumnsAmount } from '../../config';
import {
  ExpandableCell,
  ExpandableCellContent,
  StyledRow,
} from './ExpandableHomeRow.styles';
import { ExpandableRowProps } from './ExpandableHomeRow.types';

export const ExpandableHomeRow = ({
  leadProfile,
  isOpen,
  type,
}: ExpandableRowProps) => {
  return columns && columns.length ? (
    <StyledRow key={leadProfile.lead_profile_id}>
      <ExpandableCell colSpan={getHomeColumnsAmount()}>
        <ExpandableCellContent {...{ isOpen }}>
          <JobDescription
            isMyCompanyLeadProfile={leadProfile.belonging === 'my'}
            files={leadProfile.files}
            description={leadProfile.description}
            leadProfileId={leadProfile.lead_profile_id}
            leadId={leadProfile.lead_id}
          />
          <Candidates
            leadProfileData={leadProfile}
            isOpen={isOpen}
            type={type}
          />
        </ExpandableCellContent>
      </ExpandableCell>
    </StyledRow>
  ) : (
    <></>
  );
};
