import styled from 'styled-components';

export const SimilarCompanyContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 20px;
`;

export const WarningTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

export const FoundCompanyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const WarningText = styled.span`
  color: grey;
  font-size: 12px;
  font-weight: 400;
`;

export const ReviewButton = styled.span`
  color: #d32f2f;
  font-size: 12px;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`;

export const DismissButton = styled.span`
  color: grey;
  font-size: 12px;
  font-weight: 600;
  opacity: 0.4;

  &:hover {
    cursor: pointer;
  }
`;
