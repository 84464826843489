import { Currency } from '../../../store/types/Store.types';

export const getPayRate = (
  rate_of_pay_from: number | string,
  rate_of_pay_to: number | string,
  rate_of_pay_currency: Currency,
) =>
  rate_of_pay_from === rate_of_pay_to
    ? `${rate_of_pay_from || 0} ${rate_of_pay_currency}`
    : `${rate_of_pay_from || 0} - ${
        rate_of_pay_to || 0
      } ${rate_of_pay_currency}`;
