import styled from 'styled-components';

export const StyledEmployeeTable = styled.div`
  width: 100%;
`;

export const InnerTooltipText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledProfile = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
`;

export const StyledCompany = styled.div`
  color: #9fa3b3;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  width: 100px;
`;

export const StyledDate = styled.div`
  color: #adb0c0;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  width: 60px;
`;

export const StyledAvailability = styled.div`
  min-width: 100px;
`;

export const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  max-width: 30px;
`;

export const TechnologiesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
  position: relative;
`;

export const ButtonInnerInfo = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
`;
