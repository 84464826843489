export const STRING_LIMIT = 24;

export const getTopFilterOptions = (t: any) => {
  return {
    current: {
      name: 'current',
      match: (el: any) => el.status !== 'ARCHIVED',
      label: t('projects.current'),
    },
    archived: {
      name: 'archived',
      match: (el: any) => el.status === 'ARCHIVED',
      label: t('projects.archived'),
    },
    all: { name: 'all', label: t('projects.all'), match: (el: any) => true },
  };
};

export const searchInColumns = ['name', 'date'];
