/* eslint-disable no-control-regex */
const illegalRe = /[/?<>\\:*|":.]/g;
const controlRe = /[\x00-\x1f\x80-\x9f]/g;
const reservedRe = /^\.+$/;
const spaceRe = / |&nbsp;/g;
const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;

export const sanitize = (input, replacement) => {
  const sanitized = input
    .replace(illegalRe, replacement)
    .replace(controlRe, replacement)
    .replace(reservedRe, replacement)
    .replace(windowsReservedRe, replacement)
    .replace(spaceRe, '_');
  return sanitized.split('').splice(0, 255).join('');
};
