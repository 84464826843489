import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddButton } from '../../../components/common/Buttons/AddButton';
import { Loader } from '../../../components/common/Loader';
import { UsersModal } from '../../../components/common/Modals/Users/UsersModal/UsersModal.component';
import { SearchField } from '../../../components/common/SearchField/SearchField';
import { MaterialSwitch } from '../../../components/common/Switch/MaterialStyledSwitch.component';
import { Table } from '../../../components/common/Table/Table.component';
import { Tooltip } from '../../../components/common/Tooltip/Tooltip.component';
import { partnerThunks } from '../../../containers/Partner/Partner.thunk';
import { useEffectAsync } from '../../../utils/useEffectAsync';
import { userThunks } from '../User.thunk';
import { ActionColumn } from './ActionColumn/ActionColumn.component';
import {
  HeadContainer,
  InnerTooltipText,
  SearchContainer,
  StyledEmployeeTable,
  StyledStatusBar,
} from './UsersTable.styles';

const modalTypes = {
  deactivatingModal: 'deactivating',
  editingModal: 'editing',
  addingModal: 'adding',
  addingPartner: 'addingPartner',
};

export const UsersTable = () => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.user.filters);
  const [isLoading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [modalFor, setModalFor] = useState('');
  const [filteredValue, setFilteredValue] = useState(filters.search ?? '');
  const [userToEdit, setUserToEdit] = useState('');
  const users = useSelector((state) => state.user.users);
  const user = useSelector((state) => state.user.user);

  const partnerNames = useSelector((state) =>
    state.partner.all_partners.map((partner) => {
      return partner.partner_name;
    }),
  );

  const editUser = async (newUserData, userId = userToEdit.id) => {
    const result = await dispatch(
      userThunks.updateUserThunk({
        userId,
        payload: newUserData,
      }),
    );
    if (result.type === userThunks.updateUserThunk.fulfilled.type) {
      await dispatch(userThunks.getUsersThunk());
      setOpenModal(false);
    }
  };

  const deactivateUser = async (userId) => {
    const result = await dispatch(
      userThunks.deactivateUser({
        userId,
      }),
    );
    if (result.type === userThunks.deactivateUser.fulfilled.type) {
      await dispatch(userThunks.getUsersThunk());
    }
  };

  const columns = [
    {
      field: 'username',
      headerName: 'Username',
      flex: 1,
      minWidth: 150,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ row: { username } }) => (
        <Tooltip title={username} position="table">
          <InnerTooltipText>{username}</InnerTooltipText>
        </Tooltip>
      ),
    },
    {
      field: 'email',
      flex: 1,
      minWidth: 150,
      headerName: 'Email',
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ row: { email } }) => (
        <Tooltip title={email} position="table">
          <InnerTooltipText>{email}</InnerTooltipText>
        </Tooltip>
      ),
    },
    {
      field: 'department',
      headerName: 'Department',
      flex: 1,
      minWidth: 150,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ row: { department } }) => (
        <Tooltip title={department} position="table">
          <InnerTooltipText>{department}</InnerTooltipText>
        </Tooltip>
      ),
    },
    {
      field: 'isActive',
      headerName: 'Is Active',
      flex: 0.3,
      minWidth: 80,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ value, row }) => {
        return (
          <MaterialSwitch
            disabled={user.id === row.action.id}
            onChange={() =>
              value
                ? deactivateUser(row.action.id)
                : editUser({ is_active: true }, row.action.id)
            }
            checked={value}
            sx={{ opacity: user.id === row.action.id ? 0.4 : 1 }}
          />
        );
      },
      sortComparator: (v1, v2) => v2.active - v1.active,
    },
    {
      field: 'isSuperuser',
      headerName: 'Is Superuser',
      flex: 0.3,
      align: 'center',
      headerAlign: 'left',
      type: 'boolean',
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      sortable: false,
      renderCell: ({ value }) => {
        return (
          <ActionColumn
            onEditUserHandler={() => {
              setModalFor(modalTypes.editingModal);
              setUserToEdit(value);
              setOpenModal(true);
            }}
          />
        );
      },
    },
  ];

  const createRow = (userData, idx) => {
    return {
      id: idx,
      username: userData.username,
      email: userData.email,
      isActive: userData.is_active,
      isSuperuser: userData.is_admin,
      department: userData.department,
      action: userData,
    };
  };

  useEffect(async () => {
    await dispatch(partnerThunks.getAllPartnersThunk());
    dispatch(
      userThunks.saveStateThunk({
        search: filteredValue,
      }),
    );
  }, [filteredValue]);

  useEffectAsync(async () => {
    await dispatch(userThunks.getUsersThunk());
    setLoading(false);
  }, []);

  const handleAddingUser = () => {
    setModalFor(modalTypes.addingModal);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const filterUsers = (usersParam) =>
    usersParam.filter((userP) =>
      userP.username.toLowerCase().includes(filteredValue.toLowerCase()),
    );

  const superAdmins =
    users &&
    filterUsers(users)
      .filter((userL) => userL.is_admin)
      .sort((a, b) => b.is_active - a.is_active);

  const sortedNormalUsers =
    users &&
    filterUsers(users)
      .filter((userS) => !userS.is_admin)
      .sort((a, b) => b.is_active - a.is_active);

  const rows =
    users &&
    [...superAdmins, ...sortedNormalUsers].map((p, i) => createRow(p, i));

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <StyledStatusBar>
        <SearchContainer>
          <SearchField
            initialValue={filteredValue}
            onSubmit={setFilteredValue}
          />
        </SearchContainer>
        <AddButton action={handleAddingUser}>Add new user</AddButton>
      </StyledStatusBar>
      <StyledEmployeeTable>
        <HeadContainer>
          <UsersModal
            modalFor={modalFor}
            openModal={openModal}
            closeModal={handleCloseModal}
            partnerNames={partnerNames}
            userToEdit={userToEdit}
            editUser={editUser}
          />
        </HeadContainer>
        <Table columns={columns} rows={rows} checkboxSelection={false} />
      </StyledEmployeeTable>
    </>
  );
};
