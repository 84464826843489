import { Chip, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from '../../../../theme/colors';

type StyledChipProps = {
  customcolor?: string;
  custombackground?: string;
};

export const StyledChip = styled(Chip)<StyledChipProps>(
  ({ customcolor, custombackground }) => ({
    color: customcolor || COLORS.accent.primary,
    border: `1px solid ${COLORS.info.border}`,
    background: custombackground || COLORS.info.main,
    height: '18px',
    fontSize: '12px',
    fontFamily: '"Poppins", sans-serif',
    textTransform: 'capitalize',
    padding: '10px 4px',
    overflow: 'hidden',
    '.MuiChip-label': {
      padding: '2px 7px !important',
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 500,
    },
    '.MuiChip-deleteIcon': {
      marginLeft: -4,
    },
  }),
);

export const StyledIconButton = styled(IconButton)({
  padding: 0,
  margin: 0,
});
