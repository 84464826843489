import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { COLORS } from '../../../theme/colors';

export const MainContainer = styled.footer`
  align-items: center;
  background-color: ${COLORS.background.footer};
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 500px;
  justify-content: center;
`;

export const StyledEmail = styled.a`
  color: ${COLORS.accent.primary};
  font-size: 64px;
  font-weight: 300;
  line-height: 64px;

  &:hover {
    color: ${COLORS.typography.body};
    text-decoration: none;
  }
`;

export const SocialMediaContainer = styled.section`
  align-items: center;
  display: flex;
  gap: 40px;
`;

export const StyledIcon = styled.a`
  height: 27px;
  transition-duration: 0.2s;
  svg {
    fill: ${COLORS.accent.primary};
  }
  &:hover {
    svg {
      fill: ${COLORS.typography.body};
    }
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 48px;
`;

export const StyledLink = styled(NavLink)`
  color: ${COLORS.typography.secondary};
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  text-decoration: underline;

  &:hover {
    color: ${COLORS.typography.body};
  }
`;
