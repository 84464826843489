import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import parse from 'html-react-parser';
import React from 'react';
import {
  CloseContainer,
  GlobalStyle,
  StyledContainer,
  StyledFormWrapper,
} from '../LeadDetail.styles';
import {
  StyledDescription,
  StyledFormTitle,
} from './ShowDescriptionModalContent.styles';
import { ShowDescriptionModalContentProps } from './ShowDescriptionModalContent.types';

export const ShowDescriptionModalContent = React.forwardRef<
  HTMLDivElement,
  ShowDescriptionModalContentProps
>(({ descriptionToShow, closeModal }, ref) => {
  const htmlDescription = descriptionToShow.description.replace(
    /(\r\n|\n|\r)/gm,
    '<br>',
  );
  return (
    <>
      <GlobalStyle />
      <StyledContainer ref={ref} tabIndex={-1}>
        <CloseContainer>
          <IconButton onClick={closeModal}>
            <CloseIcon htmlColor="black" fontSize="small" />
          </IconButton>
        </CloseContainer>
        <StyledFormWrapper>
          <StyledFormTitle>{descriptionToShow.name}</StyledFormTitle>
          <StyledDescription>{parse(htmlDescription)}</StyledDescription>
        </StyledFormWrapper>
      </StyledContainer>
    </>
  );
});
