import styled from 'styled-components';

export const StyledNumber = styled.span`
  background: ${({ color }) => color};
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: auto;
  margin-top: auto;
  padding: 2px 7px;
`;
