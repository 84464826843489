export const MyProfilePaths = {
  myVacancies: '',
  myCandidates: 'my-candidates',
  myProjects: 'my-projects',
  watched: 'watched',
  ratings: 'ratings',
  companyProfile: 'company-profile',
  accountsSettings: 'accounts-settings',
  payments: 'payments',
  generalPayments: 'general-payments',
  partnerCompanies: 'partner-companies',
};
