import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { PriorityIcon } from '../../Icons/PriorityIcon';
import { usePriorityOptionsConfig } from './config';
import { StyledChip } from './PriorityChip.styles';
import { PriorityChipProps } from './PriorityChip.types';

export const PriorityChip = ({
  clickable,
  priority,
  handleClick,
  option,
  isClicked,
}: PriorityChipProps) => {
  const { priorityOptions } = usePriorityOptionsConfig();
  const [clicked, setClicked] = useState<boolean>(false);

  useEffect(() => {
    setClicked(isClicked);
  }, [isClicked]);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#F4F5F7',
      },
      secondary: {
        main: '#CDCEDA',
      },
    },
  });

  const label =
    priorityOptions.find((opt) => opt.priority === priority)?.label ||
    'unknown';

  return (
    <ThemeProvider theme={theme}>
      <StyledChip
        icon={<PriorityIcon priority={priority} />}
        label={label}
        clickable={clickable}
        onClick={() => {
          if (clickable) {
            handleClick(option);
            setClicked((prevClicked) => !prevClicked);
          }
        }}
        color={!clicked ? 'primary' : 'secondary'}
      />
    </ThemeProvider>
  );
};
