export const isTimePeriodValid = (value: string, isPresentDate: boolean) => {
  const numbers = value.match(/\d+/g)?.map((elem) => Number(elem));
  if (numbers) {
    const [startMonth, startYear, endMonth, endYear] = numbers;
    const currMonth = new Date().getMonth();
    const currYear = new Date().getFullYear();
    if (isPresentDate) {
      if (startYear < 1950) {
        return false;
      }
      return (
        startYear < currYear ||
        (startYear === currYear && startMonth < currMonth)
      );
    }
    if (startYear < 1950 || endYear < 1950) {
      return false;
    }
    return (
      startYear < endYear || (startYear === endYear && startMonth <= endMonth)
    );
  }
  return false;
};
