import styled from 'styled-components';

export const QuoteWrapper = styled.div`
  margin: 350px auto 0px auto;
  min-height: 40%;
  width: 74%;

  @media screen and (max-width: 780px) {
    margin: 170px auto 0px auto;
    max-height: 300px;
  }
`;

export const QuoteTop = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 18px;
  width: 100%;
`;

export const StyledQuoteSign = styled.img`
  margin-right: 26px;
  max-height: 25px;
`;

export const Line = styled.div`
  border: 0.5px solid #3c4674;
  width: 100%;

  @media screen and (max-width: 780px) {
    border: 0.5px solid white;
  }
`;

export const QuoteBottom = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
`;

export const BottomLine = styled.svg`
  height: 35px;
  stroke-width: 1px;
  width: 100%;
`;

export const StyledQuoteText = styled.span`
  color: #e4e9ef;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;

  @media screen and (max-width: 780px) {
    font-weight: 400;
    font-size: 18px;
    color: #101840;
  }
`;

export const OwnerWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 15px;
  width: 100%;
`;

export const OwnerPhoto = styled.img`
  border-radius: 200px;
  margin-right: 26px;
  max-height: 45px;
`;

export const OwnerData = styled.div`
  display: flex;
  flex-direction: column;
  margin: 28px 0;
  width: 100%;
`;

export const OwnerFullName = styled.span`
  color: #efefef;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;

  @media screen and (max-width: 780px) {
    color: #101840;
  }
`;

export const OwnerTitle = styled.span`
  color: #efefef;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  width: 90%;

  @media screen and (max-width: 780px) {
    color: #101840;
  } ;
`;
