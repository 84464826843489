import { Switch } from '@mui/material';
import { styled } from '@mui/system';

export const MaterialStyledSwitch = styled(Switch)`
  & .MuiSwitch-track {
    background-color: #d3d4d9;
    opacity: 1;
  }
  & .MuiSwitch-switchBase {
    & .MuiSwitch-thumb {
      background-color: #efefef;
    }
    &.Mui-checked .MuiSwitch-thumb {
      background-color: #00bfbf;
    }
    & + .MuiSwitch-track {
      background-color: #eeeff1;
    }

    &.Mui-checked + .MuiSwitch-track {
      background-color: #d3d4d9;
      opacity: 1;
    }
  }
`;
