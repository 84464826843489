import { createSlice } from '@reduxjs/toolkit';
import { partnerThunks } from './Partner.thunk';

const { getPartnerThunk, getAllPartnersThunk, saveStateThunk } = partnerThunks;

const initialState = {
  partner: {
    name: '',
    address: '',
    phone: '',
    logo: [],
    color: '',
    partners_employees: [],
  },
  all_partners: [],
  filters: JSON.parse(localStorage.getItem('userFilters')) ?? {
    search: '',
  },
};

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {},
  extraReducers: {
    [saveStateThunk.fulfilled]: (state, action) => {
      const data = action.payload;
      state.filters.search = data.search;
    },
    [getPartnerThunk.fulfilled]: (state, action) => {
      state.partner = action.payload;
    },
    [getAllPartnersThunk.fulfilled]: (state, action) => {
      state.all_partners = action.payload;
    },
  },
});

export const actions = { ...partnerSlice.actions };
export const { reducer } = partnerSlice;
