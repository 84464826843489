import { useDispatch } from 'react-redux';
import { leadProfileCandidatesThunks } from '../../../../../../containers/Leads/redux/LeadProfileCandidates.thunk';
import { leadProfilesThunks } from '../../../../../../containers/Leads/redux/LeadProfiles.thunk';
import { vacanciesPageThunks } from '../../../../../../containers/Vacancies/Vacancies.thunk';
import { AppDispatch } from '../../../../../../store/store';
import {
  Candidate,
  EmployeeIdType,
} from '../../../../../../store/types/Store.candidates.types';
import {
  HomeLeadProfile,
  LeadIdType,
  LeadProfileIdType,
  SuggestedCandidate,
} from '../../../../../../store/types/Store.home.types';

export const useHrNotesHandler = (
  leadId?: LeadIdType,
  profileId?: LeadProfileIdType,
  candidateId?: number,
  employeeId?: EmployeeIdType,
  homeLeadProfile?: HomeLeadProfile,
  candidateInfo?: SuggestedCandidate | Candidate,
) => {
  const dispatch = useDispatch<AppDispatch>();

  const updateCandidateHrNotes = async (
    candidate: SuggestedCandidate | Candidate,
    hrNotes: string,
  ) => {
    if (leadId && profileId) {
      await dispatch(
        leadProfileCandidatesThunks.updateCandidateNotesThunk({
          lead_id: leadId,
          candidate_id: candidateId || Number(candidate.id),
          lead_profile_id: profileId,
          notes: {
            employee: employeeId || candidate.employee_data?.id,
            hr_notes: hrNotes,
          },
        }),
      );
      homeLeadProfile
        ? dispatch(vacanciesPageThunks.getHomePageDataThunk())
        : dispatch(
            leadProfilesThunks.getLeadRequestsThunk({
              leadId,
            }),
          );

      dispatch(
        leadProfileCandidatesThunks.getLeadRequestCandidatesThunk({
          leadId,
          requestId: Number(profileId),
        }),
      );
    }
  };

  const handleHrSaving = (notes: string) => {
    if (notes !== candidateInfo?.hr_notes && candidateInfo && notes) {
      updateCandidateHrNotes(candidateInfo, notes);
    }
  };

  return {
    handleHrSaving,
  };
};
