export const STRING_LIMIT = 24;

export const getTopFilterOptions = (t: any) => {
  return {
    current: {
      name: 'current',
      match: (el: { is_active: boolean }) => el.is_active,
      label: t('current'),
    },
    inactive: {
      name: 'inactive',
      match: (el: { is_active: boolean }) => !el.is_active,
      label: t('inactive'),
    },
    all: { name: 'all', label: t('all'), match: () => true },
  };
};

export const searchInColumns = [
  'username',
  'email',
  'dateJoined',
  'isCompanyAdministrator',
];

export const modalTypes = {
  deactivatingModal: 'deactivating',
  editingModal: 'editing',
  addingModal: 'adding',
};
