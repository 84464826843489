import { createSlice } from '@reduxjs/toolkit';
import { Lead } from '../../../store/types/Store.leads.types';
import { leadDetailsThunks } from './LeadDetails.thunk';

const { getLeadThunk } = leadDetailsThunks;

const initialState: Lead[] = [];

const leadDetailsSlice = createSlice({
  name: 'leadDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLeadThunk.fulfilled, (state, action) => {
      const data = action.payload;
      const foundLead = state.find((elem) => elem.id === data.id);
      if (foundLead) {
        const index = state.indexOf(foundLead);
        state[index] = {
          id: data.id,
          name: data.name,
          sales_owner: data.sales_owner,
          industry: data.industry,
          website: data.website,
          updated_at: data.updated_at,
          created_at: data.created_at,
          status: data.status,
          profiles: data.profiles,
          candidate_statuses: data.candidate_statuses,
          details_fetched: data.details_fetched,
          requests_num: data.requests_num,
          status_history: data.status_history,
        };
      } else {
        state.push(data);
      }
    });
  },
});

export const actions = { ...leadDetailsSlice.actions };
export const { reducer } = leadDetailsSlice;
