import { Skeleton } from '@mui/material';
import {
  FilesListItem,
  FilesListItemTitle,
  FilesListRemoveButton,
  StyledContainer,
} from './FilesList.styles';
import { FilesListProps } from './FilesList.types';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { COLORS } from '../../../theme/colors';
import { addDotsToString } from '../../../Helpers';

const FILE_NAME_LIMIT = 27;

export const FilesList = ({
  files,
  handleFileRemoval,
  showFileLoader,
}: FilesListProps) => {
  return (
    <StyledContainer>
      {files &&
        files.map(({ file_name, file }, index) => (
          <FilesListItem key={index}>
            <a
              href={file}
              target="_blank"
              download={file_name}
              rel="noreferrer"
            >
              <FilesListItemTitle>
                {addDotsToString(file_name, FILE_NAME_LIMIT)}
              </FilesListItemTitle>
            </a>
            <FilesListRemoveButton onClick={() => handleFileRemoval(index)}>
              <DeleteIcon
                width={20}
                height={20}
                stroke={COLORS.typography.secondary}
              />
            </FilesListRemoveButton>
          </FilesListItem>
        ))}
      {showFileLoader && <Skeleton variant="rounded" width={257} height={50} />}
    </StyledContainer>
  );
};
