import { CreatedRowHome } from '../../../../containers/Vacancies/VacanciesPageHrSales/CreateRow/CreateRow.types';
import { OrderBy, PriorityKeyType, SortOrderKey } from '../CollapseTable.types';

export const isTimeStamp = (val: string) => new Date(val).getTime() > 0;

const sorterErrorHandler = (key: OrderBy) =>
  console.error(`Wrong ${key} fromat`);

const priorityDict: Record<PriorityKeyType, number> = {
  'very high': 4,
  high: 3,
  medium: 2,
  low: 1,
};

export const getHomeComparator = (
  order: SortOrderKey,
  orderBy: keyof CreatedRowHome,
) => {
  switch (orderBy) {
    case 'date':
      if (order === 'asc') {
        return (a: CreatedRowHome, b: CreatedRowHome) => {
          if (
            !isTimeStamp(a[orderBy] as string) ||
            !isTimeStamp(b[orderBy] as string)
          ) {
            sorterErrorHandler(orderBy);
            return 0;
          }
          return (
            new Date(a[orderBy]).valueOf() - new Date(b[orderBy]).valueOf()
          );
        };
      }
      return (a: CreatedRowHome, b: CreatedRowHome) => {
        if (
          !isTimeStamp(a[orderBy] as string) ||
          !isTimeStamp(b[orderBy] as string)
        ) {
          sorterErrorHandler(orderBy);
          return 0;
        }
        return new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf();
      };

    case 'candidatesNum':
      if (order === 'asc') {
        return (a: CreatedRowHome, b: CreatedRowHome) => {
          if (Number.isNaN((a[orderBy] as number) - (b[orderBy] as number))) {
            sorterErrorHandler(orderBy);
            return 0;
          }

          if (a[orderBy] - b[orderBy] === 0) {
            return new Date(b.date).valueOf() - new Date(a.date).valueOf();
          }

          return (a[orderBy] as number) - (b[orderBy] as number);
        };
      }
      return (a: CreatedRowHome, b: CreatedRowHome) => {
        if (Number.isNaN(a[orderBy] - b[orderBy])) {
          sorterErrorHandler(orderBy);
          return 0;
        }

        if (b[orderBy] - a[orderBy] === 0) {
          return new Date(b.date).valueOf() - new Date(a.date).valueOf();
        }

        return b[orderBy] - a[orderBy];
      };
    case 'priority':
      if (order === 'asc') {
        return (a: CreatedRowHome, b: CreatedRowHome) => {
          if (!priorityDict[a[orderBy]] || !priorityDict[b[orderBy]]) {
            sorterErrorHandler(orderBy);
            return 0;
          }

          if (priorityDict[b[orderBy]] - priorityDict[a[orderBy]] === 0) {
            return new Date(b.date).valueOf() - new Date(a.date).valueOf();
          }

          return priorityDict[a[orderBy]] - priorityDict[b[orderBy]];
        };
      }
      return (a: CreatedRowHome, b: CreatedRowHome) => {
        if (!priorityDict[a[orderBy]] || !priorityDict[b[orderBy]]) {
          sorterErrorHandler(orderBy);
          return 0;
        }

        if (priorityDict[b[orderBy]] - priorityDict[a[orderBy]] === 0) {
          return new Date(b.date).valueOf() - new Date(a.date).valueOf();
        }

        return priorityDict[b[orderBy]] - priorityDict[a[orderBy]];
      };
    default:
      if (order === 'asc') {
        return (a: CreatedRowHome, b: CreatedRowHome) => {
          if (
            typeof a[orderBy] !== 'string' ||
            typeof b[orderBy] !== 'string'
          ) {
            sorterErrorHandler(orderBy);
            return 0;
          }

          return (b[orderBy] as string).localeCompare(a[orderBy] as string);
        };
      }
      return (a: CreatedRowHome, b: CreatedRowHome) => {
        if (typeof a[orderBy] !== 'string' || typeof b[orderBy] !== 'string') {
          sorterErrorHandler(orderBy);
          return 0;
        }

        return (a[orderBy] as string).localeCompare(b[orderBy] as string);
      };
  }
};
