import styled from 'styled-components';
import { COLORS } from '../../../../../../theme/colors';

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  svg {
    color: ${COLORS.accent.primary};
  }
`;
