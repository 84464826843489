import styled from 'styled-components';

export const IconWrapper = styled.div`
  background-color: #8f9dac;
  border-radius: 300px;
  display: flex;
  height: 14px;
  justify-content: space-around;
  width: 14px;
`;

export const Mark = styled.p`
  color: #ffffff;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 10px;
  padding: 0;
`;
