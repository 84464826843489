import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../store/store';
import { employeeThunks } from '../../../../containers/Employee/Employees.thunk';
import { ArchiveModalProps } from './types';
import { StyledArchiveModalContainer, StyledArchiveModalTitle } from './styles';
import { DialogButton } from '../../Buttons/DialogButton/DialogButton.component';

export const DeleteModalContent = ({
  onClose,
  employeeId,
}: ArchiveModalProps) => {
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.candidates',
  });
  const dispatch = useDispatch();
  const employeeFetched = useSelector((state: RootState) =>
    state.employees.people.results.find((empl) => empl.id === employeeId),
  );

  const handleDeleteConfirm = async () => {
    dispatch(
      employeeThunks.deleteEmployeeThunk({
        employeeId,
        fullName,
      }) as unknown as AnyAction,
    );

    // not awaiting on purpose. We want an immediate reaction
    // of the UI when the request is successfull
    dispatch(employeeThunks.getMyEmployeesThunk() as unknown as AnyAction);
    onClose();
  };

  const fullName = `${employeeFetched?.first_name} ${employeeFetched?.last_name}`;

  return (
    <StyledArchiveModalContainer>
      <StyledArchiveModalTitle>
        {t('confirm_delete', { name: fullName })}
      </StyledArchiveModalTitle>
      <DialogButton action={handleDeleteConfirm}>
        {t('confirm', { ns: 'common', keyPrefix: 'buttons' })}
      </DialogButton>
    </StyledArchiveModalContainer>
  );
};
