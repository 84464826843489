import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { DialogButton } from '../../Buttons/DialogButton/DialogButton.component';
import {
  CloseContainer,
  GlobalStyle,
  StyledContainer,
  StyledFormTitle,
  StyledFormWrapper,
  StyledLabelWrapper,
  StyledMessage,
} from './DeleteModal.styles';
import { DeleteModalProps } from './DeleteModal.types';

export const DeleteModal = React.forwardRef<HTMLDivElement, DeleteModalProps>(
  ({ dialogTitle, buttonsConfig, handleClose, message, content }, ref) => (
    <>
      <GlobalStyle />
      <StyledContainer ref={ref} tabIndex={-1}>
        <StyledFormWrapper>
          <StyledLabelWrapper>
            <StyledFormTitle>{dialogTitle}</StyledFormTitle>
            <CloseContainer>
              <IconButton onClick={handleClose}>
                <CloseIcon htmlColor="black" fontSize="small" />
              </IconButton>
            </CloseContainer>
          </StyledLabelWrapper>
          <StyledMessage>{message}</StyledMessage>
          <StyledMessage>{content}</StyledMessage>
          <DialogButton
            color={buttonsConfig.primaryButton.color}
            action={buttonsConfig.primaryButton.action}
          >
            {buttonsConfig.primaryButton.label}
          </DialogButton>
          {buttonsConfig.secondaryButton && (
            <DialogButton
              color={buttonsConfig.secondaryButton.color}
              action={buttonsConfig.secondaryButton.action}
            >
              {buttonsConfig.secondaryButton.label}
            </DialogButton>
          )}
        </StyledFormWrapper>
      </StyledContainer>
    </>
  ),
);
