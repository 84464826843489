import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { LeadDetailContext } from '../../../../../containers/Leads/LeadDetail/LeadDetailContext/LeadDetailContext';
import { VacanciesPageContext } from '../../../../../containers/Vacancies/VacanciesPageHrSales/VacanciesPageContext/VacanciesPageContext';
import { SuggestedCandidate } from '../../../../../store/types/Store.home.types';
import { useChangeStatus } from '../../../../Vacancies/Candidates/CandidateBar/useChangeStatus/useChangeStatus';
import { modalSizes, wideModals } from '../../Modal.config';
import { StyledModal } from '../../Modal.styles';
import { AddingSuggestedCandidatesModalContent } from '../AddingSuggestedCandidatesModalContent';
import { CandidateDetailsModalContent } from '../CandidateDetailsModalContent';
import { ReasonChangeStatusModalContent } from '../ReasonChangeStatusModalContent/ReasonChangeStatusModalConent';
import { ShowDescriptionModalContent } from '../ShowDescriptionModalContent/ShowDescriptionModalContent.component';
import { SuggestedCandidateDeleteModalContent } from '../SuggestedCandidateDeleteModalContent';
import { LeadProfileDeleteModalContent } from '../LeadProfileDeleteModalContent';
import { StyledModalWrapper } from './LeadDetailModal.styles';
import { LeadDetailModalProps } from './LeadDetailModal.types';

export const LeadDetailModal = React.forwardRef<
  HTMLDivElement,
  LeadDetailModalProps
>(
  (
    {
      modalFor,
      openModal,
      closeModal,
      oldStatus,
      newStatus,
      reasonTags,
      descriptionToShow,
      name,
      candidateInfo,
      homeLeadProfile,
      candidateId,
      employeeId,
      candidateStatus,
      suggestedCandidatesIds,
      leadId,
      profileId,
    },
    ref,
  ) => {
    const modalWidth: string = wideModals.includes(modalFor)
      ? modalSizes.wide
      : modalSizes.narrow;

    const { id } = useParams<string>();

    const { changeStatus } = useChangeStatus(
      homeLeadProfile?.lead_id || Number(id),
      homeLeadProfile?.lead_profile_id || Number(profileId),
      candidateInfo as SuggestedCandidate,
    );

    const { deleteSuggestedCandidate } = useContext(VacanciesPageContext);
    const { handleDeleteLeadProfile } = useContext(VacanciesPageContext);
    const { deleteSuggestedCandidateFromLeadDetail } =
      useContext(LeadDetailContext);

    const modalsOptions = () => {
      switch (modalFor) {
        case 'reasonChangeStatus':
          return (
            <ReasonChangeStatusModalContent
              {...{
                closeModal,
                oldStatus,
                newStatus,
                reasonTags,
                changeStatus,
              }}
            />
          );
        case 'showDescription':
          return (
            <ShowDescriptionModalContent
              closeModal={closeModal}
              descriptionToShow={descriptionToShow!}
            />
          );
        case 'deleteSuggestedCandidate':
          return (
            <SuggestedCandidateDeleteModalContent
              closeModal={closeModal}
              name={name}
              handleDelete={
                deleteSuggestedCandidate ||
                deleteSuggestedCandidateFromLeadDetail
              }
            />
          );
        case 'deleteLeadProfile':
          return (
            <LeadProfileDeleteModalContent
              closeModal={closeModal}
              handleDelete={() => handleDeleteLeadProfile(profileId, leadId)}
            />
          );
        case 'addSuggestedCandidates':
          return (
            <AddingSuggestedCandidatesModalContent
              closeModal={closeModal}
              leadId={leadId}
              leadProfileId={Number(profileId)}
              suggestedCandidatesIds={suggestedCandidatesIds}
            />
          );
        case 'candidateDetails':
          return (
            candidateStatus && (
              <CandidateDetailsModalContent
                closeModal={closeModal}
                candidateInfo={candidateInfo}
                homeLeadProfile={homeLeadProfile}
                candidateId={candidateId}
                employeeId={employeeId}
                candidateStatus={candidateStatus}
              />
            )
          );

        default:
          return <></>;
      }
    };

    return (
      <StyledModal
        ref={ref}
        open={openModal}
        onClose={closeModal}
        aria-labelledby="lead-detail-modal-title"
        aria-describedby="lead-detail-modal-description"
        width={modalWidth}
      >
        <StyledModalWrapper>{modalsOptions()}</StyledModalWrapper>
      </StyledModal>
    );
  },
);
