import styled from 'styled-components';

export const StyledHeader = styled.div`
  align-items: center;
  border-bottom: 2px solid #f4f4f4;
  display: flex;
  justify-content: space-between;
  margin: 0px 80px 0px auto;
  min-height: 140px;
`;

export const StyledCompanyLogo = styled.div`
  height: 50px;
  width: auto;
  &:before {
    border-bottom: 5px solid ${({ color }) => color};
    content: '';
    display: block;
    margin-top: 91px;
    position: absolute;
    width: 126px;
  }
`;

export const StyledCompanyMotto = styled.div`
  float: left;
  height: 64px;
  width: 222px;
`;

export const StyledNavImg = styled.img`
  height: 100%;
  width: auto;
`;

export const StyledPartnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  float: left;
`;

export const StyledPartnerName = styled.span`
  font-size: 20px;
  font-weight: 600;
`;

export const StyledAddress = styled.span`
  font-size: 16px;
`;

export const StyledPhoneNumber = styled.span`
  font-size: 16px;
`;
