import { CreatedRowLead } from '../ProjectsTable.types';

export const activeFilter = (row: CreatedRowLead) => row.status === 'ACTIVE';

export const archivedFilter = (row: CreatedRowLead) =>
  row.status === 'ARCHIVED';

export const searchFilter = (row: CreatedRowLead, filteredValue: string) =>
  row.name.toLowerCase().includes(filteredValue.toLowerCase()) ? true : false;

export const ownerFilter = (
  row: CreatedRowLead,
  filteredSupervisorValue: string,
) =>
  filteredSupervisorValue &&
  row.salesOwner?.toLowerCase().includes(filteredSupervisorValue.toLowerCase())
    ? true
    : false;
