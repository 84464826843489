import ClearIcon from '@mui/icons-material/Clear';
import { AutocompleteProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FormLabel } from '../FormLabel/FormLabel';
import {
  StyledInputContainer,
  StyledSpan,
  StyledAutoComplete,
  InputIcon,
} from './AutocompleteInput.styles';
import { AutocompleteInputProps } from './AutocompleteInput.types';
import { AutocompleteLoader } from './AutocompleteLoader';

export const AutocompleteInput = <K, T extends object>({
  label,
  error,
  name,
  options,
  value,
  handleInputChange,
  handleCustomOption,
  handleKeyDown,
  required,
  freeSolo,
  disabled = false,
  disableCloseOnSelect,
  disableClearable,
  displayAsRequired,
  customStyles,
  inputValue,
  placeholder,
  leftIcon,
  isOptionEqualToValue,
  renderOption,
  filterOptions,
  ...props
}: AutocompleteInputProps<
  AutocompleteProps<T, boolean, undefined, boolean, 'div'>,
  K
>) => {
  const isFieldFilled =
    (inputValue?.length ? inputValue?.length > 0 : false) ||
    ((value as string)?.length ? (value as string)?.length > 0 : false);

  return (
    <StyledInputContainer style={customStyles}>
      {label && (
        <FormLabel
          htmlFor={name}
          label={error ? `${label}: ${error}` : label}
          disabled={disabled}
          required={displayAsRequired}
          error={!!error}
        />
      )}
      {leftIcon && <InputIcon>{leftIcon}</InputIcon>}
      <StyledAutoComplete
        id={name}
        value={value}
        withLeftIcon={!!leftIcon}
        inputValue={inputValue}
        disablePortal
        options={options}
        isOptionEqualToValue={isOptionEqualToValue}
        renderOption={
          renderOption
            ? renderOption
            : (prop, option) => (
                <StyledSpan {...prop} key={prop.accessKey} id={name}>
                  {option}
                </StyledSpan>
              )
        }
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleCustomOption}
            name={name}
            placeholder={placeholder || '...'}
            required
          />
        )}
        onKeyDown={handleKeyDown}
        filterOptions={filterOptions}
        renderTags={() => <></>}
        {...props}
        onChange={props.onChange ? props.onChange : handleInputChange}
        freeSolo={freeSolo}
        disabled={disabled}
        disableCloseOnSelect={disableCloseOnSelect}
        noOptionsText={<AutocompleteLoader />}
        disableClearable={disableClearable || !isFieldFilled}
        clearIcon={<ClearIcon fontSize="small" id={name} />}
      />
    </StyledInputContainer>
  );
};
