import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: 'Neue Haas Grotesk Display Pro';
  }

  body {
    background: white;
  }
`;

export const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 34px 10px 34px;
  position: sticky;
  top: 0px;
  z-index: 1;
  & .MuiButtonBase-root {
    padding: 0px;
  }
`;

export const StyledFormWrapper = styled.div`
  background: white;
  border-radius: 10px;
  max-height: 450px;
  overflow-y: auto;
  padding: 0px 16px 30px 16px;
  width: 100%;
`;

export const StyledContainer = styled.div`
  align-items: center;
  background: white;
  border-radius: 10px;
  margin: 0px auto;
  padding-bottom: 10px;
  padding-top: 0px;
`;
