import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';
import { pastePlainText } from '../../../../Helpers';
import {
  StyledDeleteButton,
  StyledIcon,
  StyledTechnologiesEntry,
  StyledTextDiv,
} from './TechnologyEntry.styles';

export const TechnologyEntry = forwardRef(
  (
    {
      draggable,
      handleDrag,
      handleDrop,
      handleDragOver,
      editable,
      setTextFormattingPanelVisibility,
      data,
      handleEditData,
      handleRemoveEntry,
    },
    ref,
  ) => {
    const handleBlur = (e) => {
      if (!editable) {
        return;
      }

      setTextFormattingPanelVisibility(false);
      handleEditData({ technologyName: e.target.innerHTML });
    };

    return (
      <StyledTechnologiesEntry
        ref={ref}
        draggable={draggable}
        onDragStart={handleDrag}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'space-between',
          }}
        >
          <StyledTextDiv
            placeholder="Technology"
            suppressContentEditableWarning
            contentEditable={editable}
            onFocus={() => {
              setTextFormattingPanelVisibility(true);
            }}
            onPaste={(e) => pastePlainText(e)}
            onBlur={handleBlur}
            spellCheck={false}
            dangerouslySetInnerHTML={{
              __html: data.technologyName,
            }}
          />
          {editable ? (
            <StyledDeleteButton>
              <StyledIcon onClick={() => handleRemoveEntry()}>
                <FontAwesomeIcon icon={faMinusCircle} />
              </StyledIcon>
            </StyledDeleteButton>
          ) : undefined}
        </div>
      </StyledTechnologiesEntry>
    );
  },
);
