import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { statusesWithReason } from '../../../../../containers/Leads/Leads.config';
import { leadProfileCandidateHistoryThunks } from '../../../../../containers/Leads/redux/LeadProfileCandidateHistory.thunk';
import { isPartner } from '../../../../../Helpers';
import { AppDispatch } from '../../../../../store/store';
import {
  CandidateStatus,
  Lead,
  LeadStatusHistoryType,
} from '../../../../../store/types/Store.leads.types';
import { AppStore } from '../../../../../store/types/Store.types';
import { UserState } from '../../../../../store/types/Store.user.types';
import { useEffectAsync } from '../../../../../utils/useEffectAsync';
import { Loader } from '../../../Loader';
import { StyledContainer, StyledFormWrapper } from '../LeadDetail.styles';
import {
  BottomContainer,
  StyledFormTitle,
} from './CandidateDetailsModalContent.styles';
import { CandidateDetailsModalContentProps } from './CandidateDetailsModalContent.types';
import { getLeadId } from './helpers/getLeadId';
import { getProfileId } from './helpers/getProfileId';
import { getStatusHistoryDisplay } from './helpers/getStatusHistoryDisplay';
import { Notes } from './Notes';
import { Status } from './Status';
import { StatusHistory } from './StatusHistory';

export const CandidateDetailsModalContent = ({
  candidateInfo,
  homeLeadProfile,
  candidateId,
  employeeId,
  candidateStatus,
}: CandidateDetailsModalContentProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const userState = useSelector<AppStore, UserState>((state) => state.user);
  const lead = useSelector<AppStore, Lead | undefined>((state) =>
    state.leadsRelated.list.find((elem) => elem.id === candidateId),
  );
  const candidateStatusHistory = useSelector<AppStore, CandidateStatus[]>(
    (state) => state.leadsRelated.candidates.history,
  );
  const [isLoading, setLoading] = useState(true);
  const [displayedStatusHistory, setDisplayedStatusHistory] = useState<
    Partial<LeadStatusHistoryType>[]
  >(candidateStatusHistory);
  const reason = displayedStatusHistory?.find(
    (elem) => elem.status && statusesWithReason.includes(elem.status),
  )?.reason;
  const profileId = getProfileId(homeLeadProfile, candidateInfo, params.id);
  const leadId = getLeadId(homeLeadProfile, params.id);
  const firstName =
    candidateInfo?.employee_data?.first_name || candidateInfo?.first_name;

  const lastName =
    candidateInfo?.employee_data?.last_name || candidateInfo?.last_name;
  const fullName = isPartner(userState.user)
    ? `${firstName} - ${candidateInfo?.employee_data?.readable_id}`
    : `${firstName} ${lastName}`;
  const currentStatus =
    candidateStatus || candidateInfo?.current_status?.status;

  useEffectAsync(async () => {
    profileId &&
      leadId &&
      (await dispatch(
        leadProfileCandidateHistoryThunks.getCandidateStatusHistoryThunk({
          leadId,
          requestId: profileId,
          candidateId: candidateId || Number(candidateInfo?.id),
        }),
      ));
    setLoading(false);
  }, []);

  useEffect(() => {
    setDisplayedStatusHistory(
      getStatusHistoryDisplay(candidateStatusHistory, lead),
    );
  }, [lead, candidateStatusHistory]);

  if (isLoading) return <Loader />;

  return (
    <StyledContainer tabIndex={-1}>
      <StyledFormWrapper>
        <StyledFormTitle>{`Candidate: ${fullName}`}</StyledFormTitle>
        <Status {...{ currentStatus, reason }} />
        <BottomContainer>
          <Notes
            partnerView={isPartner(userState.user)}
            {...{
              candidateInfo,
              leadId,
              profileId,
              candidateId,
              employeeId,
              homeLeadProfile,
            }}
          />
          <StatusHistory {...{ displayedStatusHistory }} />
        </BottomContainer>
      </StyledFormWrapper>
    </StyledContainer>
  );
};
