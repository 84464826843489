import parse from 'html-react-parser';
import { useState } from 'react';
import { Button } from '../../../../../Button/Button.component';
import { EditButton } from '../../../../../IconButtons/EditButton';
import { TextField } from '../../../../../TextField';
import { useEmployeePagePartnerNotesHandler } from '../../hooks/useEmloyeePagePartnerNotesHandler';
import { usePartnerNotesHandler } from '../../hooks/usePartnerNotesHandlers';
import {
  NotesDisplay,
  StyledDescription,
  StyledTextContainer,
} from '../Notes.styles';
import { PartnerNotesProps } from '../Notes.types';

export const PartnerNotes = ({
  devRequestDetails,
  candidateInfo,
  leadId,
  profileId,
  candidateId,
  employeeId,
  homeLeadProfile,
}: PartnerNotesProps) => {
  const [currentlyEditingPartnerNotes, setCurrentlyEditingPartnerNotes] =
    useState(false);
  const [notes, setNotes] = useState(
    devRequestDetails?.partner_notes || candidateInfo?.partner_notes || '',
  );
  const { handlePartnerSaving } = devRequestDetails
    ? useEmployeePagePartnerNotesHandler(devRequestDetails)
    : usePartnerNotesHandler(
        leadId,
        profileId,
        candidateId,
        employeeId,
        homeLeadProfile,
        candidateInfo,
      );

  if (currentlyEditingPartnerNotes) {
    return (
      <StyledTextContainer>
        <TextField
          name="partner_notes"
          placeholder=""
          multiline
          rows={5}
          value={notes}
          handleInputChange={(e) => setNotes(e.target.value)}
        />
        <Button
          action={() => {
            handlePartnerSaving(notes);
            setCurrentlyEditingPartnerNotes(false);
          }}
        >
          Save
        </Button>
      </StyledTextContainer>
    );
  }

  return (
    <NotesDisplay>
      <StyledDescription>
        {notes && parse(notes.replace(/(\r\n|\n|\r)/gm, '<br>'))}
      </StyledDescription>
      <EditButton
        action={() => setCurrentlyEditingPartnerNotes(true)}
        title="Edit notes"
      />
    </NotesDisplay>
  );
};
