import {
  StyledContainer,
  StyledText,
  StyledWarningIcon,
} from './ForbiddenAccess.styles';

export const ForbiddenAccess = () => {
  return (
    <StyledContainer>
      <StyledWarningIcon />
      <StyledText>You don`t have permission to use this page.</StyledText>
    </StyledContainer>
  );
};
