import React from 'react';
import {
  StyledPanel,
  StyledBoldIcon,
  StyledItalicIcon,
  StyledBulletIcon,
  StyledUnderlineIcon,
} from './TextFormattingPanel.styles';
import { bulletForChrome, bulletForFirefox } from './FormattingFunctions';

const TextFormattingPanel = ({ positionY, bulletable = false }) => {
  const preventBlur = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const toggleBold = () => document.execCommand('bold');
  const toggleItalic = () => document.execCommand('italic');
  const toggleUnderline = () => document.execCommand('underline');
  const toggleBullet = () => {
    if (typeof InstallTrigger !== 'undefined') {
      bulletForFirefox(document);
    } else {
      bulletForChrome(document);
    }
  };

  return (
    <StyledPanel positionY={positionY}>
      <StyledBoldIcon onClick={toggleBold} onMouseDown={preventBlur} />
      <StyledItalicIcon onClick={toggleItalic} onMouseDown={preventBlur} />
      <StyledUnderlineIcon
        onClick={toggleUnderline}
        onMouseDown={preventBlur}
      />
      {bulletable && (
        <StyledBulletIcon action={toggleBullet} onMouseDown={preventBlur} />
      )}
    </StyledPanel>
  );
};

export default TextFormattingPanel;
