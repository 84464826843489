import styled from 'styled-components';

export const StyledArchiveModalContainer = styled.div`
  align-items: center;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  margin: 0px;
  max-height: 300px;
  padding: 60px 0 60px 0;
  width: 501px;

  @media (min-width: 700px) {
    min-width: 450px;
    max-width: 700px;
  }

  @media (max-width: 699px) {
    max-width: 450px;
    padding: 25px 30px 25px 30px;
  }
`;

export const StyledArchiveModalTitle = styled.h3`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 24px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
`;
