import { Tooltip } from '../../../../Tooltip/Tooltip.component';
import {
  AdditionalHeadline,
  NotesWrapper,
  StyledNotesContainer,
} from '../CandidateDetailsModalContent.styles';
import { HrNotes } from './HrNotes/HrNotes';
import { Label } from './Notes.styles';
import { NotesProps } from './Notes.types';
import { PartnerNotes } from './PartnerNotes/PartnerNotes';

export const Notes = ({
  partnerView,
  devRequestDetails,
  candidateInfo,
  leadId,
  profileId,
  candidateId,
  employeeId,
  homeLeadProfile,
}: NotesProps) => {
  const isLeadOwnedByPartner =
    devRequestDetails?.owned_by_partner || candidateInfo?.owned_by_partner;

  if (partnerView) {
    return (
      <NotesWrapper>
        <AdditionalHeadline>Notes</AdditionalHeadline>
        <StyledNotesContainer>
          <PartnerNotes
            {...{
              devRequestDetails,
              candidateInfo,
              leadId,
              profileId,
              candidateId,
              employeeId,
              homeLeadProfile,
            }}
          />
        </StyledNotesContainer>
      </NotesWrapper>
    );
  }

  return (
    <NotesWrapper>
      <AdditionalHeadline>Notes</AdditionalHeadline>
      <StyledNotesContainer>
        {isLeadOwnedByPartner && <Label>HR notes</Label>}
        <HrNotes
          {...{
            devRequestDetails,
            candidateInfo,
            leadId,
            profileId,
            candidateId,
            employeeId,
            homeLeadProfile,
          }}
        />
        {isLeadOwnedByPartner && (
          <>
            <Tooltip
              title="This message will be visible for partner"
              position="close"
            >
              <Label>Partner notes</Label>
            </Tooltip>
            <PartnerNotes
              {...{
                devRequestDetails,
                candidateInfo,
                leadId,
                profileId,
                candidateId,
                employeeId,
                homeLeadProfile,
              }}
            />
          </>
        )}
      </StyledNotesContainer>
    </NotesWrapper>
  );
};
