import { AnyAction } from '@reduxjs/toolkit';
import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppPaths } from '../../../../../../config/AppPaths';
import { leadProfilesThunks } from '../../../../../../containers/Leads/redux/LeadProfiles.thunk';
import { LeadProfile } from '../../../../../../store/types/Store.leads.types';
import { AppStore } from '../../../../../../store/types/Store.types';
import { DeleteModal } from '../../../DeleteModal';
import { RequestDeletingModalContentProps } from './RequestDeletingModalContent.types';

export const RequestDeletingModalContent = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<RequestDeletingModalContentProps>
>(({ leadId, requestId, closeModal }, ref) => {
  const { t } = useTranslation(['common', 'company'], {
    keyPrefix: 'vacancies',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const requestFetched = useSelector<AppStore, LeadProfile | undefined>(
    (state) =>
      state.leadsRelated.profiles.find((elem) => elem.id === requestId),
  );

  useEffect(() => {
    dispatch(
      leadProfilesThunks.getLeadRequestsThunk({
        leadId,
      }) as unknown as AnyAction,
    );
  }, []);

  const name = requestFetched ? requestFetched.name : 'no data';

  const handleDeleting = async () => {
    const result = await dispatch(
      leadProfilesThunks.deleteLeadRequestThunk({
        leadId,
        requestId,
      }) as unknown as AnyAction,
    );

    if (
      result.type === leadProfilesThunks.deleteLeadRequestThunk.fulfilled.type
    ) {
      dispatch(
        leadProfilesThunks.getLeadRequestsThunk({
          leadId,
        }) as unknown as AnyAction,
      );
      closeModal();
      navigate(
        generatePath(AppPaths.leadDetail, {
          id: leadId,
        }),
        {
          state: {
            prevPath: `${location?.state?.prevPath || AppPaths.leads}`,
          },
        },
      );
    }
  };

  const buttonsConfig = {
    primaryButton: {
      label: t('confirm_delete', { ns: 'common', keyPrefix: 'buttons' }),
      action: handleDeleting,
    },
  };

  const dialogText = `${t('projects.do_you_want_to_remove', {
    ns: 'company',
    keyPrefix: 'company_details',
  })} ${t('projects.request', {
    ns: 'company',
    keyPrefix: 'company_details',
  })} ${name}?`;

  return (
    <DeleteModal
      dialogTitle=""
      message={dialogText}
      buttonsConfig={buttonsConfig}
      handleClose={closeModal}
      ref={ref}
    />
  );
});
