import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';

export const StyledData = styled.div`
  display: flex;
  margin-right: 10px;
  margin-right: 80px;
  overflow-x: hidden;

  :hover div {
    visibility: visible;
  }

  @media print {
    border: 0;
    overflow-y: hidden;
  }
  overflow-y: auto;
`;

export const StyledAddCustomInfoButton = styled.div`
  ${'' /* position: absolute; */}
  color: ${COLORS.accent.primary};
  display: flex;
  height: 0px;
  visibility: hidden;
`;

export const StyledDeleteInfoButton = styled.div`
  bottom: 30px;
  color: ${COLORS.accent.primary};
  float: right;
  left: 35px;
  position: relative;
  visibility: hidden;
`;

export const StyledIcon = styled.div`
  cursor: pointer;
  margin-right: 10px;
`;

export const StyledTimePeriod = styled.div`
  font-weight: 200;
  margin-right: 80px;
  width: 300px;

  :empty:before {
    content: attr(placeholder);
    opacity: 0.3;

    @media print {
      opacity: 0;
    }
  }
`;

export const StyledInfo = styled.div``;

export const StyledMinorInfo = styled.div`
  padding-top: 10px;
`;

export const StyledLargeHeader = styled.div`
  float: left;
  font-weight: 700;
  min-width: 30px;
  padding-right: 5px;

  :empty:before {
    content: attr(placeholder);
    opacity: 0.3;

    @media print {
      opacity: 0;
    }
  }
`;

export const StyledSmallHeader = styled.span`
  float: left;
  font-weight: 400;
  min-width: 30px;
  padding-right: 5px;

  :empty:before {
    content: attr(placeholder);
    opacity: 0.3;

    @media print {
      opacity: 0;
    }
  }
`;

export const StyledLargeTextDiv = styled.div`
  font-weight: 700;

  & > div {
    font-weight: 700;
  }

  :empty:before {
    content: attr(placeholder);
    opacity: 0.3;

    @media print {
      opacity: 0;
    }
  }
`;

export const StyledTextDiv = styled.div`
  font-weight: 200;

  :empty:before {
    content: attr(placeholder);
    opacity: 0.3;

    @media print {
      opacity: 0;
    }
  }
`;
