export enum PersistedStoreKeys {
  userFilters = 'userFilters',
  user = 'user',
  token = 'token',
  leadProfileId = 'leadProfileId',
  employeeFilters = 'employeeFilters',
  salesFilters = 'salesFilters',
  clientFilters = 'clientFilters',
  leadsFilters = 'leadsFilters',
  externalClientToken = 'externalClientToken',
  externalClientId = 'externalClientId',
  leadId = 'leadId',
}

export interface LeadsFiltersType {
  search: string;
  owner: string;
  checkboxes: { active: boolean; archived: boolean };
}

export type ExternalClientIdType = string;
