import { TableFooter } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CreatedRowLead } from '../../../containers/Leads/LeadsTable/LeadsTable.types';
import { CreatedRowHome } from '../../../containers/Vacancies/VacanciesPageHrSales/CreateRow/CreateRow.types';
import { NoResult } from '../NoResult';
import {
  StyledFooterRow,
  StyledPagination,
  StyledPaper,
  StyledTableCell,
  useStyles,
} from './CollapseTable.styles';
import {
  CollapseTableProps,
  HomeColumn,
  OrderBy,
  SortOrderKey,
} from './CollapseTable.types';
import { EnhancedTableHead } from './EnhancedTableHead/EnhancedTableHead';
import { getHomeComparator } from './helpers/getHomeComparator';
import { getLeadsComparator } from './helpers/getLeadsComparator';
import { getPageWithLeadToOpen } from './helpers/getPageWithLeadToOpen';
import { reverseSortOrder } from './helpers/reverseSortOrder';
import { Row } from './Row';

export const CollapseTable = ({
  tableData,
  defaultSortKey,
  homeLeads,
  columnAmount,
  type,
}: CollapseTableProps) => {
  const { t } = useTranslation(['common'], { keyPrefix: 'table' });
  const [order, setOrder] = useState<SortOrderKey>('desc');
  const [orderBy, setOrderBy] = useState<string>(defaultSortKey || 'date');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const classes = useStyles();
  const [sortedRows, setSortedRows] = useState([...tableData.rows]);
  const [searchParams, setSearchParams] = useSearchParams();
  const idOpenedLead = Number(searchParams.get('id'));

  const sortTable = () => {
    if (tableData.type === 'home') {
      setSortedRows([
        ...tableData.rows.sort(
          getHomeComparator(order, orderBy as keyof CreatedRowHome),
        ),
      ]);
    } else {
      setSortedRows([
        ...tableData.rows.sort(
          getLeadsComparator(order, orderBy as keyof CreatedRowLead),
        ),
      ]);
    }
  };

  useEffect(() => {
    sortTable();
    if (idOpenedLead && sortedRows.length) {
      setPage(getPageWithLeadToOpen(tableData.rows, idOpenedLead, rowsPerPage));
    }
  }, [order, orderBy, tableData]);

  useEffect(() => {
    if (idOpenedLead && sortedRows.length) {
      const rowId = document.getElementById(`id${idOpenedLead}`);
      if (rowId) {
        rowId.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        setSearchParams();
      }
    }
  }, [idOpenedLead]);

  const handleRequestSort = (property: OrderBy) => {
    setOrder(reverseSortOrder(order));
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
    setSearchParams();
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSearchParams();
  };

  return (
    <StyledPaper>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" size="small">
          <EnhancedTableHead
            columns={tableData.columns}
            {...{ order, orderBy: orderBy as OrderBy, handleRequestSort }}
          />
          <TableBody>
            {sortedRows.length ? (
              sortedRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <Row
                    key={row.id}
                    tableData={{
                      columns: tableData.columns as HomeColumn[],
                      row: row as CreatedRowHome,
                      type: tableData.type as 'home',
                    }}
                    idOpenedLead={idOpenedLead || undefined}
                    homeLeads={homeLeads}
                    type={type}
                  />
                ))
            ) : (
              <tr>
                <StyledTableCell colSpan={columnAmount}>
                  <NoResult message="No rows found" />
                </StyledTableCell>
              </tr>
            )}
          </TableBody>
          <TableFooter>
            <StyledFooterRow>
              <StyledPagination
                labelRowsPerPage={t('rows_per_page')}
                rowsPerPageOptions={[10, 25, 50]}
                showFirstButton
                showLastButton
                count={tableData.rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  MenuProps: {
                    className: classes.dropdown,
                  },
                }}
              />
            </StyledFooterRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </StyledPaper>
  );
};
