import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../../theme/colors';
import { StyledTableHeaderProps } from './Row.types';

export const StyledCell = styled.td<HTMLAttributes<HTMLTableCellElement>>`
  border: 0;
  color: #8f9dac;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 16px;
  min-width: 138px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media screen and (max-width: 699px) {
    display: block;
    min-width: unset;
    max-width: unset;
    padding: 7px;
  }
`;

export const StyledTableHeader = styled.td<StyledTableHeaderProps>`
  padding: 10px 0;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
`;

export const ArrowCellStyled = styled.td`
  border: 0;
  max-width: 65px;
  width: 65px;
  @media screen and (max-width: 699px) {
    display: block;
    flex-basis: 10%;
    min-width: unset;
    max-width: unset;
    padding: 0;
  }
`;

type StyledSpanProps = {
  fontWeight?: string;
  color?: string;
  isHidden: boolean;
};

export const StyledSpan = styled.span<StyledSpanProps>`
  color: ${({ color, isHidden }) =>
    isHidden ? COLORS.typography.disabled : color || '#000000'};
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 16px;
`;

type StyledTableRowProps = { isOpen: boolean };

export const StyledTableRow = styled.tr<StyledTableRowProps>`
  border-top: 1px solid #e5e5e5;
  @media screen and (max-width: 699px) {
    padding: 10px 2px 22px 20px;
  }
  ${({ isOpen }) => isOpen && 'box-shadow: 0 4px 2px -2px rgba(0,0,0,0.05);'}
`;

export const StyledExpandableRow = styled.tr``;
