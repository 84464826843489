import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';
import { WithHeightUpdate } from '../../../../utils/WithHeightUpdate';
import {
  StyledAddColumnButton,
  StyledContainer,
  StyledIcon,
} from './TechnologiesEntry.styles';

const TechnologiesEntryComponent = forwardRef(
  ({ editable, handleAddEntry, children }, ref) => {
    return (
      <StyledContainer ref={ref}>
        {editable && (
          <StyledAddColumnButton>
            <StyledIcon onClick={handleAddEntry}>
              <FontAwesomeIcon icon={faPlusCircle} />
            </StyledIcon>
          </StyledAddColumnButton>
        )}
        {children()}
      </StyledContainer>
    );
  },
);

export const TechnologiesEntry = WithHeightUpdate(TechnologiesEntryComponent);
