import AttachFileIcon from '@mui/icons-material/AttachFile';
import parse from 'html-react-parser';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledHeadline } from '../../../containers/Vacancies/VacanciesPageHrSales/VacanciesPageHrSales.styles';
import {
  AttachedFileContainer,
  AttachedFileLabel,
  DescriptionBox,
  Line,
  StyledBox,
  HeadContainer,
} from './JobDescription.styles';
import { VacanciesPageContext } from '../../../containers/Vacancies/VacanciesPageHrSales/VacanciesPageContext/VacanciesPageContext';
import { DeleteItemButton } from '../../common/Buttons/DeleteItemButton/DeleteItemButton';

export const JobDescription = ({
  description,
  files,
  leadProfileId,
  leadId,
  isMyCompanyLeadProfile,
}) => {
  const { handleDeleteLeadProfileModal } = useContext(VacanciesPageContext);
  const { t } = useTranslation(['vacancies'], { keyPrefix: 'vacancies' });
  return (
    <StyledBox>
      <HeadContainer>
        <StyledHeadline>{t('job_description')}</StyledHeadline>
        {isMyCompanyLeadProfile && handleDeleteLeadProfileModal && (
          <DeleteItemButton
            action={() => handleDeleteLeadProfileModal(leadProfileId, leadId)}
          />
        )}
      </HeadContainer>
      <Line />
      <DescriptionBox>
        {parse(description?.replace(/(\r\n|\n|\r)/gm, '<br>') || '')}
      </DescriptionBox>
      {files &&
        files.map(({ file_name, file }, index) => (
          <a
            key={index}
            href={file}
            target="_blank"
            download={file_name}
            rel="noreferrer"
          >
            <AttachedFileContainer>
              <AttachFileIcon style={{ fontSize: '17px', color: '#9FA3B3' }} />
              <AttachedFileLabel>{file_name}</AttachedFileLabel>
            </AttachedFileContainer>
          </a>
        ))}
    </StyledBox>
  );
};
