import styled from '@mui/styled-engine';
import Modal from '@mui/material/Modal';

export const StyledCVModal = styled(Modal)({
  overflow: 'auto',

  '& .MuiBackdrop-root': {
    backgroundColor: 'rgb(0, 0, 0, 0.4)',
    fontFamily: 'Neue Haas Grotesk Display Pro !important',
  },
});
