import { isWithinInterval, sub } from 'date-fns';

export const isDateWithin24Hours = (date: Date): boolean => {
  const currDate = new Date();
  const range = {
    start: sub(currDate, { hours: 24 }),
    end: currDate,
  };
  return isWithinInterval(date, range);
};
