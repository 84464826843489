import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthHeader } from '../../utils/api';
import { establishSecureWebsocketConnection } from './auth';
import { WebSocketEvent } from './eventTypes';
import { WebSocketEventContext } from './helpers';

interface Props {
  children: React.ReactNode;
  onOpen?: (event: Event) => any;
  onClose?: (event: CloseEvent) => any;
}

const WsEventProvider = ({ onOpen, onClose, children }: Props) => {
  const [websocketConn, setWebsocketConn] = useState<WebSocket | null>(null);
  const [onMessageEvents, setOnMessageEvents] = useState<WebSocketEvent>({});
  const authToken = useSelector(getAuthHeader);

  const dispatchMessageEvents = (msg: MessageEvent) => {
    const data = JSON.parse(msg.data);
    const { category } = data;

    Object.keys(onMessageEvents).forEach((name: string) => {
      if (onMessageEvents[name].category === category) {
        onMessageEvents[name].callback(data.content);
      }
    });
  };

  useEffect(() => {
    if (!websocketConn) {
      return;
    }
    websocketConn.onmessage = dispatchMessageEvents;
  }, [onMessageEvents, websocketConn]);

  useEffect(() => {
    /*
    isMounted variable is created here so we will not be able
    to rerender unset component
    */
    let isMounted = true;

    if (isMounted) {
      establishSecureWebsocketConnection(authToken)
        .then((ws) => {
          if (!ws) {
            return;
          }
          /*
          We are only establishing the connection. Updating
          the event list must be perform in above "useEffect"
          Otherwise we will not be able to get updated result from
          the useState hook
          */
          setWebsocketConn(ws);

          if (onClose) {
            ws.onclose = onClose;
          }

          if (onOpen) {
            ws.onopen = onOpen;
          }
        })
        .catch((e) => console.error(e));
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const ctxValue = useMemo(
    () => ({
      connection: websocketConn,
      setConnection: setWebsocketConn,
      onMessageEvents,
      setOnMessageEvents,
    }),
    [websocketConn, onMessageEvents],
  );

  return (
    <WebSocketEventContext.Provider value={ctxValue}>
      {children}
    </WebSocketEventContext.Provider>
  );
};

export default WsEventProvider;
