import styled from 'styled-components';
import { COLORS } from '../../../../theme/colors';

interface ButtonContainerProps {
  disabled?: boolean;
}

export const ButtonContainer = styled.div<ButtonContainerProps>`
  display: flex;
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};
`;

export const ButtonLabel = styled.span`
  color: ${COLORS.accent.primary};
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-left: 5px;
  text-transform: none;
`;
