import { API_EVENT_TICKET, WEBSOCKET_EVENTS_ENDPOINT } from '../../config/urls';

interface WebsocketAuthResponse {
  user_id: string;
  ticket: string;
  created_at: string;
}

export const establishSecureWebsocketConnection = async (
  token: any,
): Promise<WebSocket | undefined> => {
  if (!token) {
    console.log(
      'User does not have nessessary auth token. Please log in again.',
    );
    return;
  }

  const response = await fetch(API_EVENT_TICKET, {
    method: 'GET',
    headers: token,
  });

  if (response.status !== 200) {
    // this will be commented until the backend will be ready
    // with the events functionality
    // console.error('Could not connect to the websocket on the backend');
    return;
  }

  const responseData: WebsocketAuthResponse = await response.json();
  const sock = new WebSocket(
    `${WEBSOCKET_EVENTS_ENDPOINT}/${responseData.ticket}/`,
  );
  console.log('Connection by websocket has been established');

  return sock;
};
