import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';
import { pastePlainText } from '../../../../Helpers';
import { WithHeightUpdate } from '../../../../utils/WithHeightUpdate';
import {
  StyledAddCustomInfoButton,
  StyledIcon,
  StyledPosition,
  StyledPositionSection,
} from './PositionHeader.styles';

export const PositionHeaderComponent = forwardRef(
  (
    {
      headerText,
      handleDrag,
      handleDrop,
      handleDragOver,
      editable,
      addEntry,
      hasEntry,
      editHeaderText,
      setTextFormattingPanelVisibility,
    },
    ref,
  ) => {
    return (
      <StyledPositionSection
        ref={ref}
        draggable
        onDragStart={handleDrag}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {editable && !hasEntry && (
          <StyledAddCustomInfoButton>
            <StyledIcon onClick={addEntry}>
              <FontAwesomeIcon icon={faPlusCircle} />
            </StyledIcon>
          </StyledAddCustomInfoButton>
        )}
        <StyledPosition
          placeholder="Senior Frontend Developer"
          suppressContentEditableWarning
          contentEditable={editable}
          onFocus={() => {
            setTextFormattingPanelVisibility(false);
          }}
          onPaste={(e) => pastePlainText(e)}
          onBlur={(e) => {
            setTextFormattingPanelVisibility(false);
            editHeaderText(e.currentTarget.innerHTML);
          }}
          spellCheck={false}
          dangerouslySetInnerHTML={{
            __html: headerText,
          }}
        />
      </StyledPositionSection>
    );
  },
);

export const PositionHeader = WithHeightUpdate(PositionHeaderComponent);
