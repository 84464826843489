import { useLeadsConfig } from '../../../../../../containers/Leads/useLeadsConfig';
import {
  CandidateStatus,
  Lead,
  LeadStatusHistoryType,
} from '../../../../../../store/types/Store.leads.types';

export const getStatusHistoryDisplay = (
  candidateStatusHistory: CandidateStatus[],
  lead?: Lead,
) => {
  const { candidateStatuses } = useLeadsConfig();
  const tempStatusHistory: Partial<LeadStatusHistoryType>[] = [];
  const statusSorter = (
    a: LeadStatusHistoryType | CandidateStatus,
    b: LeadStatusHistoryType | CandidateStatus,
  ) => new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf();

  if (candidateStatusHistory?.length > 0) {
    const sorted = [...candidateStatusHistory].sort(statusSorter);
    sorted.forEach((histStat) => {
      const statusDesc = candidateStatuses.find(
        (statDesc) =>
          statDesc.name.toUpperCase() === histStat.status.toUpperCase(),
      );
      tempStatusHistory.push({
        ...histStat,
        ...statusDesc,
      });
    });
  }
  if (lead?.candidate_statuses) {
    const sorted = [...lead.candidate_statuses].sort(statusSorter);
    sorted.forEach((histStat) => {
      const statusDesc = candidateStatuses.find(
        (statDesc) =>
          statDesc.name.toUpperCase() === histStat.status.toUpperCase(),
      );
      tempStatusHistory.push({
        ...histStat,
        ...statusDesc,
      });
    });
  }
  return tempStatusHistory;
};
