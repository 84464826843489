import { Badge, IconButton } from '@mui/material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import React from 'react';
import { NotificationButtonProps } from './Notifications.types';
import { COLORS } from '../../../theme/colors';

const NotificationButton = React.memo((props: NotificationButtonProps) => {
  return (
    <IconButton onClick={props.onClick} disabled={props.disabled}>
      <Badge
        badgeContent={props.disabled ? 0 : props.numberOfNotifications}
        color="error"
      >
        <NotificationsOutlinedIcon
          style={{
            fontSize: '23px',
            color: COLORS.typography.main,
            opacity: props.disabled ? '30%' : '100%',
          }}
        />
      </Badge>
    </IconButton>
  );
});

export default NotificationButton;
