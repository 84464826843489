import { LeadRequestFormFieldType } from '../LeadRequestForm.config';
import { useDedicatedHandlerProps } from '../LeadRequestForm.types';

export const useDedicatedHandler = ({
  type,
  e,
  setOwner,
  setName,
  setReason,
}: useDedicatedHandlerProps) => {
  switch (type) {
    case LeadRequestFormFieldType.name:
      setName(e.target.innerText || '');
      break;
    case LeadRequestFormFieldType.hrOwner:
      setOwner(e.target.innerText || '');
      break;
    case LeadRequestFormFieldType.reason:
      setReason(e.target.innerText || '');
      break;

    default:
      break;
  }
};
