import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { NoResult } from '../../common/NoResult';
import { statisticsChartOptions } from './config';
import { CustomLegend } from './CustomLegend/CustomLegend.component';
import { getStatusesStatistics } from './Helpers/getStatusesStatistics';
import {
  ChartContainer,
  LegendContainer,
  Line,
  NoResWrapper,
  StyledBox,
  StyledHeadline,
} from './Statistics.styles';
import { StatisticsProps } from './Statistics.types';
import { useLeadsConfig } from '../../../containers/Leads/useLeadsConfig';

export const Statistics = ({ candidates }: StatisticsProps) => {
  const { candidateStatuses } = useLeadsConfig();
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.projects',
  });
  const statusesData =
    candidates && getStatusesStatistics(candidateStatuses, candidates);
  const data = {
    labels: candidateStatuses.map((status) => status.showedName),
    datasets: [
      {
        data:
          candidates &&
          Object.values(getStatusesStatistics(candidateStatuses, candidates)),
        backgroundColor: candidateStatuses.map((status) => status.chartColor),
        radius: 90,
        hoverOffset: 4,
        borderWidth: 0,
        cutout: '30%',
      },
    ],
  };
  return (
    <StyledBox>
      <StyledHeadline>{t('statistics')}</StyledHeadline>
      <Line />
      {candidates?.length ? (
        <ChartContainer>
          <LegendContainer>
            {statusesData &&
              Object.entries(statusesData)?.map(([currentStatus, amount]) => (
                <CustomLegend
                  key={currentStatus}
                  name={currentStatus}
                  number={amount}
                  color={
                    candidateStatuses.find(
                      (status) => status.name === currentStatus,
                    )?.chartColor
                  }
                />
              ))}
          </LegendContainer>
          <Doughnut
            data={data}
            options={statisticsChartOptions}
            height={240}
            width={230}
          />
        </ChartContainer>
      ) : (
        <NoResWrapper>
          <NoResult message="No suggested candidates" />
        </NoResWrapper>
      )}
    </StyledBox>
  );
};
