import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormGroup from '@mui/material/FormGroup';
import { generatePath, Link } from 'react-router-dom';
import { AppPaths } from '../../../config/AppPaths';
import { removeHtml } from '../../../Helpers';
import TextFormattingPanel from '../../common/TextFormattingPanel/TextFormattingPanel';
import {
  BenchlySwitch,
  StyledButton,
  StyledCustomContainer,
  StyledDeleteButton,
  StyledFormControlLabel,
  StyledIcon,
  StyledLabel,
  StyledLink,
  StyledSidebar,
  StyledSpan,
  StyledSwitchContainer,
  StyledTextFormattingPanel,
  Wrapper,
} from './Sidebar.styles';

export const Sidebar = ({
  saveCv,
  message,
  downloadPdf,
  rootSections,
  employeeContext,
  addCustomSection,
  deleteCustomSection,
  toggleSectionVisibility,
  textFormattingPanelVisible,
}) => {
  return (
    <StyledSidebar>
      <StyledSpan>
        <StyledLabel>
          {message}{' '}
          <StyledLink
            to={generatePath(AppPaths.employeeId, {
              id: employeeContext.employeeId,
            })}
          >
            {employeeContext.employeeName}
          </StyledLink>
        </StyledLabel>
      </StyledSpan>
      <Wrapper>
        <Link to={AppPaths.candidates}>
          <StyledButton>List of employees</StyledButton>
        </Link>
      </Wrapper>
      <Wrapper>
        <StyledButton onClick={saveCv}>Save</StyledButton>
      </Wrapper>
      <Wrapper>
        <StyledButton onClick={addCustomSection}>Add section</StyledButton>
      </Wrapper>
      <Wrapper>
        <StyledButton onClick={() => downloadPdf()}>Download PDF</StyledButton>
      </Wrapper>
      {textFormattingPanelVisible === 'bulletable' && (
        <StyledTextFormattingPanel>
          <StyledLabel>Text formatting</StyledLabel>
          <TextFormattingPanel bulletable />
        </StyledTextFormattingPanel>
      )}
      {textFormattingPanelVisible !== 'bulletable' &&
        textFormattingPanelVisible && (
          <StyledTextFormattingPanel>
            <StyledLabel>Text formatting</StyledLabel>
            <TextFormattingPanel bulletable={false} />
          </StyledTextFormattingPanel>
        )}
      <StyledSwitchContainer>
        <StyledLabel>Sections display</StyledLabel>
        <FormGroup>
          {rootSections.map((section) => (
            <StyledCustomContainer key={section.sectionId}>
              <StyledFormControlLabel
                control={
                  <BenchlySwitch
                    checked={section.visible}
                    onChange={() => toggleSectionVisibility(section.sectionId)}
                    color="primary"
                  />
                }
                label={removeHtml(section.displayName)}
              />
              {section.isCustom && (
                <StyledDeleteButton>
                  <StyledIcon
                    onClick={() => deleteCustomSection(section.sectionId)}
                  >
                    <FontAwesomeIcon icon={faMinusCircle} />
                  </StyledIcon>
                </StyledDeleteButton>
              )}
            </StyledCustomContainer>
          ))}
        </FormGroup>
      </StyledSwitchContainer>
    </StyledSidebar>
  );
};
