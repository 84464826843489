import React from 'react';
import { StyledModal } from '../../Modal.styles';
import { StyledModalWrapper } from './UsersModal.styles';
import { EditingModalContent } from '../EditingModalContent/EditingModalContent.component';
import { AddingModalContent } from '../AddingModalContent/AddingModalContent.component';
import { wideModals, modalSizes } from '../../Modal.config';

export const UsersModal = ({
  modalFor,
  openModal,
  closeModal,
  userToEdit,
  editUser,
  partnerNames,
}) => {
  const modalWidth = wideModals.includes(modalFor)
    ? modalSizes.wide
    : modalSizes.narrow;

  const modalsOptions = {
    editing: (
      <EditingModalContent
        handleSubmit={editUser}
        handleBack={closeModal}
        userToEdit={userToEdit}
      />
    ),
    adding: (
      <AddingModalContent
        partnerNames={partnerNames}
        openModal={openModal}
        closeModal={closeModal}
      />
    ),
  };

  return (
    <StyledModal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="users-modal-title"
      aria-describedby="users-modal-description"
      width={modalWidth}
    >
      <StyledModalWrapper>{modalsOptions[modalFor]}</StyledModalWrapper>
    </StyledModal>
  );
};
