import { LeadFormFieldType } from '../LeadForm.config';
import { useDedicatedHandlerProps } from '../LeadForm.types';

export const useDedicatedHandler = ({
  type,
  e,
  setCompany,
  setIndustry,
}: useDedicatedHandlerProps) => {
  switch (type) {
    case LeadFormFieldType.name:
      setCompany(e.target.innerText || '');
      break;
    case LeadFormFieldType.industry:
      setIndustry(e.target.innerText || '');
      break;

    default:
      break;
  }
};
