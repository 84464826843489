import React from 'react';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import Tooltip from '@mui/material/Tooltip';
import { StyledIconButton } from './UnderlineIcon.styles';

export const UnderlineIcon = ({
  action,
  title = 'Underline',
  ...iconOptions
}) => (
  <StyledIconButton onClick={action}>
    <Tooltip title={title}>
      <FormatUnderlinedIcon style={{ color: 'black' }} {...iconOptions} />
    </Tooltip>
  </StyledIconButton>
);
