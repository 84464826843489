import { idGenerator } from '../../utils/idGenerator';
import { SECTION_HEADERS, SECTION_TYPE } from './CV.config';

const initialPageEmptySpace = 1415; //todo: compute it

export const getPages = (
  sectionConfig,
  pagesFailed,
  setPagesFailed,
  pagesFinished,
  setPagesFinished,
) => {
  // only first 2 levels are taken into the flat array, and only those sections will have their heights measured
  const sectionsFlat = sectionConfig.entries.reduce((acc, section) => {
    if (!section.visible) {
      return acc;
    }
    return [...acc, section, ...section.entries];
  }, []);

  const targetSections = sectionsFlat.reduce(
    (acc, section, index, array) => {
      if (section.height > initialPageEmptySpace && !pagesFailed) {
        setPagesFailed(true);
      }

      if (!section.height) {
        acc.anySectionWithoutHeight = true;
      }

      const newEmptySpace = acc.currentPageEmptySpace - section.height;
      // if the current section is a header, and the next section will be placed on the next page orphaning the header -
      // don't add the header yet, add it on the next page
      if (
        SECTION_HEADERS.has(section.type) &&
        newEmptySpace >= 0 &&
        // eslint-disable-next-line no-unsafe-optional-chaining
        newEmptySpace - sectionsFlat[index + 1]?.height < 0
      ) {
        acc.leftovers.push(section);
        return acc;
      }

      const leftoversHeight = acc.leftovers.reduce((accA, item) => {
        return accA + item.height;
      }, 0);

      if (newEmptySpace <= 0 || acc.leftovers.length) {
        acc.allPages = [...acc.allPages, acc.currentPage];
        acc.currentPage = [...acc.leftovers];
        acc.leftovers = [];
        acc.currentPageEmptySpace = initialPageEmptySpace - leftoversHeight;
      }

      acc.currentPage = [...acc.currentPage, section];
      acc.currentPageEmptySpace -= section.height;

      if (index === array.length - 1) {
        acc.allPages = [...acc.allPages, acc.currentPage];
      }

      return acc;
    },
    {
      currentPageEmptySpace: initialPageEmptySpace,
      currentPage: [],
      leftovers: [],
      allPages: [],
      anySectionWithoutHeight: false,
    },
  );

  if (!targetSections.anySectionWithoutHeight && !pagesFinished) {
    setPagesFinished(true);
  }

  return targetSections;
};

export const getInitialState = () => {
  return {
    dataLoaded: false,
    employeeContext: null,
    textFormattingPanelVisible: false,
    sectionConfig: {
      entries: [
        {
          sectionId: idGenerator(),
          type: SECTION_TYPE.POSITION_HEADER,
          visible: true,
          height: 0,
          entries: [
            {
              sectionId: idGenerator(),
              type: SECTION_TYPE.POSITION_ENTRY,
              height: 0,
            },
          ],
        },
        {
          sectionId: idGenerator(),
          type: SECTION_TYPE.TECHNOLOGIES_HEADER,
          visible: true,
          height: 0,
          entries: [
            {
              sectionId: idGenerator(),
              type: SECTION_TYPE.TECHNOLOGIES_ENTRY,
              visible: true,
              height: 0,
              entries: [
                {
                  sectionId: idGenerator(),
                  type: SECTION_TYPE.TECHNOLOGY_HEADER,
                  height: 0,
                  defaultData: {
                    technologyGroupName: 'Frontend',
                  },
                  entries: [
                    {
                      sectionId: idGenerator(),
                      type: SECTION_TYPE.TECHNOLOGY_ENTRY,
                      height: 0,
                      defaultData: {
                        technologyName: 'JavaScript',
                      },
                    },
                    {
                      sectionId: idGenerator(),
                      type: SECTION_TYPE.TECHNOLOGY_ENTRY,
                      height: 0,
                      defaultData: {
                        technologyName: 'React',
                      },
                    },
                    {
                      sectionId: idGenerator(),
                      type: SECTION_TYPE.TECHNOLOGY_ENTRY,
                      height: 0,
                      defaultData: {
                        technologyName: 'Angular',
                      },
                    },
                  ],
                },
                {
                  sectionId: idGenerator(),
                  type: SECTION_TYPE.TECHNOLOGY_HEADER,
                  height: 0,
                  defaultData: {
                    technologyGroupName: 'Backend',
                  },
                  entries: [
                    {
                      sectionId: idGenerator(),
                      type: SECTION_TYPE.TECHNOLOGY_ENTRY,
                      height: 0,
                      defaultData: {
                        technologyName: 'Python',
                      },
                    },
                    {
                      sectionId: idGenerator(),
                      type: SECTION_TYPE.TECHNOLOGY_ENTRY,
                      height: 0,
                      defaultData: {
                        technologyName: 'Django',
                      },
                    },
                    {
                      sectionId: idGenerator(),
                      type: SECTION_TYPE.TECHNOLOGY_ENTRY,
                      height: 0,
                      defaultData: {
                        technologyName: 'Flask',
                      },
                    },
                    {
                      sectionId: idGenerator(),
                      type: SECTION_TYPE.TECHNOLOGY_ENTRY,
                      height: 0,
                      defaultData: {
                        technologyName: 'Java',
                      },
                    },
                    {
                      sectionId: idGenerator(),
                      type: SECTION_TYPE.TECHNOLOGY_ENTRY,
                      height: 0,
                      defaultData: {
                        technologyName: 'C++',
                      },
                    },
                  ],
                },
                {
                  sectionId: idGenerator(),
                  type: SECTION_TYPE.TECHNOLOGY_HEADER,
                  height: 0,
                  defaultData: {
                    technologyGroupName: 'Devops',
                  },
                  entries: [
                    {
                      sectionId: idGenerator(),
                      type: SECTION_TYPE.TECHNOLOGY_ENTRY,
                      height: 0,
                      defaultData: {
                        technologyName: 'Docker',
                      },
                    },
                    {
                      sectionId: idGenerator(),
                      type: SECTION_TYPE.TECHNOLOGY_ENTRY,
                      height: 0,
                      defaultData: {
                        technologyName: 'Jenkins',
                      },
                    },
                    {
                      sectionId: idGenerator(),
                      type: SECTION_TYPE.TECHNOLOGY_ENTRY,
                      height: 0,
                      defaultData: {
                        technologyName: 'Ansible',
                      },
                    },
                  ],
                },
                {
                  sectionId: idGenerator(),
                  type: SECTION_TYPE.TECHNOLOGY_HEADER,
                  height: 0,
                  defaultData: {
                    technologyGroupName: 'Tools',
                  },
                  entries: [
                    {
                      sectionId: idGenerator(),
                      type: SECTION_TYPE.TECHNOLOGY_ENTRY,
                      height: 0,
                      defaultData: {
                        technologyName: 'Git',
                      },
                    },
                    {
                      sectionId: idGenerator(),
                      type: SECTION_TYPE.TECHNOLOGY_ENTRY,
                      height: 0,
                      defaultData: {
                        technologyName: 'VS Code',
                      },
                    },
                    {
                      sectionId: idGenerator(),
                      type: SECTION_TYPE.TECHNOLOGY_ENTRY,
                      height: 0,
                      defaultData: {
                        technologyName: 'Emacs',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          sectionId: idGenerator(),
          type: SECTION_TYPE.ADDITIONAL_INFO_HEADER,
          visible: true,
          height: 0,
          entries: [
            {
              sectionId: idGenerator(),
              type: SECTION_TYPE.ADDITIONAL_INFO_ENTRY,
              height: 0,
            },
          ],
        },
        {
          sectionId: idGenerator(),
          type: SECTION_TYPE.EXPERIENCE_HEADER,
          visible: true,
          height: 0,
          entries: [
            {
              sectionId: idGenerator(),
              type: SECTION_TYPE.EXPERIENCE_ENTRY,
              height: 0,
            },
          ],
        },
        {
          sectionId: idGenerator(),
          type: SECTION_TYPE.EDUCATION_HEADER,
          visible: true,
          height: 0,
          entries: [
            {
              sectionId: idGenerator(),
              type: SECTION_TYPE.EDUCATION_ENTRY,
              height: 0,
            },
          ],
        },
        {
          sectionId: idGenerator(),
          type: SECTION_TYPE.LANGUAGES_HEADER,
          visible: true,
          height: 0,
          entries: [
            {
              sectionId: idGenerator(),
              type: SECTION_TYPE.LANGUAGES_ENTRY,
              height: 0,
              defaultData: {
                languageName: 'English',
              },
            },
            {
              sectionId: idGenerator(),
              type: SECTION_TYPE.LANGUAGES_ENTRY,
              height: 0,
              defaultData: {
                languageName: 'Polish',
              },
            },
            {
              sectionId: idGenerator(),
              type: SECTION_TYPE.LANGUAGES_ENTRY,
              height: 0,
              defaultData: {
                languageName: 'German',
              },
            },
          ],
        },
        {
          sectionId: idGenerator(),
          type: SECTION_TYPE.SIDE_PROJECTS_HEADER,
          visible: true,
          height: 0,
          entries: [
            {
              sectionId: idGenerator(),
              type: SECTION_TYPE.SIDE_PROJECTS_ENTRY,
              height: 0,
            },
          ],
        },
        {
          sectionId: idGenerator(),
          type: SECTION_TYPE.CERTIFICATES_HEADER,
          visible: true,
          height: 0,
          entries: [
            {
              sectionId: idGenerator(),
              type: SECTION_TYPE.CERTIFICATES_ENTRY,
              height: 0,
            },
          ],
        },
        {
          sectionId: idGenerator(),
          type: SECTION_TYPE.CUSTOM_SECTION_HEADER,
          visible: true,
          height: 0,
          entries: [
            {
              sectionId: idGenerator(),
              type: SECTION_TYPE.CUSTOM_SECTION_ENTRY,
              height: 0,
            },
          ],
        },
      ],
    },
  };
};
