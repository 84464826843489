import { Chip } from '@mui/material';
import styled from 'styled-components';
import { styled as MUIstyled } from '@mui/material/styles';
import { COLORS } from '../../../../theme/colors';
import { StyledChipProps, LabelProps } from './SeniorityChip.types';

export const StyledChip = MUIstyled(Chip)<StyledChipProps>(
  ({ customColor, customBackground }) => ({
    color: customColor || COLORS.typography.body,
    background: customBackground || COLORS.info.secondary,
    borderRadius: 24,
    height: '22px',
    fontSize: '12px',
    fontFamily: '"Poppins", sans-serif',
    textTransform: 'capitalize',
    padding: '10px 4px',
    overflow: 'hidden',
    '.MuiChip-label': {
      padding: '2px 7px !important',
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 500,
    },
  }),
);

export const LabelContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 6px;
`;

export const LabelItems = styled.div`
  align-items: center;
  display: flex;
  gap: 2px;
`;

export const LabelIcon = styled.span<LabelProps>`
  background-color: ${({ variant }) =>
    variant === 'filled' ? COLORS.accent.primary : COLORS.typography.disabled}}
  border-radius: 6px;
  height: 6px;
  width: 6px;
`;
