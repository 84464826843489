import Button from '@mui/material/Button';
import { styled as materialUIStyled } from '@mui/material/styles';
import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  margin-top: 4px;
`;

export const StyledButton = materialUIStyled(Button)({
  color: 'rgba(0, 0, 0, 0.6)',
  minWidth: '40px',
});
