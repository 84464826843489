import styled from 'styled-components';
import { COLORS } from '../../../theme/colors';

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  width: 100%;
`;

export const StyledTextField = styled.textarea`
  border: 1px solid ${COLORS.accent.border};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${COLORS.typography.main};
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 15px;
  width: 100%;

  &:focus {
    outline-width: 0;
  }

  &::placeholder {
    color: ${COLORS.stroke.secondary};
    font-weight: 400;
  }

  &:disabled {
    opacity: 0.6;
  }
`;
