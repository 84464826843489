import { faMinusCircle, faTasks } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from '@mui/material';
import React, { forwardRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { NEW_EXPERIENCE_DATA_TEMPLATE } from '../../../dataTemplates';
import { pastePlainText } from '../../../Helpers';
import { raiseToast } from '../../../utils/raiseToasts';
import { WithHeightUpdate } from '../../../utils/WithHeightUpdate';
import { StyledEntryContainer } from '../../common/CV/EntryContainer/EntryContainer.styles';
import {
  CURRENT_EXPERIENCE_CONFIG,
  ERR_MSG,
  EXPERIENCE_CONFIG,
  SUB_DATA_TYPE,
} from './ExperienceEntry.config';
import {
  CheckboxWrapper,
  StyledAddSubsectionButtons,
  StyledData,
  StyledDate,
  StyledDateContainer,
  StyledDeleteButton,
  StyledDeleteSubsectionButton,
  StyledError,
  StyledIcon,
  StyledInfo,
  StyledInput,
  StyledLabel,
  StyledLargeHeader,
  StyledLargeTextDiv,
  StyledMinorInfo,
  StyledNumberFormatContainer,
  StyledOldDate,
  StyledPresentLabel,
  StyledSmallHeader,
  StyledTextDiv,
} from './ExperienceEntry.styles';
import { checkPresentDate } from './helpers/checkPresentDate';
import { isTimePeriodValid } from './helpers/isTimePeriodValid';
import i18n from '../../../i18n';

function supplySubsectionAttrs(
  { editable, data, handleEditData, setTextFormattingPanelVisibility },
  subsectionName,
  parent,
) {
  return {
    placeholder: NEW_EXPERIENCE_DATA_TEMPLATE[subsectionName],
    suppressContentEditableWarning: true,
    contentEditable: editable,
    onPaste: (e) => pastePlainText(e),
    onBlur: (e) => {
      if (
        e.currentTarget.innerHTML === '' ||
        e.currentTarget.innerHTML === '<br>'
      ) {
        e.currentTarget.style.opacity = 1;
      }
      setTextFormattingPanelVisibility(false);
      handleEditData(
        {
          [subsectionName]: e.currentTarget.innerHTML,
        },
        parent,
      );
    },
    onFocus: (e) => {
      if (
        e.currentTarget.innerHTML ===
        NEW_EXPERIENCE_DATA_TEMPLATE[subsectionName]
      ) {
        e.currentTarget.style.opacity = 1;
      }
      if (subsectionName === 'responsibilities') {
        setTextFormattingPanelVisibility('bulletable');
      } else {
        setTextFormattingPanelVisibility(true);
      }
    },
    spellCheck: false,
    dangerouslySetInnerHTML: {
      __html: data[subsectionName],
    },
  };
}

const ExperienceEntryComponent = forwardRef(
  (
    {
      handleDrop,
      handleDrag,
      handleDragOver,
      editable,
      data,
      setTextFormattingPanelVisibility,
      handleEditData,
      handleRemoveEntry,
      handleDeleteSubData,
      handleCreateSubData,
      index,
    },
    ref,
  ) => {
    const [focused, setFocused] = React.useState(false);
    const commonProps = {
      data,
      handleEditData,
      editable,
      setTextFormattingPanelVisibility,
    };

    const [isPresentDate, setIsPresentDate] = useState(
      checkPresentDate(data?.timePeriod),
    );
    const [experienceConfig, setExperienceConfig] = useState(
      isPresentDate ? CURRENT_EXPERIENCE_CONFIG : EXPERIENCE_CONFIG,
    );
    const [displayedPeriod, setDisplayedPeriod] = useState(data?.timePeriod);

    const isFormatHandled =
      displayedPeriod?.replace(/\D/g, '').length ===
        experienceConfig.mask.length &&
      isTimePeriodValid(displayedPeriod, isPresentDate);

    const handleStartDateChange = (values) => {
      const { formattedValue } = values;
      if (!formattedValue.includes('Y')) {
        if (formattedValue.substring(0, 2) > 12) {
          raiseToast.error(ERR_MSG);
          setDisplayedPeriod('12' + formattedValue.substring(2));
        } else if (formattedValue.substring(3, 7) < 1950) {
          raiseToast.error(
            i18n.t('raiseToastMessage.invalid_time_period', {
              ns: 'common',
            }),
          );
        } else {
          setDisplayedPeriod(formattedValue);
          handleEditData({
            timePeriod: `${formattedValue} - present`,
          });
        }
      }
    };

    const handleDatePeriodChange = (values) => {
      const { formattedValue } = values;
      if (!formattedValue.includes('Y')) {
        if (formattedValue.substring(0, 2) > 12) {
          raiseToast.error(ERR_MSG);
          setDisplayedPeriod('12' + formattedValue.substring(2));
        } else if (formattedValue.substring(10, 12) > 12) {
          setDisplayedPeriod(
            formattedValue.substring(0, 10) +
              '12' +
              formattedValue.substring(12),
          );
          raiseToast.error(ERR_MSG);
        } else if (
          formattedValue.substring(3, 7) >
            formattedValue.substring(13, formattedValue.length) ||
          (formattedValue.substring(3, 7) ===
            formattedValue.substring(13, formattedValue.length) &&
            formattedValue.substring(0, 2) >= formattedValue.substring(10, 12))
        ) {
          raiseToast.error(
            i18n.t('raiseToastMessage.invalid_time_period_older_end_date', {
              ns: 'common',
            }),
          );
          setDisplayedPeriod(formattedValue.substring(0, 13));
        } else if (
          formattedValue.substring(13, formattedValue.length) < 1950 ||
          formattedValue.substring(3, 7) < 1950
        ) {
          raiseToast.error(
            i18n.t('raiseToastMessage.invalid_time_period', {
              ns: 'common',
            }),
          );
        } else {
          setDisplayedPeriod(formattedValue);
          handleEditData({
            timePeriod: formattedValue,
          });
        }
      }
    };

    return (
      <StyledEntryContainer
        ref={ref}
        draggable={!focused}
        onDragStart={handleDrag}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        paddingTop={index ? 40 : undefined}
      >
        <StyledData>
          <StyledDateContainer>
            {!isFormatHandled && (
              <>
                <StyledError>Date format is incorrect</StyledError>
                <StyledOldDate>{data?.timePeriod}</StyledOldDate>
              </>
            )}
            <StyledDate>
              <StyledNumberFormatContainer present>
                {isPresentDate && (
                  <StyledPresentLabel>Start date:</StyledPresentLabel>
                )}
                <NumberFormat
                  format={experienceConfig.format}
                  placeholder={experienceConfig.placeholder}
                  name="formattedTimePeriod"
                  contentEditable={editable}
                  value={isFormatHandled ? displayedPeriod : ''}
                  onValueChange={(values) =>
                    isPresentDate
                      ? handleStartDateChange(values)
                      : handleDatePeriodChange(values)
                  }
                  customInput={StyledInput}
                  mask={experienceConfig.mask}
                />
              </StyledNumberFormatContainer>
              <CheckboxWrapper>
                {editable && (
                  <>
                    <Checkbox
                      checked={isPresentDate}
                      onChange={() => {
                        handleEditData({
                          timePeriod: !isPresentDate
                            ? `${data?.timePeriod.substring(0, 7)} - present`
                            : `${data?.timePeriod.substring(0, 7)}`,
                        });
                        setExperienceConfig(
                          !isPresentDate
                            ? CURRENT_EXPERIENCE_CONFIG
                            : EXPERIENCE_CONFIG,
                        );
                        setIsPresentDate(!isPresentDate);
                      }}
                    />
                    <StyledLabel>Current place of work</StyledLabel>
                  </>
                )}
              </CheckboxWrapper>
            </StyledDate>
          </StyledDateContainer>
          {editable && (
            <StyledDeleteButton>
              <StyledIcon onClick={handleRemoveEntry}>
                <FontAwesomeIcon icon={faMinusCircle} />
              </StyledIcon>
            </StyledDeleteButton>
          )}
          <StyledInfo>
            {data.position !== undefined && (
              <div>
                <StyledLargeHeader>Position:</StyledLargeHeader>
                <StyledLargeTextDiv
                  {...supplySubsectionAttrs(
                    commonProps,
                    'position',
                    SUB_DATA_TYPE.POSITION,
                  )}
                />
              </div>
            )}
            {data.company !== undefined && (
              <StyledMinorInfo>
                <StyledSmallHeader>Company:</StyledSmallHeader>
                <StyledTextDiv
                  {...supplySubsectionAttrs(
                    commonProps,
                    'company',
                    SUB_DATA_TYPE.COMPANY,
                  )}
                />
              </StyledMinorInfo>
            )}
            {data.responsibilities !== undefined && (
              <StyledMinorInfo>
                <StyledSmallHeader>
                  Main tasks/responsibilities:
                </StyledSmallHeader>
                <StyledTextDiv
                  {...supplySubsectionAttrs(
                    commonProps,
                    'responsibilities',
                    SUB_DATA_TYPE.RESPONSIBILITIES,
                  )}
                />
                {editable && (
                  <StyledDeleteSubsectionButton>
                    <StyledIcon
                      onClick={() =>
                        handleDeleteSubData(SUB_DATA_TYPE.RESPONSIBILITIES)
                      }
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </StyledIcon>
                  </StyledDeleteSubsectionButton>
                )}
              </StyledMinorInfo>
            )}
            {editable && data.responsibilities === undefined && (
              <StyledAddSubsectionButtons>
                <StyledIcon
                  title="Add tasks/responsibilities"
                  onClick={() => {
                    handleCreateSubData({
                      propertyName: 'responsibilities',
                      initialData: '',
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faTasks} />
                </StyledIcon>
              </StyledAddSubsectionButtons>
            )}
          </StyledInfo>
        </StyledData>
      </StyledEntryContainer>
    );
  },
);

export const ExperienceEntry = WithHeightUpdate(ExperienceEntryComponent);
