import { CandidateStatusType } from '../../store/types/Store.candidates.types';

export interface CandidateStatusModel {
  name: CandidateStatusType;
  showedName: string;
  chartColor: string;
  bgColor: string;
}

export const statusesWithReason = ['Lost', 'Rejected', 'LOST', 'REJECTED'];

export const statusOfRequestWithReason = 'LOST';
export const defaultStatusOfNewRequest = 'ACTIVE';

export const priorities = {
  low: 'low',
  medium: 'medium',
  high: 'high',
  veryHigh: 'very high',
};

export const statuses = {
  active: 'ACTIVE',
  won: 'WON',
  lost: 'LOST',
  notActive: 'NOT ACTIVE',
};

export const profileProps = {
  priority: 'priority',
  status: 'lead_profile_status',
};
