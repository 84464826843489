import { HomeColumn } from '../../../../components/common/CollapseTable/CollapseTable.types';

export const ADDITIONAL_COLUMNS = 2;

export const getHomeColumnsAmount = (columns: HomeColumn[]) =>
  columns.length + ADDITIONAL_COLUMNS;

export const getTopFilterOptions = (t: any) => {
  return {
    my: {
      name: 'my',
      match: (el: any) => el.belonging === 'my',
      label: t('my'),
    },
    partner: {
      name: 'partner',
      match: (el: any) => el.belonging !== 'my',
      label: t('partner'),
    },
    all: { name: 'all', label: t('all'), match: () => true },
  };
};

export const searchInColumns = [
  'lead',
  'company',
  'name',
  'hr_owner',
  'sales_owner',
];
