import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_CANDIDATE_REASON_TAGS } from '../../../../config/urls';
import { getAuthHeader, handleResponse } from '../../../../utils/api';

const getCandidateReasonTagsThunk = createAsyncThunk(
  'leads/getCandidateReasonTags',
  async (_, { getState, dispatch }) => {
    const request = fetch(`${API_CANDIDATE_REASON_TAGS}`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);

export const candidateReasonTagsThunks = {
  getCandidateReasonTagsThunk,
};
