import { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SideProjectsEntry } from '../../../../../components/CV/SideProjects/SideProjectsEntry.component';
import { actions as cvActions } from '../../../CV.slice';
import { actions as sideProjectsActions } from '../SideProjects.slice';

export const SideProjectsEntryContainer = forwardRef(
  (
    {
      editable,
      sectionWithParent,
      updateSectionHeight,
      handleDrop,
      handleDragOver,
      handleDrag,
      element,
      removeSectionRef,
      setTextFormattingPanelVisibility,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const {
      sectionIndex,
      section: { sectionId, height },
      parentSection: { parentSectionId },
    } = sectionWithParent;
    const data = useSelector((state) =>
      state.cv.present.data.sideProjects.entries.find(
        (entry) => entry.sectionId === sectionId,
      ),
    )?.data;

    useEffect(() => {
      if (!data) {
        dispatch(sideProjectsActions.createData(sectionId));
      }
    }, [data]);

    const handleEditData = (dataA) => {
      dispatch(
        sideProjectsActions.updateEntryData({
          sectionId,
          data: dataA,
        }),
      );
    };

    const handleCreateSubData = (dataB) => {
      const { propertyName, initialData } = dataB;
      dispatch(
        sideProjectsActions.createSubData({
          sectionId,
          propertyName,
          initialData,
        }),
      );
    };

    const handleDeleteSubData = (propertyName) => {
      dispatch(
        sideProjectsActions.deleteSubData({
          sectionId,
          propertyName,
        }),
      );
    };

    const handleRemoveEntry = () => {
      dispatch(
        cvActions.deleteEntry({
          sectionId,
          parentSectionId,
        }),
      );
      dispatch(sideProjectsActions.deleteData(sectionId));
    };

    return (
      <SideProjectsEntry
        ref={ref}
        sectionId={sectionId}
        removeSectionRef={removeSectionRef}
        element={element}
        editable={editable}
        handleDrag={handleDrag}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        handleEditData={handleEditData}
        isExperienceSectionDisplayed
        updateSectionHeight={updateSectionHeight}
        setTextFormattingPanelVisibility={setTextFormattingPanelVisibility}
        handleRemoveEntry={handleRemoveEntry}
        handleDeleteSubData={handleDeleteSubData}
        handleCreateSubData={handleCreateSubData}
        data={data || {}}
        index={sectionIndex}
        height={height}
      />
    );
  },
);
