import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { ClientLoginPage } from '../containers/ClientList/components/ClientLoginPage/ClientLoginPage';
import { ForbiddenAccess } from '../containers/User/ForbiddenAccess/ForbiddenAccess.component';
import Login from '../containers/User/Login/Login.component';
import { isPartner } from '../Helpers';
import { hasPermission } from './ForbiddenPaths';

export const StandardAuthenticationProvider = ({ Component, ...props }) => {
  const location = useLocation();
  const userState = useSelector((state) => state.user);

  if (!userState.token) {
    return <Login />;
  }

  if (!hasPermission(location.pathname, userState.user)) {
    return <ForbiddenAccess />;
  }

  return <Component authData={userState} {...props} />;
};

export const PublicTokenAuthenticationProvider = ({ Component, ...props }) => {
  const clientCredentials = useSelector(
    (state) => state.clientListRelated.credentials,
  );
  const user = useSelector((state) => state.user.user);
  const params = useParams();
  const clientId = params.id;

  if (
    (clientCredentials && clientCredentials?.id === clientId) ||
    isPartner(user)
  ) {
    return <Component authData={user} {...props} />;
  }

  return <ClientLoginPage authData={user} {...props} />;
};
