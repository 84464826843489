import { Fade } from '@mui/material';
import { useEffect } from 'react';
import { useKeyPress } from '../../Hooks/useKeyPress';
import { StyledCVModal } from './CVModal.styles';
import { CVModalProps } from './CVModal.types';
import { CVModalContainer } from './CVModalContainer/CVModalContainer.component';

export const CVModal = ({
  openModal,
  closeModal,
  cvId,
  injectData = undefined,
}: CVModalProps) => {
  const escPressed = useKeyPress('Escape');
  useEffect(() => {
    escPressed && closeModal();
  }, [escPressed]);

  return (
    <StyledCVModal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="candidates-modal-title"
      aria-describedby="candidates-modal-description"
    >
      <Fade>
        <>
          <CVModalContainer
            openModal={openModal}
            closeModal={closeModal}
            cvId={cvId}
            injectData={injectData}
          />
        </>
      </Fade>
    </StyledCVModal>
  );
};
