import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddButton } from '../../../components/common/Buttons/AddButton';
import { ColorCell } from '../../../components/common/ColorCell/ColorCell.component';
import { Loader } from '../../../components/common/Loader';
import { PartnersModal } from '../../../components/common/Modals/Partners/PartnersModal/PartnersModal.component';
import { Table } from '../../../components/common/Table/Table.component';
import { Tooltip } from '../../../components/common/Tooltip/Tooltip.component';
import { partnerThunks } from '../../../containers/Partner/Partner.thunk';
import { useEffectAsync } from '../../../utils/useEffectAsync';
import { ActionColumn } from './ActionColumn/ActionColumn.component';
import {
  HeadContainer,
  InnerTooltipText,
  StyledPartnerTable,
  StyledStatusBar,
} from './PartnersTable.styles';

const modalTypes = {
  editing: 'editing',
  adding: 'adding',
};

export const PartnersTable = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [modalFor, setModalFor] = useState('');
  const [partnerToEdit, setPartnerToEdit] = useState('');
  const partners = useSelector((state) => state.partner.all_partners);

  useEffectAsync(async () => {
    await dispatch(partnerThunks.getAllPartnersThunk());
    setLoading(false);
  }, []);

  const editPartner = async (newPartnerData, partnerId = partnerToEdit.id) => {
    const result = await dispatch(
      partnerThunks.updatePartnerThunk({
        partnerId,
        payload: newPartnerData,
      }),
    );
    if (result.type === partnerThunks.updatePartnerThunk.fulfilled.type) {
      await dispatch(partnerThunks.getAllPartnersThunk());
      setOpenModal(false);
    }
  };

  const columns = [
    {
      field: 'partner_name',
      headerName: 'Name',
      flex: 2,
      minWidth: 150,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ row: { partner_name } }) => (
        <Tooltip title={partner_name} position="table">
          <InnerTooltipText>{partner_name}</InnerTooltipText>
        </Tooltip>
      ),
    },
    {
      field: 'phone_number',
      flex: 2,
      minWidth: 150,
      headerName: 'Phone number',
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ row: { phone_number } }) =>
        phone_number && (
          <Tooltip title={phone_number} position="table">
            <InnerTooltipText>{phone_number}</InnerTooltipText>
          </Tooltip>
        ),
    },
    {
      field: 'address',
      headerName: 'Address',
      sortable: false,
      flex: 2,
      minWidth: 150,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ row: { address } }) =>
        address && (
          <Tooltip title={address} position="table">
            <InnerTooltipText>{address}</InnerTooltipText>
          </Tooltip>
        ),
    },
    {
      field: 'color',
      headerName: 'Color',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row: { color } }) => <ColorCell color={color} />,
    },
    {
      field: 'hasLogo',
      headerName: 'Logo',
      flex: 1,
      align: 'center',
      sortable: false,
      headerAlign: 'center',
      type: 'boolean',
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      renderCell: ({ value }) => {
        return (
          <ActionColumn
            onEditUserHandler={() => {
              setModalFor(modalTypes.editing);
              setPartnerToEdit(value);
              setOpenModal(true);
            }}
          />
        );
      },
    },
  ];

  const createRow = (partnerData, idx) => {
    return {
      id: idx,
      partner_name: partnerData.partner_name,
      phone_number: partnerData.phone_number,
      address: partnerData.address,
      hasLogo: partnerData.logo !== null,
      color: partnerData.main_color,
      action: partnerData,
    };
  };

  useEffectAsync(async () => {
    await dispatch(partnerThunks.getAllPartnersThunk());
    setLoading(false);
  }, []);

  const handleAdding = () => {
    setModalFor(modalTypes.adding);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const rows = partners?.map((p, i) => createRow(p, i));

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <StyledStatusBar>
        <AddButton action={handleAdding}>Add new partner</AddButton>
      </StyledStatusBar>
      <StyledPartnerTable>
        <HeadContainer>
          <PartnersModal
            modalFor={modalFor}
            openModal={openModal}
            closeModal={handleCloseModal}
            partnerToEdit={partnerToEdit}
            editPartner={editPartner}
          />
        </HeadContainer>
        <Table columns={columns} rows={rows} checkboxSelection={false} />
      </StyledPartnerTable>
    </>
  );
};
