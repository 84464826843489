import { EmployeeTableDataInterface } from '../../../../../store/types/Store.candidates.types';
import { Currency } from '../../../../../store/types/Store.types';

export const LANGUAGE_LEVELS = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
export type LanguageLevel = typeof LANGUAGE_LEVELS[number];

export interface EmployeeFormProps {
  employeeData?: Partial<EmployeeTableDataInterface>;
  supervisorList: string[];
  selectedTechnologies?: string[];
  technologyNames: string[];
  defaultSupervisor?: string;
  formTitle: string;
  submitButtonName: string;
  suggestedSources: string[];
  onFormClose?: () => void;
  onFormSubmit?: (e: EmployeeFormSubmitResult) => void;
}

export interface EmployeeFormSubmitResult {
  first_name: string;
  last_name: string;
  profile: string;
  availability: string;
  rate_of_pay_from: number;
  rate_of_pay_to: number;
  rate_of_pay_currency: Currency;
  supervisor: string;
  source: string;
  technologies?: string[];
  english_level?: LanguageLevel;
  recruitee_link: string;
  technology_tags?: number[];
}
