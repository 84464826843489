import { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomSectionEntry } from '../../../../../components/CV/CustomSection/CustomSectionEntry.component';
import { actions as cvActions } from '../../../CV.slice';
import { actions as customSectionActions } from '../../CustomSection/CustomSection.slice';

export const CustomSectionEntryContainer = forwardRef(
  (
    {
      editable,
      sectionWithParent,
      updateSectionHeight,
      handleDrop,
      handleDragOver,
      handleDrag,
      element,
      removeSectionRef,
      setTextFormattingPanelVisibility,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const {
      sectionIndex,
      section: { sectionId, height },
      parentSection: { sectionId: parentSectionId },
    } = sectionWithParent;
    const parentEntries = useSelector((state) =>
      state.cv.present.data.customSections.entries.find(
        (entry) => entry.sectionId === parentSectionId,
      ),
    )?.entries;
    const data = parentEntries?.find((entry) => entry.sectionId === sectionId);

    useEffect(() => {
      if (!data) {
        dispatch(
          customSectionActions.createEntryData({ sectionId, parentSectionId }),
        );
      }
    }, [data]);

    const handleEditData = (dataP) => {
      dispatch(
        customSectionActions.updateEntryData({
          sectionId,
          parentSectionId,
          data: dataP,
        }),
      );
    };

    const handleCreateInnerEntry = () => {
      dispatch(
        customSectionActions.createInnerEntry({
          parentSectionId,
          sectionId,
        }),
      );
    };

    const handleDeleteInnerEntry = (entryId) => {
      dispatch(
        customSectionActions.deleteInnerEntry({
          parentSectionId,
          sectionId,
          entryId,
        }),
      );
    };

    const handleEditInnerEntryData = (entryId, dataA) => {
      dispatch(
        customSectionActions.editInnerEntry({
          parentSectionId,
          sectionId,
          entryId,
          data: dataA,
        }),
      );
    };

    const handleRemoveEntry = () => {
      dispatch(
        cvActions.deleteEntry({
          sectionId,
          parentSectionId,
        }),
      );
      dispatch(
        customSectionActions.deleteData({
          sectionId,
          parentSectionId,
        }),
      );
    };

    return (
      <CustomSectionEntry
        ref={ref}
        sectionId={sectionId}
        removeSectionRef={removeSectionRef}
        element={element}
        editable={editable}
        handleDrag={handleDrag}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        handleEditData={handleEditData}
        updateSectionHeight={updateSectionHeight}
        setTextFormattingPanelVisibility={setTextFormattingPanelVisibility}
        handleRemoveEntry={handleRemoveEntry}
        handleEditInnerEntryData={handleEditInnerEntryData}
        handleCreateInnerEntry={handleCreateInnerEntry}
        handleDeleteInnerEntry={handleDeleteInnerEntry}
        data={data || {}}
        index={sectionIndex}
        height={height}
      />
    );
  },
);
