import React from 'react';
import {
  StyledFooter,
  StyledFooterInnerBox,
  StyledPageNumber,
} from './Footer.styles';

export const Footer = ({ page, nPages }) => {
  return (
    <StyledFooter>
      <StyledFooterInnerBox>
        <div />
        <div>
          <StyledPageNumber>{page}</StyledPageNumber> / {nPages}
        </div>
      </StyledFooterInnerBox>
    </StyledFooter>
  );
};
