import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: 'Neue Haas Grotesk Display Pro';
  }

  body {
    background: white;
  }
`;

export const StyledContainer = styled.div`
  align-items: center;
  background: white;
  border-radius: 10px;
  margin: 0px;
  max-height: 100vh;
  min-width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0a0a0;
    border-radius: 25px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 0px 25px 25px 0px;
  }
`;

export const StyledLabelWrapper = styled.div`
  background: white;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  height: 62px;
  justify-content: space-between;
  padding: 24px 34px 0px 34px;
  position: sticky;
  top: 0px;
  z-index: 1;
`;

export const StyledFormTitle = styled.h3`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  overflow: hidden;
  padding-bottom: 12px;
  padding-right: 6px;
  text-overflow: ellipsis;
`;

export const CloseContainer = styled.div`
  padding-left: 12px;

  & .MuiButtonBase-root {
    margin-bottom: 5px;
    padding: 0px;
  }
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const StyledFormWrapper = styled.div`
  background: white;
  padding: 12px 34px 8px 34px;
`;

export const StyledButtonsContainer = styled.div`
  background: #f4f5f7;
  border-radius: 0px 0px 10px 10px;
  bottom: 0px;
  height: 68px;
  padding: 14px 24px;
  position: sticky;

  @media (min-width: 700px) {
    flex-direction: row-reverse;
  }

  @media (max-width: 699px) {
    flex-direction: column;
  }
`;

export const StyledArrowIcon = styled(ArrowForwardIosIcon)`
  font-size: 15px;
  margin: 5px 5px 0px 0px;
`;
