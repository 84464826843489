import { PartnerForm } from '../../../../Partner/PartnerForm/PartnerForm.component';

export const EditingModalContent = ({
  handleSubmit,
  closeModal,
  partnerToEdit,
}) => (
  <PartnerForm
    handleSubmit={handleSubmit}
    closeModal={closeModal}
    partnerToEdit={partnerToEdit}
  />
);
