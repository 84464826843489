import { createSlice } from '@reduxjs/toolkit';
import { HrList } from '../../../../store/types/Store.hrList.types';
import { hrListsThunks } from './HrLists.thunk';

const { getHrListsThunk } = hrListsThunks;

const initialState: HrList[] = [];

const hrListsSlice = createSlice({
  name: 'hrLists',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHrListsThunk.fulfilled, (state, action) => {
      state.length = 0;
      action.payload.forEach((data: HrList) => {
        state.push({
          id: data.id,
          created_at: data.created_at,
          updated_at: data.updated_at,
          cvs: data.cvs,
          notes: data.notes,
          created_by_username: data.created_by_username,
        });
      });
    });
  },
});

export const actions = { ...hrListsSlice.actions };
export const { reducer } = hrListsSlice;
