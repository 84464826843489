import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  height: 90%;
  margin-top: 50px;
  overflow: auto;
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  color: white;
  cursor: pointer;
  font-size: 30px;
  position: fixed;
  right: 50%;
  top: 10px;
`;
