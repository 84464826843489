import { priorityLabels } from '../../PriorityIndicator/config';
import { Tooltip } from '../../Tooltip/Tooltip.component';
import { IconWrapper, Mark } from './PriorityIcon.styles';
import { PriorityIconProps } from './PriorityIcon.types';

export const PriorityIcon = ({ priority }: PriorityIconProps) => {
  const exclamationMarkNum = Object.keys(priorityLabels).findIndex(
    (prop) => prop === priority,
  );
  return (
    <Tooltip
      title={`${priority[0].toUpperCase() + priority.slice(1)} priority`}
      position="regular"
    >
      <IconWrapper>
        <Mark>{'!'.repeat(exclamationMarkNum + 1)}</Mark>
      </IconWrapper>
    </Tooltip>
  );
};
