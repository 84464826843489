import { createSlice } from '@reduxjs/toolkit';
import { ClientList } from '../../../store/types/Store.clientList.types';
import { clientListsThunks } from './ClientList.thunk';

const {
  getClientListsThunk,
  getPartnerListsThunk,
  getExternalClientListDetailsThunk,
} = clientListsThunks;

const initialState: ClientList[] = [];

const clientListSlice = createSlice({
  name: 'clientList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClientListsThunk.fulfilled, (state, action) => {
      state.length = 0;
      action.payload.forEach((data: ClientList) => {
        state.push({
          cvs: data.cvs,
          id: data.id,
          name: data.name,
          notes: data.notes,
          shared_at: data.shared_at,
          owner: data.owner,
          owner_name: data.owner_name,
          status: data.status,
          rate_of_pay_currency: data.rate_of_pay_currency,
          related_hr_list: data.related_hr_list,
          related_partner: data.related_partner,
          opened_at: data.opened_at,
          updated_at: data.updated_at,
          token: data.token,
        });
      });
    });
    builder.addCase(getPartnerListsThunk.fulfilled, (state, action) => {
      state.length = 0;
      action.payload.forEach((data: ClientList) => {
        state.push({
          cvs: data.cvs,
          id: data.id,
          name: data.name,
          notes: data.notes,
          shared_at: data.shared_at,
          owner: data.owner,
          owner_name: data.owner_name,
          status: data.status,
          rate_of_pay_currency: data.rate_of_pay_currency,
          related_hr_list: data.related_hr_list,
          related_partner: data.related_partner,
          opened_at: data.opened_at,
          updated_at: data.updated_at,
          token: data.token,
        });
      });
    });
    builder.addCase(
      getExternalClientListDetailsThunk.fulfilled,
      (state, action) => {
        state.length = 0;
        state.push(action.payload);
      },
    );
  },
});

export const actions = { ...clientListSlice.actions };
export const { reducer } = clientListSlice;
