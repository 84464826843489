import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { Sidebar } from '../../../components/CV/Sidebar/Sidebar.component';
import { AppPaths } from '../../../config/AppPaths';
import { useEffectAsync } from '../../../utils/useEffectAsync';
import { employeeThunks } from '../../Employee/Employees.thunk';
import { SECTION_TYPE } from '../CV.config';
import { sectionTypeToComponent } from '../CV.options';
import { actions as cvActions } from '../CV.slice';
import { cvThunks } from '../CV.thunk';
// eslint-disable-next-line import/no-cycle
import { PdfDownloader } from '../PdfDownloader/PdfDownloader.component';

export const sanitizeSectionConfig = ({ height, defaultData, ...entry }) => {
  return {
    ...entry,
    entries: entry.entries?.map(sanitizeSectionConfig),
  };
};

export const SidebarContainer = ({ sectionConfig, cvId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const employeeContext = useSelector(
    (state) => state.cv.present.config.employeeContext,
  );
  const sectionData = useSelector((state) => state.cv.present.data);
  const textFormattingPanelVisible = useSelector(
    (state) => state.cv.present.config.textFormattingPanelVisible,
  );
  const intervalRef = useRef();
  const [downloadFinished, setDownloadFinished] = useState(true);
  const employeeData = useSelector((state) => state.employeePage.data.details);
  const cvProfileName = useSelector(
    (state) => state.cv.present.data?.position?.headerText,
  );

  useEffect(() => {
    intervalRef.current = setTimeout(saveCv, 8 * 1000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [sectionData, sectionConfig]);

  useEffect(() => {
    document.addEventListener('keydown', handleUndo);
    document.addEventListener('keydown', handleRedo);
    document.addEventListener('keydown', () =>
      clearInterval(intervalRef.current),
    );

    return () => {
      document.removeEventListener('keydown', handleUndo);
      document.removeEventListener('keydown', handleRedo);
    };
  }, [textFormattingPanelVisible]);

  useEffectAsync(async () => {
    await dispatch(
      employeeThunks.getEmployeeThunk({
        employeeId: employeeContext.employeeId,
      }),
    );
  }, []);

  const downloadPdf = () => {
    setDownloadFinished(false);
  };

  const saveCv = async () => {
    const payload = {
      editor_config: sanitizeSectionConfig(sectionConfig).entries,
      editor_content: sectionData,
      employee: employeeContext.employeeId,
      cvId,
    };
    if (!cvId) {
      const result = await dispatch(cvThunks.saveNewCvThunk(payload)).unwrap();
      navigate(generatePath(AppPaths.employeeCvId, { id: result.id }));
      return;
    }

    const result = await dispatch(cvThunks.updateCvThunk(payload));
    if (result.type === cvThunks.updateCvThunk.fulfilled.type) {
      clearInterval(intervalRef.current);
    }
  };

  const handleUndo = (event) => {
    const { ctrlKey, metaKey, shiftKey, key } = event;
    if (
      (!(ctrlKey && key === 'z') && !(metaKey && !shiftKey && key === 'z')) ||
      textFormattingPanelVisible
    ) {
      return;
    }

    if (
      ((ctrlKey && key === 'z') || (metaKey && key === 'z')) &&
      !textFormattingPanelVisible
    ) {
      event.preventDefault();
    }
    dispatch(cvActions.undo());
  };

  // eslint-disable-next-line complexity
  const handleRedo = (event) => {
    const { ctrlKey, shiftKey, metaKey, key } = event;
    if (
      (!(ctrlKey && shiftKey && key === 'Z') &&
        !(metaKey && shiftKey && key === 'z') &&
        !(metaKey && key === 'y')) ||
      textFormattingPanelVisible
    ) {
      return;
    }

    if (
      ((ctrlKey && shiftKey && key === 'Z') ||
        (metaKey && shiftKey && key === 'z') ||
        (metaKey && key === 'y')) &&
      !textFormattingPanelVisible
    ) {
      event.preventDefault();
    }

    dispatch(cvActions.redo());
  };

  const rootSections = sectionConfig.entries.map((section) => {
    const { dataPropName, displayName } = sectionTypeToComponent.get(
      section.type,
    );
    let targetDisplayName;
    const isCustom = section.type === SECTION_TYPE.CUSTOM_SECTION_HEADER;
    if (isCustom) {
      const data = sectionData[dataPropName].entries.find(
        (customSection) => customSection.sectionId === section.sectionId,
      );
      targetDisplayName = data?.headerText;
    } else {
      targetDisplayName = displayName;
    }

    return {
      sectionId: section.sectionId,
      visible: section.visible,
      displayName: targetDisplayName,
      isCustom,
    };
  });

  const addCustomSection = () => {
    dispatch(
      cvActions.addSection({
        sectionType: SECTION_TYPE.CUSTOM_SECTION_HEADER,
        entries: [
          {
            sectionType: SECTION_TYPE.CUSTOM_SECTION_ENTRY,
          },
        ],
      }),
    );
  };

  const deleteCustomSection = (sectionId) => {
    dispatch(
      cvActions.deleteSection({
        sectionId,
      }),
    );
  };

  const toggleSectionVisibility = (sectionId) => {
    dispatch(
      cvActions.toggleVisibility({
        sectionId,
      }),
    );
  };

  return (
    <>
      {!downloadFinished && (
        <PdfDownloader
          cvId={cvId}
          setDownloadFinished={setDownloadFinished}
          fileName={`${employeeData.first_name}-${
            cvProfileName || employeeData?.profile
          }-${employeeData.readable_id || 'CV'}`}
        />
      )}
      <Sidebar
        downloadPdf={downloadPdf}
        message={!cvId ? 'New CV of:' : 'Updating CV of:'}
        employeeContext={employeeContext}
        rootSections={rootSections}
        saveCv={saveCv}
        addCustomSection={addCustomSection}
        deleteCustomSection={deleteCustomSection}
        toggleSectionVisibility={toggleSectionVisibility}
        textFormattingPanelVisible={textFormattingPanelVisible}
      />
    </>
  );
};
