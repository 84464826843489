import { StyledNumber } from './Counter.styles';

type Props = {
  number?: number;
  color?: string;
};

export function Counter({ number, color }: Props) {
  return <StyledNumber color={color}>{number}</StyledNumber>;
}
