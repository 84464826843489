import { PropsWithChildren } from 'react';
import { ButtonWrapper, StyledAddIcon } from './AddButton.styles';
import { AddButtonProps } from './AddButton.types';

export function AddButton({
  variant = 'primary',
  children,
  action,
  marginLeft,
  marginRight,
  disabled = false,
}: PropsWithChildren<AddButtonProps>) {
  return (
    <ButtonWrapper
      variant={variant}
      marginLeft={marginLeft}
      marginRight={marginRight}
      onClick={action}
      disabled={disabled}
    >
      <StyledAddIcon />
      {children}
    </ButtonWrapper>
  );
}
