import TextField from '@mui/material/TextField';
import { addDotsToString } from '../../../Helpers';
import { FormLabel } from '../../common/FormLabel/FormLabel';
import { Tooltip } from '../../common/Tooltip/Tooltip.component';
import {
  StyledAutoComplete,
  StyledChip,
  StyledInputContainer,
  StyledSpan,
} from './MainTechnologiesAutocomplete.styles';

const stringLimit = 36;

export const MainTechnologiesAutocomplete = ({
  options,
  label,
  name,
  handleInputChange,
  value,
  ...props
}) => {
  return (
    <StyledInputContainer>
      {label && <FormLabel htmlFor={name} label={label} />}
      <StyledAutoComplete
        multiple
        label="Main technologies"
        placeholder="..."
        name="technologies"
        value={value}
        options={options}
        getOptionLabel={(option) => option.name}
        limitTags={3}
        onChange={handleInputChange}
        renderTags={(val, getTagProps) =>
          val.map((option, index) => (
            <StyledChip
              key={index}
              {...getTagProps({ index })}
              label={option.name}
            />
          ))
        }
        renderOption={(prop, option) => {
          return (
            <>
              {option.name?.length > stringLimit ? (
                <Tooltip title={option.name} position="close">
                  <StyledSpan {...prop}>
                    {addDotsToString(option.name, stringLimit)}
                  </StyledSpan>
                </Tooltip>
              ) : (
                <StyledSpan {...prop}>{option.name}</StyledSpan>
              )}
            </>
          );
        }}
        isOptionEqualToValue={(option, valueA) => option.name === valueA.name}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            maxRows={3}
            name="technologies"
            placeholder="..."
            multiline
          />
        )}
        disableCloseOnSelect
        {...props}
        noOptionsText={<StyledSpan>No option</StyledSpan>}
      />
    </StyledInputContainer>
  );
};
