import WarningIcon from '@mui/icons-material/Warning';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  margin: 100px auto;
`;

export const StyledText = styled.div`
  display: inline-block;
`;

export const StyledWarningIcon = styled(WarningIcon)`
  font-size: 80px;
`;
