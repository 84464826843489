import { useContext, useEffect, useState } from 'react';
import { statusesWithReason } from '../../../../../containers/Leads/Leads.config';
import { VacanciesPageContext } from '../../../../../containers/Vacancies/VacanciesPageHrSales/VacanciesPageContext/VacanciesPageContext';
import { buttonsColors } from '../../../../common/Button/Button.config';
import { DropDownIcon } from '../../../../common/Icons/DropDownIcon/DropDownIcon.component';
import {
  CheckMark,
  MenuPaperProps,
  StyledMenu,
  StyledMenuItem,
} from './StatusMenu.styles';
import { StatusMenuProps } from './StatusMenu.types';
import { useLeadsConfig } from '../../../../../containers/Leads/useLeadsConfig';

export const StatusMenu = ({
  currentStatus,
  setChangeStatusInProgress,
  changeStatus,
  leadProfile,
  suggestedCandidate,
}: StatusMenuProps) => {
  const { candidateStatuses } = useLeadsConfig();
  const [anchorEl, setAnchorEl] = useState();
  const { handleShowChangeStatusWithReason } = useContext(VacanciesPageContext);

  const handleShowDropDown = (event: React.BaseSyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setChangeStatusInProgress(false);
  }, [currentStatus]);

  const handleChangeStatusRequest = (e: React.BaseSyntheticEvent) => {
    setChangeStatusInProgress(true);
    const newStatus = e.target.innerHTML;
    if (statusesWithReason.includes(newStatus)) {
      handleShowChangeStatusWithReason(
        currentStatus,
        newStatus,
        suggestedCandidate,
        leadProfile,
      );
    } else {
      changeStatus(newStatus);
    }
    setAnchorEl(undefined);
  };
  return (
    <>
      <DropDownIcon
        action={handleShowDropDown}
        style={{ color: buttonsColors.grey, fontSize: '14px' }}
        title="Change status"
      />
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={MenuPaperProps}
      >
        {candidateStatuses.map((status, index) => (
          <StyledMenuItem
            key={`${index}-${status.name}`}
            onClick={handleChangeStatusRequest}
            bgColor={
              status.name === currentStatus ? status.chartColor : status.bgColor
            }
            isActive={status.name === currentStatus}
          >
            {status.showedName}
            {status.name === currentStatus && <CheckMark />}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};
