import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_HOME_PAGE, API_HOME_PAGE_STATS } from '../../config/urls';
import { HomeLeadProfile } from '../../store/types/Store.home.types';
import { Stats } from '../../store/types/Store.stats.types';
import { getAuthHeader, handleResponse } from '../../utils/api';
import { generateLeadRequestCandidatesUrl } from '../../utils/urlGenerators';

const getHomePageDataThunk = createAsyncThunk<HomeLeadProfile>(
  'home/getHomePageData',
  async (_data, { getState, dispatch }) => {
    const request = fetch(`${API_HOME_PAGE}my-vacancies/?company_filter=2`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const getHomePageStatsThunk = createAsyncThunk<Stats>(
  'home/getHomePageStats',
  async (_data, { getState, dispatch }) => {
    const request = fetch(API_HOME_PAGE_STATS, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const getHomeCandidatesThunk = createAsyncThunk(
  'home/getLeadRequestCandidates',
  async (
    data: { leadId: number; requestId: number | number },
    { getState, dispatch },
  ) => {
    const URL = generateLeadRequestCandidatesUrl(
      data.leadId,
      data.requestId as unknown as number,
    );
    const request = fetch(`${URL}`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);

export const vacanciesPageThunks = {
  getHomePageDataThunk,
  getHomePageStatsThunk,
  getHomeCandidatesThunk,
};
