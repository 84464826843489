import { excludeAction } from 'redux-undo';
import { actions as cvActions } from '../containers/CV/CV.slice';
import { actions as technologiesActions } from '../containers/CV/Sections/Technologies/Technologies.slice';
import { actions as languagesActions } from '../containers/CV/Sections/Languages/Languages.slice';
import { actions as educationActions } from '../containers/CV/Sections/Education/Education.slice';
import { actions as positionActions } from '../containers/CV/Sections/Position/Position.slice';
import { actions as additionalInfoActions } from '../containers/CV/Sections/AdditionalInfo/AdditionalInfo.slice';
import { actions as sideProjectsActions } from '../containers/CV/Sections/SideProjects/SideProjects.slice';
import { actions as customSectionActions } from '../containers/CV/Sections/CustomSection/CustomSection.slice';
import { actions as experienceActions } from '../containers/CV/Sections/Experience/Experience.slice';
import { idGenerator } from '../utils/idGenerator';
import { cvThunks } from '../containers/CV/CV.thunk';

const startingActions = [
  cvActions.addEntry.type,
  cvActions.addSection.type,
  cvActions.deleteEntry.type,
];

const followUpActions = [
  technologiesActions.createTechnologyData.type,
  technologiesActions.createTechnologyGroupData.type,
  languagesActions.createData.type,
  educationActions.createData.type,
  positionActions.createData.type,
  additionalInfoActions.createData.type,
  sideProjectsActions.createData.type,
  customSectionActions.createData.type,
  customSectionActions.createEntryData.type,
  experienceActions.createData.type,

  technologiesActions.deleteData.type,
  languagesActions.deleteData.type,
  educationActions.deleteData.type,
  positionActions.deleteData.type,
  sideProjectsActions.deleteData.type,
  customSectionActions.deleteData.type,
  customSectionActions.deleteData.type,
  experienceActions.deleteData.type,
];

export const undoableConfig = {
  limit: 10,
  undoType: 'cv/undo',
  redoType: 'cv/redo',
  clearHistoryType: 'cv/clearHistory',
  filter: excludeAction([
    cvActions.updateSectionHeight.type,
    cvActions.setEmployeeContext.type,
    cvActions.setTextFormattingPanelVisibility.type,
    cvThunks.loadCvThunk.fulfilled.type,
  ]),
  groupBy: (action, currentState, previousHistory) => {
    if (startingActions.includes(action.type)) {
      return `group-${idGenerator()}`;
    }

    if (
      previousHistory?.group?.includes('group') &&
      !followUpActions.includes(action.type)
    ) {
      return null;
    }

    if (previousHistory?.group?.includes('group')) {
      return previousHistory.group;
    }
  },
  syncFilter: true,
};
