import { AnyAction } from '@reduxjs/toolkit';
import React, { PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { leadDetailsThunks } from '../../../../../containers/Leads/redux/LeadDetails.thunk';
import { leadsListThunks } from '../../../../../containers/Leads/redux/LeadsList.thunk';
import { Lead, Tag } from '../../../../../store/types/Store.leads.types';
import { AppStore } from '../../../../../store/types/Store.types';
import { LeadForm } from '../../../../Lead/LeadForm/LeadForm';
import { buttonsColors } from '../../../Button/Button.config';
import { EditingModalContentProps } from './EditingModalContent.types';

export const EditingModalContent = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<EditingModalContentProps>
>(({ closeModal, leadIdToEdit }, ref) => {
  const { t } = useTranslation(['common', 'company'], { keyPrefix: 'buttons' });
  const dispatch = useDispatch();
  const companyTags = useSelector<AppStore, Tag[]>(
    (state) => state.leadsRelated.tags.lead,
  )?.map((tag) => tag.name);
  const industryTags = useSelector<AppStore, Tag[]>(
    (state) => state.leadsRelated.tags.industry,
  )?.map((tag) => tag.name);
  const leadFetched = useSelector<AppStore, Lead | undefined>((state) =>
    state.leadsRelated.list.find((elem) => elem.id === leadIdToEdit),
  );

  const handleSubmit = async (lead: Partial<Lead>) => {
    const result = await dispatch(
      leadsListThunks.updateLeadThunk({
        id: leadIdToEdit,
        name: lead.name,
        industry: lead.industry,
        website: lead.website,
        sales_owner: lead.sales_owner?.id,
      }) as unknown as AnyAction,
    );

    if (result.type === leadsListThunks.updateLeadThunk.fulfilled.type) {
      dispatch(
        leadDetailsThunks.getLeadThunk(leadIdToEdit) as unknown as AnyAction,
      );
      dispatch(leadsListThunks.getLeadsThunk() as unknown as AnyAction);
      closeModal();
    }
  };

  const getButtonsConfig = (lead: Partial<Lead>) => {
    return {
      primaryButton: {
        label: t('save'),
        color: buttonsColors.default,
        action: () => handleSubmit(lead),
      },
    };
  };

  return (
    <LeadForm
      ref={ref}
      dialogTitle={t('projects.edit_project', {
        ns: 'company',
        keyPrefix: 'company_details',
      })}
      getButtonsConfig={getButtonsConfig}
      handleClose={closeModal}
      companyTags={companyTags}
      industryTags={industryTags}
      leadFetched={leadFetched}
    />
  );
});
