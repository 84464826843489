import { createSlice } from '@reduxjs/toolkit';
import { ClientListProfile } from '../../../store/types/Store.clientList.types';
import { clientListProfileThunks } from './ClientListProfile.thunks';

export const {
  getClientListProfiles,
  getExternalClientListProfiles,
  getPartnerListProfiles,
  getClientListProfile,
} = clientListProfileThunks;

const initialState: ClientListProfile[] = [];

const clientListsProfileSlice = createSlice({
  name: 'clientListProfiles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClientListProfiles.fulfilled, (state, action) => {
      state.length = 0;
      action.payload.forEach((data: any) => {
        state.push({
          cv: data.cv,
          client_list_id: data.client_list,
          created_at: data.created_at,
          technologies:
            data.technology_tags.length === 0 ? null : data.technology_tags,
          rate_of_pay_to: data.rate_of_pay_to,
          rate_of_pay_currency: data.rate_of_pay_currency,
          confirmed_by_client: data.confirmed_by_client,
          history_exists: data.history_exists,
          archived: data?.archived,
          notes: data.notes,
          status: data.status,
          technology_tags: data.technology_tags,
        });
      });
    });
    builder.addCase(
      getExternalClientListProfiles.fulfilled,
      (state, action) => {
        state.length = 0;
        action.payload.forEach((data: ClientListProfile) => {
          state.push({
            cv: data.cv,
            client_list_id: data.client_list_id,
            created_at: data.created_at,
            rate_of_pay_to: data.rate_of_pay_to,
            rate_of_pay_currency: data.rate_of_pay_currency,
            confirmed_by_client: data.confirmed_by_client,
            history_exists: data.history_exists,
            archived: data?.archived,
            notes: data.notes,
            status: data.status,
            technology_tags: data.technology_tags,
          });
        });
      },
    );
    builder.addCase(getPartnerListProfiles.fulfilled, (state, action) => {
      state.length = 0;
      action.payload.forEach((data: any) => {
        state.push({
          cv: data.cv,
          client_list_id: data.client_list,
          created_at: data.created_at,
          technologies: data.technology_tags,
          rate_of_pay_to: data.rate_of_pay_to,
          rate_of_pay_currency: data.rate_of_pay_currency,
          confirmed_by_client: data.confirmed_by_client,
          history_exists: data.history_exists,
          archived: data?.archived,
          notes: data.notes,
          status: data.status,
          technology_tags: data.technology_tags,
        });
      });
    });
  },
});

export const actions = { ...clientListsProfileSlice.actions };
export const { reducer } = clientListsProfileSlice;
