import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button } from '../../../../../components/common/Button/Button.component';
import { DetailSmallButton } from '../../../../../components/common/Buttons/DetailSmallButton/DetailSmallButton.component';
import { AppPaths } from '../../../../../config/AppPaths';
import { generateLinkToLeadDetails } from '../helpers/generateLinkToLeadDetails';
import { StyledLink } from './ExpandableRowLink.styles';
import { ExpandableRowLinkProps } from './ExpandableRowLink.types';

const MOBILE_START_SIZE = 700;

export const ExpandableRowLink = ({
  leadId,
  leadProfileId,
}: ExpandableRowLinkProps) => {
  const { pathname } = useLocation();
  const { t } = useTranslation(['user', 'common'], {
    keyPrefix: 'my_profile.projects',
  });
  return (
    <StyledLink
      className="link"
      to={generateLinkToLeadDetails(leadId, leadProfileId)}
      state={{ prevPath: pathname || AppPaths.vacancies }}
    >
      {window.innerWidth < MOBILE_START_SIZE ? (
        <DetailSmallButton />
      ) : (
        <Button>{t('view_detail')}</Button>
      )}
    </StyledLink>
  );
};
